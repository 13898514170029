import { createAsyncAction } from '../../../../shared/state/createAsyncAction';
import type { IServiceBaseParams } from '../../../../shared/types/ServiceBaseParams';
import type { IRegularizationSubject } from '../../entities/RegularizationSubject';
import { regularizationSubjectGetAllService } from '../../services/RegularizationSubjectGetAllService';

/**
 * Retrieves all regularization subjects.
 *
 * @param {IRegularizationSubject[]} payload - The regularization subjects to be retrieved.
 * @param {IServiceBaseParams} params - Base service parameters for the retrieval.
 * @returns {Promise<void>} - A promise that resolves to an array of all regularization subjects.
 */
export const regularizationSubjectGetAll = createAsyncAction<IRegularizationSubject[], IServiceBaseParams>(
	'regularizationSubject/getAll',
	regularizationSubjectGetAllService,
);
