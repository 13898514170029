import { createSyncAction } from '@crac/core/modules/shared/state/createAction';
import { createAsyncAction } from '@crac/core/modules/shared/state/createAsyncAction';

import type { IWorkshopAssignment } from '../../entities/WorkshopAssignment';
import type { IWorkshopAssignmentDeleteParams } from '../../services/WorkshopAssignmentDeleteService';
import { workshopAssignmentDeleteService } from '../../services/WorkshopAssignmentDeleteService';
import type { IWorkshopAssignmentInsertParams } from '../../services/WorkshopAssignmentInsertService';
import { workshopAssignmentInsertService } from '../../services/WorkshopAssignmentInsertService';
import type { IWorkshopAssignmentModifyParams } from '../../services/WorkshopAssignmentModifyService';
import { workshopAssignmentModifyService } from '../../services/WorkshopAssignmentModifyService';
import type { IWorkshopAssignmentSearchParams } from '../../services/WorkshopAssignmentSearchService';
import { workshopAssignmentSearchService } from '../../services/WorkshopAssignmentSearchService';

export const workshopAssignmentSearch = createAsyncAction<IWorkshopAssignment[], IWorkshopAssignmentSearchParams>(
	'workshop/assignment/search',
	workshopAssignmentSearchService,
);

export const workshopAssignmentClear = createSyncAction('workshop/assignment/clear');

export const workshopAssignmentInsert = createAsyncAction<IWorkshopAssignment, IWorkshopAssignmentInsertParams>(
	'workshop/assignment/insert',
	workshopAssignmentInsertService,
);

export const workshopAssignmentModify = createAsyncAction<IWorkshopAssignment, IWorkshopAssignmentModifyParams>(
	'workshop/assignment/modify',
	workshopAssignmentModifyService,
);

export const workshopAssignmentDelete = createAsyncAction<IWorkshopAssignment, IWorkshopAssignmentDeleteParams>(
	'workshop/assignment/delete',
	workshopAssignmentDeleteService,
);
