import { BudgetEndPoints } from './BudgetEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../../shared/types/ServiceResponse';
import type { IBudget } from '../entities/Budget';

export interface IBudgetChangeWorkTypeParams extends IServiceBaseParams {
	budgetId: number;
}

/**
 * Change workType of the budget
 * @param `budgetId` number
 * @returns IBudget
 */
export const budgetChangeWorkTypeService = (model: IBudgetChangeWorkTypeParams): Promise<ServiceResponse<IBudget>> => {
	return Api.post<IBudget, IBudgetChangeWorkTypeParams>(BudgetEndPoints.CHANGE_WORK_TYPE, model);
};
