import { SupplierLocationEndpoints } from './SupplierLocationEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ISupplierLocation } from '../entities/SupplierLocation';

export interface ISupplierLocationDeleteParams extends IServiceBaseParams {
	id: number;
}

export const supplierLocationDeleteService = (model: ISupplierLocationDeleteParams) =>
	Api.post<ISupplierLocation, ISupplierLocationDeleteParams>(SupplierLocationEndpoints.DELETE, model);
