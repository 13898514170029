import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

import { VehicleColorEndPoints } from './FleetVehicleColorEndpoints';
import type { IVehicleColor } from '../entities/VehicleColor';

export interface IFleetVehicleColorSearchParams extends IServiceBaseParams {
	provider: string;
	code?: string;
	name?: string;
}

export const fleetVehicleColorSearchService = (model: IFleetVehicleColorSearchParams) => {
	return Api.get<IVehicleColor[], IFleetVehicleColorSearchParams>(VehicleColorEndPoints.SEARCH, model);
};
