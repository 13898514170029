import { createSyncAction } from '../../../../shared/state/createAction';
import { createAsyncAction } from '../../../../shared/state/createAsyncAction';
import type { IBudgetLine } from '../../entities/BudgetLine';
import type { IBudgetLineGetByBudgetAndVehicleParams } from '../../services/BudgetLineGetByBudgetAndVehicleService';
import { budgetLineGetByBudgetAndVehicleService } from '../../services/BudgetLineGetByBudgetAndVehicleService';

/**
 * Get budget lines by budget and vehicle.
 * @param {IBudgetLine[]} payload - The budget lines.
 * @param {IBudgetLineGetByBudgetAndVehicleParams} params - The parameters to get the budget lines.
 * @returns {Promise<void>} - A promise that resolves to an array of budget lines.
 */
export const budgetLineGetByBudgetAndVehicle = createAsyncAction<IBudgetLine[], IBudgetLineGetByBudgetAndVehicleParams>(
	'budgetLine/getByBudgetAndVehicle',
	budgetLineGetByBudgetAndVehicleService,
);

/**
 * Clear the budget lines.
 * @returns {void}
 */
export const budgetLineClear = createSyncAction('budgetLine/clear');
