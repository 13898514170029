import { MetaModelEndpoints } from './MetaModelEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IMetaModel } from '../entities/MetaModel';

export interface IMetaModelSearchParams extends IServiceBaseParams {
	id?: number;
	name?: string;
	brand?: string;
	modelYear?: number;
}

export const metaModelSearchService = (model: IMetaModelSearchParams) => {
	return Api.get<IMetaModel[], IMetaModelSearchParams>(MetaModelEndpoints.SEARCH, model);
};
