import { BudgetEndPoints } from './BudgetEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../../shared/types/ServiceResponse';
import type { IBudget } from '../entities/Budget';

export interface IBudgetGetByVehicleAndIdParams extends IServiceBaseParams {
	plateNumber: string;
	budgetId: number;
}

/**
 * Get budgets by plate number and budget code
 * @param string `plateNumber`
 * @param string `budgetCode`
 * @returns `Array<IBudget>`
 */
export const budgetGetByVehicleAndIdService = (
	model: IBudgetGetByVehicleAndIdParams,
): Promise<ServiceResponse<IBudget>> => {
	return Api.get<IBudget, IBudgetGetByVehicleAndIdParams>(BudgetEndPoints.GET_BY_VEHICLE_AND_ID, model);
};
