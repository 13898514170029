import type {
	IVehicleAssignTagParams,
	IVehicleBlockParams,
	IVehicleChangeAgreedRepurchaseDateParams,
	IVehicleChangeBrandParkingParams,
	IVehicleChangeCarrierParams,
	IVehicleChangeDeFleetDateParams,
	IVehicleChangeEndAmortizationDateParams,
	IVehicleChangeFleetTypologyParams,
	IVehicleChangeInsuranceCompanyParams,
	IVehicleChangeLastRevisionKmParams,
	IVehicleChangeNextITVDateParams,
	IVehicleChangePlateNumberParams,
	IVehicleChangeRegistrationDateParams,
	IVehicleChangeRentEndDateParams,
	IVehicleChangeRentStartDateParams,
	IVehicleChangeSaleAmountParams,
	IVehicleChangeStateParams,
	IVehicleChangeUnregisterTrafficDateParams,
	IVehicleChangeVehicleGroupParams,
	IVehicleChangeVehicleModelParams,
	IVehicleCloseParams,
	IVehicleFleetSearchParams,
	IVehicleGetByPlateNumberParams,
	IVehicleGetReadyByCurrentBranchParams,
	IVehicleLockFiturParams,
	IVehicleLockParams,
	IVehicleOpenParams,
	IVehiclePreparationParams,
	IVehicleReceptionParams,
	IVehicleReturnParams,
	IVehicleRevisionParams,
	IVehicleSearchParams,
	IVehicleUnlockFiturParams,
	IVehicleUnlockParams,
	IVehicleUpdateKeysLocationParams,
	IVehicleUpdateObeParams,
} from '../../models/serviceParams/VehicleParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import type { IVehicle } from '../../modules/vehicle/entities/Vehicle';
import { VehicleEndPoints } from '../endPoints/VehicleEndPoints';

/**
 * @deprecated Use `vehicleServiceSearch` from `modules\vehicle\search\services`
 * Search vehicles
 * @param model {IVehicleSearchParams} - The search parameters.
 * @returns {Promise} - A promise that resolves to the vehicles that match the search parameters.
 */
export const vehicleServiceSearch = (model: IVehicleSearchParams): Promise<ServiceResponse<IVehicle[]>> => {
	return Api.get<IVehicle[], IVehicleSearchParams>(VehicleEndPoints.SEARCH, model);
};

/**
 * @deprecated Use `vehicleGetByPlateNumberService` from `modules\vehicle\detail\services`
 * Get vehicle by plate number
 *  {string} plateNumber Plate number
 * @returns {Promise} `ResponsePropType`
 */

export const vehicleServiceGetByPlateNumber = (
	model: IVehicleGetByPlateNumberParams,
): Promise<ServiceResponse<IVehicle>> => {
	return Api.get<IVehicle, IVehicleGetByPlateNumberParams>(VehicleEndPoints.GET_BY_PLATE, model);
};

/**
 * @deprecated Use `vehicleGetReadyByCurrentBranchService` from `modules\vehicle\availables\services`
 * Get available by branch and vehicle group order by ready
 *  {string} branchCode bookingNumber
 * @returns {Promise} `ResponsePropType`
 */

export const vehicleServiceGetReadyByCurrentBranch = (
	model: IVehicleGetReadyByCurrentBranchParams,
): Promise<ServiceResponse<IVehicle[]>> => {
	return Api.get<IVehicle[], IVehicleGetReadyByCurrentBranchParams>(
		VehicleEndPoints.GET_READY_BY_CURRENT_BRANCH,
		model,
	);
};

/**
 * @deprecated - use 'vehicleUpdateOBEService' from 'modules\vehicle\detail\services'
 * Update vehicle OBE, only in Portugal
 *  {string} plateNumber Plate number
 *  {string} obe OBE code
 * @returns {Promise} `VehiclePropType`
 */
export const vehicleServiceUpdateOBE = (model: IVehicleUpdateObeParams): Promise<ServiceResponse<IVehicle>> => {
	return Api.post<IVehicle, IVehicleUpdateObeParams>(VehicleEndPoints.UPDATE_OBE, model);
};

/**
 * @deprecated use 'vehicleAssignTagService' from 'modules\vehicle\detail\services'
 * Update vehicle nfc tag
 *  {string} plateNumber Plate number
 *  {number} add or modify nfc tag
 * @returns {Promise} `VehiclePropType`
 */
export const vehicleServiceAssignTag = (model: IVehicleAssignTagParams): Promise<ServiceResponse<IVehicle[]>> => {
	return Api.post<IVehicle[], IVehicleAssignTagParams>(VehicleEndPoints.ASSIGN_TAG, model);
};

/**
 * @deprecated use 'vehicleReceptionService' from 'modules\vehicle\reception\services'
 * Vehicle reception
 *  {Array} `Array<VehiclePropType>` Vehicles to reception
 *  {Array} files Images to vehicles reception
 * @returns {Promise} `VehiclePropType`
 */
export const vehicleServiceReception = (model: IVehicleReceptionParams): Promise<ServiceResponse<IVehicle>> => {
	return Api.post<IVehicle, IVehicleReceptionParams>(VehicleEndPoints.RECEPTION, model);
};

/**
 * @deprecated use 'vehicleReturnService' from 'modules\vehicle\return\services'
 * Vehicles return
 *  {Array} `Array<VehiclePropType>`
 *  {HttpPostFile} image Image relate in this return
 * @returns {Promise} `VehiclePropType`
 */
export const vehicleServiceReturn = (model: IVehicleReturnParams): Promise<ServiceResponse<IVehicle>> => {
	return Api.post<IVehicle, IVehicleReturnParams>(VehicleEndPoints.RETURN, model);
};

/**
 * @deprecated use 'vehicleChangeState' from 'modules\vehicle\changeState\services'
 * Change vehicle state
 *  {string} state Vehicle new state
 *  {string} message This message is to insert in expedient vehicle
 *  {string} supplierCode Supplier code, only if state is taller externo/interno
 * @returns {Promise} `VehiclePropType`
 */

export const vehicleServiceChangeState = (model: IVehicleChangeStateParams): Promise<ServiceResponse<IVehicle>> => {
	return Api.post<IVehicle, IVehicleChangeStateParams>(VehicleEndPoints.CHANGE_STATE, model);
};

/**
 * @deprecated use 'vehiclePreparationService' from 'modules\vehicle\preparation\services'
 * Prepare vehicle
 * @param model {IVehiclePreparationParams} - The vehicle to be prepared.
 * @returns {Promise} - A promise that resolves after the vehicle is prepared.
 */
export const vehicleServicePreparation = (model: IVehiclePreparationParams): Promise<ServiceResponse<IVehicle>> => {
	return Api.post<IVehicle, IVehiclePreparationParams>(VehicleEndPoints.PREPARATION, model);
};

/**
 * @deprecated use 'vehicleBlockService' from 'modules\vehicle\detail\services'
 * Block vehicle
 * @param model {IVehicleBlockParams} - The vehicle to be blocked.
 * @returns {Promise} - A promise that resolves after the vehicle is blocked.
 */
export const vehicleServiceBlock = (model: IVehicleBlockParams): Promise<ServiceResponse<IVehicle>> => {
	return Api.post<IVehicle, IVehicleBlockParams>(VehicleEndPoints.BLOCK_VEHICLE, model);
};

export const vehicleServiceOpen = (model: IVehicleOpenParams): Promise<ServiceResponse<boolean>> => {
	return Api.post<boolean, IVehicleOpenParams>(VehicleEndPoints.OPEN, model);
};

export const vehicleServiceClose = (model: IVehicleCloseParams): Promise<ServiceResponse<boolean>> => {
	return Api.post<boolean, IVehicleCloseParams>(VehicleEndPoints.CLOSE, model);
};

/**
 * @deprecated use 'vehicleUnlockService' from 'modules\vehicle\detail\services'
 * Unlock vehicle
 * @param model {IVehicleUnlockParams} - The vehicle to be unlocked.
 * @returns {Promise} - A promise that resolves after the vehicle is unlocked.
 */
export const vehicleServiceUnlock = (model: IVehicleUnlockParams): Promise<ServiceResponse<boolean>> => {
	return Api.post<boolean, IVehicleUnlockParams>(VehicleEndPoints.UNLOCK, model);
};

/**
 * @deprecated use 'vehicleLockService' from 'modules\vehicle\detail\services'
 * Lock vehicle
 * @param model {IVehicleLockParams} - The vehicle to be locked.
 * @returns {Promise} - A promise that resolves after the vehicle is locked.
 */
export const vehicleServiceLock = (model: IVehicleLockParams): Promise<ServiceResponse<boolean>> => {
	return Api.post<boolean, IVehicleLockParams>(VehicleEndPoints.LOCK, model);
};

export const vehicleServiceUnlockFitur = (model: IVehicleUnlockFiturParams) => {
	return Api.post<boolean, IVehicleUnlockFiturParams>(VehicleEndPoints.UNLOCK_FITUR, model);
};

export const vehicleServiceLockFitur = (model: IVehicleLockFiturParams) => {
	return Api.post<boolean, IVehicleLockFiturParams>(VehicleEndPoints.LOCK_FITUR, model);
};

export const vehicleServiceSearchFleet = (model: IVehicleFleetSearchParams) => {
	return Api.get<IVehicle[], IVehicleFleetSearchParams>(VehicleEndPoints.SEARCH_FLEET, model);
};

export const vehicleServiceChangeVehicleGroup = (model: IVehicleChangeVehicleGroupParams) => {
	return Api.post<IVehicle[], IVehicleChangeVehicleGroupParams>(VehicleEndPoints.CHANGE_VEHICLE_GROUP, model);
};

export const vehicleServiceChangeDeFleetDate = (
	model: IVehicleChangeDeFleetDateParams,
): Promise<ServiceResponse<IVehicle[]>> => {
	return Api.post<IVehicle[], IVehicleChangeDeFleetDateParams>(VehicleEndPoints.CHANGE_DEFLEET_DATE, model);
};

/**
 * @deprecated - use 'vehicleModifyChangeAgreedRepurchaseDateService' from 'packages\core\src\modules\fleet\vehicleModify\services\VehicleModifyChangeAgreedRepurchaseDateService.ts' instead.
 */
export const vehicleServiceChangeAgreedRepurchaseDate = (
	model: IVehicleChangeAgreedRepurchaseDateParams,
): Promise<ServiceResponse<IVehicle[]>> => {
	return Api.post<IVehicle[], IVehicleChangeAgreedRepurchaseDateParams>(
		VehicleEndPoints.CHANGE_AGREED_REPURCHASE_DATE,
		model,
	);
};

/**
 * @deprecated Use `vehicleChangeEndAmortizationDateService` from `modules\vehicle\detail\services`
 * @param model - The model containing the plate numbers of the vehicles and the new amortization date
 * @returns {Promise} - A promise that resolves after the vehicles are updated.
 */
export const vehicleServiceChangeEndAmortizationDate = (
	model: IVehicleChangeEndAmortizationDateParams,
): Promise<ServiceResponse<IVehicle[]>> => {
	return Api.post<IVehicle[], IVehicleChangeEndAmortizationDateParams>(
		VehicleEndPoints.CHANGE_END_AMORTIZATION_DATE,
		model,
	);
};

/**
 * @deprecated - use 'vehicleModifyChangeNextITVDateService' from 'packages\core\src\modules\fleet\vehicleModify\services\VehicleModifyChangeNextITVDateService.ts' instead.
 */
export const vehicleServiceChangeNextITVDate = (
	model: IVehicleChangeNextITVDateParams,
): Promise<ServiceResponse<IVehicle[]>> => {
	return Api.post<IVehicle[], IVehicleChangeNextITVDateParams>(VehicleEndPoints.CHANGE_NEXT_ITV_DATE, model);
};

/**
 * @deprecated - use 'vehicleModifyChangeRentStartDateService' from 'packages\core\src\modules\fleet\vehicleModify\services\VehicleModifyChangeRentStartDateService.ts' instead.
 */
export const vehicleServiceChangeRentStartDate = (
	model: IVehicleChangeRentStartDateParams,
): Promise<ServiceResponse<IVehicle[]>> => {
	return Api.post<IVehicle[], IVehicleChangeRentStartDateParams>(VehicleEndPoints.CHANGE_RENT_START_DATE, model);
};

/**
 * @deprecated - use 'vehicleModifyChangeRentEndDateService' from 'packages\core\src\modules\fleet\vehicleModify\services\VehicleModifyChangeRentEndDateService.ts' instead.
 */
export const vehicleServiceChangeRentEndDate = (
	model: IVehicleChangeRentEndDateParams,
): Promise<ServiceResponse<IVehicle[]>> => {
	return Api.post<IVehicle[], IVehicleChangeRentEndDateParams>(VehicleEndPoints.CHANGE_RENT_END_DATE, model);
};

/**
 * @deprecated - use 'vehicleModifyChangeUnregisterTrafficDateService' from 'packages\core\src\modules\fleet\vehicleModify\services\VehicleModifyChangeUnregisterTrafficDateService.ts' instead.
 */
export const vehicleServiceChangeUnregisterTrafficDate = (
	model: IVehicleChangeUnregisterTrafficDateParams,
): Promise<ServiceResponse<IVehicle[]>> => {
	return Api.post<IVehicle[], IVehicleChangeUnregisterTrafficDateParams>(
		VehicleEndPoints.CHANGE_UNREGISTER_TRAFFIC_DATE,
		model,
	);
};

/**
 * @deprecated Use `vehicleUpdateKeysLocationService` from `modules\vehicle\detail\services`
 * Update keys location
 * @param model {IVehicleUpdateKeysLocationParams} - The vehicle for which the keys location is updated.
 * @returns {Promise} - A promise that resolves to the specified vehicle.
 */
export const vehicleServiceUpdateKeysLocation = (
	model: IVehicleUpdateKeysLocationParams,
): Promise<ServiceResponse<IVehicle>> => {
	return Api.post<IVehicle, IVehicleUpdateKeysLocationParams>(VehicleEndPoints.UPDATE_KEYS_LOCATION, model);
};

/**
 * @deprecated Use `vehicleServiceRevision` from `modules\vehicle\revision\services`
 * Set vehicle revisions
 * @param model {IVehicleRevisionParams} - The model containing the plate numbers of the vehicles and the new revision status.
 * @returns {Promise} - A promise that resolves after the vehicles are updated.
 */
export const vehicleServiceRevision = (model: IVehicleRevisionParams): Promise<ServiceResponse<boolean>> => {
	return Api.post<boolean, IVehicleRevisionParams>(VehicleEndPoints.REVISION, model);
};

/**
 * @deprecated Use `vehicleServiceChangeLastRevisionKm` from `modules\vehicle\detail\services`
 * Change last revision km
 * @param model {IVehicleChangeLastRevisionKmParams} - The model containing the plate numbers of the vehicles and the new last revision km.
 * @returns {Promise} - A promise that resolves after the vehicles are updated.
 */
export const vehicleServiceChangeLastRevisionKm = (
	model: IVehicleChangeLastRevisionKmParams,
): Promise<ServiceResponse<IVehicle>> => {
	return Api.post<IVehicle, IVehicleChangeLastRevisionKmParams>(VehicleEndPoints.CHANGE_LAST_REVISION_KM, model);
};

/**
 * @deprecated - use 'vehicleModifyChangeFleetTypologyService' from 'packages\core\src\modules\fleet\vehicleModify\services\VehicleModifyChangeFleetTypologyService.ts' instead.
 */
export const vehicleServiceChangeFleetTypology = (
	model: IVehicleChangeFleetTypologyParams,
): Promise<ServiceResponse<IVehicle[]>> => {
	return Api.post<IVehicle[], IVehicleChangeFleetTypologyParams>(VehicleEndPoints.CHANGE_FLEET_TYPOLOGY, model);
};

/**
 * @deprecated - use 'vehicleModifyChangeCarrierService' from 'packages\core\src\modules\fleet\vehicleModify\services\VehicleModifyChangeCarrierService.ts' instead.
 * @param model - The model containing the plate numbers of the vehicles and the new carrier.
 * @returns {Promise} - A promise that resolves after the vehicles are updated.
 */
export const vehicleServiceChangeCarrier = (
	model: IVehicleChangeCarrierParams,
): Promise<ServiceResponse<IVehicle[]>> => {
	return Api.post<IVehicle[], IVehicleChangeCarrierParams>(VehicleEndPoints.CHANGE_CARRIER, model);
};

/**
 * @deprecated - use 'vehicleModifyChangeRegistrationDateService' from 'packages\core\src\modules\fleet\vehicleModify\services\VehicleModifyChangeRegistratioDateService.ts' instead.
 */
export const vehicleServiceChangeRegistrationDate = (
	model: IVehicleChangeRegistrationDateParams,
): Promise<ServiceResponse<IVehicle[]>> => {
	return Api.post<IVehicle[], IVehicleChangeRegistrationDateParams>(VehicleEndPoints.CHANGE_REGISTRATION_DATE, model);
};

/**
 * @deprecated - use 'vehicleModifyChangeBrandParkingService' from 'packages\core\src\modules\fleet\vehicleModify\services\VehicleModifyChangeBrandParkingService.ts' instead.
 */
export const vehicleServiceChangeBrandParking = (
	model: IVehicleChangeBrandParkingParams,
): Promise<ServiceResponse<IVehicle[]>> => {
	return Api.post<IVehicle[], IVehicleChangeBrandParkingParams>(VehicleEndPoints.CHANGE_BRAND_PARKING, model);
};

/**
 * @deprecated - use 'vehicleModifyChangeSaleAmountService' from 'packages\core\src\modules\fleet\vehicleModify\services\VehicleModifyChangeSaleAmountService.ts' instead.
 */
export const vehicleServiceChangeSaleAmount = (
	model: IVehicleChangeSaleAmountParams,
): Promise<ServiceResponse<IVehicle[]>> => {
	return Api.post<IVehicle[], IVehicleChangeSaleAmountParams>(VehicleEndPoints.CHANGE_SALE_PRICE, model);
};

/**
 * @deprecated - use 'vehicleModifyChangeVehicleModelService' from 'packages\core\src\modules\fleet\vehicleModify\services\VehicleModifyChangeVehicleModelService.ts' instead.
 */
export const vehicleServiceChangeVehicleModel = (
	model: IVehicleChangeVehicleModelParams,
): Promise<ServiceResponse<IVehicle[]>> => {
	return Api.post<IVehicle[], IVehicleChangeVehicleModelParams>(VehicleEndPoints.CHANGE_VEHICLE_MODEL, model);
};

/**
 * @deprecated - use 'vehicleModifyChangePlateNumberService' from 'packages\core\src\modules\fleet\vehicleModify\services\VehicleModifyChangePlateNumberService.ts' instead.
 */
export const vehicleServiceChangePlateNumber = (
	model: IVehicleChangePlateNumberParams,
): Promise<ServiceResponse<IVehicle>> => {
	return Api.post<IVehicle, IVehicleChangePlateNumberParams>(VehicleEndPoints.CHANGE_PLATE_NUMBER, model);
};

/**
 * @deprecated - use 'vehicleModifyChangeInsuranceCompanyService' from 'packages\core\src\modules\fleet\vehicleModify\services\VehicleModifyChangeInsuranceCompanyService.ts' instead.
 */
export const vehicleServiceChangeInsuranceCompany = (
	model: IVehicleChangeInsuranceCompanyParams,
): Promise<ServiceResponse<IVehicle[]>> => {
	return Api.post<IVehicle[], IVehicleChangeInsuranceCompanyParams>(VehicleEndPoints.CHANGE_INSURANCE_COMPANY, model);
};
