import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

import { ExcessRateEndpoints } from './ExcessRateEndpoints';
import type { IExcessRate } from '../entities/ExcessRate';

export interface IExcessRateInsertParams extends IExcessRate, IServiceBaseParams {
	provider: string;
}

/**
 * Insert an excess rate
 * @param model - excess rate to insert
 * @returns - inserted excess rate
 */
export const excessRateInsertService = (model: IExcessRateInsertParams) => {
	return Api.post<IExcessRate, IExcessRateInsertParams>(ExcessRateEndpoints.INSERT, model);
};
