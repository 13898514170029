import type { IBooking } from '@crac/core/models/entities/Booking';
import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaTaxi } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { bookingDetailLoader } from '~/features/booking/shared/utils/bookingDetailLoader';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const Fines = lazyWithRetry(() => import('./pages/Fines'));

export const finesRoutes: { [key: number]: IRouteConfigType } = {};

finesRoutes[routesIdMap.BookingsDetailFines] = {
	component: Fines,
	exact: true,
	id: routesIdMap.BookingsDetailFines,
	name: 'Fines',
	path: '/bookings/detail/:id/fines',
	permissions: [PermissionsType.BookingDetail],
	loader: ({ params }): Promise<IBooking | null | undefined> => {
		return bookingDetailLoader(params?.id || '');
	},
	icon: FaTaxi,
};

finesRoutes[routesIdMap.BookingsDetailFineDetail] = {
	component: Fines,
	exact: true,
	id: routesIdMap.BookingsDetailFineDetail,
	name: 'Fines detail',
	path: '/bookings/detail/:id/fines/:fine',
	permissions: [PermissionsType.BookingDetail],
	loader: ({ params }): Promise<IBooking | null | undefined> => {
		return bookingDetailLoader(params?.id || '');
	},
	icon: FaTaxi,
};
