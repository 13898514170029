/**
 * @deprecated - use 'RevisionCostEndPoints' from 'packages\core\src\modules\workshop\revisionCost\services\RevisionCostEndpoints.ts' instead.
 */
export enum RevisionCostEndPoints {
	/**
	 * Search revision costs
	 * @host `/revisionCost/search`
	 */
	SEARCH = '/revisionCost/search',
	/**
	 * Save revision costs
	 * @host `/revisionCost/save`
	 */
	SAVE = '/revisionCost/save',
}
