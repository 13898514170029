import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { gCSwal } from '@crac/components/external/sweet-alert';
import type { IEmployee } from '@crac/core/models/entities/Employee';

import { REAL_TIME_SCRIPT_CODES } from '~/constants/RealTimeEventNames';
import { useAlertMessage } from '~/features/shared/hooks/useAlertMessage';
import { removeBrowserCache } from '~/serviceWorker';

import type { ISignalrConnection } from './useSignalR';

const updateAllBrowsers = () => {
	window.location.reload();
};

export const useSignalRConnection = (
	hubConnection: ISignalrConnection,
	employee: IEmployee,
	employeeUpdate: (payload: IEmployee) => {
		payload: IEmployee;
		type: string;
	},
) => {
	const history = useNavigate();
	const { showAlert } = useAlertMessage();
	// const { handleLoadCachedData, isSubmitted, isLoading } = useLoadCachedData(employee, true);

	const refreshEmployeeData = (data: any) => {
		employeeUpdate({
			...employee,
			branchCode: data.branchCode,
			name: data.name,
			permissions: data.permissions,
			token: data.token,
			userName: data.userName,
		});
	};

	const beforeUpdatingBrowser = async () => {
		await showAlert({
			title: 'We have updated the application!',
			message: 'It is necessary to refresh the browser.',
			type: 'warning',
		});
		updateAllBrowsers();
	};

	const launchScript = async (response: any) => {
		if (response.ok) {
			switch (response.script) {
				case 'F5':
					await beforeUpdatingBrowser();
					break;
				case 'CLOSE_SESSION':
					history('/login');
					break;
				case 'DELETE_BROWSER_CACHES':
					removeBrowserCache();
					setTimeout(() => {
						updateAllBrowsers();
					}, 0);
					break;
				case REAL_TIME_SCRIPT_CODES.UPDATE_ALL_CACHED_DATA:
					// handleLoadCachedData();
					break;
				default:
					break;
			}
		}
	};

	const showMessage = async (res: any) => {
		if (res.ok) {
			await gCSwal(res.message, '', 'info');
		}
	};

	const showGlobalMessage = async (res: any) => {
		if (res.ok) {
			await gCSwal(res.message, '', 'info');
		}
	};

	useEffect(() => {
		if (hubConnection) {
			hubConnection.addListener('onLaunchScript', launchScript);
			hubConnection.addListener('onRefreshEmployeeData', refreshEmployeeData);
			hubConnection.addListener('onShowPrivateMessage', showMessage);
			hubConnection.addListener('onShowGlobalMessage', showGlobalMessage);
		}

		/*
		 * REFRESH WINDOW WHEN CACHED DATA FINISH LOADING
		 * if (isSubmitted && !isLoading) {
		 * 	updateAllBrowsers();
		 * }
		 */

		return () => {
			if (hubConnection) {
				hubConnection.removeListener('onLaunchScript');
				hubConnection.removeListener('onRefreshEmployeeData');
				hubConnection.removeListener('onShowPrivateMessage');
				hubConnection.removeListener('onShowGlobalMessage');
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hubConnection]);

	return null;
};
