import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

import { VehicleColorEndPoints } from './FleetVehicleColorEndpoints';
import type { IVehicleColor } from '../entities/VehicleColor';

export interface IFleetVehicleColorInsertParams extends IVehicleColor, IServiceBaseParams {}

export const fleetVehicleColorInsertService = (model: IFleetVehicleColorInsertParams) => {
	return Api.post<IVehicleColor, IFleetVehicleColorInsertParams>(VehicleColorEndPoints.INSERT, model);
};
