import type { IBooking } from '@crac/core/models/entities/Booking';
import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaSignInAlt } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { bookingDetailLoader } from '~/features/booking/shared/utils/bookingDetailLoader';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const ExtraDrivers = lazyWithRetry(() => import('./pages/ExtraDrivers'));

export const extraDriversRoutes: { [key: number]: IRouteConfigType } = {};

extraDriversRoutes[routesIdMap.BookingsPickUpExtraDrivers] = {
	component: ExtraDrivers,
	exact: true,
	id: routesIdMap.BookingsPickUpExtraDrivers,
	name: 'Drivers',
	path: '/booking/pick-up/:id/drivers',
	permissions: [PermissionsType.BookingPickUpProcess],
	loader: ({ params }): Promise<IBooking | null | undefined> => {
		return bookingDetailLoader(params?.id || '');
	},
	icon: FaSignInAlt,
};

extraDriversRoutes[routesIdMap.BookingsPickUpExtraDriversAdd] = {
	component: ExtraDrivers,
	exact: true,
	id: routesIdMap.BookingsPickUpExtraDriversAdd,
	name: 'Drivers',
	path: '/booking/pick-up/:id/drivers/add/:number',
	permissions: [PermissionsType.BookingPickUpProcess],
	loader: ({ params }): Promise<IBooking | null | undefined> => {
		return bookingDetailLoader(params?.id || '');
	},
	icon: FaSignInAlt,
};

extraDriversRoutes[routesIdMap.BookingsPickUpExtraDriversModify] = {
	component: ExtraDrivers,
	exact: true,
	id: routesIdMap.BookingsPickUpExtraDriversModify,
	name: 'Drivers',
	path: '/booking/pick-up/:id/drivers/modify/:number',
	permissions: [PermissionsType.BookingPickUpProcess],
	loader: ({ params }): Promise<IBooking | null | undefined> => {
		return bookingDetailLoader(params?.id || '');
	},
	icon: FaSignInAlt,
};
