'use client';

import * as DialogPrimitive from '@radix-ui/react-dialog';

import { AlertDialog } from './components/AlertDialog';
import { ConfirmDialog } from './components/ConfirmDialog';
import { DialogContent } from './components/DialogContent';
import { DialogDescription } from './components/DialogDescription';
import { DialogFooter } from './components/DialogFooter';
import { DialogHeader } from './components/DialogHeader';
import { DialogOverlay } from './components/DialogOverlay';
import { DialogTitle } from './components/DialogTitle';

const Dialog = DialogPrimitive.Root;

const DialogTrigger = DialogPrimitive.Trigger;

const DialogPortal = DialogPrimitive.Portal;

const DialogClose = DialogPrimitive.Close;

export {
	AlertDialog,
	ConfirmDialog,
	Dialog,
	DialogPortal,
	DialogOverlay,
	DialogClose,
	DialogTrigger,
	DialogContent,
	DialogHeader,
	DialogFooter,
	DialogTitle,
	DialogDescription,
};
