import { dashboardRoutes } from '~/features/sale/dashboard/routes';
import { saleAcceptReconditioningRoutes } from '~/features/sale/saleAcceptReconditioning/routes';
import { saleMarginsRoutes } from '~/features/sale/saleMargins/routes';
import { saleOrderRoutes } from '~/features/sale/saleOrder/routes';
import { salePriceModifyRoutes } from '~/features/sale/salePriceModify/routes';
import { saleServiceRoutes } from '~/features/sale/saleService/routes';
import { saleServiceRateRoutes } from '~/features/sale/saleServiceRate/routes';

export const saleRoutes = {
	...saleOrderRoutes,
	...saleMarginsRoutes,
	...saleServiceRateRoutes,
	...saleServiceRoutes,
	...dashboardRoutes,
	...salePriceModifyRoutes,
	...saleAcceptReconditioningRoutes,
};
