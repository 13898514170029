import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaLinkAlt, FaWrenchAlt } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const DamageRepair = lazyWithRetry(() => import('./pages/DamageRepair'));
const DamageRepairLink = lazyWithRetry(() => import('./pages/DamageRepairLink'));

export const damageRepairRoutes: { [key: number]: IRouteConfigType } = {};
damageRepairRoutes[routesIdMap.WorkshopDamageRepair] = {
	component: DamageRepair,
	exact: true,
	id: routesIdMap.WorkshopDamageRepair,
	name: 'Damage Repair',
	menuText: 'Costs',
	path: '/workshop/damage-repair',
	icon: FaWrenchAlt,
	permissions: [PermissionsType.DamageRepairCost],
};

damageRepairRoutes[routesIdMap.WorkshopDamageRepairLink] = {
	component: DamageRepairLink,
	exact: true,
	id: routesIdMap.WorkshopDamageRepairLink,
	name: 'Damage repair link',
	path: '/workshop/damage-repair/link/:movement/:plateNumber',
	permissions: [PermissionsType.DamageRepairCost],
	icon: FaLinkAlt,
};
