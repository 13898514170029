import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { IVehicle } from '@crac/core/modules/vehicle/entities/Vehicle';

import { FleetMovementRestrictionEndPoints } from './MovementRestrictionEndpoints';

export interface IFleetMovementRestrictionRemoveParams extends IServiceBaseParams {
	plateNumbers: string[];
}

export const fleetMovementRestrictionRemoveService = (model: IFleetMovementRestrictionRemoveParams) => {
	return Api.post<IVehicle[], IFleetMovementRestrictionRemoveParams>(FleetMovementRestrictionEndPoints.REMOVE, model);
};
