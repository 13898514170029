import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaPencil, FaPlus, FaRegFileLines } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

import { getPricingPermission } from '../utils/helpers';

const InvoiceableServiceConfiguration = lazyWithRetry(() => import('./pages/InvoiceableServiceConfiguration'));
const InvoiceableServiceConfigurationNew = lazyWithRetry(() => import('./pages/InvoiceableServiceConfigurationNew'));
const InvoiceableServiceConfigurationModify = lazyWithRetry(
	() => import('./pages/InvoiceableServiceConfigurationModify'),
);

export const invoiceableServiceConfigurationRoutes: { [key: number]: IRouteConfigType } = {};

// INVOICEABLE SERVICES
invoiceableServiceConfigurationRoutes[routesIdMap.PricingInvoiceableServiceConfiguration] = {
	component: InvoiceableServiceConfiguration,
	exact: true,
	id: routesIdMap.PricingInvoiceableServiceConfiguration,
	name: 'Invoiceable services configuration',
	menuText: 'Invoiceable services',
	path: '/pricing/invoiceable-services',
	permissions: getPricingPermission([PermissionsType.PricingInvoiceableServiceConfiguration]),
	childrens: [
		routesIdMap.PricingInvoiceableServiceConfigurationNew,
		routesIdMap.PricingInvoiceableServiceConfigurationModify,
	],
	icon: FaRegFileLines,
};

invoiceableServiceConfigurationRoutes[routesIdMap.PricingInvoiceableServiceConfigurationNew] = {
	component: InvoiceableServiceConfigurationNew,
	exact: true,
	id: routesIdMap.PricingInvoiceableServiceConfigurationNew,
	name: 'New invoiceable service',
	path: '/pricing/invoiceable-services/new',
	permissions: getPricingPermission([PermissionsType.PricingInvoiceableServiceConfiguration]),
	icon: FaPlus,
	menuText: 'New invoiceable service',
};

invoiceableServiceConfigurationRoutes[routesIdMap.PricingInvoiceableServiceConfigurationModify] = {
	component: InvoiceableServiceConfigurationModify,
	exact: true,
	id: routesIdMap.PricingInvoiceableServiceConfigurationModify,
	name: 'Invoiceable services modify',
	path: '/pricing/invoiceable-services/:id',
	permissions: getPricingPermission([PermissionsType.PricingInvoiceableServiceConfiguration]),
	icon: FaPencil,
};
