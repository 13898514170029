/**
 * @deprecated Use `ServiceEndpoints` from `modules/service/services`
 */
export enum ServicesEndPoints {
	/**
	 * Get all services
	 * @host `/service/getall/`
	 */
	GET_ALL = '/service/getall/',
}
