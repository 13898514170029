import { VehicleModifyEndPoints } from './VehicleModifyEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IVehicle } from '../../../vehicle/entities/Vehicle';

export interface IVehicleChangeVehicleGroupParams extends IServiceBaseParams {
	plateNumbers: string[];
	vehicleGroup: string;
}

export const vehicleModifyChangeVehicleGroupService = (model: IVehicleChangeVehicleGroupParams) => {
	return Api.post<IVehicle[], IVehicleChangeVehicleGroupParams>(VehicleModifyEndPoints.CHANGE_VEHICLE_GROUP, model);
};
