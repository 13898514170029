import type { IBooking } from '@crac/core/models/entities/Booking';
import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaSignInAlt } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { bookingDetailLoader } from '~/features/booking/shared/utils/bookingDetailLoader';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const InvoiceTo = lazyWithRetry(() => import('./pages/InvoiceTo'));
const InvoiceToCompanyNew = lazyWithRetry(() => import('./pages/InvoiceToCompanyNew'));
const InvoiceToCompanySearch = lazyWithRetry(() => import('./pages/InvoiceToCompanySearch'));
const InvoiceToCustomerNew = lazyWithRetry(() => import('./pages/InvoiceToCustomerNew'));
const InvoiceToCustomerSearch = lazyWithRetry(() => import('./pages/InvoiceToCustomerSearch'));

export const invoiceToRoutes: { [key: number]: IRouteConfigType } = {};

invoiceToRoutes[routesIdMap.BookingsPickUpInvoiceTo] = {
	childrens: [
		routesIdMap.BookingsPickUpInvoiceToCustomerSearch,
		routesIdMap.BookingsPickUpInvoiceToCompanySearch,
		routesIdMap.BookingsPickUpInvoiceToCustomerNew,
		routesIdMap.BookingsPickUpInvoiceToCompanyNew,
		routesIdMap.BookingsPickUpInvoiceToCustomerDetail,
		routesIdMap.BookingsPickUpInvoiceToCompanyDetail,
	],
	component: InvoiceTo,
	exact: false,
	id: routesIdMap.BookingsPickUpInvoiceTo,
	name: 'Invoice to',
	path: '/booking/pick-up/:id/invoice-to',
	permissions: [PermissionsType.BookingPickUpProcess],
	loader: ({ params }): Promise<IBooking | null | undefined> => {
		return bookingDetailLoader(params?.id || '');
	},
	icon: FaSignInAlt,
};

// * Customer
invoiceToRoutes[routesIdMap.BookingsPickUpInvoiceToCustomerSearch] = {
	component: InvoiceToCustomerSearch,
	exact: true,
	isChild: true,
	id: routesIdMap.BookingsPickUpInvoiceToCustomerSearch,
	name: 'Search customer',
	path: '/booking/pick-up/:id/invoice-to/customer-search',
	permissions: [PermissionsType.BookingPickUpProcess, PermissionsType.BookingChangeInvoiceToCustomer],
	icon: FaSignInAlt,
};

invoiceToRoutes[routesIdMap.BookingsPickUpInvoiceToCustomerNew] = {
	component: InvoiceToCustomerNew,
	exact: true,
	isChild: true,
	id: routesIdMap.BookingsPickUpInvoiceToCustomerNew,
	name: 'New customer',
	path: '/booking/pick-up/:id/invoice-to/customer-new',
	permissions: [PermissionsType.BookingPickUpProcess, PermissionsType.BookingChangeInvoiceToCustomer],
	icon: FaSignInAlt,
};

invoiceToRoutes[routesIdMap.BookingsPickUpInvoiceToCustomerDetail] = {
	component: InvoiceToCustomerNew,
	exact: true,
	isChild: true,
	id: routesIdMap.BookingsPickUpInvoiceToCustomerDetail,
	name: 'Check customer data',
	path: '/booking/pick-up/:id/invoice-to/:customer/customer-detail',
	permissions: [PermissionsType.BookingPickUpProcess, PermissionsType.BookingChangeInvoiceToCustomer],
	icon: FaSignInAlt,
};

// * Company
invoiceToRoutes[routesIdMap.BookingsPickUpInvoiceToCompanySearch] = {
	component: InvoiceToCompanySearch,
	exact: true,
	isChild: true,
	id: routesIdMap.BookingsPickUpInvoiceToCompanySearch,
	name: 'Search company',
	path: '/booking/pick-up/:id/invoice-to/company-search',
	permissions: [PermissionsType.BookingPickUpProcess, PermissionsType.BookingChangeInvoiceToCustomer],
	icon: FaSignInAlt,
};

invoiceToRoutes[routesIdMap.BookingsPickUpInvoiceToCompanyNew] = {
	component: InvoiceToCompanyNew,
	exact: true,
	isChild: true,
	id: routesIdMap.BookingsPickUpInvoiceToCompanyNew,
	name: 'New company',
	path: '/booking/pick-up/:id/invoice-to/company-new',
	permissions: [PermissionsType.BookingPickUpProcess, PermissionsType.BookingChangeInvoiceToCustomer],
	icon: FaSignInAlt,
};

invoiceToRoutes[routesIdMap.BookingsPickUpInvoiceToCompanyDetail] = {
	component: InvoiceToCompanyNew,
	exact: true,
	isChild: true,
	id: routesIdMap.BookingsPickUpInvoiceToCompanyDetail,
	name: 'Check company data',
	path: '/booking/pick-up/:id/invoice-to/:company/company-detail',
	permissions: [PermissionsType.BookingPickUpProcess, PermissionsType.BookingChangeInvoiceToCustomer],
	icon: FaSignInAlt,
};
