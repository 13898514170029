import type { FC } from 'react';

import { Col } from '@crac/components/external/reactstrap/col';
import { Row } from '@crac/components/external/reactstrap/row';
import { Fieldset } from '@crac/components/intranet/shared/fieldset/Fieldset';
import type { ISupplierModifyParams } from '@crac/core/modules/supplier/services/SupplierModifyService';
import { InputField } from '@crac/form/field';
import { isEmail } from '@crac/form/validators/isEmail';

interface ISupplierFormContactFieldsProps {
	initialValues?: Partial<ISupplierModifyParams>;
	isLocation?: boolean;
}

export const SupplierFormContactFields: FC<ISupplierFormContactFieldsProps> = ({
	initialValues,
	isLocation = false,
}) => {
	return (
		<Fieldset legend="Contact information">
			<>
				<Row>
					<Col>
						<InputField
							id="email"
							label="Email"
							name="email"
							validateOnLoad={Boolean(initialValues)}
							validation={{
								required: true,
								validate: { email: isEmail() },
								maxLength: 50,
							}}
						/>
					</Col>
				</Row>
				<Row>
					<Col lg={6}>
						<InputField
							id="contact"
							label="Contact"
							name="contact"
							validateOnLoad={Boolean(initialValues)}
							validation={{
								required: !isLocation,
								maxLength: 50,
							}}
						/>
					</Col>
					<Col lg={6}>
						<InputField
							id="phone"
							label="Phone"
							name="phone"
							validateOnLoad={Boolean(initialValues)}
							validation={{
								required: !isLocation,
								maxLength: isLocation ? 20 : 30,
							}}
						/>
					</Col>
				</Row>
			</>
		</Fieldset>
	);
};
