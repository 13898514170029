import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaCalendarDays } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const Bookings = lazyWithRetry(() => import('./pages/Bookings'));

export const bookingsRoutes: { [key: number]: IRouteConfigType } = {};

bookingsRoutes[routesIdMap.VehiclesDetailBookings] = {
	component: Bookings,
	exact: true,
	id: routesIdMap.VehiclesDetailBookings,
	name: 'Bookings',
	path: '/vehicles/detail/:id/bookings',
	permissions: [PermissionsType.VehicleDetail],
	icon: FaCalendarDays,
};
