import { Api } from '@crac/core/modules/shared/api';

import { SaleServiceRateEndpoints } from './SaleServiceRateEndpoints';
import type { SaleCustomerType } from '../../../../models/types/SaleCustomerType';
import type { ShowActiveType } from '../../../../models/types/ShowActiveType';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ISaleServiceRate } from '../entities/SaleServiceRate';

export interface ISaleServiceRateSearchParams extends IServiceBaseParams {
	amount?: number;
	branches?: string[];
	brands?: string[];
	colors?: string[];
	company: string;
	countries?: string[];
	frameNumbers?: string[];
	groups?: string[];
	kmFrom?: number;
	kmTo?: number;
	models?: string[];
	plateNumbers?: string[];
	quoteFrom?: string;
	quoteTo?: string;
	registrationFrom?: string;
	registrationTo?: string;
	saleCustomerType?: SaleCustomerType;
	saleServiceId?: number;
	showActive?: ShowActiveType;
}

export const saleServiceRateSearchService = (model: ISaleServiceRateSearchParams) => {
	return Api.get<ISaleServiceRate[], ISaleServiceRateSearchParams>(SaleServiceRateEndpoints.SEARCH, model);
};
