import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaPencil, FaPlus, FaPlusCircle } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const SaleService = lazyWithRetry(() => import('./pages/SaleService'));
const SaleServiceNew = lazyWithRetry(() => import('./pages/SaleServiceNew'));
const SaleServiceModify = lazyWithRetry(() => import('./pages/SaleServiceModify'));

export const saleServiceRoutes: { [key: number]: IRouteConfigType } = {};

saleServiceRoutes[routesIdMap.SaleService] = {
	component: SaleService,
	exact: true,
	id: routesIdMap.SaleService,
	name: 'Sale services',
	menuText: 'Services',
	path: '/sale/services',
	permissions: [PermissionsType.SaleService],
	childrens: [routesIdMap.SaleServiceNew, routesIdMap.SaleServiceModify],
	icon: FaPlusCircle,
};

saleServiceRoutes[routesIdMap.SaleServiceNew] = {
	component: SaleServiceNew,
	exact: true,
	id: routesIdMap.SaleServiceNew,
	name: 'New sale service',
	menuText: 'New sale service',
	path: '/sale/services/new',
	permissions: [PermissionsType.SaleService],
	childrens: [],
	icon: FaPlus,
	isChild: true,
};

saleServiceRoutes[routesIdMap.SaleServiceModify] = {
	component: SaleServiceModify,
	exact: true,
	id: routesIdMap.SaleServiceModify,
	name: 'Modify sale service',
	path: '/sale/services/:id',
	permissions: [PermissionsType.SaleService],
	childrens: [],
	icon: FaPencil,
	isChild: true,
};
