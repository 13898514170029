import type { IBooking } from '@crac/core/models/entities/Booking';
import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaPencil, FaUsersAlt } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { bookingDetailLoader } from '~/features/booking/shared/utils/bookingDetailLoader';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const Drivers = lazyWithRetry(() => import('./pages/ExtraDrivers'));

export const extraDriversRoutes: { [key: number]: IRouteConfigType } = {};

extraDriversRoutes[routesIdMap.BookingsDetailExtraDrivers] = {
	component: Drivers,
	exact: true,
	id: routesIdMap.BookingsDetailExtraDrivers,
	name: 'Drivers',
	path: '/bookings/detail/:id/drivers',
	permissions: [PermissionsType.BookingDetail],
	loader: ({ params }): Promise<IBooking | null | undefined> => {
		return bookingDetailLoader(params?.id || '');
	},
	icon: FaUsersAlt,
};

extraDriversRoutes[routesIdMap.BookingsDetailExtraDriverAdd] = {
	component: Drivers,
	exact: true,
	id: routesIdMap.BookingsDetailExtraDriverAdd,
	name: 'Driver add',
	path: '/bookings/detail/:id/drivers/add/:number',
	permissions: [PermissionsType.BookingDetail],
	loader: ({ params }): Promise<IBooking | null | undefined> => {
		return bookingDetailLoader(params?.id || '');
	},
	icon: FaUsersAlt,
};

extraDriversRoutes[routesIdMap.BookingsDetailExtraDriverModify] = {
	component: Drivers,
	exact: true,
	id: routesIdMap.BookingsDetailExtraDriverModify,
	name: 'Driver modify',
	path: '/bookings/detail/:id/drivers/modify/:number',
	permissions: [PermissionsType.BookingDetail],
	loader: ({ params }): Promise<IBooking | null | undefined> => {
		return bookingDetailLoader(params?.id || '');
	},
	icon: FaPencil,
};
