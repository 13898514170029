import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaPencil, FaPlus, FaUsersAlt } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

import { getPricingPermission } from '../utils/helpers';

const PartnerGroup = lazyWithRetry(() => import('./pages/PartnerGroup'));
const PartnerGroupNew = lazyWithRetry(() => import('./pages/PartnerGroupNew'));
const PartnerGroupModify = lazyWithRetry(() => import('./pages/PartnerGroupModify'));

export const partnerGroupRoutes: { [key: number]: IRouteConfigType } = {};

// PARTNER GROUP
partnerGroupRoutes[routesIdMap.PricingPartnerGroup] = {
	component: PartnerGroup,
	exact: true,
	id: routesIdMap.PricingPartnerGroup,
	name: 'Partner groups',
	menuText: 'Groups',
	path: '/pricing/partner-groups',
	permissions: getPricingPermission([PermissionsType.PricingPartnerGroup]),
	childrens: [routesIdMap.PricingPartnerGroupNew, routesIdMap.PricingPartnerGroupModify],
	icon: FaUsersAlt,
};

partnerGroupRoutes[routesIdMap.PricingPartnerGroupNew] = {
	component: PartnerGroupNew,
	exact: true,
	id: routesIdMap.PricingPartnerGroupNew,
	name: 'New partner group',
	path: '/pricing/partner-groups/new',
	permissions: getPricingPermission([PermissionsType.PricingPartnerGroup]),
	icon: FaPlus,
	menuText: 'New partner group',
};

partnerGroupRoutes[routesIdMap.PricingPartnerGroupModify] = {
	component: PartnerGroupModify,
	exact: true,
	id: routesIdMap.PricingPartnerGroupModify,
	name: 'Partner groups new',
	path: '/pricing/partner-groups/:id',
	permissions: getPricingPermission([PermissionsType.PricingPartnerGroup]),
	icon: FaPencil,
};
