import type { IVehicleModel } from '../../models/entities/VehicleModel';
import type {
	IFleetVehicleModelDeactivateParams,
	IFleetVehicleModelInsertParams,
	IFleetVehicleModelModifyParams,
	IFleetVehicleModelSearchParams,
} from '../../models/serviceParams/FleetVehicleModelParams';
import { Api } from '../../modules/shared/api';
import { VehicleModelEndPoints } from '../endPoints/VehicleModelEndPoints';

/**
 * @deprecated - use 'fleetVehicleModelSearchService' from 'packages\core\src\modules\fleet\vehicleModel\services\FleetVehicleModelSearchService.ts' instead.
 */
export const fleetVehicleModelServiceSearch = (model: IFleetVehicleModelSearchParams) => {
	return Api.get<IVehicleModel[], IFleetVehicleModelSearchParams>(VehicleModelEndPoints.SEARCH, model);
};

/**
 * @deprecated - use 'fleetVehicleModelInsertService' from 'packages\core\src\modules\fleet\vehicleModel\services\FleetVehicleModelInsertService.ts' instead.
 */
export const fleetVehicleModelServiceInsert = (model: IFleetVehicleModelInsertParams) => {
	return Api.post<IVehicleModel, IFleetVehicleModelInsertParams>(VehicleModelEndPoints.INSERT, model);
};

/**
 * @deprecated - use 'fleetVehicleModelModifyService' from 'packages\core\src\modules\fleet\vehicleModel\services\FleetVehicleModelModifyService.ts' instead.
 */
export const fleetVehicleModelServiceModify = (model: IFleetVehicleModelModifyParams) => {
	return Api.post<IVehicleModel, IFleetVehicleModelModifyParams>(VehicleModelEndPoints.MODIFY, model);
};

/**
 * @deprecated - use 'fleetVehicleModelDeactivateService' from 'packages\core\src\modules\fleet\vehicleModel\services\FleetVehicleModelDeactivateService.ts' instead.
 */
export const fleetVehicleModelServiceDeactivate = (model: IFleetVehicleModelDeactivateParams) => {
	return Api.post<IVehicleModel, IFleetVehicleModelDeactivateParams>(VehicleModelEndPoints.DEACTIVATE, model);
};

/**
 * @deprecated - use 'fleetVehicleModelActivateService' from 'packages\core\src\modules\fleet\vehicleModel\services\FleetVehicleModelActivateService.ts' instead.
 */
export const fleetVehicleModelServiceActivate = (model: IFleetVehicleModelDeactivateParams) => {
	return Api.post<IVehicleModel, IFleetVehicleModelDeactivateParams>(VehicleModelEndPoints.ACTIVATE, model);
};
