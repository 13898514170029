import * as React from 'react';

import { LuChevronDown } from 'react-icons/lu';
import type { DropdownIndicatorProps } from 'react-select';
import { components } from 'react-select';

export const DropdownIndicator = (props: DropdownIndicatorProps) => {
	return (
		<components.DropdownIndicator {...props}>
			<LuChevronDown className="tw-w-4 tw-h-4" />
		</components.DropdownIndicator>
	);
};
