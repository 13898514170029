import type { IBooking } from '@crac/core/models/entities/Booking';
import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaSignInAlt } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { bookingDetailLoader } from '~/features/booking/shared/utils/bookingDetailLoader';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const Start = lazyWithRetry(() => import('./pages/Start'));

export const startRoutes: { [key: number]: IRouteConfigType } = {};

startRoutes[routesIdMap.BookingsPickUpStart] = {
	component: Start,
	exact: true,
	id: routesIdMap.BookingsPickUpStart,
	name: 'Pick up start',
	path: '/booking/pick-up/:id',
	permissions: [PermissionsType.BookingPickUpProcess],
	loader: ({ params }): Promise<IBooking | null | undefined> => {
		return bookingDetailLoader(params?.id || '');
	},
	icon: FaSignInAlt,
};

startRoutes[routesIdMap.BookingsPickUpStartCustomerSearchResult] = {
	component: Start,
	exact: true,
	id: routesIdMap.BookingsPickUpStartCustomerSearchResult,
	name: 'Customer search',
	path: '/booking/pick-up/:id/search',
	permissions: [PermissionsType.BookingPickUpProcess],
	loader: ({ params }): Promise<IBooking | null | undefined> => {
		return bookingDetailLoader(params?.id || '');
	},
	icon: FaSignInAlt,
};
