import { useCallback, useEffect, useState } from 'react';

import type { UnknownAction } from '@reduxjs/toolkit';

import type { IBranch } from '@crac/core/models/entities/Branch';
import type { IEmployee } from '@crac/core/models/entities/Employee';
import { BookingPermissionsType } from '@crac/core/models/permissions/BookingPermissionsType';
import { regularizationSubjectGetAll } from '@crac/core/modules/booking/regularization/state/actions/RegularizationSubjectActions';
import { branchGetAll } from '@crac/core/redux/actions/BranchActions';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import { setHostName } from '@crac/core/redux/actions/ComputerActions';
import { countryGetAll } from '@crac/core/redux/actions/CountryActions';
import { employeeLogIn } from '@crac/core/redux/actions/EmployeeActions';
import { excessRateGetAll } from '@crac/core/redux/actions/ExcessRateActions';
import { fineReasonGetAll } from '@crac/core/redux/actions/FineReasonActions';
import { franchiseGetAll } from '@crac/core/redux/actions/FranchiseActions';
import { languageGetAll } from '@crac/core/redux/actions/LanguageActions';
import { paymentMethodGetAll } from '@crac/core/redux/actions/PaymentMethodActions';
import { paymentTermGetAll } from '@crac/core/redux/actions/PaymentTermActions';
import { providerGetAll } from '@crac/core/redux/actions/ProviderActions';
import { repairTypeGetAll } from '@crac/core/redux/actions/RepairTypeActions';
import { servicesGetAll } from '@crac/core/redux/actions/ServicesActions';
import { spendingTypeGetAll } from '@crac/core/redux/actions/SpendingTypeActions';
import { taxOfficeGetAll } from '@crac/core/redux/actions/TaxOfficeActions';
import { vehicleGroupGetByProvider } from '@crac/core/redux/actions/VehicleGroupActions';

import { useBookingRegularizationSubjectsSelector } from '~/features/booking/regularizations/state/selectors/regularizationSubject';
import { useCashSpendingTypeSelector } from '~/features/management/cash/state/selectors';
import { checkCreationMethod } from '~/features/shared/helpers/checkCreationMethod';
import { useAlertMessage } from '~/features/shared/hooks/useAlertMessage';
import { useBindAndMemoizeActions } from '~/features/shared/hooks/useBindAndMemoizeActions';
import { useGetBranchesSelector } from '~/features/shared/state/selectors/BranchSelectors';
import { useCountrySelector } from '~/features/shared/state/selectors/CountrySelectors';
import { useExcessRateSelector } from '~/features/shared/state/selectors/ExcessRateSelectors';
import { useLanguageSelector } from '~/features/shared/state/selectors/LanguageSelectors';
import { usePaymentMethodSelector } from '~/features/shared/state/selectors/PaymentMethodSelector';
import { usePaymentTermSelector } from '~/features/shared/state/selectors/PaymentTermSelector';
import { useProviderSelector } from '~/features/shared/state/selectors/ProviderSelectors';
import { useRepairTypeSelector } from '~/features/shared/state/selectors/RepairTypeSelectors';
import { useServicesSelector } from '~/features/shared/state/selectors/ServicesSelectors';
import { useTaxOfficeSelector } from '~/features/shared/state/selectors/TaxOfficeSelectors';
import { useVehicleGroupSelector } from '~/features/shared/state/selectors/VehicleGroupSelector';

const actionsImported = {
	branchGetAll,
	clearGlobalCache,
	countryGetAll,
	employeeLogIn,
	fineReasonGetAll,
	franchiseGetAll,
	languageGetAll,
	providerGetAll,
	regularizationSubjectGetAll,
	repairTypeGetAll,
	servicesGetAll,
	setHostName,
	spendingTypeGetAll,
	taxOfficeGetAll,
	vehicleGroupGetByProvider,
	excessRateGetAll,
	paymentTermGetAll,
	paymentMethodGetAll,
};

export const useLoadCachedData = (employee: IEmployee | null, isEmployeeLoginSubmitted: boolean) => {
	const [isSubmitted, setIsSubmitted] = useState(false);

	const actions = useBindAndMemoizeActions(actionsImported);
	const { showAlert } = useAlertMessage();

	// Load cached data
	const { branchRequest } = useGetBranchesSelector();
	const { countriesGetAllRequest } = useCountrySelector();
	const { languagesRequest } = useLanguageSelector();
	const { taxOfficesRequest } = useTaxOfficeSelector();
	const { providersRequest } = useProviderSelector();
	const { regularizationSubjectsRequest } = useBookingRegularizationSubjectsSelector();
	const { excessRatesPerProviderRequest } = useExcessRateSelector();
	const { repairTypesRequest } = useRepairTypeSelector();
	const { servicesRequest } = useServicesSelector();
	const { spendingTypesRequest } = useCashSpendingTypeSelector();
	const { vehicleGroupsByProviderRequest } = useVehicleGroupSelector();
	const { paymentTermGetAllRequest } = usePaymentTermSelector();
	const { paymentMethodGetAllRequest } = usePaymentMethodSelector();

	const allRequests = [
		vehicleGroupsByProviderRequest,
		countriesGetAllRequest,
		languagesRequest,
		branchRequest,
		providersRequest,
		spendingTypesRequest,
		regularizationSubjectsRequest,
		excessRatesPerProviderRequest,
		repairTypesRequest,
		servicesRequest,
		taxOfficesRequest,
		paymentTermGetAllRequest,
		paymentMethodGetAllRequest,
	];

	const isLoading = allRequests.some((request) => Boolean(request?.inProgress));
	const errors = allRequests.map((request) => request?.messages ?? []).flat();

	const handleLoadCachedData = useCallback(async () => {
		if (!employee) {
			return;
		}
		const disabledLoader = { loader: { active: false } };
		const creationMethod = checkCreationMethod(new BookingPermissionsType(employee.permissions));
		actions.vehicleGroupGetByProvider({ token: undefined, ...disabledLoader });
		actions.countryGetAll(disabledLoader);
		actions.languageGetAll({
			creationMethod,
			...disabledLoader,
		});

		const branchGetAllResult = await (actions.branchGetAll(disabledLoader) as unknown as Promise<UnknownAction>);
		if (branchGetAll.fulfilled.match(branchGetAllResult)) {
			const branches = branchGetAllResult.payload.branches as IBranch[];
			const branch = branches.find((branch: IBranch) => branch.code === employee.branchCode);
			if (branch) {
				// Load payment terms
				actions.paymentTermGetAll({ provider: branch?.provider, ...disabledLoader });
				// Load payment methods
				actions.paymentMethodGetAll({ provider: branch?.provider, ...disabledLoader });
			}
		}

		actions.providerGetAll({ ...disabledLoader, isPublic: true });
		actions.spendingTypeGetAll(disabledLoader);
		actions.regularizationSubjectGetAll(disabledLoader);
		actions.excessRateGetAll(disabledLoader);
		actions.repairTypeGetAll(disabledLoader);
		actions.servicesGetAll(disabledLoader);
		actions.taxOfficeGetAll(disabledLoader);
	}, [actions, employee]);

	useEffect(() => {
		if (!employee || isSubmitted || !isEmployeeLoginSubmitted) {
			return;
		}
		setIsSubmitted(true);
		handleLoadCachedData();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [employee, isSubmitted]);

	// CHECK REQUESTS
	useEffect(() => {
		if (isSubmitted && !isLoading) {
			if (errors.length) {
				showAlert({ message: errors.map((error) => error.message).join('\n'), type: 'error' });
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [errors, isSubmitted, isLoading]);

	return { handleLoadCachedData, isLoading, isSubmitted };
};
