import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaRegPenToSquare } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const RevisionCost = lazyWithRetry(() => import('./pages/RevisionCost'));
const RevisionCostLink = lazyWithRetry(() => import('./pages/RevisionCostLink'));

export const revisionCostRoutes: { [key: number]: IRouteConfigType } = {};

revisionCostRoutes[routesIdMap.WorkshopRevisionCost] = {
	component: RevisionCost,
	exact: true,
	id: routesIdMap.WorkshopRevisionCost,
	name: 'Revision',
	menuText: 'Cost',
	path: '/workshop/revision-cost',
	icon: FaRegPenToSquare,
	permissions: [PermissionsType.RevisionCost],
};

revisionCostRoutes[routesIdMap.WorkshopRevisionCostLink] = {
	component: RevisionCostLink,
	exact: true,
	id: routesIdMap.WorkshopRevisionCostLink,
	name: 'Revision Link',
	path: '/workshop/revision-cost/link/:movement/:plateNumber',
	permissions: [PermissionsType.RevisionCost],
	icon: FaRegPenToSquare,
};
