/**
 * @deprecated - use 'VehicleModelEndPoints' from 'packages\core\src\modules\fleet\vehicleModel\services\FleetVehicleModelEndpoints.ts' instead.
 */
export enum VehicleModelEndPoints {
	/**
	 * Vehicle model search
	 * @host `/vehicleModel/search/`
	 */
	SEARCH = '/vehicleModel/search/',
	/**
	 * Vehicle model insert
	 * @host `/vehicleModel/insert/`
	 */
	INSERT = '/vehicleModel/insert/',
	/**
	 * Vehicle model modify
	 * @host `/vehicleModel/modify/`
	 */
	MODIFY = '/vehicleModel/modify/',
	/**
	 * Vehicle model deactivate
	 * @host `/vehicleModel/deactivate/`
	 */
	DEACTIVATE = '/vehicleModel/deactivate/',

	/**
	 * Vehicle model activate
	 * @host `/vehicleModel/activate/`
	 */
	ACTIVATE = '/vehicleModel/activate/',
}
