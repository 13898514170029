import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaPencil, FaPlus, FaRegFilePowerpointAlt } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

import { getPricingPermission } from '../utils/helpers';

const PreauthorizationRate = lazyWithRetry(() => import('./pages/PreauthorizationRate'));
const PreauthorizationRateNew = lazyWithRetry(() => import('./pages/PreauthorizationRateNew'));
const PreauthorizationRateEdit = lazyWithRetry(() => import('./pages/PreauthorizationRateEdit'));

export const preauthorizationRateRoutes: { [key: number]: IRouteConfigType } = {};

preauthorizationRateRoutes[routesIdMap.PreauthorizationRate] = {
	component: PreauthorizationRate,
	exact: true,
	id: routesIdMap.PreauthorizationRate,
	name: 'Preauthorization rates',
	menuText: 'Preauthorization',
	path: '/pricing/preauthorization-rate',
	permissions: getPricingPermission([PermissionsType.PricingPreauthorizationRate]),
	childrens: [routesIdMap.PreauthorizationRateNew, routesIdMap.PreauthorizationRateEdit],
	icon: FaRegFilePowerpointAlt,
};

preauthorizationRateRoutes[routesIdMap.PreauthorizationRateNew] = {
	component: PreauthorizationRateNew,
	exact: true,
	isChild: true,
	id: routesIdMap.PreauthorizationRateNew,
	name: 'Preauthorization rate new',
	path: '/pricing/preauthorization-rate/new',
	permissions: getPricingPermission([PermissionsType.PricingPreauthorizationRate]),
	icon: FaPlus,
	menuText: 'New preauthorization rate',
};

preauthorizationRateRoutes[routesIdMap.PreauthorizationRateEdit] = {
	component: PreauthorizationRateEdit,
	exact: true,
	isChild: true,
	id: routesIdMap.PreauthorizationRateEdit,
	name: 'Preauthorization rate modify',
	path: '/pricing/preauthorization-rate/modify/:id',
	permissions: getPricingPermission([PermissionsType.PricingPreauthorizationRate]),
	icon: FaPencil,
};
