import { createSlice } from '@reduxjs/toolkit';

import {
	supplierLocationClear,
	supplierLocationDelete,
	supplierLocationInsert,
	supplierLocationModify,
	supplierLocationSearch,
} from '@crac/core/modules/supplier/location/state/actions/SupplierLocationActions';
import type { ISupplierLocationStateType } from '@crac/core/modules/supplier/location/state/stateType/SupplierLocationStateType';
const initialState: ISupplierLocationStateType = {
	locations: [],
	supplierLocationDeleteRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	supplierLocationInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	supplierLocationModifyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	supplierLocationSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const locationSlice = createSlice({
	name: 'LocationSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// SEARCH
		builder
			.addCase(supplierLocationSearch.pending, (state) => {
				return {
					...state,
					supplierLocationSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(supplierLocationSearch.rejected, (state, action) => {
				return {
					...state,
					supplierLocationSearchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			})
			.addCase(supplierLocationSearch.fulfilled, (state, action) => {
				return {
					...state,
					supplierLocationSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					locations: action.payload,
				};
			});
		// INSERT
		builder
			.addCase(supplierLocationInsert.pending, (state) => {
				return {
					...state,
					supplierLocationInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(supplierLocationInsert.rejected, (state, action) => {
				return {
					...state,
					supplierLocationInsertRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			})
			.addCase(supplierLocationInsert.fulfilled, (state, action) => {
				return {
					...state,
					supplierLocationInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					locations: [...state.locations, action.payload],
				};
			});
		// MODIFY
		builder
			.addCase(supplierLocationModify.pending, (state) => {
				return {
					...state,
					supplierLocationModifyRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(supplierLocationModify.rejected, (state, action) => {
				return {
					...state,
					supplierLocationModifyRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			})
			.addCase(supplierLocationModify.fulfilled, (state, action) => {
				return {
					...state,
					supplierLocationModifyRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					locations: state.locations.map((item) => (item.id === action.payload.id ? action.payload : item)),
				};
			});
		// DELETE
		builder
			.addCase(supplierLocationDelete.pending, (state) => {
				return {
					...state,
					supplierLocationDeleteRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(supplierLocationDelete.rejected, (state, action) => {
				return {
					...state,
					supplierLocationDeleteRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			})
			.addCase(supplierLocationDelete.fulfilled, (state, action) => {
				return {
					...state,
					supplierLocationDeleteRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					locations: state.locations.filter(({ id }) => id !== action.payload.id),
				};
			});
		// CLEAR
		builder.addCase(supplierLocationClear, (state) => {
			return {
				...state,
				locations: [],
			};
		});
	},
});

export const locationReducer = locationSlice.reducer;
