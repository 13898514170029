import { createAction } from '@reduxjs/toolkit';

import {
	vehicleServiceAssignTag,
	vehicleServiceBlock,
	vehicleServiceChangeCarrier,
	vehicleServiceChangeEndAmortizationDate,
	vehicleServiceChangeLastRevisionKm,
	vehicleServiceChangeState,
	vehicleServiceClose,
	vehicleServiceGetByPlateNumber,
	vehicleServiceGetReadyByCurrentBranch,
	vehicleServiceLock,
	vehicleServiceOpen,
	vehicleServicePreparation,
	vehicleServiceReception,
	vehicleServiceReturn,
	vehicleServiceRevision,
	vehicleServiceSearch,
	vehicleServiceSearchFleet,
	vehicleServiceUnlock,
	vehicleServiceUpdateKeysLocation,
	vehicleServiceUpdateOBE,
} from '../../data/services/VehicleService';
import type {
	IVehicleAssignTagParams,
	IVehicleBlockParams,
	IVehicleChangeCarrierParams,
	IVehicleChangeEndAmortizationDateParams,
	IVehicleChangeLastRevisionKmParams,
	IVehicleChangeStateParams,
	IVehicleCloseParams,
	IVehicleFleetSearchParams,
	IVehicleGetByPlateNumberParams,
	IVehicleGetKeychainsLabelsParams,
	IVehicleGetReadyByCurrentBranchParams,
	IVehicleLockParams,
	IVehicleOpenParams,
	IVehiclePreparationParams,
	IVehicleReceptionParams,
	IVehicleReturnParams,
	IVehicleRevisionParams,
	IVehicleSearchParams,
	IVehicleUnlockParams,
	IVehicleUpdateKeysLocationParams,
	IVehicleUpdateObeParams,
} from '../../models/serviceParams/VehicleParams';
import { createSyncAction } from '../../modules/shared/state/createAction';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';
import type { IVehicle } from '../../modules/vehicle/entities/Vehicle';

/**
 * @deprecated - use 'vehicleSearch' from 'modules\vehicle\search\state\actions'
 * Searches for vehicles based on specified parameters.
 *
 * @param {IVehicle[]} payload - The vehicles to be retrieved.
 * @param {IVehicleSearchParams} params - Parameters for the search of vehicles.
 * @returns {Promise<void>} - A promise that resolves to an array of vehicles.
 */
export const vehicleSearch = createAsyncAction<IVehicle[], IVehicleSearchParams>(
	'vehicle/search',
	vehicleServiceSearch,
);

/**
 * @deprecated - use 'vehicleGetByPlateNumber' from 'modules\vehicle\detail\state\actions'
 * Retrieves a vehicle by its plate number.
 *
 * @param {IVehicle} payload - The vehicle to be retrieved.
 * @param {IVehicleGetByPlateNumberParams} params - Parameters for identifying the vehicle by its plate number.
 * @returns {Promise<void>} - A promise that resolves to the specified vehicle.
 */
export const vehicleGetByPlateNumber = createAsyncAction<IVehicle, IVehicleGetByPlateNumberParams>(
	'vehicle/getByPlateNumber',
	vehicleServiceGetByPlateNumber,
);

/**
 * @deprecated Use `vehicleGetReadyByCurrentBranch` from `modules\vehicle\availables\state\actions`
 * Retrieves vehicles that are ready at the current branch.
 *
 * @param {IVehicle[]} payload - The vehicles to be retrieved.
 * @param {IVehicleGetReadyByCurrentBranchParams} params - Parameters for identifying vehicles ready at the current branch.
 * @returns {Promise<void>} - A promise that resolves to an array of vehicles.
 */
export const vehicleGetReadyByCurrentBranch = createAsyncAction<IVehicle[], IVehicleGetReadyByCurrentBranchParams>(
	'vehicle/getReadyByCurrentBranch',
	vehicleServiceGetReadyByCurrentBranch,
);

/**
 * @deprecated - use 'vehicleUpdateOBE' from 'modules\vehicle\detail\state\actions'
 * Updates the OBE (On-Board Equipment) of a vehicle.
 *
 * @param {IVehicle} payload - The vehicle for which the OBE is updated.
 * @param {IVehicleUpdateObeParams} params - Parameters for updating the OBE of the vehicle.
 * @returns {Promise<void>} - A promise that resolves after the OBE update.
 */
export const vehicleUpdateOBE = createAsyncAction<IVehicle, IVehicleUpdateObeParams>(
	'vehicle/updateObe',
	vehicleServiceUpdateOBE,
);

/**
 * @deprecated - use 'vehicleAssignTag' from 'modules\vehicle\detail\state\actions'
 * Assigns a tag to a vehicle or vehicles.
 *
 * @param {IVehicle[]} payload - The vehicles to which the tag is assigned.
 * @param {IVehicleAssignTagParams} params - Parameters for the assignment of the tag.
 * @returns {Promise<void>} - A promise that resolves after the tag is assigned to the vehicle(s).
 */
export const vehicleAssignTag = createAsyncAction<IVehicle[], IVehicleAssignTagParams>(
	'vehicle/assignTag',
	vehicleServiceAssignTag,
);

/**
 * @deprecated - use 'vehicleReception' from 'modules\vehicle\reception\state\actions'
 * Processes the reception of a vehicle.
 *
 * @param {IVehicle} payload - The vehicle being received.
 * @param {IVehicleReceptionParams} params - Parameters for the reception of the vehicle.
 * @returns {Promise<void>} - A promise that resolves after the vehicle is processed for reception.
 */
export const vehicleReception = createAsyncAction<IVehicle, IVehicleReceptionParams>(
	'vehicle/reception',
	vehicleServiceReception,
);

/**
 * @deprecated - use 'vehicleReturn' from 'modules\vehicle\return\state\actions'
 * Processes the return of a vehicle.
 *
 * @param {IVehicle} payload - The vehicle being returned.
 * @param {IVehicleReturnParams} params - Parameters for the return of the vehicle.
 * @returns {Promise<void>} - A promise that resolves after the vehicle is processed for return.
 */
export const vehicleReturn = createAsyncAction<IVehicle, IVehicleReturnParams>('vehicle/return', vehicleServiceReturn);

/**
 * @deprecated - use 'vehicleGetKeychainsLabels' from 'modules\vehicle\keyChainsLabels\state\actions'
 * Retrieves keychains labels for vehicles.
 *
 * @param {IVehicle[]} payload - The vehicles for which keychains labels are retrieved.
 * @param {IVehicleGetKeychainsLabelsParams} params - Parameters for retrieving the keychains labels.
 * @returns {Promise<void>} - A promise that resolves to an array of vehicles with their keychains labels.
 */
export const vehicleGetKeychainsLabels = createAsyncAction<IVehicle[], IVehicleGetKeychainsLabelsParams>(
	'vehicle/getKeychainsLabels',
	vehicleServiceSearch,
);

/**
 * @deprecated - use 'vehicleChangeState' from 'modules\vehicle\changeState\state\actions'
 * Changes the state of a vehicle.
 *
 * @param {IVehicle} payload - The vehicle for which the state is changed.
 * @param {IVehicleChangeStateParams} params - Parameters for changing the state of the vehicle.
 * @returns {Promise<void>} - A promise that resolves after the state of the vehicle is changed.
 */
export const vehicleChangeState = createAsyncAction<IVehicle, IVehicleChangeStateParams>(
	'vehicle/changeState',
	vehicleServiceChangeState,
);

/**
 * @deprecated - use 'vehiclePreparation' from 'modules\vehicle\preparation\state\actions'
 * Prepares a vehicle for rental or other use.
 *
 * @param {IVehicle} payload - The vehicle to be prepared.
 * @param {IVehiclePreparationParams} params - Parameters for the preparation of the vehicle.
 * @returns {Promise<void>} - A promise that resolves after the vehicle is prepared.
 */
export const vehiclePreparation = createAsyncAction<IVehicle, IVehiclePreparationParams>(
	'vehicle/preparation',
	vehicleServicePreparation,
);

/**
 * @deprecated - use 'vehicleBlock' from 'modules\vehicle\detail\state\actions'
 * Blocks a vehicle, preventing it from being used or rented.
 *
 * @param {IVehicle} payload - The vehicle to be blocked.
 * @param {IVehicleBlockParams} params - Parameters for the blocking of the vehicle.
 * @returns {Promise<void>} - A promise that resolves after the vehicle is blocked.
 */
export const vehicleBlock = createAsyncAction<IVehicle, IVehicleBlockParams>('vehicle/block', vehicleServiceBlock);

/**
 * @deprecated - use 'vehicleUnlock' from 'modules\vehicle\detail\state\actions'
 * Unlocks a vehicle, allowing it to be used or rented.
 *
 * @param {boolean} payload - The result of the unlock operation.
 * @param {IVehicleUnlockParams} params - Parameters for the unlocking of the vehicle.
 * @returns {Promise<void>} - A promise that resolves to a boolean indicating the success of the unlock operation.
 */
export const vehicleUnlock = createAsyncAction<boolean, IVehicleUnlockParams>('vehicle/unlock', vehicleServiceUnlock);

/**
 * @deprecated - use 'vehicleLock' from 'modules\vehicle\detail\state\actions'
 * Locks a vehicle, preventing unauthorized use or access.
 *
 * @param {boolean} payload - The result of the lock operation.
 * @param {IVehicleLockParams} params - Parameters for the locking of the vehicle.
 * @returns {Promise<void>} - A promise that resolves to a boolean indicating the success of the lock operation.
 */
export const vehicleLock = createAsyncAction<boolean, IVehicleLockParams>('vehicle/lock', vehicleServiceLock);

/**
 * @deprecated - use 'vehicleClear' from 'modules\vehicle\search\state\actions'
 * Clears the vehicle state.
 */
export const vehicleClear = createAction('vehicle/clear');

/**
 * @deprecated - use 'vehiclePreparationAdd' from 'modules\vehicle\preparation\state\actions'
 * Adds vehicles to the preparation list.
 *
 * @param {IVehicle[]} payload - The vehicles to be added to the preparation list.
 */
export const vehiclePreparationAdd = createAction<IVehicle[]>('vehicle/preparationAdd');

/**
 * @deprecated - use 'vehiclePreparationRemove' from 'modules\vehicle\preparation\state\actions'
 * Removes a vehicle from the preparation list.
 *
 * @param {IVehicle} payload - The vehicle to be removed from the preparation list.
 */
export const vehiclePreparationRemove = createAction<IVehicle>('vehicle/preparationRemove');

/**
 * @deprecated - use 'vehiclePreparationUpdate' from 'modules\vehicle\preparation\state\actions'
 * Updates the list of vehicles in preparation.
 *
 * @param {IVehicle[]} payload - The updated list of vehicles in preparation.
 */
export const vehiclePreparationUpdate = createSyncAction<IVehicle[]>('vehicle/preparationUpdate');

/**
 * @deprecated - use 'vehiclePreparationClear' from 'modules\vehicle\preparation\state\actions'
 * Clears the vehicle preparation state.
 */
export const vehiclePreparationClear = createAction('vehicle/preparationClear');

/**
 * @deprecated - use 'vehicleSearchClear' from 'modules\vehicle\search\state\actions'
 * Clears the vehicle search state.
 */
export const vehicleSearchClear = createAction('vehicle/searchClear');

/**
 * @deprecated - use 'vehicleKeychainsLabelsClear' from 'modules\vehicle\detail\state\actions'
 * Clears the vehicle keychains labels state.
 */
export const vehicleKeychainsLabelsClear = createAction('vehicle/keychainsLabelsClear');

/**
 * Opens a vehicle, typically for rental or inspection purposes.
 *
 * @param {boolean} payload - The result of the open operation.
 * @param {IVehicleOpenParams} params - Parameters for the opening of the vehicle.
 * @returns {Promise<void>} - A promise that resolves to a boolean indicating the success of the open operation.
 */
export const vehicleOpen = createAsyncAction<boolean, IVehicleOpenParams>('vehicle/open', vehicleServiceOpen);

/**
 * Closes a vehicle, typically after rental or inspection.
 *
 * @param {boolean} payload - The result of the close operation.
 * @param {IVehicleCloseParams} params - Parameters for the closing of the vehicle.
 * @returns {Promise<void>} - A promise that resolves to a boolean indicating the success of the close operation.
 */
export const vehicleClose = createAsyncAction<boolean, IVehicleCloseParams>('vehicle/close', vehicleServiceClose);

/**
 * Searches for vehicles in the fleet based on specified parameters.
 *
 * @param {IVehicle[]} payload - The vehicles to be retrieved.
 * @param {IVehicleFleetSearchParams} params - Parameters for the search of fleet vehicles.
 * @returns {Promise<void>} - A promise that resolves to an array of fleet vehicles.
 */
export const vehicleFleetSearch = createAsyncAction<IVehicle[], IVehicleFleetSearchParams>(
	'vehicle/fleetSearch',
	vehicleServiceSearchFleet,
);

/**
 * Clears the vehicle fleet search state.
 */
export const vehicleFleetSearchClear = createAction('vehicle/fleetSearchClear');

/**
 * @deprecated - use 'vehicleUpdateKeysLocation' from 'modules\vehicle\detail\state\actions'
 * Updates the location of the keys for a vehicle.
 * @param {IVehicle} payload - The vehicle for which the keys location is updated.
 * @param {IVehicleUpdateKeysLocationParams} params - Parameters for updating the keys location of the vehicle.
 */
export const vehicleUpdateKeysLocation = createAsyncAction<IVehicle, IVehicleUpdateKeysLocationParams>(
	'vehicle/updateKeysLocation',
	vehicleServiceUpdateKeysLocation,
);

/**
 * @deprecated - use 'vehicleRevision' from 'modules\vehicle\revision\state\actions'
 * Change the revision status of the selected vehicles.
 */
export const vehicleRevision = createAsyncAction<boolean, IVehicleRevisionParams>(
	'vehicle/revision',
	vehicleServiceRevision,
);

/**
 * @deprecated - use 'vehicleRevisionClear' from 'modules\vehicle\revision\state\actions'
 * Clear the revision status.
 */
export const vehicleRevisionClear = createAction('vehicle/revisionClear');

/**
 * @deprecated - use 'vehicleChangeLastRevisionKm' from 'modules\vehicle\detail\state\actions'
 * Change last revision km
 * @param plateNumber `string` the plate number of the vehicle
 * @param lastRevisionKm `number` the value of the km with decimals
 * @returns `IVehicle` the modified vehicle
 */
export const vehicleChangeLastRevisionKm = createAsyncAction<IVehicle, IVehicleChangeLastRevisionKmParams>(
	'vehicle/changeLastRevisionKm',
	vehicleServiceChangeLastRevisionKm,
);

/**
 * @deprecated - use 'vehicleChangeEndAmortizationDate' from 'modules\vehicle\detail\state\actions'
 * Change end amortization date
 * @param plateNumbers `string[]` the plate numbers of the vehicles
 * @param amortizationDate `string` the new amortization date
 * @returns `IVehicle[]` the modified vehicles
 */
export const vehicleChangeEndAmortizationDate = createAsyncAction<IVehicle[], IVehicleChangeEndAmortizationDateParams>(
	'vehicle/changeEndAmortizationDate',
	vehicleServiceChangeEndAmortizationDate,
);

/**
 * @deprecated - use 'vehicleChangeCarrier' from 'modules\vehicle\detail\state\actions'
 * Change the supplier for the vehicle loaded in the detail page
 * @param plateNumbers `string[]` the plate numbers of the vehicles
 * @param supplierCode `string` the supplier code
 * @returns `IVehicle[]` the changed vehicles
 */
export const vehicleChangeCarrier = createAsyncAction<IVehicle[], IVehicleChangeCarrierParams>(
	'vehicle/changeCarrier',
	vehicleServiceChangeCarrier,
);
