import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

import { VehicleModelEndPoints } from './FleetVehicleModelEndpoints';
import type { IVehicleModel } from '../entities/VehicleModel';

export interface IFleetVehicleModelInsertParams extends IVehicleModel, IServiceBaseParams {}

export const fleetVehicleModelInsertService = (model: IFleetVehicleModelInsertParams) => {
	return Api.post<IVehicleModel, IFleetVehicleModelInsertParams>(VehicleModelEndPoints.INSERT, model);
};
