import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaRegFileAlt } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const InvoiceTo = lazyWithRetry(() => import('./pages/InvoiceTo'));
const InvoiceToCompanyNew = lazyWithRetry(() => import('./pages/InvoiceToCompanyNew'));
const InvoiceToCompanySearch = lazyWithRetry(() => import('./pages/InvoiceToCompanySearch'));
const InvoiceToCustomerNew = lazyWithRetry(() => import('./pages/InvoiceToCustomerNew'));
const InvoiceToCustomerSearch = lazyWithRetry(() => import('./pages/InvoiceToCustomerSearch'));

export const invoiceToRoutes: { [key: number]: IRouteConfigType } = {};

invoiceToRoutes[routesIdMap.BookingsNewInvoiceTo] = {
	component: InvoiceTo,
	exact: true,
	id: routesIdMap.BookingsNewInvoiceTo,
	name: 'Booking invoice to',
	path: '/booking/new/invoice-to',
	permissions: [PermissionsType.BookingNew],
	icon: FaRegFileAlt,
};

const backToPath = invoiceToRoutes[routesIdMap.BookingsNewInvoiceTo].path;
invoiceToRoutes[routesIdMap.BookingsNewInvoiceToCustomerNew] = {
	backToPath,
	component: InvoiceToCustomerNew,
	exact: true,
	id: routesIdMap.BookingsNewInvoiceToCustomerNew,
	name: 'Booking invoice to',
	path: '/booking/new/invoice-to/new-customer',
	permissions: [PermissionsType.BookingNew, PermissionsType.BookingChangeInvoiceToCustomer],
	icon: FaRegFileAlt,
};

invoiceToRoutes[routesIdMap.BookingsNewInvoiceToCustomerSearch] = {
	backToPath,
	component: InvoiceToCustomerSearch,
	exact: true,
	id: routesIdMap.BookingsNewInvoiceToCustomerSearch,
	name: 'Booking invoice to',
	path: '/booking/new/invoice-to/search-customer',
	permissions: [PermissionsType.BookingNew, PermissionsType.BookingChangeInvoiceToCustomer],
	icon: FaRegFileAlt,
};

invoiceToRoutes[routesIdMap.BookingsNewInvoiceToCompanyNew] = {
	backToPath,
	component: InvoiceToCompanyNew,
	exact: true,
	id: routesIdMap.BookingsNewInvoiceToCompanyNew,
	name: 'Booking invoice to',
	path: '/booking/new/invoice-to/new-company',
	permissions: [PermissionsType.BookingNew, PermissionsType.BookingChangeInvoiceToCustomer],
	icon: FaRegFileAlt,
};

invoiceToRoutes[routesIdMap.BookingsNewInvoiceToCompanySearch] = {
	backToPath,
	component: InvoiceToCompanySearch,
	exact: true,
	id: routesIdMap.BookingsNewInvoiceToCompanySearch,
	name: 'Booking invoice to',
	path: '/booking/new/invoice-to/search-company',
	permissions: [PermissionsType.BookingNew, PermissionsType.BookingChangeInvoiceToCustomer],
	icon: FaRegFileAlt,
};
