import { VehicleGroupEndPoints } from './VehicleGroupEndpoints';
import { Api } from '../../../../modules/shared/api';
import type { IServiceBaseParams } from '../../../../modules/shared/types/ServiceBaseParams';
import type { IVehicleGroup } from '../entities/VehicleGroup';

export interface IVehicleGroupSearchParams extends IServiceBaseParams {
	provider: string;
	code?: string;
}
/**
 * Search vehicle groups
 * @params IVehicleGroupSearchParams
 * @returns {Array} IVehicleGroup[]
 */
export const vehicleGroupSearchService = (model: IVehicleGroupSearchParams) =>
	Api.get<IVehicleGroup[], IVehicleGroupSearchParams>(VehicleGroupEndPoints.SEARCH, model);
