import { servicesServiceGetAll } from '../../data/services/ServicesService';
import type { IService } from '../../models/entities/Service';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';
import type { IServiceBaseParams } from '../../modules/shared/types/ServiceBaseParams';

/**
 * @deprecated Use `modules/service/state/actions`
 * Retrieves all services.
 *
 * @param {IService[]} payload - The services to be retrieved.
 * @param {IServiceBaseParams} params - Base service parameters, potentially used for filtering or configuration.
 * @returns {Promise<void>} - A promise that resolves to an array of all services.
 */
export const servicesGetAll = createAsyncAction<IService[], IServiceBaseParams>(
	'services/getAll',
	servicesServiceGetAll,
);
