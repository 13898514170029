import { DateTime } from 'luxon';

import type { IBooking } from '@crac/core/modules/booking/entities/Booking';
import type { ICustomer } from '@crac/core/modules/customer/entities/Customer';
import { customerGetByCodeService } from '@crac/core/modules/customer/services/CustomerGetByCodeService';
import { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';
import { vehicleGetByPlateNumberService } from '@crac/core/modules/vehicle/detail/services/VehicleGetByPlateNumberService';

import { linkServiceAddToAccident } from '../../../../../../src/data/services/LinkService';
import type { ILinkAddToAccidentParams } from '../../../../../../src/models/serviceParams/LinkParams';
import type { AccidentDocument } from '../../../../../../src/modules/workshop/accident/types/AccidentDocumentType';
import { AccidentDocumentType } from '../../../../../../src/modules/workshop/accident/types/AccidentDocumentType';
import { bookingServiceGetByAccidentPartData } from '../../../../../data/services/BookingService';
import type { IBranch } from '../../../../../models/entities/Branch';
import { createSyncAction } from '../../../../shared/state/createAction';
import { createAsyncAction } from '../../../../shared/state/createAsyncAction';
import type { IServiceBaseParams } from '../../../../shared/types/ServiceBaseParams';
import type { IVehicle } from '../../../../vehicle/entities/Vehicle';
import type { IAccident } from '../../entities/Accident';
import type { IAccidentDeleteParams } from '../../services/AccidentDeleteService';
import { accidentDeleteService } from '../../services/AccidentDeleteService';
import type { IAccidentGetByAccidentPartParams } from '../../services/AccidentGetByAccidentPartService';
import { accidentGetByAccidentPartService } from '../../services/AccidentGetByAccidentPartService';
import type { IAccidentGetByBookingParams } from '../../services/AccidentGetByBookingService';
import { accidentGetByBookingService } from '../../services/AccidentGetByBookingService';
import type { IAccidentGetByVehicleParams } from '../../services/AccidentGetByVehicleService';
import { accidentGetByVehicleService } from '../../services/AccidentGetByVehicleService';
import type { IAccidentInsertParams } from '../../services/AccidentInsertService';
import { accidentInsertService } from '../../services/AccidentInsertService';
import type { IAccidentModifyParams } from '../../services/AccidentModifyService';
import { accidentModifyService } from '../../services/AccidentModifyService';
import type { IAccidentSearchParams } from '../../services/AccidentSearchService';
import { accidentSearchService } from '../../services/AccidentSearchService';
import type { IAccidentSendMailParams } from '../../services/AccidentSendEmailService';
import { accidentSendMailService } from '../../services/AccidentSendEmailService';

//* SEARCH
export const accidentSearch = createAsyncAction<IAccident[], IAccidentSearchParams>(
	'accident/search',
	accidentSearchService,
);

//* GET BY ACCIDENT PART
export const accidentGetByAccidentPart = createAsyncAction<IAccident[], IAccidentGetByAccidentPartParams>(
	'accident/getByAccidentPart',
	accidentGetByAccidentPartService,
);

//* GET BY BOOKING
export const accidentGetByBooking = createAsyncAction<IAccident[], IAccidentGetByBookingParams>(
	'accident/getByBooking',
	accidentGetByBookingService,
);

//* GET BY VEHICLE
export const accidentGetByVehicle = createAsyncAction<IAccident[], IAccidentGetByVehicleParams>(
	'accident/getByVehicle',
	accidentGetByVehicleService,
);

//* INSERT
export const accidentInsert = createAsyncAction<IAccident, IAccidentInsertParams>(
	'accident/insert',
	accidentInsertService,
);

export type IAccidentGetPartDataParams = {
	plateNumber: string;
	accidentDateTime: Date;
	branches: IBranch[];
} & IServiceBaseParams;

type AccidentPartDataType = {
	booking: IBooking;
	customer: ICustomer;
	vehicle: IVehicle;
};

//* GET PART DATA
export const accidentGetPartData = createAsyncAction<AccidentPartDataType | null, IAccidentGetPartDataParams>(
	'accident/getPartData',
	async (params: IAccidentGetPartDataParams) => {
		const { branches, accidentDateTime, ...model } = params;
		const vehicleResponse = await vehicleGetByPlateNumberService({
			plateNumber: model.plateNumber,
		});

		if (vehicleResponse.ok && vehicleResponse.data) {
			const timeZone = branches.find(({ code }) => code === vehicleResponse.data?.currentBranchCode)?.timeZone;
			if (timeZone) {
				const bookingResponse = await bookingServiceGetByAccidentPartData({
					...model,
					accidentDateTime: DateTime.fromJSDate(accidentDateTime).setZone(timeZone).toJSDate(),
				});

				if (bookingResponse.ok && bookingResponse.data) {
					if (!bookingResponse.data.customerCode) {
						return new ServiceResponse<AccidentPartDataType>(false, null, [
							{ message: 'Missing customer in booking.' },
						]);
					}
					const customerResponse = await customerGetByCodeService({
						customerCode: bookingResponse.data.customerCode,
					});

					if (customerResponse.ok && customerResponse.data) {
						return {
							data: {
								booking: bookingResponse.data,
								customer: customerResponse.data,
								vehicle: vehicleResponse.data,
							},
							ok: true,
							errors: [],
						};
					}

					return new ServiceResponse<AccidentPartDataType>(false, null, customerResponse.errors);
				}
				return new ServiceResponse<AccidentPartDataType>(false, null, bookingResponse.errors);
			}
		}
		return new ServiceResponse<AccidentPartDataType>(false, null, vehicleResponse.errors);
	},
);

type AccidentAndDocumentsType = {
	accident: IAccident;
	documents: AccidentDocument[];
};

//* MODIFY
export const accidentModify = createAsyncAction<AccidentAndDocumentsType, IAccidentModifyParams>(
	'accident/modify',
	async (params: IAccidentModifyParams) => {
		const accidentFiles: ILinkAddToAccidentParams['files'] = [];

		if (params.appraisalAmountCollectedUpload) {
			params.appraisalAmountCollectedUpload.forEach((file) => {
				accidentFiles.push({
					accidentDocumentType: AccidentDocumentType.AppraisalCollected,
					file,
				});
			});
		}

		if (params.appraisalReceivedUpload) {
			params.appraisalReceivedUpload.forEach((file) => {
				accidentFiles.push({
					accidentDocumentType: AccidentDocumentType.AppraisalReceived,
					file,
				});
			});
		}

		if (params.appraisalPhotosUpload) {
			params.appraisalPhotosUpload.forEach((file) => {
				accidentFiles.push({
					accidentDocumentType: AccidentDocumentType.AppraisalPhoto,
					file,
				});
			});
		}

		if (params.dneaUpload) {
			params.dneaUpload.forEach((file) => {
				accidentFiles.push({
					accidentDocumentType: AccidentDocumentType.Declaration,
					file,
				});
			});
		}

		if (params.dneaUploadReceivedAt) {
			params.dneaUploadReceivedAt.forEach((file) => {
				accidentFiles.push({
					accidentDocumentType: AccidentDocumentType.DeclarationReceived,
					file,
				});
			});
		}

		if (params.sheetUpload) {
			params.sheetUpload.forEach((file) => {
				accidentFiles.push({
					accidentDocumentType: AccidentDocumentType.Sheet,
					file,
				});
			});
		}

		let documents: AccidentDocument[] = [];

		if (accidentFiles.length) {
			const linkResponse = await linkServiceAddToAccident({
				documentNumber: params.documentNumber,
				files: accidentFiles,
				key: params.key,
				plateNumber: params.plateNumber,
			});

			if (!linkResponse.ok || !linkResponse.data) {
				return new ServiceResponse<AccidentAndDocumentsType>(false, null, linkResponse.errors);
			}

			documents = linkResponse.data;
		}

		const modifyResponse = await accidentModifyService({
			...params,
			appraisalAmountCollectedUpload: undefined,
			appraisalPhotosUpload: undefined,
			appraisalReceivedUpload: undefined,
			dneaUpload: undefined,
			dneaUploadReceivedAt: undefined,
			sheetUpload: undefined,
		});

		if (!modifyResponse.ok || !modifyResponse.data) {
			return new ServiceResponse<AccidentAndDocumentsType>(false, null, modifyResponse.errors);
		}

		return new ServiceResponse<AccidentAndDocumentsType>(true, { accident: modifyResponse.data, documents });
	},
);

//* DELETE
export const accidentDelete = createAsyncAction<IAccident, IAccidentDeleteParams>(
	'accident/delete',
	accidentDeleteService,
);

//* SEND MAIL
export const accidentSendMail = createAsyncAction<IAccident, IAccidentSendMailParams>(
	'accident/sendMail',
	accidentSendMailService,
);

//* CLEAR
export const accidentsClear = createSyncAction('accidents/clear');
