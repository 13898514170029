import { SaleOrderEndPoints } from './SaleOrderEndPoints';
import { Api } from '../../../../modules/shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ISaleOrder } from '../entities/SaleOrder';
import type { SaleOrderType } from '../types/SaleOrderType';

export interface ISaleOrderInsertParams extends IServiceBaseParams {
	customerCode: string;
	orderDate: string;
	vehicles: { plateNumber: string; amount: number }[];
	type: SaleOrderType;
	isDepositRequired?: boolean;
}

/**
 * Inserts a new vehicle sale order.
 * @param params - Parameters for the insertion of the sale order.
 * @returns A promise that resolves to the inserted sale order.
 */
export const saleOrderInsertService = (params: ISaleOrderInsertParams) => {
	return Api.post<ISaleOrder, ISaleOrderInsertParams>(SaleOrderEndPoints.INSERT, params);
};
