import { ExcessRateEndpoints } from './ExcessRateEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IExcessRate } from '../entities/ExcessRate';

export interface IExcessRateDeleteParams extends IServiceBaseParams {
	provider: string;
	code: string;
	vehicleGroup?: string;
	vehicleModel?: string;
}

/**
 * Delete an excess rate
 * @param model - excess rate to delete
 * @returns - deleted excess rate
 */
export const excessRateDeleteService = (model: IExcessRateDeleteParams) => {
	return Api.post<IExcessRate, IExcessRateDeleteParams>(ExcessRateEndpoints.DELETE, model);
};
