import { FleetPrevisionEndPoints } from './FleetPrevisionEndPoints';
import { Api } from '../../../../modules/shared/api';
import type { IServiceBaseParams } from '../../../../modules/shared/types/ServiceBaseParams';
import type { IFleetPrevision } from '../entities/FleetPrevision';

export interface IFleetPrevisionSearchParams extends IServiceBaseParams {
	provider: string;
	branchCodes?: string[];
	groups?: string[];
	brands?: string[];
	models?: string[];
	suppliers?: string[];
	show?: number;
	previsionCode?: string;
	fromPurchaseDate?: string;
	toPurchaseDate?: string;
	fromSaleDate?: string;
	toSaleDate?: string;
	insuranceProviderCodes?: string[];
	fleetTypologies?: number[];
}

export const fleetPrevisionSearchService = (model: IFleetPrevisionSearchParams) => {
	return Api.get<IFleetPrevision[], IFleetPrevisionSearchParams>(FleetPrevisionEndPoints.SEARCH, model);
};
