import { SupplierLocationEndpoints } from './SupplierLocationEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ISupplierLocation } from '../entities/SupplierLocation';

export interface ISupplierLocationSearchParams extends IServiceBaseParams {
	id?: number;
	supplier?: string;
	company?: string;
	name?: string;
	country?: string;
	city?: string;
	postCode?: string;
	county?: string;
}

export const supplierLocationSearchService = (model: ISupplierLocationSearchParams) =>
	Api.get<ISupplierLocation[], ISupplierLocationSearchParams>(SupplierLocationEndpoints.SEARCH, model);
