import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaArrowDownAZ, FaPlus } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

import { getPricingPermission } from '../utils/helpers';

const AvailabilityConfig = lazyWithRetry(() => import('./pages/AvailabilityConfig'));
const AvailabilityConfigNew = lazyWithRetry(() => import('./pages/AvailabilityConfigNew'));

export const availabilityConfigRoutes: { [key: number]: IRouteConfigType } = {};

// GROUP AVAILABILITY
availabilityConfigRoutes[routesIdMap.VehicleGroupAvailability] = {
	component: AvailabilityConfig,
	exact: true,
	id: routesIdMap.VehicleGroupAvailability,
	name: 'Vehicle group availability',
	menuText: 'Group availability',
	path: '/pricing/vehicle-group-availability',
	permissions: getPricingPermission([PermissionsType.PricingAvailabilityConfiguration]),
	childrens: [routesIdMap.VehicleGroupAvailabilityNew],
	icon: FaArrowDownAZ,
};

availabilityConfigRoutes[routesIdMap.VehicleGroupAvailabilityNew] = {
	component: AvailabilityConfigNew,
	exact: true,
	isChild: true,
	id: routesIdMap.VehicleGroupAvailabilityNew,
	name: 'New group availability',
	path: '/pricing/vehicle-group-availability/new',
	permissions: getPricingPermission([PermissionsType.PricingAvailabilityConfiguration]),
	icon: FaPlus,
	menuText: 'New group availability',
};
