/**
 * @deprecated - use 'RevisionLineEndPoints' from 'packages\core\src\modules\workshop\revisionCost\services\RevisionLineEndpoints.ts'
 */
export enum RevisionLineEndPoints {
	/**
	 * Get revision lines by vehicle plateNumber & budget id
	 * @host `/revisionLine/getByBudgetAndVehicle`
	 */
	GET_BY_BUDGET = '/revisionLine/getByBudget',
}
