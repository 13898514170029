import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaChartBar, FaPlus } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const SaleMargins = lazyWithRetry(() => import('./pages/SaleMargins'));
const SaleMarginsNew = lazyWithRetry(() => import('./pages/SaleMarginsNew'));

export const saleMarginsRoutes: { [key: number]: IRouteConfigType } = {};

saleMarginsRoutes[routesIdMap.SaleMargins] = {
	component: SaleMargins,
	exact: true,
	id: routesIdMap.SaleMargins,
	name: 'Sale margins',
	menuText: 'Margins',
	path: '/sale/margins',
	permissions: [PermissionsType.SaleMargin],
	childrens: [routesIdMap.SaleMarginsNew],
	icon: FaChartBar,
};

saleMarginsRoutes[routesIdMap.SaleMarginsNew] = {
	component: SaleMarginsNew,
	exact: true,
	id: routesIdMap.SaleMarginsNew,
	name: 'New sale margin',
	menuText: 'New sale margin',
	path: '/sale/margins/new',
	permissions: [PermissionsType.SaleMargin],
	icon: FaPlus,
	isChild: true,
};
