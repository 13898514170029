import { createSlice } from '@reduxjs/toolkit';

import type { IVehicleModel } from '@crac/core/modules/fleet/vehicleModel/entities/VehicleModel';
import {
	fleetVehicleModelActivate,
	fleetVehicleModelClear,
	fleetVehicleModelDeactivate,
	fleetVehicleModelExport,
	fleetVehicleModelImport,
	fleetVehicleModelImportClear,
	fleetVehicleModelInsert,
	fleetVehicleModelModify,
	fleetVehicleModelSearch,
} from '@crac/core/modules/fleet/vehicleModel/state/actions/FleetVehicleModelActions';
import type { IFleetVehicleModelReducerState } from '@crac/core/modules/fleet/vehicleModel/state/stateType/FleetVehicleModelStateType';

const sortVehicleModels = (accumulate: IVehicleModel, current: IVehicleModel) => {
	if (accumulate.brand === current.brand && accumulate.modelCode && current.modelCode) {
		return accumulate.modelCode.localeCompare(current.modelCode);
	}
	return accumulate.brand.localeCompare(current.brand);
};

const initialState: IFleetVehicleModelReducerState = {
	fleetVehicleModelInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetVehicleModels: [],
	fleetVehicleModelSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetVehicleModelModifyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetVehicleModelActivateRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetVehicleModelDeactivateRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	importedModels: [],
	fleetVehicleModelImportRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetVehicleModelExportRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const vehicleModelSlice = createSlice({
	name: 'FleetVehicleModel',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// SEARCH
		builder
			.addCase(fleetVehicleModelSearch.pending, (state) => {
				return {
					...state,
					fleetVehicleModelSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetVehicleModelSearch.fulfilled, (state, action) => {
				return {
					...state,
					fleetVehicleModelSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					fleetVehicleModels: action.payload,
				};
			})
			.addCase(fleetVehicleModelSearch.rejected, (state, action) => {
				return {
					...state,
					fleetVehicleModelSearchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CLEAR SEARCH
		builder.addCase(fleetVehicleModelClear, (state) => {
			return {
				...state,
				fleetVehicleModels: [],
				importedModels: [],
			};
		});
		// INSERT
		builder
			.addCase(fleetVehicleModelInsert.pending, (state) => {
				return {
					...state,
					fleetVehicleModelInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetVehicleModelInsert.fulfilled, (state, action) => {
				return {
					...state,
					fleetVehicleModelInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					fleetVehicleModels: [...state.fleetVehicleModels, action.payload].sort(sortVehicleModels),
				};
			})
			.addCase(fleetVehicleModelInsert.rejected, (state, action) => {
				return {
					...state,
					fleetVehicleModelInsertRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// MODIFY
		builder
			.addCase(fleetVehicleModelModify.pending, (state) => {
				return {
					...state,
					fleetVehicleModelModifyRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetVehicleModelModify.fulfilled, (state, action) => {
				const { modelCode, brand } = action.payload as IVehicleModel;
				const fleetVehicleModels = state.fleetVehicleModels?.map((vehicleModel) =>
					vehicleModel.modelCode === modelCode && vehicleModel.brand === brand
						? { ...action.payload }
						: vehicleModel,
				);
				return {
					...state,
					fleetVehicleModels,
					fleetVehicleModelModifyRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetVehicleModelModify.rejected, (state, action) => {
				return {
					...state,
					fleetVehicleModelModifyRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// ACTIVATE
		builder
			.addCase(fleetVehicleModelActivate.pending, (state) => {
				return {
					...state,
					fleetVehicleModelActivateRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetVehicleModelActivate.fulfilled, (state, action) => {
				return {
					...state,
					fleetVehicleModelActivateRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					fleetVehicleModels: [
						...state.fleetVehicleModels.map((item) =>
							item.modelCode === action.payload.modelCode && item.brand === action.payload.brand
								? action.payload
								: item,
						),
					],
				};
			})
			.addCase(fleetVehicleModelActivate.rejected, (state, action) => {
				return {
					...state,
					fleetVehicleModelActivateRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// DEACTIVATE
		builder
			.addCase(fleetVehicleModelDeactivate.pending, (state) => {
				return {
					...state,
					fleetVehicleModelDeactivateRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetVehicleModelDeactivate.fulfilled, (state, action) => {
				return {
					...state,
					fleetVehicleModelDeactivateRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					fleetVehicleModels: [
						...state.fleetVehicleModels.map((item) =>
							item.modelCode === action.payload.modelCode && item.brand === action.payload.brand
								? action.payload
								: item,
						),
					],
				};
			})
			.addCase(fleetVehicleModelDeactivate.rejected, (state, action) => {
				return {
					...state,
					fleetVehicleModelDeactivateRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		builder
			.addCase(fleetVehicleModelImport.pending, (state) => {
				return {
					...state,
					fleetVehicleModelImportRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
					importedModels: [],
				};
			})
			.addCase(fleetVehicleModelImport.rejected, (state, action) => {
				return {
					...state,
					fleetVehicleModelImportRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
					importedModels: [],
				};
			})
			.addCase(fleetVehicleModelImport.fulfilled, (state, action) => {
				return {
					...state,
					fleetVehicleModelImportRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					fleetVehicleModels: [...state.fleetVehicleModels, ...action.payload],
					importedModels: action.payload,
				};
			});
		builder.addCase(fleetVehicleModelImportClear, (state) => {
			return {
				...state,
				importedModels: [],
			};
		});
		builder
			.addCase(fleetVehicleModelExport.pending, (state) => {
				return {
					...state,
					fleetVehicleModelExportRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetVehicleModelExport.rejected, (state, action) => {
				return {
					...state,
					fleetVehicleModelExportRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			})
			.addCase(fleetVehicleModelExport.fulfilled, (state) => {
				return {
					...state,
					fleetVehicleModelExportRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			});
	},
});

export const vehicleModelReducer = vehicleModelSlice.reducer;
