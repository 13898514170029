import { FineTransmitterEndPoints } from './FineTransmitterEndpoints';
import { Api } from '../../../../modules/shared/api';
import type { IServiceBaseParams } from '../../../../modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../../../modules/shared/types/ServiceResponse';
import type { IFineTransmitter } from '../entities/FineTransmitter';

export interface IFineTransmitterModifyParams extends IServiceBaseParams {
	code: string;
	country: string;
	email: string;
	lastModificationDate: string | Date;
	name: string;
	searchName: string;
	telephoneNumber: string;
}

/**
 * Modify a fine transmitter
 * @return {Promise<FineTransmitterType>} `FineTransmitterType`
 */
export const fineTransmitterModifyService = (
	model: IFineTransmitterModifyParams,
): Promise<ServiceResponse<IFineTransmitter>> => {
	return Api.post<IFineTransmitter, IFineTransmitterModifyParams>(FineTransmitterEndPoints.MODIFY, model);
};
