import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaRoadAlt } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const Tolls = lazyWithRetry(() => import('./pages/Tolls'));

export const tollsRoutes: { [key: number]: IRouteConfigType } = {};

tollsRoutes[routesIdMap.VehiclesDetailTolls] = {
	component: Tolls,
	exact: true,
	id: routesIdMap.VehiclesDetailTolls,
	name: 'Tolls',
	path: '/vehicles/detail/:id/tolls',
	permissions: [PermissionsType.VehicleDetail],
	icon: FaRoadAlt,
};
