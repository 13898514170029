import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

import { RevisionTypeEndPoints } from './RevisionTypeEndpoint';
import type { IRevisionType } from '../entities/RevisionType';

export interface IRevisionTypeSearchParams extends IServiceBaseParams {
	code?: string;
	description?: string;
	showActive?: number;
}

export const revisionTypeSearchService = (model: IRevisionTypeSearchParams) => {
	return Api.get<IRevisionType[], IRevisionTypeSearchParams>(RevisionTypeEndPoints.SEARCH, model);
};
