import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaRegCreditCardAlt } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const CustomerPayment = lazyWithRetry(() => import('./pages/Payment'));

export const paymentRoutes: { [key: number]: IRouteConfigType } = {};

paymentRoutes[routesIdMap.CustomersPayments] = {
	component: CustomerPayment,
	exact: true,
	id: routesIdMap.CustomersPayments,
	name: 'Customer payments',
	path: '/customer/payments',
	permissions: [PermissionsType.CustomerDetail],
	icon: FaRegCreditCardAlt,
	menuText: 'Payments',
};
