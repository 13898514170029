import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaRegFileAlt } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const Customer = lazyWithRetry(() => import('./pages/Customer'));

export const customerRoutes: { [key: number]: IRouteConfigType } = {};

customerRoutes[routesIdMap.BookingsNewCustomer] = {
	component: Customer,
	exact: true,
	id: routesIdMap.BookingsNewCustomer,
	name: 'Booking customer',
	path: '/booking/new/customer',
	permissions: [PermissionsType.BookingNew],
	icon: FaRegFileAlt,
};

customerRoutes[routesIdMap.BookingsNewCustomerSearch] = {
	component: Customer,
	exact: true,
	id: routesIdMap.BookingsNewCustomerSearch,
	name: 'Booking customer search',
	path: '/booking/new/customer-search',
	permissions: [PermissionsType.BookingNew],
	icon: FaRegFileAlt,
};

customerRoutes[routesIdMap.BookingsNewCustomerNew] = {
	component: Customer,
	exact: true,
	id: routesIdMap.BookingsNewCustomerNew,
	name: 'Booking customer new',
	path: '/booking/new/customer-new',
	permissions: [PermissionsType.BookingNew],
	icon: FaRegFileAlt,
};

customerRoutes[routesIdMap.BookingsNewCustomerGeneric] = {
	component: Customer,
	exact: true,
	id: routesIdMap.BookingsNewCustomerGeneric,
	name: 'Booking customer generic',
	path: '/booking/new/customer-generic',
	permissions: [PermissionsType.BookingNew],
	icon: FaRegFileAlt,
};
