import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaFlagCheckeredAlt, FaPencil, FaPlus } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

import { getPricingPermission } from '../utils/helpers';

const KmRate = lazyWithRetry(() => import('./pages/KmRate'));
const KmRateNew = lazyWithRetry(() => import('./pages/KmRateNew'));
const KmRateEdit = lazyWithRetry(() => import('./pages/KmRateEdit'));

export const kmRateRoutes: { [key: number]: IRouteConfigType } = {};

// KM
kmRateRoutes[routesIdMap.KmRate] = {
	component: KmRate,
	exact: true,
	id: routesIdMap.KmRate,
	name: 'Km rates',
	menuText: 'Km',
	path: '/pricing/km-rate',
	permissions: getPricingPermission([PermissionsType.PricingKmRate]),
	childrens: [routesIdMap.KmRateNew, routesIdMap.KmRateEdit],
	icon: FaFlagCheckeredAlt,
};

kmRateRoutes[routesIdMap.KmRateNew] = {
	component: KmRateNew,
	exact: true,
	isChild: true,
	id: routesIdMap.KmRateNew,
	name: 'New Km rate',
	path: '/pricing/km-rate/new',
	permissions: getPricingPermission([PermissionsType.PricingKmRate]),
	menuText: 'New Km rate',
	icon: FaPlus,
};

kmRateRoutes[routesIdMap.KmRateEdit] = {
	component: KmRateEdit,
	exact: true,
	isChild: true,
	id: routesIdMap.KmRateEdit,
	name: 'Modify Km rates',
	path: '/pricing/km-rate/modify/:id',
	permissions: getPricingPermission([PermissionsType.PricingKmRate]),
	icon: FaPencil,
};
