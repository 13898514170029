export enum DropOffEndpoints {
	/**
	 * End drop off
	 * @host `/booking/endDropOff/`
	 */
	DROPOFF_END = '/booking/endDropOff/',

	/**
	 * Get pending drop off
	 * @host `/booking/getPendingDropOffsByPlateNumberAndBranchCode/`
	 */
	DROPOFF_GET_PENDING_BY_PLATE_AND_BRANCH = '/booking/getPendingDropOffsByPlateNumberAndBranchCode/',

	/**
	 * Init drop off
	 * @host `/booking/initDropOff/`
	 */
	DROPOFF_INIT = '/booking/initDropOff/',

	/**
	 * Send invoice
	 * @host `/booking/sendDropOffInvoice/`
	 */
	DROPOFF_SEND_INVOICE = '/booking/sendDropOffInvoice/',

	/**
	 * Start drop off
	 * @host `/booking/startDropOff/`
	 */
	DROPOFF_START = '/booking/startDropOff/',

	/**
	 * Gets bookings by branch
	 * @host '/booking/getDropOffsByBranch/',
	 */
	DROPOFF_GET_BY_BRANCH = '/booking/getDropOffsByBranch/',
}
