import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaBan, FaPencil, FaPlus } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

import { getPricingPermission } from '../utils/helpers';

const NotAllowedMovement = lazyWithRetry(() => import('./pages/NotAllowedMovement'));
const NotAllowedMovementNew = lazyWithRetry(() => import('./pages/NotAllowedMovementNew'));
const NotAllowedMovementEdit = lazyWithRetry(() => import('./pages/NotAllowedMovementEdit'));

export const notAllowedMovementRoutes: { [key: number]: IRouteConfigType } = {};

notAllowedMovementRoutes[routesIdMap.NotAllowedMovement] = {
	component: NotAllowedMovement,
	exact: true,
	id: routesIdMap.NotAllowedMovement,
	name: 'Not allowed movements',
	path: '/pricing/not-allowed-movement',
	permissions: getPricingPermission([PermissionsType.PricingNotAllowedMovementBetweenZones]),
	childrens: [routesIdMap.NotAllowedMovementNew, routesIdMap.NotAllowedMovementEdit],
	icon: FaBan,
	menuText: 'Not allowed movements',
};

notAllowedMovementRoutes[routesIdMap.NotAllowedMovementNew] = {
	component: NotAllowedMovementNew,
	exact: true,
	isChild: true,
	id: routesIdMap.NotAllowedMovementNew,
	name: 'New not allowed movement',
	path: '/pricing/not-allowed-movement/new',
	permissions: getPricingPermission([PermissionsType.PricingNotAllowedMovementBetweenZones]),
	icon: FaPlus,
	menuText: 'New not allowed movement',
};

notAllowedMovementRoutes[routesIdMap.NotAllowedMovementEdit] = {
	component: NotAllowedMovementEdit,
	exact: true,
	isChild: true,
	id: routesIdMap.NotAllowedMovementEdit,
	name: 'Modify not allowed movement',
	path: '/pricing/not-allowed-movement/modify',
	permissions: getPricingPermission([PermissionsType.PricingNotAllowedMovementBetweenZones]),
	icon: FaPencil,
};
