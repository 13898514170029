import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaRegPenToSquare } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const RevisionCost = lazyWithRetry(() => import('./pages/RevisionCost'));

export const revisionCostRoutes: { [key: number]: IRouteConfigType } = {};

revisionCostRoutes[routesIdMap.VehiclesDetailRevisionCost] = {
	component: RevisionCost,
	exact: true,
	id: routesIdMap.VehiclesDetailRevisionCost,
	name: 'Revision',
	path: '/vehicles/detail/:id/revision-cost',
	permissions: [PermissionsType.RevisionCost],
	icon: FaRegPenToSquare,
};
