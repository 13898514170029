import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaTagsAlt } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const KeyChainsLabels = lazyWithRetry(() => import('./pages/KeyChainsLabels'));

export const keyChainsLabelsRoutes: { [key: number]: IRouteConfigType } = {};

keyChainsLabelsRoutes[routesIdMap.VehiclesKeyChainsLabels] = {
	component: KeyChainsLabels,
	exact: false,
	id: routesIdMap.VehiclesKeyChainsLabels,
	name: 'Key chains labels',
	path: '/vehicle/key-chains-labels',
	permissions: [PermissionsType.VehicleKeyChainsLabels],
	icon: FaTagsAlt,
	menuText: 'Key chains labels',
};

keyChainsLabelsRoutes[routesIdMap.VehiclesKeyChainsLabelsSelected] = {
	component: KeyChainsLabels,
	exact: true,
	id: routesIdMap.VehiclesKeyChainsLabelsSelected,
	name: 'Vehicles selected',
	path: '/vehicle/key-chains-labels/selected-list',
	permissions: [PermissionsType.VehicleKeyChainsLabels],
	icon: FaTagsAlt,
};
