import { API_ENDPOINT } from '@crac/core/modules/shared/api/ApiEndPoint';
import { getToken } from '@crac/core/modules/shared/api/helpers';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { FleetTransferEndPoints } from './FleetTransferEndpoints';

export interface IFleetTransferExportParams extends IServiceBaseParams {
	code: string;
}

export const fleetTransferExportService = async (model: IFleetTransferExportParams): Promise<ServiceResponse<null>> => {
	const { code } = model;
	const token = model.token ?? getToken();
	const headers = new Headers();
	headers.append('Content-Language', 'en-GB');
	headers.append('Authorization', `Bearer ${token}`);

	try {
		const request = await fetch(`${API_ENDPOINT}${FleetTransferEndPoints.EXPORT}?code=${code}`, { headers });

		if (!request.ok) {
			const jsonResponse = await request.json();
			return {
				ok: false,
				errors: [{ message: jsonResponse.message as string }],
			};
		}

		const response = await request.blob();

		const link = document.createElement('a');

		link.href = window.URL.createObjectURL(response);
		link.download = code;

		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);

		return { ok: true, errors: [] };
	} catch (error) {
		let msg = 'Document cannot be downloaded';
		if (error instanceof Error) {
			const { message } = error;
			msg = message;
		}

		return {
			ok: false,
			errors: [{ message: msg }],
		};
	}
};
