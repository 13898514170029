import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

import { RevisionTypeEndPoints } from './RevisionTypeEndpoint';
import type { IRevisionType } from '../entities/RevisionType';

export type IRevisionTypeGetAllParams = IServiceBaseParams;

export const revisionTypeGetAllService = (model?: IRevisionTypeGetAllParams) => {
	return Api.get<IRevisionType[], IRevisionTypeGetAllParams>(RevisionTypeEndPoints.GET_ALL, model);
};
