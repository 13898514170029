import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaPaintbrushAlt, FaPencil, FaPlus } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const VehicleColor = lazyWithRetry(() => import('~/features/fleet/vehicleColor/pages/VehicleColor'));
const VehicleColorNew = lazyWithRetry(() => import('~/features/fleet/vehicleColor/pages/VehicleColorNew'));
const VehicleColorModify = lazyWithRetry(() => import('~/features/fleet/vehicleColor/pages/VehicleColorModify'));

export const vehicleColorRoutes: { [ket: number]: IRouteConfigType } = {};

vehicleColorRoutes[routesIdMap.FleetVehicleColor] = {
	component: VehicleColor,
	exact: true,
	id: routesIdMap.FleetVehicleColor,
	name: 'Vehicle color',
	path: '/fleet/vehicle-color',
	permissions: [PermissionsType.FleetVehicleColor],
	childrens: [routesIdMap.FleetVehicleColorNew, routesIdMap.FleetVehicleColorModify],
	icon: FaPaintbrushAlt,
	menuText: 'Colors',
};

vehicleColorRoutes[routesIdMap.FleetVehicleColorNew] = {
	component: VehicleColorNew,
	exact: true,
	id: routesIdMap.FleetVehicleColorNew,
	name: 'New vehicle color',
	path: '/fleet/vehicle-color/new',
	permissions: [PermissionsType.FleetVehicleColor],
	isChild: true,
	icon: FaPlus,
	menuText: 'New vehicle color',
};

vehicleColorRoutes[routesIdMap.FleetVehicleColorModify] = {
	component: VehicleColorModify,
	exact: true,
	id: routesIdMap.FleetVehicleColorModify,
	name: 'Modify vehicle color',
	path: '/fleet/vehicle-color/modify/:code',
	permissions: [PermissionsType.FleetVehicleColor],
	isChild: true,
	icon: FaPencil,
};
