import { SupplierLocationEndpoints } from './SupplierLocationEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ISupplierLocation } from '../entities/SupplierLocation';

export interface ISupplierLocationInsertParams extends IServiceBaseParams {
	supplier: string;
	company: string;
	name: string;
	country: string;
	nif: string;
	postCode: string;
	county: string;
	city: string;
	address: string;
	email: string;
	contact?: string;
	phone?: string;
}

export const supplierLocationInsertService = (model: ISupplierLocationInsertParams) =>
	Api.post<ISupplierLocation, ISupplierLocationInsertParams>(SupplierLocationEndpoints.INSERT, model);
