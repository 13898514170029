import { createSyncAction } from '../../../../shared/state/createAction';
import { createAsyncAction } from '../../../../shared/state/createAsyncAction';
import type { IExcessType } from '../../entities/ExcessType';
import type { IExcessTypeDeleteParams } from '../../services/ExcessTypeDeleteService';
import { excessTypeDeleteService } from '../../services/ExcessTypeDeleteService';
import type { IExcessTypeSaveParams } from '../../services/ExcessTypeInsertService';
import { excessTypeInsertService } from '../../services/ExcessTypeInsertService';
import { ExcessTypeModifyService } from '../../services/ExcessTypeModifyService';
import { type IExcessTypeSearchParams, excessTypeSearchService } from '../../services/ExcessTypeSearchService';

/*
 * Clear excess type state
 */
export const excessTypeClear = createSyncAction('accessType/Clear');

/**
 * Searches for excess type based on specified parameters.
 *
 * @param {IExcessType[]} payload - The excess type to be returned.
 * @param {IExcessTypeSearchParams} params - Parameters for the search.
 * @returns {Promise<void>} - A promise that resolves to an array of excess type.
 */
export const excessTypeSearch = createAsyncAction<IExcessType[], IExcessTypeSearchParams>(
	'accessType/Search',
	excessTypeSearchService,
);

/**
 * Inserts new excess type into the system.
 *
 * @param {IExcessType} payload - The excess type to be inserted.
 * @param {IExcessTypeSaveParams} params - Parameters detailing the excess type to be inserted.
 * @returns {Promise<void>} - A promise that resolves after the excess type is successfully inserted.
 */
export const excessTypeInsert = createAsyncAction<IExcessType, IExcessTypeSaveParams>(
	'accessType/insert',
	excessTypeInsertService,
);

/**
 * Modifies an existing excess type.
 *
 * @param {IExcessType} payload - The excess type to be modified.
 * @param {IExcessTypeSaveParams} params - Parameters detailing the modifications to be made on the excess type.
 * @returns {Promise<void>} - A promise that resolves after the excess type is successfully modified.
 */
export const excessTypeModify = createAsyncAction<IExcessType, IExcessTypeSaveParams>(
	'accessType/modify',
	ExcessTypeModifyService,
);

/**
 * Deletes an existing excess type.
 *
 * @param {IExcessType} payload - The excess type to be deleted.
 * @param {IExcessTypeSaveParams} params - Parameters detailing the excess type to be deleted.
 * @returns {Promise<void>} - A promise that resolves after the excess type is successfully deleted.
 */
export const excessTypeDelete = createAsyncAction<IExcessType, IExcessTypeDeleteParams>(
	'accessType/delete',
	excessTypeDeleteService,
);
