import { VehicleBrandEndPoints } from './FleetVehicleBrandEndpoints';
import { Api } from '../../../../modules/shared/api';
import type { IServiceBaseParams } from '../../../../modules/shared/types/ServiceBaseParams';
import type { IVehicleBrand } from '../entities/VehicleBrand';

export interface IFleetVehicleBrandSearchParams extends IServiceBaseParams {
	brand?: string;
	description?: string;
	web?: boolean;
}

export const fleetVehicleBrandSearchService = (model: IFleetVehicleBrandSearchParams) => {
	return Api.get<IVehicleBrand[], IFleetVehicleBrandSearchParams>(VehicleBrandEndPoints.SEARCH, model);
};
