import { createSyncAction } from '../../../../shared/state/createAction';
import { createAsyncAction } from '../../../../shared/state/createAsyncAction';
import type { ISupplierLocation } from '../../entities/SupplierLocation';
import type { ISupplierLocationDeleteParams } from '../../services/SupplierLocationDeleteService';
import { supplierLocationDeleteService } from '../../services/SupplierLocationDeleteService';
import type { ISupplierLocationInsertParams } from '../../services/SupplierLocationInsertService';
import { supplierLocationInsertService } from '../../services/SupplierLocationInsertService';
import type { ISupplierLocationModifyParams } from '../../services/SupplierLocationModifyService';
import { supplierLocationModifyService } from '../../services/SupplierLocationModifyService';
import type { ISupplierLocationSearchParams } from '../../services/SupplierLocationSearchService';
import { supplierLocationSearchService } from '../../services/SupplierLocationSearchService';

/**
 * Search locations
 * @param {ISupplierLocation[]} payload - The list of locations.
 * @param {ISupplierLocationSearchParams} params - Parameters for searching the locations.
 * @returns {Promise<ServiceResponse<ISupplierLocation[]>>} - A promise that resolves to the list of locations
 */
export const supplierLocationSearch = createAsyncAction<ISupplierLocation[], ISupplierLocationSearchParams>(
	'supplierLocation/search',
	supplierLocationSearchService,
);

/**
 * Insert Location
 * @param {ISupplierLocation} payload - location inserted.
 * @param {ISupplierLocationInsertParams} params - Parameters for the insertion of the location
 * @returns {Promise<ServiceResponse<ISupplierLocation>>} - A promise that resolves to the inserted location
 */
export const supplierLocationInsert = createAsyncAction<ISupplierLocation, ISupplierLocationInsertParams>(
	'supplierLocation/insert',
	supplierLocationInsertService,
);

/**
 * Modify location
 * @param {ISupplier} payload - modified location
 * @param {ISupplierLocationModifyParams} params - Parameters for the modification of the location
 * @returns {Promise<ServiceResponse<ISupplierLocation>>} - A promise that resolves to the modified location
 */
export const supplierLocationModify = createAsyncAction<ISupplierLocation, ISupplierLocationModifyParams>(
	'supplierLocation/modify',
	supplierLocationModifyService,
);

/**
 * Delete location
 * @param {ISupplierLocation} payload - deleted location
 * @param {ISupplierLocationDeleteParams} params - Parameters for deleting the location
 * @returns {Promise<ServiceResponse<ISupplierLocation>>} - A promise that resolves to the deleted location
 */
export const supplierLocationDelete = createAsyncAction<ISupplierLocation, ISupplierLocationDeleteParams>(
	'supplierLocation/delete',
	supplierLocationDeleteService,
);

export const supplierLocationClear = createSyncAction('supplierLocation/clear');
