import type { IServicePrice } from '../../models/entities/ServicePrice';
import type { IServicePriceGetParams } from '../../models/serviceParams/ServicePriceParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { ServicePriceEndPoints } from '../endPoints/ServicePriceEndPoints';

/**
 * @deprecated Use `servicePriceGetPriceService` from `modules/service/servicePrice/services`
 */
export const servicePriceServiceGet = (model: IServicePriceGetParams): Promise<ServiceResponse<IServicePrice[]>> => {
	return Api.get<IServicePrice[], IServicePriceGetParams>(ServicePriceEndPoints.GET, model);
};
