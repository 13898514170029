import type { FC } from 'react';
import { useEffect } from 'react';

import { EMPLOYEE_HOSTNAME_KEY } from '~/constants/LocalStorageKey';

import styles from './login.module.scss';
import { LoginForm } from './LoginForm';
import { useLoginRedirect } from '../hooks/useLoginRedirect';
import type { LoginActionsType } from '../pages/Login';

export const LoginContent: FC<LoginActionsType> = ({ clearGlobalCache, setHostName }) => {
	const { handleRedirect } = useLoginRedirect();

	/**
	 * Clear cached data when the login page is loaded
	 */
	useEffect(() => {
		const clear = () => {
			const hostName = localStorage.getItem(EMPLOYEE_HOSTNAME_KEY);

			sessionStorage.clear();

			const columnOrderStorage = localStorage.getItem('columnOrder');
			const columnConfigStorage = localStorage.getItem('columnConfiguration');

			localStorage.clear();

			if (columnOrderStorage) {
				localStorage.setItem('columnOrder', columnOrderStorage);
			}
			if (columnConfigStorage) {
				localStorage.setItem('columnConfiguration', columnConfigStorage);
			}

			clearGlobalCache();

			if (hostName) {
				setHostName(hostName);
			}
		};
		clear();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.container}>
			<LoginForm onLoginSuccess={handleRedirect} />
		</div>
	);
};
