import type { IBooking } from '@crac/core/modules/booking/entities/Booking';

import { createSyncAction } from '../../../../shared/state/createAction';
import { createAsyncAction } from '../../../../shared/state/createAsyncAction';
import type { IBookingDropOffEndParams } from '../../services/BookingDropOffEndService';
import { bookingDropOffEndService } from '../../services/BookingDropOffEndService';
import type { IBookingDropOffGetByBranchParams } from '../../services/BookingDropOffGetByBranchService';
import { bookingDropOffGetByBranchService } from '../../services/BookingDropOffGetByBranchService';
import type { IBookingDropOffGetPendingByPlateNumberAndBranchCodeParams } from '../../services/BookingDropOffGetPendingByPlateNumberAndBranchCodeService';
import { bookingDropOffGetPendingsByPlateNumberAndBranchCodeService } from '../../services/BookingDropOffGetPendingByPlateNumberAndBranchCodeService';
import type { IBookingDropOffInitParams } from '../../services/BookingDropOffInitService';
import { bookingDropOffInitService } from '../../services/BookingDropOffInitService';
import type { IBookingDropOffSendInvoiceParams } from '../../services/BookingDropOffSendInvoiceService';
import { bookingDropOffSendInvoiceService } from '../../services/BookingDropOffSendInvoiceService';
import type { IBookingDropOffStartParams } from '../../services/BookingDropOffStartService';
import { bookingDropOffStartService } from '../../services/BookingDropOffStartService';

/**
 * Initiates the drop-off process for a booking.
 *
 * @param {IBooking} payload - The booking to start the drop-off process.
 * @param {IBookingDropOffStartParams} params - Parameters needed to start the drop-off process.
 * @returns {Promise<void>} - A promise that resolves after initiating the drop-off process.
 */
export const bookingDropOffStart = createAsyncAction<IBooking, IBookingDropOffStartParams>(
	'bookingDropOff/start',
	bookingDropOffStartService,
);

/**
 * Sends an invoice related to a booking drop-off.
 *
 * @param {IBooking} payload - The booking for which the invoice is sent.
 * @param {IBookingDropOffSendInvoiceParams} params - Parameters for sending the invoice.
 * @returns {Promise<void>} - A promise that resolves after sending the invoice.
 */
export const bookingDropOffSendInvoice = createAsyncAction<IBooking, IBookingDropOffSendInvoiceParams>(
	'bookingDropOff/sendInvoice',
	bookingDropOffSendInvoiceService,
);

/**
 * Retrieves pending drop-offs based on plate number and branch code.
 *
 * @param {IBooking[]} payload - List of bookings with pending drop-offs.
 * @param {IBookingDropOffGetPendingByPlateNumberAndBranchCodeParams} params - Parameters to identify pending drop-offs.
 * @returns {Promise<void>} - A promise that resolves to an array of pending bookings.
 */
export const bookingDropOffGetPendingsByPlateNumberAndBranchCode = createAsyncAction<
	IBooking[],
	IBookingDropOffGetPendingByPlateNumberAndBranchCodeParams
>('bookingDropOff/getPendingsByPlateNumberAndBranchCode', bookingDropOffGetPendingsByPlateNumberAndBranchCodeService);

/**
 * Clears the pending drop-offs from the state.
 */
export const bookingDropOffClearPendings = createSyncAction('bookingDropOff/clearPendings');

/**
 * Initializes the booking drop-off process.
 *
 * @param {IBooking} payload - The booking to initialize for drop-off.
 * @param {IBookingDropOffInitParams} params - Parameters needed to initialize the drop-off.
 * @returns {Promise<void>} - A promise that resolves after initializing the drop-off process.
 */
export const bookingDropOffInit = createAsyncAction<IBooking, IBookingDropOffInitParams>(
	'bookingDropOff/init',
	bookingDropOffInitService,
);

/**
 * Ends the drop-off process for a booking.
 *
 * @param {IBooking} payload - The booking to end the drop-off process.
 * @param {IBookingDropOffEndParams} params - Parameters needed to end the drop-off process.
 * @returns {Promise<void>} - A promise that resolves after ending the drop-off process.
 */
export const bookingDropOffEnd = createAsyncAction<IBooking, IBookingDropOffEndParams>(
	'bookingDropOff/end',
	bookingDropOffEndService,
);

/**
 * Gets dropOffs by branch.
 *
 * @param {IBooking[]} payload - Bookings by branch
 * @param {IBookingDropOffGetByBranchParams} params - Parameters needed to get dropOffs by branch.
 * @returns {Promise<void>} - A promise that resolves after the service call.
 */
export const bookingDropOffGetByBranch = createAsyncAction<IBooking[], IBookingDropOffGetByBranchParams>(
	'bookingDropOff/getByBranch',
	bookingDropOffGetByBranchService,
);
