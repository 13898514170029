export enum SaleOrderLineEndPoints {
	/**
	 * Search for vehicle sale order getByOrderId
	 * @host `/saleOrderLine/getByOrderId`
	 */
	GET_BY_ORDER_ID = '/saleOrderLine/getByOrderId/',
	/**
	 * Inserts saleOrderLine
	 * @host `/saleOrderLine/insert`
	 */
	INSERT = '/saleOrderLine/insert/',
	/**
	 * Deletes saleOrderLine
	 * @host `/saleOrderLine/delete`
	 */
	DELETE = '/saleOrderLine/delete/',
}
