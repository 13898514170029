/**
 * @deprecated Use `ServicePriceEndpoints` from `modules/service/servicePrice/services`
 */
export enum ServicePriceEndPoints {
	/**
	 * Get price
	 * @host `/serviceprice/getprice/`
	 */
	GET = '/serviceprice/getprice/',
}
