import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaBoltAlt } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const Damages = lazyWithRetry(() => import('./pages/Damages'));

export const damagesRoutes: { [key: number]: IRouteConfigType } = {};

damagesRoutes[routesIdMap.VehiclesDetailDamages] = {
	component: Damages,
	exact: true,
	id: routesIdMap.VehiclesDetailDamages,
	name: 'Damages',
	path: '/vehicles/detail/:id/damages',
	permissions: [PermissionsType.VehicleDetail],
	icon: FaBoltAlt,
};

damagesRoutes[routesIdMap.VehiclesDetailDamagesDetail] = {
	component: Damages,
	exact: true,
	id: routesIdMap.VehiclesDetailDamagesDetail,
	name: 'Damage detail',
	path: '/vehicles/detail/:id/damages/:line/:document?',
	permissions: [PermissionsType.VehicleDetail],
	icon: FaBoltAlt,
};
