import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaCheckAlt, FaPencil, FaPlus } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const RevisionType = lazyWithRetry(() => import('./pages/RevisionType'));
const RevisionTypeNew = lazyWithRetry(() => import('./pages/RevisionTypeNew'));
const RevisionTypeModify = lazyWithRetry(() => import('./pages/RevisionTypeModify'));

export const revisionTypeRoutes: { [key: number]: IRouteConfigType } = {};

// TYPE
revisionTypeRoutes[routesIdMap.WorkshopRevisionType] = {
	component: RevisionType,
	exact: true,
	id: routesIdMap.WorkshopRevisionType,
	name: 'Revision type',
	menuText: 'Type',
	path: '/workshop/revision-type',
	icon: FaCheckAlt,
	permissions: [PermissionsType.RevisionType],
	childrens: [routesIdMap.WorkshopRevisionTypeNew, routesIdMap.WorkshopRevisionTypeModify],
};

revisionTypeRoutes[routesIdMap.WorkshopRevisionTypeNew] = {
	component: RevisionTypeNew,
	exact: true,
	id: routesIdMap.WorkshopRevisionTypeNew,
	name: 'New revision type',
	path: '/workshop/revision-type/new',
	permissions: [PermissionsType.RevisionType],
	isChild: true,
	menuText: 'New revision type',
	icon: FaPlus,
};

revisionTypeRoutes[routesIdMap.WorkshopRevisionTypeModify] = {
	component: RevisionTypeModify,
	exact: true,
	id: routesIdMap.WorkshopRevisionTypeModify,
	name: 'Modify revision type',
	path: '/workshop/revision-type/modify/:code',
	permissions: [PermissionsType.RevisionType],
	isChild: true,
	icon: FaPencil,
};
