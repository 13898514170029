import type { IRevisionLine } from '../../models/entities/RevisionLine';
import type {
	IRevisionLineGetByBudgetParams,
	IRevisionLineGetByBudgetsParams,
} from '../../models/serviceParams/RevisionLineParams';
import { BudgetState } from '../../models/types/BudgetState';
import { Api } from '../../modules/shared/api';
import { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { RevisionLineEndPoints } from '../endPoints/RevisionLineEndPoints';

/**
 * @deprecated - use 'revisionLineGetByBudgetService' from 'packages\core\src\modules\workshop\revisionCost\services\RevisionLineGetByBudgetService.ts' instead.
 */
export const revisionLineServiceGetByBudget = (
	model: IRevisionLineGetByBudgetParams,
): Promise<ServiceResponse<IRevisionLine[]>> => {
	return Api.get<IRevisionLine[], IRevisionLineGetByBudgetParams>(RevisionLineEndPoints.GET_BY_BUDGET, model);
};

/**
 * @deprecated - use 'revisionLineGetByBudgetsService' from 'packages\core\src\modules\workshop\revisionCost\services\RevisionLineGetByBudgetsService.ts' instead.
 */
export const revisionLineServiceGetByBudgets = async (
	model: IRevisionLineGetByBudgetsParams,
): Promise<ServiceResponse<IRevisionLine[]>> => {
	const { budgets } = model;
	if (budgets.length === 0) {
		return {
			ok: true,
			data: [],
			errors: [],
		};
	}

	const filteredBudgets = budgets.filter(({ state }) => state !== BudgetState.Rejected);
	const tasks: any[] = [];
	filteredBudgets.forEach(({ id }) => {
		tasks.push(revisionLineServiceGetByBudget({ budgetId: id }));
	});

	const requestResponses: ServiceResponse<IRevisionLine[]>[] = await Promise.all(tasks);

	const payload: IRevisionLine[] = [];
	requestResponses.forEach((response) => {
		if (response.ok && response.data && response.data.length > 0) {
			payload.push(...response.data);
		}
	});

	return new ServiceResponse<IRevisionLine[]>(true, payload, []);
};
