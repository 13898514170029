import { VehicleGroupEndPoints } from './VehicleGroupEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IVehicleGroup } from '../entities/VehicleGroup';

export type IVehicleGroupInsertParams = IServiceBaseParams & IVehicleGroup;

/**
 * Insert vehicle group
 * @params IVehicleGroupInsertParams
 * @returns {Object} IVehicleGroup
 */
export const vehicleGroupInsertService = (model: IVehicleGroupInsertParams) =>
	Api.post<IVehicleGroup, IVehicleGroupInsertParams>(VehicleGroupEndPoints.INSERT, model);
