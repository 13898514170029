import { accidentRoutes } from './accident/routes';
import { assignmentRoutes } from './assignment/routes';
import { damageRepairRoutes } from './damageRepair/routes';
import { dashboardRoutes } from './dashboard/routes';
import { excessRateRoutes } from './excessRate/routes';
import { excessTypeRoutes } from './excessType/routes';
import { locationRoutes } from './location/routes';
import { revisionChecklistRoutes } from './RevisionChecklist/routes';
import { revisionCostRoutes } from './revisionCost/routes';
import { revisionTypeRoutes } from './revisionType/routes';
import { supplierRoutes } from './supplier/routes';

export const workshopRoutes = {
	...accidentRoutes,
	...assignmentRoutes,
	...damageRepairRoutes,
	...dashboardRoutes,
	...excessRateRoutes,
	...excessTypeRoutes,
	...revisionChecklistRoutes,
	...revisionCostRoutes,
	...revisionTypeRoutes,
	...supplierRoutes,
	...locationRoutes,
};
