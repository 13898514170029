import type { IBooking } from '@crac/core/models/entities/Booking';
import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaRegCreditCardAlt } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { bookingDetailLoader } from '~/features/booking/shared/utils/bookingDetailLoader';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const Amounts = lazyWithRetry(() => import('./pages/Amounts'));

export const amountsRoutes: { [key: number]: IRouteConfigType } = {};

amountsRoutes[routesIdMap.BookingsDetailAmounts] = {
	component: Amounts,
	exact: true,
	id: routesIdMap.BookingsDetailAmounts,
	name: 'Amounts',
	path: '/bookings/detail/:id/amounts',
	permissions: [PermissionsType.BookingDetail],
	loader: ({ params }): Promise<IBooking | null | undefined> => {
		return bookingDetailLoader(params?.id || '');
	},
	icon: FaRegCreditCardAlt,
};

/*
 * amountsRoutes[routesIdMap.BookingsDetailAmountsModify] = {
 * component: Amounts,
 * exact: false,
 * id: routesIdMap.BookingsDetailAmountsModify,
 * name: 'Amount modify',
 * path: '/bookings/detail/:id/amounts/modify',
 * permissions: [PermissionsType.BookingDetail],
 * icon: FaPencil,
 * };
 */
