import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaCartArrowDownAlt, FaPencil, FaPlus } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

import { getPricingPermission } from '../utils/helpers';

const IncludedServiceConfiguration = lazyWithRetry(() => import('./pages/IncludedServiceConfiguration'));
const IncludedServiceConfigurationNew = lazyWithRetry(() => import('./pages/IncludedServiceConfigurationNew'));
const IncludedServiceConfigurationModify = lazyWithRetry(() => import('./pages/IncludedServiceConfigurationModify'));

export const includedServiceConfigurationRoutes: { [key: number]: IRouteConfigType } = {};

// INCLUDED SERVICES
includedServiceConfigurationRoutes[routesIdMap.PricingIncludedServiceConfiguration] = {
	component: IncludedServiceConfiguration,
	exact: true,
	id: routesIdMap.PricingIncludedServiceConfiguration,
	name: 'Included services configuration',
	menuText: 'Included services',
	path: '/pricing/included-services',
	permissions: getPricingPermission([PermissionsType.PricingIncludedServiceConfiguration]),
	childrens: [
		routesIdMap.PricingIncludedServiceConfigurationNew,
		routesIdMap.PricingIncludedServiceConfigurationModify,
	],
	icon: FaCartArrowDownAlt,
};

includedServiceConfigurationRoutes[routesIdMap.PricingIncludedServiceConfigurationNew] = {
	component: IncludedServiceConfigurationNew,
	exact: true,
	id: routesIdMap.PricingIncludedServiceConfigurationNew,
	name: 'New included service',
	path: '/pricing/included-services/new',
	permissions: getPricingPermission([PermissionsType.PricingIncludedServiceConfiguration]),
	icon: FaPlus,
	menuText: 'New included service',
};

includedServiceConfigurationRoutes[routesIdMap.PricingIncludedServiceConfigurationModify] = {
	component: IncludedServiceConfigurationModify,
	exact: true,
	id: routesIdMap.PricingIncludedServiceConfigurationModify,
	name: 'Included services modify',
	path: '/pricing/included-services/:id',
	permissions: getPricingPermission([PermissionsType.PricingIncludedServiceConfiguration]),
	icon: FaPencil,
};
