import React from 'react';

import type { RowProps } from './types';
import { cn } from '../lib/utils/tailwindClass';

const Row = React.forwardRef<HTMLDivElement, RowProps>(
	({ children, className, alignItems, justifyContent, tag: Tag = 'div', ...props }, ref) => {
		return (
			<Tag
				className={cn(
					'tw-row',
					alignItems === 'start' && 'tw-items-start',
					alignItems === 'center' && 'tw-items-center',
					alignItems === 'end' && 'tw-items-end',
					alignItems === 'stretch' && 'tw-items-stretch',
					alignItems === 'baseline' && 'tw-items-baseline',
					justifyContent === 'start' && 'tw-justify-start',
					justifyContent === 'center' && 'tw-justify-center',
					justifyContent === 'end' && 'tw-justify-end',
					justifyContent === 'between' && 'tw-justify-between',
					justifyContent === 'around' && 'tw-justify-around',
					justifyContent === 'evenly' && 'tw-justify-evenly',
					className,
				)}
				ref={ref}
				{...props}
			>
				{children}
			</Tag>
		);
	},
);

Row.displayName = 'Row';

export { Row };
