import { FleetTransferEndPoints } from './FleetTransferEndpoints';
import { Api } from '../../../../modules/shared/api';
import type { IServiceBaseParams } from '../../../../modules/shared/types/ServiceBaseParams';
import type { IFleetTransfer } from '../entities/FleetTransfer';

export interface IFleetTransferDeactivateParams extends IServiceBaseParams {
	ids: number[];
}

export const fleetTransferDeactivateService = (model: IFleetTransferDeactivateParams) => {
	return Api.post<IFleetTransfer[], IFleetTransferDeactivateParams>(FleetTransferEndPoints.DEACTIVATE, model);
};
