import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaBars, FaPencil, FaPlus } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const PreviousRegistration = lazyWithRetry(
	() => import('~/features/fleet/previousRegistration/pages/PreviousRegistration'),
);
const PreviousRegistrationNew = lazyWithRetry(
	() => import('~/features/fleet/previousRegistration/pages/PreviousRegistrationImport'),
);
const PreviousRegistrationModify = lazyWithRetry(
	() => import('~/features/fleet/previousRegistration/pages/PreviousRegistrationModify'),
);
const PreviousRegistrationRegister = lazyWithRetry(
	() => import('~/features/fleet/previousRegistration/pages/PreviousRegistrationRegister'),
);

export const previousRegistrationRoutes: { [key: number]: IRouteConfigType } = {};

previousRegistrationRoutes[routesIdMap.FleetPreviousRegistration] = {
	component: PreviousRegistration,
	exact: true,
	id: routesIdMap.FleetPreviousRegistration,
	name: 'Previous registration',
	path: '/fleet/vehicle-registration',
	permissions: [PermissionsType.VehiclePreviousRegistrationManagement],
	childrens: [
		routesIdMap.FleetPreviousRegistrationImport,
		routesIdMap.FleetPreviousRegistrationModify,
		routesIdMap.FleetPreviousRegistrationRegister,
	],
	icon: FaBars,
	menuText: 'Vehicle registration',
};

previousRegistrationRoutes[routesIdMap.FleetPreviousRegistrationImport] = {
	component: PreviousRegistrationNew,
	exact: true,
	id: routesIdMap.FleetPreviousRegistrationImport,
	name: 'Import previous registration',
	path: '/fleet/vehicle-registration/import',
	permissions: [PermissionsType.VehiclePreviousRegistrationManagement],
	isChild: true,
	menuText: 'Import previous registration',
	icon: FaPlus,
};

previousRegistrationRoutes[routesIdMap.FleetPreviousRegistrationModify] = {
	component: PreviousRegistrationModify,
	exact: true,
	id: routesIdMap.FleetPreviousRegistrationModify,
	name: 'Modify previous registration',
	path: '/fleet/vehicle-registration/modify/:id',
	permissions: [PermissionsType.VehiclePreviousRegistrationManagement],
	isChild: true,
	icon: FaPencil,
};

previousRegistrationRoutes[routesIdMap.FleetPreviousRegistrationRegister] = {
	component: PreviousRegistrationRegister,
	exact: true,
	id: routesIdMap.FleetPreviousRegistrationRegister,
	name: 'Modify previous registration',
	path: '/fleet/vehicle-registration/register/:previsionCode',
	permissions: [PermissionsType.VehiclePreviousRegistrationManagement],
	isChild: true,
};
