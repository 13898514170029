export const permissionsCategories = [
	{ label: 'All', value: 'all' },
	{ label: 'Booking', value: 'booking' },
	{ label: 'Companies', value: 'company' },
	{ label: 'Customer', value: 'customer' },
	{ label: 'Franchise', value: 'franchise' },
	{ label: 'Management', value: 'management' },
	{ label: 'Payment', value: 'payment' },
	{ label: 'Vehicles', value: 'vehicle' },
	{ label: 'Pricing', value: 'pricing' },
	{ label: 'Fleet', value: 'fleet' },
];
