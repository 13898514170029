import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaWrenchAlt } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const DamageRepair = lazyWithRetry(() => import('./pages/DamageRepair'));

export const damageRepairRoutes: { [key: number]: IRouteConfigType } = {};

damageRepairRoutes[routesIdMap.VehiclesDetailDamageRepair] = {
	component: DamageRepair,
	exact: true,
	id: routesIdMap.VehiclesDetailDamageRepair,
	name: 'Damage repair',
	path: '/vehicles/detail/:id/damage-repair',
	permissions: [PermissionsType.DamageRepairCost],
	icon: FaWrenchAlt,
};
