import { VehicleModelEndPoints } from './FleetVehicleModelEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IVehicleModel } from '../entities/VehicleModel';

export interface IFleetVehicleModelImportParams extends IServiceBaseParams {
	provider: string;
	file: File;
}

export const fleetVehicleModelImportService = (model: IFleetVehicleModelImportParams) => {
	return Api.post<IVehicleModel[], IFleetVehicleModelImportParams>(VehicleModelEndPoints.IMPORT, model);
};
