import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaPencil, FaPlus, FaSitemapAlt } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

import { getPricingPermission } from '../utils/helpers';

const PartnerCategory = lazyWithRetry(() => import('./pages/PartnerCategory'));
const PartnerCategoryNew = lazyWithRetry(() => import('./pages/PartnerCategoryNew'));
const PartnerCategoryModify = lazyWithRetry(() => import('./pages/PartnerCategoryModify'));

export const partnerCategoryRoutes: { [key: number]: IRouteConfigType } = {};

// PARTNER CATEGORY
partnerCategoryRoutes[routesIdMap.PricingPartnerCategory] = {
	component: PartnerCategory,
	exact: true,
	id: routesIdMap.PricingPartnerCategory,
	name: 'Partner categories',
	menuText: 'Categories',
	path: '/pricing/partner-categories',
	permissions: getPricingPermission([PermissionsType.PricingPartnerCategory]),
	childrens: [routesIdMap.PricingPartnerCategoryNew, routesIdMap.PricingPartnerCategoryModify],
	icon: FaSitemapAlt,
};
partnerCategoryRoutes[routesIdMap.PricingPartnerCategoryModify] = {
	component: PartnerCategoryModify,
	exact: true,
	id: routesIdMap.PricingPartnerCategoryModify,
	name: 'New partner category',
	path: '/pricing/partner-categories/:id',
	permissions: getPricingPermission([PermissionsType.PricingPartnerCategory]),
	icon: FaPencil,
};
partnerCategoryRoutes[routesIdMap.PricingPartnerCategoryNew] = {
	component: PartnerCategoryNew,
	exact: true,
	id: routesIdMap.PricingPartnerCategoryNew,
	name: 'Partner category new',
	path: '/pricing/partner-categories/new',
	permissions: getPricingPermission([PermissionsType.PricingPartnerCategory]),
	menuText: 'New partner category',
	icon: FaPlus,
};
