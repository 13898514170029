import { FineReasonEndPoints } from './FineReasonEndpoints';
import { Api } from '../../../../modules/shared/api';
import type { IServiceBaseParams } from '../../../../modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../../../modules/shared/types/ServiceResponse';
import type { IFineReason } from '../entities/FineReason';

export interface IFineReasonModifyParams extends IServiceBaseParams {
	code: number;
	companyPay: boolean;
	description: string;
	toll: boolean;
}

/**
 * Modify a fine reason
 * @return {Promise<FineReasonType>} `FineReasonType`
 */
export const fineReasonModifyService = (model: IFineReasonModifyParams): Promise<ServiceResponse<IFineReason>> => {
	return Api.post<IFineReason, IFineReasonModifyParams>(FineReasonEndPoints.MODIFY, model);
};
