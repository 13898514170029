import { DropOffEndpoints } from './DropOffEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../../shared/types/ServiceResponse';
import type { IBooking } from '../../entities/Booking';

export interface IBookingDropOffInitParams extends IServiceBaseParams {
	bookingNumber: string;
}
/**
 * Inicia el proceso de dropOff, lo usan en navision para borrar datos temporales.
 *  @param {Number} bookingNumber Booking number
 */
export const bookingDropOffInitService = (model: IBookingDropOffInitParams): Promise<ServiceResponse<IBooking>> => {
	return Api.post<IBooking, IBookingDropOffInitParams>(DropOffEndpoints.DROPOFF_INIT, model);
};
