import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaCarAlt, FaDownload, FaPencil, FaPlus, FaUpload } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const VehicleModel = lazyWithRetry(() => import('~/features/fleet/vehicleModel/pages/VehicleModel'));
const VehicleModelNew = lazyWithRetry(() => import('~/features/fleet/vehicleModel/pages/VehicleModelNew'));
const VehicleModelModify = lazyWithRetry(() => import('~/features/fleet/vehicleModel/pages/VehicleModelModify'));
const VehicleModelImport = lazyWithRetry(() => import('~/features/fleet/vehicleModel/pages/VehicleModelImport'));
const VehicleModelExport = lazyWithRetry(() => import('~/features/fleet/vehicleModel/pages/VehicleModelExport'));

export const vehicleModelRoutes: { [key: number]: IRouteConfigType } = {};

vehicleModelRoutes[routesIdMap.FleetVehicleModel] = {
	component: VehicleModel,
	exact: true,
	id: routesIdMap.FleetVehicleModel,
	name: 'Vehicle models',
	path: '/fleet/model',
	permissions: [PermissionsType.FleetVehicleModel],
	icon: FaCarAlt,
	menuText: 'Models',
	childrens: [
		routesIdMap.FleetVehicleModelNew,
		routesIdMap.FleetVehicleModelModify,
		routesIdMap.FleetVehicleModelImport,
		routesIdMap.FleetVehicleModelExport,
	],
};

vehicleModelRoutes[routesIdMap.FleetVehicleModelNew] = {
	component: VehicleModelNew,
	exact: true,
	id: routesIdMap.FleetVehicleModelNew,
	name: 'New model',
	path: '/fleet/model/new',
	permissions: [PermissionsType.FleetVehicleModel],
	isChild: true,
	icon: FaPlus,
	menuText: 'New model',
};

vehicleModelRoutes[routesIdMap.FleetVehicleModelModify] = {
	component: VehicleModelModify,
	exact: true,
	id: routesIdMap.FleetVehicleModelModify,
	name: 'Modify vehicle model',
	path: '/fleet/model/:brand/:modelCode',
	isChild: true,
	icon: FaPencil,
	permissions: [PermissionsType.FleetVehicleModel],
};

vehicleModelRoutes[routesIdMap.FleetVehicleModelImport] = {
	component: VehicleModelImport,
	exact: true,
	id: routesIdMap.FleetVehicleModelImport,
	name: 'Import vehicle model',
	path: '/fleet/model/import',
	isChild: true,
	icon: FaUpload,
	permissions: [PermissionsType.FleetVehicleModel],
};

vehicleModelRoutes[routesIdMap.FleetVehicleModelExport] = {
	component: VehicleModelExport,
	exact: true,
	id: routesIdMap.FleetVehicleModelExport,
	name: 'Export vehicle model',
	path: '/fleet/model/export',
	isChild: true,
	icon: FaDownload,
	permissions: [PermissionsType.FleetVehicleModel],
};
