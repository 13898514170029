import { RegularizationEndPoints } from './RegularizationEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IRegularization } from '../entities/Regularization';

export interface IRegularizationUpdateParams extends IServiceBaseParams {
	amount: number;
	bookingNumber: string;
	code: string;
	concept: number;
	description: string;
	state: number;
	subject: string;
}

/**
 *
 * Update booking regularization
 *  {object} bookingRegularization Booking regularization
 * @return {IRegularization} IRegularization
 */
export const regularizationUpdateService = (model: IRegularizationUpdateParams) => {
	return Api.post<IRegularization, IRegularizationUpdateParams>(RegularizationEndPoints.UPDATE, model);
};
