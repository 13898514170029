import { createSyncAction } from '@crac/core/modules/shared/state/createAction';
import { createAsyncAction } from '@crac/core/modules/shared/state/createAsyncAction';

import type { IVehicleModel } from '../../entities/VehicleModel';
import type { IFleetVehicleModelDeactivateParams } from '../../services/FleetVehicleModelActivateService';
import { fleetVehicleModelActivateService } from '../../services/FleetVehicleModelActivateService';
import { fleetVehicleModelDeactivateService } from '../../services/FleetVehicleModelDeactivateService';
import type { IFleetVehicleModelExportParams } from '../../services/FleetVehicleModelExportService';
import { fleetVehicleModelExportService } from '../../services/FleetVehicleModelExportService';
import type { IFleetVehicleModelImportParams } from '../../services/FleetVehicleModelImportService';
import { fleetVehicleModelImportService } from '../../services/FleetVehicleModelImportService';
import type { IFleetVehicleModelInsertParams } from '../../services/FleetVehicleModelInsertService';
import { fleetVehicleModelInsertService } from '../../services/FleetVehicleModelInsertService';
import type { IFleetVehicleModelModifyParams } from '../../services/FleetVehicleModelModifyService';
import { fleetVehicleModelModifyService } from '../../services/FleetVehicleModelModifyService';
import type { IFleetVehicleModelSearchParams } from '../../services/FleetVehicleModelSearchService';
import { fleetVehicleModelSearchService } from '../../services/FleetVehicleModelSearchService';

/**
 * Searches for fleet vehicle models based on search parameters.
 * @param {IFleetVehicleModelSearchParams} params The parameters used for searching fleet vehicle models.
 * @returns {Promise<void>} A promise that resolves when the search operation is complete.
 */
export const fleetVehicleModelSearch = createAsyncAction<IVehicleModel[], IFleetVehicleModelSearchParams>(
	'fleet/vehicleModel/search',
	fleetVehicleModelSearchService,
);

/**
 * Clears the fleet vehicle model data from the state.
 */
export const fleetVehicleModelClear = createSyncAction('fleet/vehicleModel/clear');

/**
 * Inserts a new fleet vehicle model.
 * @param {IFleetVehicleModelInsertParams} params The parameters for inserting a new fleet vehicle model.
 * @returns {Promise<void>} A promise that resolves when the insertion operation is complete.
 */
export const fleetVehicleModelInsert = createAsyncAction<IVehicleModel, IFleetVehicleModelInsertParams>(
	'fleet/vehicleModel/insert',
	fleetVehicleModelInsertService,
);

/**
 * Modifies an existing fleet vehicle model.
 * @param {IFleetVehicleModelModifyParams} params The parameters for modifying an existing fleet vehicle model.
 * @returns {Promise<void>} A promise that resolves when the modification operation is complete.
 */
export const fleetVehicleModelModify = createAsyncAction<IVehicleModel, IFleetVehicleModelModifyParams>(
	'fleet/vehicleModel/modify',
	fleetVehicleModelModifyService,
);

/**
 * Deactivates a fleet vehicle model.
 * @param {IFleetVehicleModelDeactivateParams} params The parameters for deactivating a fleet vehicle model.
 * @returns {Promise<void>} A promise that resolves when the deactivation operation is complete.
 */
export const fleetVehicleModelDeactivate = createAsyncAction<IVehicleModel, IFleetVehicleModelDeactivateParams>(
	'fleet/vehicleModel/deactivate',
	fleetVehicleModelDeactivateService,
);

/**
 * Activates a fleet vehicle model.
 * @param {IFleetVehicleModelDeactivateParams} params The parameters for activating a fleet vehicle model.
 * @returns {Promise<void>} A promise that resolves when the activation operation is complete.
 */
export const fleetVehicleModelActivate = createAsyncAction<IVehicleModel, IFleetVehicleModelDeactivateParams>(
	'fleet/vehicleModel/activate',
	fleetVehicleModelActivateService,
);

/**
 * Imports vehicle models.
 * @param {IFleetVehicleModelImportParams} params The parameters for importing vehicle models.
 * @returns {Promise<void>} A promise that resolves when the import operation is complete.
 */
export const fleetVehicleModelImport = createAsyncAction<IVehicleModel[], IFleetVehicleModelImportParams>(
	'fleet/vehicleModel/import',
	fleetVehicleModelImportService,
);

/**
 * Export vehicle models.
 * @param {IFleetVehicleModelExportParams} params The parameters for exporting vehicle models.
 * @returns {Promise<void>} A promise that resolves when the export operation is complete.
 */
export const fleetVehicleModelExport = createAsyncAction<boolean, IFleetVehicleModelExportParams>(
	'fleet/vehicleModel/export',
	fleetVehicleModelExportService,
);

/**
 * Clears the vehicle models imported data from the state.
 */
export const fleetVehicleModelImportClear = createSyncAction('fleet/vehicleModel/importClear');
