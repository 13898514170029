import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import {
	regularizationGetByBooking,
	regularizationGetByCode,
	regularizationGetPendingByUser,
	regularizationInsert,
	regularizationUpdate,
} from '@crac/core/modules/booking/regularization/state/actions/RegularizationActions';
import type { IRegularizationStateType } from '@crac/core/modules/booking/regularization/state/stateType/RegularizationStateType';
import { bookingDetailClear } from '@crac/core/redux/actions/BookingActions';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
const initialState: IRegularizationStateType = {
	bookingRegularization: null,
	bookingRegularizations: [],
	regularizationGetByBookingRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	regularizationGetByCodeRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	regularizationGetPendingByUserRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	regularizationInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	regularizationUpdateRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const regularizationsSlice = createSlice({
	name: 'BookingRegularizationSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// GET BY BOOKING
		builder
			.addCase(regularizationGetByBooking.pending, (state) => {
				return {
					...state,
					bookingRegularizations: [],
					regularizationGetByBookingRequest: { inProgress: true, messages: [], ok: true },
				};
			})
			.addCase(regularizationGetByBooking.fulfilled, (state, action) => {
				return {
					...state,
					bookingRegularizations: deepCopy(action.payload),
					regularizationGetByBookingRequest: { inProgress: false, messages: [], ok: true },
				};
			})
			.addCase(regularizationGetByBooking.rejected, (state, action) => {
				return {
					...state,
					regularizationGetByBookingRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// GET BY CODE
		builder
			.addCase(regularizationGetByCode.pending, (state) => {
				return {
					...state,
					bookingRegularization: null,
					regularizationGetByCodeRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(regularizationGetByCode.fulfilled, (state, action) => {
				return {
					...state,
					bookingRegularization: deepCopy(action.payload),
					regularizationGetByCodeRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(regularizationGetByCode.rejected, (state, action) => {
				return {
					...state,
					regularizationGetByCodeRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// GET PENDING BY USER
		builder
			.addCase(regularizationGetPendingByUser.pending, (state) => {
				return {
					...state,
					bookingRegularizations: [],
					regularizationGetPendingByUserRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(regularizationGetPendingByUser.fulfilled, (state, action) => {
				return {
					...state,
					bookingRegularizations: deepCopy(action.payload),
					regularizationGetPendingByUserRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(regularizationGetPendingByUser.rejected, (state, action) => {
				return {
					...state,
					regularizationGetPendingByUserRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// INSERT
		builder
			.addCase(regularizationInsert.pending, (state) => {
				return {
					...state,
					regularizationInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(regularizationInsert.fulfilled, (state, action) => {
				return {
					...state,
					bookingRegularizations: [...state.bookingRegularizations, action.payload],
					regularizationInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(regularizationInsert.rejected, (state, action) => {
				return {
					...state,
					regularizationInsertRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// UPDATE
		builder
			.addCase(regularizationUpdate.pending, (state) => {
				return {
					...state,
					regularizationUpdateRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(regularizationUpdate.fulfilled, (state, action) => {
				return {
					...state,
					bookingRegularizations: [
						...state.bookingRegularizations.filter((line) => line.code !== action.payload.code),
						action.payload,
					],
					regularizationUpdateRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(regularizationUpdate.rejected, (state, action) => {
				return {
					...state,
					regularizationUpdateRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		/**
		 * CLEAR
		 * CLEAR CACHE
		 */
		builder.addMatcher(isAnyOf(clearGlobalCache, bookingDetailClear), () => {
			return initialState;
		});
	},
});
export const regularizationsReducer = regularizationsSlice.reducer;
