import { createAsyncAction } from '@crac/core/modules/shared/state/createAsyncAction';
import type { IVehicle } from '@crac/core/modules/vehicle/entities/Vehicle';

import {
	type IFleetMovementRestrictionRemoveParams,
	fleetMovementRestrictionRemoveService,
} from '../../services/MovementRestrictionRemoveService';
import {
	type IFleetMovementRestrictionUpdateParams,
	fleetMovementRestrictionUpdateService,
} from '../../services/MovementRestrictionUpdateService';

/**
 * MovementRestrictionActions.ts' instead.
 * Updates fleet movement restrictions.
 *
 * @param {IVehicle[]} payload - The updated list of fleet movement restrictions.
 * @param {IFleetMovementRestrictionUpdateParams} params - Parameters detailing the updates to be made.
 * @returns {Promise<void>} - A promise that resolves after the movement restrictions are updated.
 */
export const fleetMovementRestrictionUpdate = createAsyncAction<IVehicle[], IFleetMovementRestrictionUpdateParams>(
	'fleetMovementRestriction/update',
	fleetMovementRestrictionUpdateService,
);

/**
 * Removes fleet movement restrictions.
 *
 * @param {IVehicle} payload - The identifiers of the fleet movement restrictions to be removed.
 * @param {IFleetMovementRestrictionRemoveParams} params - Parameters detailing the restrictions to be removed.
 * @returns {Promise<void>} - A promise that resolves after the movement restrictions are removed.
 */
export const fleetMovementRestrictionRemove = createAsyncAction<IVehicle[], IFleetMovementRestrictionRemoveParams>(
	'fleetMovementRestriction/remove',
	fleetMovementRestrictionRemoveService,
);
