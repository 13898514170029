import { DropOffEndpoints } from './DropOffEndpoints';
import type { IBooking } from '../../../../models/entities/Booking';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../../shared/types/ServiceResponse';

export interface IBookingDropOffGetPendingByPlateNumberAndBranchCodeParams extends IServiceBaseParams {
	plateNumber: string;
}

/**
 * Get list pending drop off by plate and branch code
 *  {string} plateNumber Plate number
 *  {string} branchCode Branch code
 * @return {Promise} `BookingPropType` Use await for resolve
 */
export const bookingDropOffGetPendingsByPlateNumberAndBranchCodeService = (
	model: IBookingDropOffGetPendingByPlateNumberAndBranchCodeParams,
): Promise<ServiceResponse<IBooking[]>> => {
	return Api.get<IBooking[], IBookingDropOffGetPendingByPlateNumberAndBranchCodeParams>(
		DropOffEndpoints.DROPOFF_GET_PENDING_BY_PLATE_AND_BRANCH,
		model,
	);
};
