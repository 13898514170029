export enum VehicleEngineEndPoints {
	/**
	 * search vehicle engines
	 * @host `/vehicleEngine/search`
	 */
	SEARCH = '/vehicleEngine/search',
	/**
	 * insert vehicle engines
	 * @host `/vehicleEngine/insert`
	 */
	INSERT = '/vehicleEngine/insert',
	/**
	 * modify vehicle engines
	 * @host `/vehicleEngine/modify`
	 */
	MODIFY = '/vehicleEngine/modify',
	/**
	 * delete vehicle engines
	 * @host `/vehicleEngine/delete`
	 */
	DELETE = '/vehicleEngine/delete',

	/**
	 * get engine by id
	 * @host `/vehicleEngine/getById`,
	 */
	GET_BY_ID = '/vehicleEngine/getById',
}
