import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import {
	FaCalendarDays,
	FaCarAlt,
	FaCheckAlt,
	FaPlus,
	FaRegCopyAlt,
	FaRegCreditCard,
	FaRegMoneyBill1,
} from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const Current = lazyWithRetry(() => import('./pages/Current'));
const SpendingNew = lazyWithRetry(() => import('./pages/SpendingNew'));
const Detail = lazyWithRetry(() => import('./pages/Detail'));
const Pending = lazyWithRetry(() => import('./pages/Pending'));
const History = lazyWithRetry(() => import('./pages/History'));

export const cashRoutes: { [key: number]: IRouteConfigType } = {};

// CURRENT
cashRoutes[routesIdMap.ManagementCashCurrent] = {
	component: Current,
	exact: true,
	id: routesIdMap.ManagementCashCurrent,
	name: 'Cash',
	path: '/management/cash',
	permissions: [PermissionsType.ManagementCashLine],
	icon: FaRegMoneyBill1,
	menuText: 'Cash',
};

// PENDING
cashRoutes[routesIdMap.ManagementCashPending] = {
	component: Pending,
	exact: true,
	id: routesIdMap.ManagementCashPending,
	name: 'Pending',
	path: '/management/cash/pending',
	permissions: [PermissionsType.ManagementCashLine, PermissionsType.ManagementCashLineSign],
	icon: FaCheckAlt,
	menuText: 'Pending',
};

// HISTORY
cashRoutes[routesIdMap.ManagementCashHistory] = {
	component: History,
	exact: true,
	id: routesIdMap.ManagementCashHistory,
	name: 'History',
	path: '/management/cash/history',
	permissions: [PermissionsType.ManagementCashLine, PermissionsType.ManagementCashLineSign],
	icon: FaCalendarDays,
	menuText: 'History',
};

// SPENDINGS
cashRoutes[routesIdMap.ManagementCashCurrentSpendings] = {
	component: Current,
	exact: true,
	id: routesIdMap.ManagementCashCurrentSpendings,
	name: 'Spendings',
	path: '/management/cash/spendings',
	permissions: [PermissionsType.ManagementCashLine],
	icon: FaRegCopyAlt,
	menuText: 'Spendings',
};

cashRoutes[routesIdMap.ManagementCashCurrentSpendingsDetail] = {
	component: Current,
	exact: true,
	id: routesIdMap.ManagementCashCurrentSpendingsDetail,
	name: 'Spendings',
	path: '/management/cash/spendings/:number',
	permissions: [PermissionsType.ManagementCashLine],
	icon: FaRegCopyAlt,
};

cashRoutes[routesIdMap.ManagementCashCurrentSpendingsNew] = {
	component: SpendingNew,
	exact: true,
	id: routesIdMap.ManagementCashCurrentSpendingsNew,
	name: 'New spending',
	path: '/management/cash/spendings/new',
	permissions: [PermissionsType.ManagementCashLine],
	icon: FaPlus,
	menuText: 'New spending',
};

// BOOKINGS
cashRoutes[routesIdMap.ManagementCashCurrentBookings] = {
	component: Current,
	exact: true,
	id: routesIdMap.ManagementCashCurrentBookings,
	name: 'Management bookings',
	path: '/management/cash/bookings',
	permissions: [PermissionsType.ManagementCashLine],
	icon: FaCarAlt,
	menuText: 'Management bookings',
};

// PAYMENTS
cashRoutes[routesIdMap.ManagementCashCurrentPayments] = {
	component: Current,
	exact: true,
	id: routesIdMap.ManagementCashCurrentPayments,
	name: 'Management Payments',
	path: '/management/cash/payments',
	permissions: [PermissionsType.ManagementCashLine],
	icon: FaRegCreditCard,
	menuText: 'Management payments',
};

cashRoutes[routesIdMap.ManagementCashDetail] = {
	component: Detail,
	exact: true,
	id: routesIdMap.ManagementCashDetail,
	name: 'Detail',
	path: '/management/cash/detail/:id',
	permissions: [PermissionsType.ManagementCashLine],
	icon: FaRegMoneyBill1,
};

// DETAIL
cashRoutes[routesIdMap.ManagementCashDetailSpendings] = {
	component: Detail,
	exact: true,
	id: routesIdMap.ManagementCashDetailSpendings,
	name: 'Spendings',
	path: '/management/cash/detail/:id/spendings',
	permissions: [PermissionsType.ManagementCashLine],
	icon: FaRegMoneyBill1,
};

cashRoutes[routesIdMap.ManagementCashDetailSpendingsDetail] = {
	component: Detail,
	exact: true,
	id: routesIdMap.ManagementCashDetailSpendingsDetail,
	name: 'Spendings',
	path: '/management/cash/detail/:id/spendings/:number',
	permissions: [PermissionsType.ManagementCashLine],
	icon: FaRegMoneyBill1,
};

cashRoutes[routesIdMap.ManagementCashDetailBookings] = {
	component: Detail,
	exact: true,
	id: routesIdMap.ManagementCashDetailBookings,
	name: 'Bookings',
	path: '/management/cash/detail/:id/bookings',
	permissions: [PermissionsType.ManagementCashLine],
	icon: FaRegMoneyBill1,
};

cashRoutes[routesIdMap.ManagementCashDetailPayments] = {
	component: Detail,
	exact: true,
	id: routesIdMap.ManagementCashDetailPayments,
	name: 'Payments',
	path: '/management/cash/detail/:id/payments',
	permissions: [PermissionsType.ManagementCashLine],
	icon: FaRegMoneyBill1,
};
