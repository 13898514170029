/* eslint-disable default-param-last */
import { createSlice } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { managerLocalStorage } from '@crac/core/helpers/reducerStorage';
import type { IRegularizationSubject } from '@crac/core/modules/booking/regularization/entities/RegularizationSubject';
import { regularizationSubjectGetAll } from '@crac/core/modules/booking/regularization/state/actions/RegularizationSubjectActions';
import type { IRegularizationSubjectReducerState } from '@crac/core/modules/booking/regularization/state/stateType/RegularizationSubjectStateType';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import { employeeLoginAndLoadCachedData } from '@crac/core/redux/actions/EmployeeActions';
const regularizationSubjectStorageKey = 'bookingRegularizationSubjects';
const { getItem, setItem, removeItem } = managerLocalStorage();

const initialState: IRegularizationSubjectReducerState = {
	regularizationSubjects: (getItem(regularizationSubjectStorageKey) as IRegularizationSubject[]) || [],
	regularizationSubjectsRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const regularizationSubjectsSlice = createSlice({
	name: 'RegularizationTypeSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR CACHE
		builder.addCase(clearGlobalCache, (state) => {
			removeItem(regularizationSubjectStorageKey);
			return {
				...state,
				regularizationSubjects: [],
			};
		});
		// GET ALL
		builder
			.addCase(regularizationSubjectGetAll.pending, (state) => {
				return {
					...state,
					regularizationSubjectsRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(regularizationSubjectGetAll.fulfilled, (state, action) => {
				setItem(regularizationSubjectStorageKey, action.payload);
				return {
					...state,
					regularizationSubjects: deepCopy(action.payload),
					regularizationSubjectsRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(regularizationSubjectGetAll.rejected, (state, action) => {
				return {
					...state,
					regularizationSubjectsRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			})
			.addCase(employeeLoginAndLoadCachedData.fulfilled, (state, action) => {
				setItem(regularizationSubjectStorageKey, action.payload.regularizationSubjects);
				return {
					...state,
					regularizationSubjects: deepCopy(action.payload.regularizationSubjects),
				};
			});
	},
});

export const regularizationSubjectsReducer = regularizationSubjectsSlice.reducer;
