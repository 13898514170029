import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaChartBar } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const Dashboard = lazyWithRetry(() => import('~/features/sale/dashboard/pages/Dashboard'));

export const dashboardRoutes: { [key: number]: IRouteConfigType } = {};

dashboardRoutes[routesIdMap.Sale] = {
	component: Dashboard,
	exact: true,
	id: routesIdMap.Sale,
	name: 'Sale',
	path: '/sale',
	permissions: [PermissionsType.Sale],
	icon: FaChartBar,
	menuText: 'Sale',
};
