import { createSyncAction } from '../../../../../modules/shared/state/createAction';
import { createAsyncAction } from '../../../../../modules/shared/state/createAsyncAction';
import type { IFine } from '../../entities/Fine';
import type { IFineInsertParams } from '../../services/FineInsertService';
import { fineInsertService } from '../../services/FineInsertService';
import { type IFineModifyParams, fineModifyService } from '../../services/FineModifyService';
import type { IFineSearchParams } from '../../services/FineSearchService';
import { fineSearchService } from '../../services/FineSearchService';

/**
 * Clears the fine data from the state.
 */
export const fleetFineClear = createSyncAction('fleet/fineClear');

/**
 * Searches for fines based on search parameters.
 * @param {IFine[]} payload `IFine[]` The payload for the action.
 * @param {IFineSearchParams} IFineSearchParams The parameters for retrieving fines.
 * @returns {Promise<void>} `Promise<void>` - A promise that resolves to an array of fines.
 */
export const fleetFineSearch = createAsyncAction<IFine[], IFineSearchParams>('fleet/fine/search', fineSearchService);

/**
 * Inserts a new fine into the fleet.
 * @param {IFine} payload `IFine` The fine to be inserted.
 * @param {IFineInsertParams} IFineInsertParams The parameters for inserting a new fine.
 * @returns {Promise<void>} `Promise<void>` - A promise that resolves when the fine has been inserted.
 */
export const fleetFineInsert = createAsyncAction<IFine, IFineInsertParams>('fleet/fine/insert', fineInsertService);

/**
 * Modifies an existing fine in the fleet.
 * @param {IFine} payload `IFine` The fine to be modified.
 * @param {IFineModifyParams} IFineModifyParams The parameters for modifying an existing fine.
 * @returns {Promise<void>} `Promise<void>` - A promise that resolves when the fine has been modified.
 */
export const fleetFineModify = createAsyncAction<IFine, IFineModifyParams>('fleet/fine/modify', fineModifyService);
