import { createAsyncAction } from '../../../../shared/state/createAsyncAction';
import type { IServiceBaseParams } from '../../../../shared/types/ServiceBaseParams';
import type { IRegularization } from '../../entities/Regularization';
import type { IRegularizationGetByBookingParams } from '../../services/RegularizationGetBookingService';
import { regularizationGetByBookingService } from '../../services/RegularizationGetBookingService';
import type { IRegularizationGetByCodeParams } from '../../services/RegularizationGetByCodeService';
import { regularizationGetByCodeService } from '../../services/RegularizationGetByCodeService';
import { regularizationGetPendingByUserService } from '../../services/RegularizationGetPendingByUserService';
import { regularizationInsertService } from '../../services/RegularizationInsertService';
import type { IRegularizationInsertParams } from '../../services/RegularizationInsertService';
import type { IRegularizationUpdateParams } from '../../services/RegularizationUpdateService';
import { regularizationUpdateService } from '../../services/RegularizationUpdateService';

/**
 * Retrieves a specific regularization by its code.
 *
 * @param {IRegularization} payload - The regularization to be retrieved.
 * @param {IRegularizationGetByCodeParams} params - Parameters for identifying the regularization by code.
 * @returns {Promise<void>} - A promise that resolves to the specified regularization.
 */
export const regularizationGetByCode = createAsyncAction<IRegularization, IRegularizationGetByCodeParams>(
	'regularization/getByCode',
	regularizationGetByCodeService,
);

/**
 * Retrieves regularizations associated with a specific booking.
 *
 * @param {IRegularization[]} payload - The regularizations to be retrieved.
 * @param {IRegularizationGetByBookingParams} params - Parameters to identify regularizations associated with a booking.
 * @returns {Promise<void>} - A promise that resolves to an array of regularizations.
 */
export const regularizationGetByBooking = createAsyncAction<IRegularization[], IRegularizationGetByBookingParams>(
	'regularization/getByBooking',
	regularizationGetByBookingService,
);

/**
 * Retrieves pending regularizations for a user.
 *
 * @param {IRegularization[]} payload - The pending regularizations to be retrieved.
 * @param {IServiceBaseParams} params - Base service parameters for identifying the user.
 * @returns {Promise<void>} - A promise that resolves to an array of pending regularizations.
 */
export const regularizationGetPendingByUser = createAsyncAction<IRegularization[], IServiceBaseParams>(
	'regularization/getPendingByUser',
	regularizationGetPendingByUserService,
);

/**
 * Inserts a new regularization.
 *
 * @param {IRegularization} payload - The regularization to be inserted.
 * @param {IRegularizationInsertParams} params - Parameters for inserting the regularization.
 * @returns {Promise<void>} - A promise that resolves after the regularization is inserted.
 */
export const regularizationInsert = createAsyncAction<IRegularization, IRegularizationInsertParams>(
	'regularization/insert',
	regularizationInsertService,
);

/**
 * Updates an existing regularization.
 *
 * @param {IRegularization} payload - The regularization to be updated.
 * @param {IRegularizationUpdateParams} params - Parameters for updating the regularization.
 * @returns {Promise<void>} - A promise that resolves after the regularization is updated.
 */
export const regularizationUpdate = createAsyncAction<IRegularization, IRegularizationUpdateParams>(
	'regularization/update',
	regularizationUpdateService,
);
