import { createSyncAction } from '@crac/core/modules/shared/state/createAction';
import { createAsyncAction } from '@crac/core/modules/shared/state/createAsyncAction';

import type { IRevisionType } from '../../entities/RevisionType';
import type { IRevisionTypeDeactivateParams } from '../../service/RevisionTypeDeactivateService';
import { revisionTypeDeactivateService } from '../../service/RevisionTypeDeactivateService';
import type { IRevisionTypeGetAllParams } from '../../service/RevisionTypeGetAllService';
import { revisionTypeGetAllService } from '../../service/RevisionTypeGetAllService';
import type { IRevisionTypeInsertParams } from '../../service/RevisionTypeInsertService';
import { RevisionTypeInsertService } from '../../service/RevisionTypeInsertService';
import type { IRevisionTypeModifyParams } from '../../service/RevisionTypeModifyService';
import { revisionTypeModifyService } from '../../service/RevisionTypeModifyService';
import type { IRevisionTypeReactivateParams } from '../../service/RevisionTypeReactivateService';
import { revisionTypeReactivateService } from '../../service/RevisionTypeReactivateService';
import type { IRevisionTypeSearchParams } from '../../service/RevisionTypeSearchService';
import { revisionTypeSearchService } from '../../service/RevisionTypeSearchService';

export const revisionTypeSearch = createAsyncAction<IRevisionType[], IRevisionTypeSearchParams>(
	'workshop/revisionType/search',
	revisionTypeSearchService,
);

export const revisionTypeClear = createSyncAction('workshop/revisionType/clear');

export const revisionTypeInsert = createAsyncAction<IRevisionType, IRevisionTypeInsertParams>(
	'workshop/revisionType/insert',
	RevisionTypeInsertService,
);

export const revisionTypeModify = createAsyncAction<IRevisionType, IRevisionTypeModifyParams>(
	'workshop/revisionType/modify',
	revisionTypeModifyService,
);

export const revisionTypeGetAll = createAsyncAction<IRevisionType[], IRevisionTypeGetAllParams>(
	'workshop/revisionType/getAll',
	revisionTypeGetAllService,
);

export const revisionTypeDeactivate = createAsyncAction<IRevisionType, IRevisionTypeDeactivateParams>(
	'workshop/revisionType/deactivate',
	revisionTypeDeactivateService,
);

export const revisionTypeReactivate = createAsyncAction<IRevisionType, IRevisionTypeReactivateParams>(
	'workshop/revisionType/reactivate',
	revisionTypeReactivateService,
);
