import { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { useUnAuthorizedRequest } from '@crac/components/hooks/useUnAuthorizedRequest';
import { Login } from '@crac/components/intranet/shared/login';
import { employeeLoginAndLoadCachedData } from '@crac/core/redux/actions/EmployeeActions';
import { Dialog, DialogContent } from '@crac/ui/dialog';

import { useEmployeeSelector, useEmployeeState } from '~/features/employee/state/selectors';
import { checkRequestHasErrors } from '~/features/shared/helpers/checkRequestHasErrors';
import { useBindAndMemoizeActions } from '~/features/shared/hooks/useBindAndMemoizeActions';

const actionsImported = {
	employeeLoginAndLoadCachedData,
};
/**
 * Detect if the post message is unauthorized and show a modal to login again
 * @returns RefreshToken component
 */
export const RefreshToken = () => {
	const { employeeLoginAndLoadCachedData } = useBindAndMemoizeActions(actionsImported);
	const { isUnAuthorized } = useUnAuthorizedRequest();
	const { userName } = useEmployeeSelector();
	const { login } = useEmployeeState();

	const [isSubmitted, setIsSubmitted] = useState(false);

	const handleLogin: React.ComponentProps<typeof Login>['onSubmit'] = (values) => {
		if (!values.password || !values.userName) {
			return;
		}
		employeeLoginAndLoadCachedData({ password: values.password, userName: values.userName, isPublic: true });
		setIsSubmitted(true);
	};

	useEffect(() => {
		if (!isSubmitted || login.inProgress) {
			return;
		}
		if (login.ok) {
			window.location.reload();
		} else {
			checkRequestHasErrors([login]);
		}
	}, [isSubmitted, login]);

	if (isUnAuthorized) {
		return (
			<Dialog open>
				<DialogContent>
					<h4 className="tw-text-center tw-mb-3">Session expired</h4>
					<Login
						className="tw-border-none"
						initialState={{ userName, password: '' }}
						inputs={{
							inputPassword: {
								name: 'password',
								placeholder: 'Password',
							},
							inputUserName: {
								name: 'userName',
								placeholder: 'User name',
							},
						}}
						isLoading={login.inProgress}
						onSubmit={handleLogin}
						renderAfterSubmitButton={
							<Link className="tw-btn tw-btn-link tw-block tw-text-center tw-mt-3" to="/login">
								Close session
							</Link>
						}
						showLogo={false}
						submitButton={{
							block: true,
						}}
						subtitle="Log in to your account"
					/>
				</DialogContent>
			</Dialog>
		);
	}

	return null;
};
