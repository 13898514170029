import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaPlus } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const CustomerNew = lazyWithRetry(() => import('./pages/New'));

export const newRoutes: { [key: number]: IRouteConfigType } = {};

newRoutes[routesIdMap.CustomersNew] = {
	component: CustomerNew,
	exact: true,
	id: routesIdMap.CustomersNew,
	name: 'New customer',
	path: '/customer/new',
	permissions: [PermissionsType.CustomerNew],
	icon: FaPlus,
	menuText: 'New',
};
