import type { FC } from 'react';

import { Link } from 'react-router-dom';
import useReactRouterBreadcrumbs, { humanize } from 'use-react-router-breadcrumbs';

import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage } from '@crac/ui/breadcrumb';
import { Icon } from '@crac/ui/icons/Icon';

import { routesIdMap } from '~/config/routesIdMap';
import { routes } from '~/features/routes';

const homeRoute = routes[routesIdMap.Dashboard];

// Temporal hasta modificar las rutas de estas secciones
const excludeRoutes = [
	'/vehicles/detail',
	'/bookings/detail',
	'/customers/detail',
	'/management/cash/detail',
	'/companies/detail',
	'/check-customer',
];

/**
 * https://github.com/icd2k3/use-react-router-breadcrumbs#readme
 * humanize is is the default formatter from use-react-router-breadcrumb
 */
export const BreadCrumbs: FC = () => {
	const breadcrumbs = useReactRouterBreadcrumbs(undefined, {
		defaultFormatter: (str) => (str.length > 40 ? humanize(`${str.substring(0, 30)}...`) : humanize(str)),
	});

	if (location.pathname === homeRoute.path) {
		return null;
	}

	return (
		<Breadcrumb>
			<BreadcrumbList className="tw-relative tw-mb-4 tw-p-2 tw-bg-background">
				{breadcrumbs.map(({ breadcrumb, match }) => {
					if (excludeRoutes.includes(match.pathname)) {
						return null;
					}

					if (match.pathname === location.pathname) {
						return (
							<BreadcrumbItem key={match.pathname}>
								<BreadcrumbPage>
									{match.pathname === homeRoute.path && (
										<Icon className="tw-h-3 tw-w-3" name="FaHome" size="sm" />
									)}
									{breadcrumb}
								</BreadcrumbPage>
							</BreadcrumbItem>
						);
					}

					return (
						<BreadcrumbItem key={match.pathname}>
							<BreadcrumbLink asChild>
								<Link
									className="tw-flex tw-items-center tw-gap-1"
									to={`${match.pathname}${location.search}`}
								>
									{match.pathname === homeRoute.path && (
										<Icon className="tw-h-3 tw-w-3" name="FaHome" size="sm" />
									)}
									{breadcrumb}
								</Link>
							</BreadcrumbLink>
						</BreadcrumbItem>
					);
				})}
			</BreadcrumbList>
		</Breadcrumb>
	);
};
