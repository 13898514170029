import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaRegFileLines } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const Accidents = lazyWithRetry(() => import('./pages/Accidents'));

export const accidentsRoutes: { [key: number]: IRouteConfigType } = {};

accidentsRoutes[routesIdMap.VehiclesDetailAccidents] = {
	component: Accidents,
	exact: true,
	id: routesIdMap.VehiclesDetailAccidents,
	name: 'Accidents',
	path: '/vehicles/detail/:id/accidents',
	permissions: [PermissionsType.VehicleDetail],
	icon: FaRegFileLines,
};

accidentsRoutes[routesIdMap.VehiclesDetailAccidentsDetail] = {
	component: Accidents,
	exact: true,
	id: routesIdMap.VehiclesDetailAccidentsDetail,
	name: 'Accident detail',
	path: '/vehicles/detail/:id/accidents/:document',
	permissions: [PermissionsType.VehicleDetail],
	icon: FaRegFileLines,
};
