import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaListOl, FaPencil, FaPlus } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const VehicleVersion = lazyWithRetry(() => import('~/features/fleet/vehicleVersion/pages/VehicleVersion'));
const VehicleVersionNew = lazyWithRetry(() => import('~/features/fleet/vehicleVersion/pages/VehicleVersionNew'));
const VehicleVersionModify = lazyWithRetry(() => import('~/features/fleet/vehicleVersion/pages/VehicleVersionModify'));

export const vehicleVersionRoutes: { [key: number]: IRouteConfigType } = {};

vehicleVersionRoutes[routesIdMap.FleetVehicleVersion] = {
	component: VehicleVersion,
	exact: true,
	id: routesIdMap.FleetVehicleVersion,
	name: 'Vehicle versions',
	path: '/fleet/vehicle-version',
	permissions: [PermissionsType.VehicleVersion],
	icon: FaListOl,
	menuText: 'Versions',
	childrens: [routesIdMap.FleetVehicleVersionNew, routesIdMap.FleetVehicleVersionModify],
};

vehicleVersionRoutes[routesIdMap.FleetVehicleVersionNew] = {
	component: VehicleVersionNew,
	exact: true,
	id: routesIdMap.FleetVehicleVersionNew,
	name: 'New version',
	path: '/fleet/vehicle-version/new',
	permissions: [PermissionsType.VehicleVersionManagement],
	isChild: true,
	icon: FaPlus,
	menuText: 'New version',
};

vehicleVersionRoutes[routesIdMap.FleetVehicleVersionModify] = {
	component: VehicleVersionModify,
	exact: true,
	id: routesIdMap.FleetVehicleVersionModify,
	name: 'Modify vehicle version',
	path: '/fleet/vehicle-version/:id',
	isChild: true,
	icon: FaPencil,
	permissions: [PermissionsType.VehicleVersionManagement],
};
