import { Api } from '@crac/core/modules/shared/api';

import { VehicleEndPoints } from './VehicleEndPoints';
import type { IVehicleSearchParams } from '../../../models/serviceParams/VehicleParams';
import type { IServiceBaseParams } from '../../shared/types/ServiceBaseParams';
import type { IVehicle } from '../entities/Vehicle';
import type { IVehicleTelemetry } from '../entities/VehicleConnectedTelemetry';

/*
 * Connected vehicles search
 * =================================================================================================
 */

export type IConnectedVehicleSearchParams = IVehicleSearchParams;

/**
 * Searches for connected vehicles based on the provided search parameters.
 *
 * @param model - The search parameters for the connected vehicles.
 * @returns A promise that resolves to an array of vehicles that match the search criteria.
 */
export const vehicleServiceConnectedVehicleSearch = (model: IConnectedVehicleSearchParams) => {
	return Api.get<IVehicle[], IConnectedVehicleSearchParams>(VehicleEndPoints.SEARCH, model);
};

/*
 * Vehicle telemetry
 * =================================================================================================
 */

/**
 * Represents the parameters for getting telemetry data of a vehicle.
 */
export interface IVehicleGetTelemetryParams extends IServiceBaseParams {
	plateNumber: string;
}

/**
 * Retrieves telemetry data for a vehicle.
 * @param model - The parameters for retrieving telemetry.
 * @returns A promise that resolves to the telemetry data of the vehicle.
 */
export const vehicleServiceGetTelemetry = (model: IVehicleGetTelemetryParams) => {
	return Api.get<IVehicleTelemetry, IVehicleGetTelemetryParams>(VehicleEndPoints.CHANGE_GET_TELEMETRY, model);
};

/*
 * Enroll
 * =================================================================================================
 */

/**
 * Represents the parameters for enrolling vehicles.
 */
export interface IVehicleEnrollParams extends IServiceBaseParams {
	plateNumbers: string[];
	sKProvider: string;
	qrCode?: string;
	profileId?: string;
}

type VehicleEnrollingFailed = {
	plateNumber: string;
	errorMessage: string;
};

export type IVehicleEnrollResponse = {
	enrolledVehicles: IVehicle[];
	notEnrolledVehicles: VehicleEnrollingFailed[];
};

/**
 * Enrolls vehicles.
 * @param IVehicleEnrollParams Vehicle enroll parameters
 * @returns A promise that resolves to an array of vehicles that match the search criteria.
 */
export const vehicleServiceEnroll = (model: IVehicleEnrollParams) => {
	return Api.post<IVehicleEnrollResponse, IVehicleEnrollParams>(VehicleEndPoints.ENROLL, model);
};

/*
 * Un Enroll
 * =================================================================================================
 */

/**
 * Represents the parameters for un-enrolling vehicles.
 */
export interface IVehicleUnEnrollParams extends IServiceBaseParams {
	plateNumbers: string[];
}

export type IVehicleUnenrollResponse = {
	unenrolledVehicles: IVehicle[];
	notUnenrolledVehicles: VehicleEnrollingFailed[];
};

/**
 * Un-enrolls vehicles.
 * @param IVehicleUnEnrollParams Vehicle un-enroll parameters
 * @returns A promise that resolves to an array of vehicles that match the search criteria.
 */
export const vehicleServiceUnEnroll = (model: IVehicleUnEnrollParams) => {
	return Api.post<IVehicleUnenrollResponse, IVehicleUnEnrollParams>(VehicleEndPoints.UN_ENROLL, model);
};

/*
 * Check enrollment status
 * =================================================================================================
 */

/**
 * Represents the parameters to check enrollment status
 */
export interface IVehicleCheckEnrollmentStatusParams extends IServiceBaseParams {
	plateNumber: string;
}

export type IVehicleCheckEnrollmentStatusResponse = {
	data:
		| {
				fcs_code: string | null;
				last_updated: string | null;
				message: string | null;
				product_sku: string | null;
				request_id: number | null;
				request_type: string | null;
				status: string;
				vehicle_name: string | null;
				vin: string;
		  }[]
		| null;
	errors:
		| {
				code: string;
				message: string;
				vin: string;
		  }[]
		| null;
};

/**
 * Checks enrollment status
 * @param IVehicleCheckEnrollmentStatusParams Parameters to check enrollment status
 * @returns A promise that resolves to an enrollment status
 */
export const vehicleServiceCheckEnrollmentStatus = (model: IVehicleCheckEnrollmentStatusParams) => {
	return Api.post<IVehicleCheckEnrollmentStatusResponse, IVehicleCheckEnrollmentStatusParams>(
		VehicleEndPoints.CHECK_ENROLLMENT_STATUS,
		model,
	);
};
