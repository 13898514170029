import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaWifiAlt } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const ConnectedVehicles = lazyWithRetry(() => import('~/features/fleet/connectedVehicles/pages/ConnectedVehicles'));
const ConnectedVehicleTelemetry = lazyWithRetry(
	() => import('~/features/fleet/connectedVehicles/pages/ConnectedVehicleTelemetry'),
);

export const connectedVehiclesRoutes: { [key: number]: IRouteConfigType } = {};

connectedVehiclesRoutes[routesIdMap.ConnectedVehicles] = {
	component: ConnectedVehicles,
	exact: true,
	id: routesIdMap.ConnectedVehicles,
	name: 'Connected vehicles',
	path: '/fleet/connected-vehicles',
	permissions: [PermissionsType.IT],
	menuText: 'Connected vehicles',
	icon: FaWifiAlt,
	childrens: [routesIdMap.ConnectedVehicleTelemetry],
};

connectedVehiclesRoutes[routesIdMap.ConnectedVehicleTelemetry] = {
	component: ConnectedVehicleTelemetry,
	exact: true,
	id: routesIdMap.ConnectedVehicleTelemetry,
	name: 'Connected vehicles',
	path: '/fleet/connected-vehicles/:plateNumber/telemetry',
	permissions: [PermissionsType.IT],
	icon: FaWifiAlt,
};
