import * as React from 'react';

import { BreadcrumbEllipsis } from './components/BreadcrumbEllipsis';
import { BreadcrumbItem } from './components/BreadcrumbItem';
import { BreadcrumbLink } from './components/BreadcrumbLink';
import { BreadcrumbList } from './components/BreadcrumbList';
import { BreadcrumbPage } from './components/BreadcrumbPage';
import { BreadcrumbSeparator } from './components/BreadcrumbSeparator';

const Breadcrumb = React.forwardRef<
	HTMLElement,
	React.ComponentPropsWithoutRef<'nav'> & {
		separator?: React.ReactNode;
	}
>(({ ...props }, ref) => <nav aria-label="breadcrumb" ref={ref} {...props} />);

Breadcrumb.displayName = 'Breadcrumb';

export {
	Breadcrumb,
	BreadcrumbList,
	BreadcrumbItem,
	BreadcrumbLink,
	BreadcrumbPage,
	BreadcrumbSeparator,
	BreadcrumbEllipsis,
};
