import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { FineTransmitterEndPoints } from './FineTransmitterEndpoints';
import type { IFineTransmitter } from '../entities/FineTransmitter';

export interface IFineTransmitterInsertParams extends IServiceBaseParams {
	country: string;
	email: string;
	lastModificationDate: string | Date;
	name: string;
	searchName: string;
	telephoneNumber: string;
}

/**
 * Insert a fine transmitter
 * @return {Promise<FineTransmitterType>} `FineTransmitterType`
 */
export const fineTransmitterInsertService = (
	model: IFineTransmitterInsertParams,
): Promise<ServiceResponse<IFineTransmitter>> => {
	return Api.post<IFineTransmitter, IFineTransmitterInsertParams>(FineTransmitterEndPoints.INSERT, model);
};
