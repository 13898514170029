/**
 * @deprecated - use "ExcessTypeEndpoints" from "packages\core\src\modules\workshop\excessType\services\ExcessTypeEndpoints.ts" instead.
 */
export enum ExcessTypeEndpoints {
	/**
	 * Excess type search
	 * @host `/excessType/search/`
	 */
	SEARCH = '/excessType/search/',

	/**
	 * Excess type insert
	 * @host `/excessType/insert/`
	 */
	INSERT = '/excessType/insert/',

	/**
	 * Excess type modify
	 * @host `/excessType/modify/`
	 */
	MODIFY = '/excessType/modify/',
	/**
	 * Excess type delete
	 * @host `/excessType/delete/`
	 */
	DELETE = '/excessType/delete/',
}
