import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaMagnifyingGlass, FaMagnifyingGlassPlus } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const Search = lazyWithRetry(() => import('./pages/Search'));
const PickUpSearch = lazyWithRetry(() => import('./pages/PickUpSearch'));

export const searchRoutes: { [key: number]: IRouteConfigType } = {};

searchRoutes[routesIdMap.Bookings] = {
	component: Search,
	exact: true,
	id: routesIdMap.Bookings,
	isRoot: true,
	name: 'Search booking',
	path: '/booking/search',
	permissions: [PermissionsType.BookingSearch],
	icon: FaMagnifyingGlass,
	menuText: 'Search',
};

searchRoutes[routesIdMap.BookingPickUpSearch] = {
	component: PickUpSearch,
	exact: true,
	id: routesIdMap.BookingPickUpSearch,
	name: 'Search pick up',
	path: '/booking/pickup-search',
	permissions: [PermissionsType.BookingNewExternal],
	icon: FaMagnifyingGlassPlus,
	menuText: 'Search pick up',
};
