/* eslint-disable complexity */
/* eslint-disable max-statements */
import { DateTime } from 'luxon';

import { getGcAgenciesByProvider } from '../../helpers/commons';
import type { IRegularization } from '../../modules/booking/regularization/entities/Regularization';
import type { IBooking } from '../entities/Booking';
import type { IBranch } from '../entities/Branch';
import type { IEmployee } from '../entities/Employee';
import type { IProvider } from '../entities/Provider';
import { BookingStateType } from '../types/BookingStateType';
import { BookingType } from '../types/BookingType';
import { PermissionsType } from '../types/PermissionsType';

export class BookingPermissionsType {
	private dropOffBranch?: IBranch;

	private pickUpBranch?: IBranch;

	private pickUpProvider?: IProvider;

	private dropOffProvider?: IProvider;

	// GENERAL SECTIONS
	CanViewBooking = false;

	CanViewBookingDropOff = false;

	CanBookingDropOffProcess = false;

	CanViewBookingPickUp = false;

	/**
	 * Can do booking pick up proccess, only if user having this permissions and BookingState is [Processing or Confirmed]
	 */
	CanBookingPickUpProcess = false;

	CanViewBookingDetail = false;

	CanViewBookingSearch = false;

	CanBookingNew = false;

	CanBookingNewExternal = false;

	CanViewBookingTurns = false;

	// BOOKING FRANCHISE
	CanViewBookingFranchiseLine = false;

	CanAddBookingDamage = false;

	CanAddBookingFranchiseLine = false;

	CanRemoveBookingFranchiseLine = false;

	CanRemoveBookingFranchiseLineForEver = false;

	// TURNS
	CanBookingTurnsBus = false;

	CanBookingTurnsFrontDesk = false;

	CanBookingTurnsMeeting = false;

	// BOOKING COMMENTS
	CanAddBookingComments = true;

	CanRemoveBookingComments = false;

	CanViewBookingComments = true;

	// BOOKING SERVICES
	CanViewBookingServices = true;

	CanRemoveBookingServices = false;

	CanAddBookingServices = false;

	// BOOKING PAYMENT LINES
	CanViewBookingPaymentLine = true;

	CanAddBookingPaymentLines = false;

	CanRemoveBookingPaymentLines = false;

	// BOOKING VEHICLES
	CanViewBookingChangeVehicle = true;

	CanAddBookingChangeVehicle = false;

	// BOOKING EXTRA DRIVERS
	CanAddBookingExtraDrivers = true;

	CanModifyBookingExtraDrivers = true;

	CanViewBookingExtraDrivers = true;

	// BOOKING REGULARIZATION
	CanViewBookingRegularizations = false;

	CanNewBookingRegularization = false;

	CanCloseBookingRegularization = false;

	CanBookingRegularizationAcceptReject = false;

	// DOCUMENTS
	CanViewBookingDocuments = false;

	// GENERAL BOOKING PERMISSIONS
	CanBookingCancel = false;

	private CanBookingCancelAgency = false;

	CanBookingModify = false;

	CanBookinModifyFlight = false;

	CanBookinModifyComment = false;

	CanChangeBookingInvoiceToAgencyCompany = false;

	CanChangeBookingInvoiceToCustomer = false;

	CanViewBookingInvoiceTo = false;

	CanViewBookingFine = false;

	CanBookingModifyCustomer = false;

	CanBookingSendToAgencyCompany = false;

	CanBookingSendToCustomer = false;

	CanViewPartnerAmounts = false;

	CanChangeAllowPendingsInContract = false;

	CanBookingExtend = false;

	CanUseReferencePaymentInContract = false;

	CanViewBookingAccidentParts = true;

	CanViewBookingTolls = true;

	// Only use in noShow booking state
	CanBookingRefundPayments = false;

	CanAddDamageWithoutPhoto = false;

	/**
	 * /**
	 * Se mostrará siempre excepto:
	 * En reservas de otras sucursales
	 * En estado On_Hire, Confirmed, Pending, Liquidated
	 * Empleados que no tienen el rol BookingChangeState
	 */
	CanBookingRevert = false;

	CanApplyDiscount = false;

	// Can use centauro free agency
	CanUseBookingCentauroFree = false;

	CanModifyAccidentalAddress = false;

	IT = false;

	public IsCorrectDropOffBranch: () => boolean;

	/**
	 * Ini booking permissions base in user and actual booking
	 */
	setDefaultPermissions = (permissions: string[]): void => {
		this.CanBookingModify = Boolean(permissions.includes(PermissionsType.BookingModify));
		this.CanViewBookingDetail = permissions.includes(PermissionsType.BookingDetail);
		this.CanViewBookingFine = permissions.includes(PermissionsType.BookingDetail);
		this.CanBookingNew = permissions.includes(PermissionsType.BookingNew);
		this.CanBookingNewExternal = permissions.includes(PermissionsType.BookingNewExternal);
		this.CanBookingCancel = permissions.includes(PermissionsType.BookingCancel);
		this.CanBookingCancelAgency = permissions.includes(PermissionsType.BookingCancelAgency);
		this.CanAddBookingDamage = permissions.includes(PermissionsType.BookingAddDamage);
		this.CanAddBookingFranchiseLine = permissions.includes(PermissionsType.BookingAddFranchise);
		this.CanAddBookingPaymentLines = permissions.includes(PermissionsType.BookingAddPaymentLine);
		this.CanAddBookingServices = permissions.includes(PermissionsType.BookingAddService);
		this.CanRemoveBookingServices = permissions.includes(PermissionsType.BookingAddService);

		this.CanBookingDropOffProcess = permissions.includes(PermissionsType.BookingDropOffProcess);
		this.CanViewBookingDropOff = permissions.includes(PermissionsType.BookingDropOff);
		this.CanChangeAllowPendingsInContract = permissions.includes(
			PermissionsType.BookingChangeAllowPendingsInContract,
		);
		this.CanViewBookingPickUp = permissions.includes(PermissionsType.BookingPickUp);
		this.CanBookingPickUpProcess = permissions.includes(PermissionsType.BookingPickUpProcess);
		this.CanBookingModifyCustomer = permissions.includes(PermissionsType.BookingModifyCustomer);
		this.CanBookingSendToAgencyCompany = permissions.includes(PermissionsType.BookingSendToAgencyCompany);
		this.CanBookingSendToCustomer = permissions.includes(PermissionsType.BookingSendToCustomer);
		this.CanBookingTurnsBus = permissions.includes(PermissionsType.BookingTurnsBus);
		this.CanBookingTurnsFrontDesk = permissions.includes(PermissionsType.BookingTurnsFrontDesk);
		this.CanBookingTurnsMeeting = permissions.includes(PermissionsType.BookingTurnsMeeting);
		this.CanChangeBookingInvoiceToAgencyCompany = permissions.includes(
			PermissionsType.BookingChangeInvoiceToAgencyCompany,
		);
		this.CanChangeBookingInvoiceToCustomer = permissions.includes(PermissionsType.BookingChangeInvoiceToCustomer);
		this.CanViewBookingInvoiceTo =
			permissions.includes(PermissionsType.BookingChangeInvoiceToCustomer) ||
			permissions.includes(PermissionsType.BookingChangeInvoiceToAgencyCompany);
		this.CanBookingRegularizationAcceptReject = permissions.includes(
			PermissionsType.BookingRegularizationAcceptReject,
		);
		this.CanCloseBookingRegularization = permissions.includes(PermissionsType.BookingRegularizationClose);
		this.CanNewBookingRegularization = permissions.includes(PermissionsType.BookingRegularizationNew);
		this.CanViewBookingRegularizations = permissions.includes(PermissionsType.BookingRegularization);
		this.CanRemoveBookingComments = permissions.includes(PermissionsType.BookingRemoveComment);
		this.CanViewBooking = permissions.includes(PermissionsType.Booking);
		this.CanAddBookingChangeVehicle = permissions.includes(PermissionsType.BookingChangeVehicle);
		this.CanViewBookingSearch = permissions.includes(PermissionsType.BookingSearch);
		this.CanBookingRevert = permissions.includes(PermissionsType.BookingChangeState);
		this.CanViewPartnerAmounts = permissions.includes(PermissionsType.ViewPartnerAmounts);
		this.CanRemoveBookingFranchiseLine = permissions.includes(PermissionsType.FranchiseLineRemove);
		this.CanRemoveBookingFranchiseLineForEver = permissions.includes(PermissionsType.FranchiseLineRemoveForEver);
		this.CanBookingExtend = permissions.includes(PermissionsType.BookingCanExtend);
		this.CanUseReferencePaymentInContract = permissions.includes(PermissionsType.BookingReferencePaymentInContract);
		this.CanBookingRefundPayments = permissions.includes(PermissionsType.BookingRefundPayment);
		this.IT = permissions.includes(PermissionsType.IT);
		this.CanUseBookingCentauroFree = permissions.includes(PermissionsType.BookingCentauroFree);
		this.CanModifyAccidentalAddress = permissions.includes(PermissionsType.BookingModify);
		this.CanAddDamageWithoutPhoto = permissions.includes(PermissionsType.CanAddDamageWithoutPhoto);
	};

	constructor(
		permissions: string[],
		booking?: IBooking | null,
		branches?: IBranch[],
		providers?: IProvider[],
		employee?: IEmployee,
		bookingRegularization?: IRegularization,
	) {
		this.setDefaultPermissions(permissions);

		if (booking && branches && providers && employee) {
			const { bookingState } = booking;
			const pickUpBranch = branches.find((branch) => branch.code === booking.dropOffBranchCode) as IBranch;
			const employeeCurrentBranch = employee.allowedBranches.find(
				(branch) => branch.code === employee.branchCode,
			) as IBranch;
			const employeeAllowedBranchesCode = employee.allowedBranches.map((branch) => branch.code);

			const { timeZone } = employeeCurrentBranch;
			const currentDateTieme = DateTime.utc().startOf('day');

			if (timeZone) {
				currentDateTieme.setZone(timeZone);
			}

			const currentPlusFour = currentDateTieme.plus({ days: 4 }).toJSDate();
			const pickUpDateTime = DateTime.fromISO(booking.pickUpDateTime as string)
				.startOf('day')
				.toJSDate();
			// Const dropOffDateTime = DateTime.fromISO(booking.dropOffDateTime).startOf('day').toJSDate();

			const bookingProvider: IProvider = providers.find(
				(provider) => provider.name === pickUpBranch.provider,
			) as IProvider;
			const employeeProviderName = employeeCurrentBranch.provider;
			const isGenericCustomer = booking.customerCode === bookingProvider.genericCustomerCode;
			// CAN CHANGE INVOICE TO COMPANY/AGENCY
			const gcAgencies = getGcAgenciesByProvider(bookingProvider);

			if (
				![BookingStateType.Confirmed, BookingStateType.Processing, BookingStateType.Liquidated].includes(
					booking.bookingState,
				)
			) {
				this.CanChangeBookingInvoiceToCustomer = false;
			}

			if (booking.allowRegisterWithPending && booking.bookingState === BookingStateType.Pending) {
				this.CanChangeBookingInvoiceToCustomer = true;
			}

			// CHANGE BOOKING INVOICE TO
			if (
				![BookingStateType.Confirmed, BookingStateType.Processing, BookingStateType.Liquidated].includes(
					booking.bookingState,
				) ||
				![BookingType.COMPANY, BookingType.PREBOOKING, BookingType.GUIDE].includes(booking.type) ||
				booking.invoiceToCustomerCode === bookingProvider.genericCustomerCode ||
				gcAgencies.includes(booking.invoiceToAgencyCode)
			) {
				this.CanChangeBookingInvoiceToAgencyCompany = false;
			}

			// CAN CANCEL THE BOOKING
			if (BookingStateType.Confirmed !== bookingState) {
				this.CanBookingCancel = false;
			}

			if (
				([BookingType.BROKER, BookingType.GUIDE, BookingType.COMPANY, BookingType.PREBOOKING].includes(
					booking.type,
				) &&
					!this.CanBookingCancelAgency) ||
				(booking.bookingRegistrationUser === 'WEBSERVICES' && !this.CanBookingNewExternal)
			) {
				this.CanBookingCancel = false;
			}

			// BOOKING PICKUP
			if (
				employee.branchCode !== booking.pickUpBranchCode ||
				!(pickUpDateTime <= currentPlusFour) ||
				![BookingStateType.Confirmed, BookingStateType.Processing].includes(bookingState)
			) {
				this.CanBookingPickUpProcess = false;
			}

			// BOOKING EXTEND
			if (
				![BookingStateType.OnHire].includes(bookingState) ||
				bookingProvider.name.toLocaleLowerCase() !== employeeProviderName.toLocaleLowerCase()
			) {
				this.CanBookingExtend = false;
			}

			// BOOKING REVERT
			const isInvalidStateToRevert = [
				BookingStateType.OnHire,
				BookingStateType.Confirmed,
				BookingStateType.Pending,
				BookingStateType.Liquidated,
				BookingStateType.Draft,
			].includes(booking.bookingState);

			// BOOKING REVERT GENERAL
			if (isInvalidStateToRevert) {
				this.CanBookingRevert = false;
			} else if (this.CanBookingNewExternal) {
				// CONTACT CENTER
				this.CanBookingRevert = true;
			} else if (
				employeeAllowedBranchesCode.includes(employee.branchCode) &&
				bookingState === BookingStateType.Processing &&
				this.CanBookingPickUpProcess
			) {
				// RENTAL
				this.CanBookingRevert = true;
			}

			// BOOKING DROPOFF
			if (this.CanViewBookingDropOff && this.CanBookingDropOffProcess) {
				if (
					(employeeProviderName && employeeProviderName !== bookingProvider.name) ||
					![BookingStateType.OnHire].includes(bookingState)
				) {
					this.CanBookingDropOffProcess = false;
				}
			}

			// BOOKING MODIFY - Extra drivers
			if (
				![BookingStateType.Confirmed, BookingStateType.Processing, BookingStateType.OnHire].includes(
					bookingState,
				)
			) {
				this.CanAddBookingExtraDrivers = false;
			}

			// MODIFY DRIVER
			if ([BookingStateType.OnHire].includes(bookingState)) {
				this.CanModifyBookingExtraDrivers = false;
			}
			// BOOKING MODIFY - Change vehicle
			if (
				[
					BookingStateType.Confirmed,
					BookingStateType.NoShow,
					BookingStateType.Draft,
					BookingStateType.Cancelled,
				].includes(bookingState)
			) {
				this.CanViewBookingChangeVehicle = false;
			}

			if (bookingState !== BookingStateType.OnHire) {
				this.CanAddBookingChangeVehicle = false;
			}

			// PAYMENT LINES
			if (
				!this.CanAddBookingPaymentLines ||
				booking.customerCode === bookingProvider.genericCustomerCode ||
				employeeProviderName !== bookingProvider.name ||
				![
					BookingStateType.Confirmed,
					BookingStateType.Processing,
					BookingStateType.OnHire,
					BookingStateType.Liquidated,
					BookingStateType.Pending,
				].includes(bookingState)
			) {
				this.CanAddBookingPaymentLines = false;
			}

			// FRANCHISE LINE
			if (
				[BookingStateType.OnHire, BookingStateType.Liquidated, BookingStateType.Pending].includes(bookingState)
			) {
				this.CanViewBookingFranchiseLine = true;
			} else {
				this.CanAddBookingFranchiseLine = false;
				this.CanViewBookingFranchiseLine = false;
				this.CanViewBookingAccidentParts = false;
				this.CanViewBookingTolls = false;
			}

			/*
			 * ONLY CAN REMOVE IF HAVE FranchiseLineRemoveForEver or have FranchiseLineRemove and not is On_Hire state
			 * TODO: HABILITAR CUANDO ESTE PROBADO EN NAVISION
			 */
			if (
				this.CanRemoveBookingFranchiseLine &&
				!this.CanRemoveBookingFranchiseLineForEver &&
				![BookingStateType.OnHire].includes(booking.bookingState)
			) {
				this.CanRemoveBookingFranchiseLine = false;
			}

			// SERVICES
			if (
				![
					BookingStateType.Confirmed,
					BookingStateType.Processing,
					BookingStateType.OnHire,
					BookingStateType.Draft,
				].includes(bookingState)
			) {
				this.CanAddBookingServices = false;
			}

			if (
				![
					BookingStateType.Confirmed,
					BookingStateType.Processing,
					BookingStateType.OnHire,
					BookingStateType.Draft,
				].includes(bookingState)
			) {
				this.CanRemoveBookingServices = false;
			}

			// FINE
			if (
				![BookingStateType.OnHire, BookingStateType.Pending, BookingStateType.Liquidated].includes(
					booking.bookingState,
				)
			) {
				this.CanViewBookingFine = false;
			}

			// DOCUMENTS
			this.CanViewBookingDocuments = ![BookingStateType.Draft].includes(bookingState);

			// MODIFY Flight
			this.CanBookinModifyFlight = bookingState === BookingStateType.Confirmed;
			this.CanBookinModifyComment = [
				BookingStateType.Confirmed,
				BookingStateType.Processing,
				BookingStateType.OnHire,
			].includes(bookingState);
			// SHOW AGENCY AMOUNTS
			if (![BookingType.GUIDE, BookingType.PREBOOKING, BookingType.COMPANY].includes(booking.type)) {
				this.CanViewPartnerAmounts = false;
			}

			// ALLOW PENDING
			if (![BookingStateType.Confirmed, BookingStateType.Processing].includes(booking.bookingState)) {
				this.CanChangeAllowPendingsInContract = false;
			}

			// SEND EMAIL TO CUSTOMER
			if (
				bookingProvider.genericCustomerCode === booking.customerCode ||
				![BookingStateType.Confirmed, BookingStateType.Cancelled].includes(booking.bookingState)
			) {
				this.CanBookingSendToCustomer = false;
			}

			if (
				![
					BookingStateType.NoShow,
					BookingStateType.Cancelled,
					BookingStateType.Denied,
					BookingStateType.CancelledDueToUnavailability,
				].includes(booking.bookingState)
			) {
				// REFUND PAYMENTS
				this.CanBookingRefundPayments = false;
			}

			// Aplicar descuento
			/**
			 * Los rental sólo pueden en contratos tipo WALKING, DIRECT, GOLDCLUB
			 * Los demás no pueden menos en contratos tipo PREBOOKING, COMPANY o GUIDE
			 */
			// RENTAL
			const rentalCanAddDiscount =
				[BookingType.WALKING, BookingType.DIRECT, BookingType.GOLDCLUB].includes(booking.type) &&
				[BookingStateType.Confirmed, BookingStateType.Processing].includes(booking.bookingState);
			const canAddDiscount =
				this.CanBookingNewExternal &&
				![BookingType.PREBOOKING, BookingType.COMPANY, BookingType.GUIDE, BookingType.BROKER].includes(
					booking.type,
				) &&
				[BookingStateType.Confirmed, BookingStateType.Processing, BookingStateType.OnHire].includes(
					booking.bookingState,
				);

			if (employee.discountPercentage > 0 && (rentalCanAddDiscount || canAddDiscount)) {
				this.CanApplyDiscount = true;
			}

			/*
			 * HIDE BOOKING TICKETS WHEN CUSTOMER IS GENERIC
			 * if (bookingProvider.genericCustomerCode === booking.customerCode) {
			 * 	this.CanViewBookingComments = false;
			 * }
			 */

			// MODIFY PROVISIONAL ADDRESS
			this.CanModifyAccidentalAddress = Boolean(
				this.CanBookingModify &&
					!isGenericCustomer &&
					[BookingStateType.Confirmed, BookingStateType.Processing, BookingStateType.OnHire].includes(
						booking.bookingState,
					),
			);

			// CAN ADD DAMAGE WITHOUT PHOTO
			const bookingBranch = branches.find((branch) => branch.code === booking.pickUpBranchCode) as IBranch;
			if (bookingBranch && bookingBranch.pictureRequired && !this.CanAddDamageWithoutPhoto) {
				this.CanAddDamageWithoutPhoto = false;
			}
		}

		if (bookingRegularization && employee) {
			if (bookingRegularization.branch !== employee.branchCode) {
				this.CanBookingRegularizationAcceptReject = false;
				this.CanCloseBookingRegularization = false;
			}
		}

		this.IsCorrectDropOffBranch = (): boolean => {
			if (booking && employee) {
				const { dropOffBranchCode } = booking;
				const { branchCode } = employee;

				return dropOffBranchCode === branchCode;
			}

			return false;
		};
	}
}
