import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaPencil, FaPlus, FaRegCalendarXmark } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

import { getPricingPermission } from '../utils/helpers';

const Occupancy = lazyWithRetry(() => import('./pages/Occupancy'));
const OccupancyNew = lazyWithRetry(() => import('./pages/OccupancyNew'));
const OccupancyModify = lazyWithRetry(() => import('./pages/OccupancyModify'));

export const occupancyRoutes: { [key: number]: IRouteConfigType } = {};

// OCCUPANCY
occupancyRoutes[routesIdMap.OccupancyConfiguration] = {
	component: Occupancy,
	exact: true,
	id: routesIdMap.OccupancyConfiguration,
	name: 'Occupancy',
	path: '/pricing/occupancy',
	permissions: getPricingPermission([PermissionsType.PricingOccupancyConfiguration]),
	childrens: [routesIdMap.OccupancyConfigurationNew, routesIdMap.OccupancyConfigurationModify],
	icon: FaRegCalendarXmark,
	menuText: 'Occupancy',
};

occupancyRoutes[routesIdMap.OccupancyConfigurationNew] = {
	component: OccupancyNew,
	exact: true,
	isChild: true,
	id: routesIdMap.OccupancyConfigurationNew,
	name: 'New occupancy',
	path: '/pricing/occupancy/new',
	permissions: getPricingPermission([PermissionsType.PricingOccupancyConfiguration]),
	icon: FaPlus,
	menuText: 'New occupancy',
};

occupancyRoutes[routesIdMap.OccupancyConfigurationModify] = {
	component: OccupancyModify,
	exact: true,
	isChild: true,
	id: routesIdMap.OccupancyConfigurationModify,
	name: 'Modify occupancy',
	path: '/pricing/occupancy/modify',
	permissions: getPricingPermission([PermissionsType.PricingOccupancyConfiguration]),
	icon: FaPencil,
};
