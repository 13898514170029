import { SaleOrderLineEndPoints } from './SaleOrderLineEndPoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ISaleOrderLine } from '../entities/SaleOrderLine';

export interface ISaleOrderLineDeleteParams extends IServiceBaseParams {
	ids: number[];
}

export const saleOrderLineDeleteService = (params: ISaleOrderLineDeleteParams) => {
	return Api.post<ISaleOrderLine[], ISaleOrderLineDeleteParams>(SaleOrderLineEndPoints.DELETE, params);
};
