import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaTruckAlt } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const Dashboard = lazyWithRetry(() => import('./pages/Dashboard'));

export const dashboardRoutes: { [key: number]: IRouteConfigType } = {};

dashboardRoutes[routesIdMap.Fleet] = {
	component: Dashboard,
	exact: true,
	id: routesIdMap.Fleet,
	name: 'Fleet',
	path: '/fleet',
	permissions: [PermissionsType.Fleet],
	icon: FaTruckAlt,
	menuText: 'Fleet',
};
