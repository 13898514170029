import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaIdCard } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const CustomerDigitization = lazyWithRetry(() => import('./pages/CustomerDigitization'));
const CustomerDigitizationDetail = lazyWithRetry(() => import('./pages/CustomerDigitizationDetail'));

export const customerDigitizationRoutes: { [key: number]: IRouteConfigType } = {};

customerDigitizationRoutes[routesIdMap.ITCustomerDigitization] = {
	component: CustomerDigitization,
	exact: true,
	id: routesIdMap.ITCustomerDigitization,
	name: 'Customer digitization',
	path: '/it/customer-digitization',
	permissions: [PermissionsType.IT],
	icon: FaIdCard,
	menuText: 'Customer digitization',
	childrens: [routesIdMap.ITCustomerDigitizationDetail],
};

customerDigitizationRoutes[routesIdMap.ITCustomerDigitizationDetail] = {
	component: CustomerDigitizationDetail,
	exact: true,
	id: routesIdMap.ITCustomerDigitizationDetail,
	name: 'Customer digitization detail',
	path: '/it/customer-digitization/:id',
	permissions: [PermissionsType.IT],
	icon: FaIdCard,
	isChild: true,
};
