import { ExcessRateEndpoints } from './ExcessRateEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IExcessRate } from '../entities/ExcessRate';

export interface IExcessRateSearchParams extends IServiceBaseParams {
	provider: string;
	code?: string;
	vehicleGroup?: string;
	vehicleModel?: string;
}

export const excessRateSearchService = (model: IExcessRateSearchParams) => {
	return Api.get<IExcessRate[], IExcessRateSearchParams>(ExcessRateEndpoints.SEARCH, model);
};
