import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

import { RevisionTypeEndPoints } from './RevisionTypeEndpoint';
import type { IRevisionType } from '../entities/RevisionType';

export interface IRevisionTypeInsertParams extends IRevisionType, IServiceBaseParams {}

export const RevisionTypeInsertService = (model: IRevisionTypeInsertParams) => {
	return Api.post<IRevisionType, IRevisionTypeInsertParams>(RevisionTypeEndPoints.INSERT, model);
};
