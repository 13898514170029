/* eslint-disable complexity */
import { createAction } from '@reduxjs/toolkit';

import { branchServiceGetAll } from '../../data/services/BranchService';
import { countryServiceGetAll } from '../../data/services/CountryService';
import {
	employeeServiceChangeBranch,
	employeeServiceChangePassword,
	employeeServiceLogIn,
	employeeServiceRefreshToken,
} from '../../data/services/EmployeeService';
import { excessRateServiceGetAll } from '../../data/services/ExcessRateService';
import { languageServiceGetAll } from '../../data/services/LanguageService';
import { paymentMethodServiceGetAll } from '../../data/services/PaymentMethodService';
import { paymentTermServiceGetAll } from '../../data/services/PaymentTermService';
import { providerServiceGetAll } from '../../data/services/ProviderService';
import { repairTypeServiceGetAll } from '../../data/services/RepairTypeService';
import { servicesServiceGetAll } from '../../data/services/ServicesService';
import { spendingTypeServiceGetAll } from '../../data/services/SpendingTypeService';
import { taxOfficeServiceGetAll } from '../../data/services/TaxOfficeService';
import { vehicleGroupServiceGetByProvider } from '../../data/services/VehicleGroupService';
import { managerLocalStorage } from '../../helpers/reducerStorage';
import type { IBranch } from '../../models/entities/Branch';
import type { ICountry } from '../../models/entities/Country';
import type { IEmployee } from '../../models/entities/Employee';
import type { IExcessRatePerProvider } from '../../models/entities/ExcessRate';
import type { ILanguage } from '../../models/entities/Language';
import type { IPaymentMethod } from '../../models/entities/PaymentMethod';
import type { IPaymentTerm } from '../../models/entities/PaymentTerm';
import type { IProvider } from '../../models/entities/Provider';
import type { IRepairType } from '../../models/entities/RepairType';
import type { IService } from '../../models/entities/Service';
import type { ISpendingType } from '../../models/entities/SpendingType';
import type { ITaxOffice } from '../../models/entities/TaxOffice';
import type { IVehicleGroupByProvider } from '../../models/entities/VehicleGroup';
import { BookingPermissionsType } from '../../models/permissions/BookingPermissionsType';
import type {
	IEmployeeChangeBranchParams,
	IEmployeeChangePasswordParams,
	IEmployeeLogInParams,
} from '../../models/serviceParams/EmployeeParams';
import type { IRegularizationSubject } from '../../modules/booking/regularization/entities/RegularizationSubject';
import { regularizationSubjectGetAllService } from '../../modules/booking/regularization/services/RegularizationSubjectGetAllService';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';
import { CreationMethod } from '../../modules/shared/types/CreationMethod';
import type { IServiceBaseParams } from '../../modules/shared/types/ServiceBaseParams';
import { ServiceResponse } from '../../modules/shared/types/ServiceResponse';

/**
 * Changes the branch for an employee.
 *
 * @param {IEmployee} payload - The employee whose branch is being changed.
 * @param {IEmployeeChangeBranchParams} params - Parameters detailing the branch change for the employee.
 * @returns {Promise<void>} - A promise that resolves after the employee's branch is changed.
 */
export const employeeChangeBranch = createAsyncAction<IEmployee, IEmployeeChangeBranchParams>(
	'employee/changeBranch',
	employeeServiceChangeBranch,
);

/**
 * Changes the password for an employee.
 *
 * @param {IEmployee} payload - The employee whose password is being changed.
 * @param {IEmployeeChangePasswordParams} params - Parameters detailing the password change for the employee.
 * @returns {Promise<void>} - A promise that resolves after the employee's password is changed.
 */
export const employeeChangePassword = createAsyncAction<IEmployee, IEmployeeChangePasswordParams>(
	'employee/changePassword',
	employeeServiceChangePassword,
);

/**
 * Logs in an employee.
 *
 * @param {IEmployee} payload - The employee logging in.
 * @param {IEmployeeLogInParams} params - Parameters for the employee log in.
 * @returns {Promise<void>} - A promise that resolves with the employee's information after successful login.
 */
export const employeeLogIn = createAsyncAction<IEmployee, IEmployeeLogInParams>('employee/logIn', employeeServiceLogIn);

export type EmployeeLogInAndLoadCachedDataResponse = {
	employee: IEmployee;
	vehicleGroups: IVehicleGroupByProvider[];
	countries: ICountry[];
	languages: ILanguage[];
	branches: IBranch[];
	paymentTerms: IPaymentTerm[];
	paymentMethods: IPaymentMethod[];
	providers: IProvider[];
	spendingTypes: ISpendingType[];
	regularizationSubjects: IRegularizationSubject[];
	excessRates: IExcessRatePerProvider[];
	repairTypes: IRepairType[];
	services: IService[];
	taxOffices: ITaxOffice[];
};

export const employeeLoginAndLoadCachedData = createAsyncAction(
	'employee/loginAndLoadCachedData',
	async (params: IEmployeeLogInParams) => {
		const { setItem } = managerLocalStorage();
		const { password, userName } = params;

		const loginResponse = await employeeServiceLogIn({ password, userName, isPublic: true });

		if (!loginResponse.ok || !loginResponse.data) {
			return new ServiceResponse<EmployeeLogInAndLoadCachedDataResponse>(false, null, loginResponse.errors);
		}

		const employee = loginResponse.data;
		const { token } = employee;
		const permissions = new BookingPermissionsType(employee.permissions);
		const creationMethod = permissions.CanBookingNewExternal ? CreationMethod.CONTACTCENTER : CreationMethod.BRANCH;

		setItem('employee', employee);
		setItem('token', token);

		const vehicleGroupResponse = await vehicleGroupServiceGetByProvider({ creationMethod, token });
		if (!vehicleGroupResponse.ok || !vehicleGroupResponse.data) {
			return new ServiceResponse<EmployeeLogInAndLoadCachedDataResponse>(
				false,
				null,
				vehicleGroupResponse.errors,
			);
		}
		const countryResponse = await countryServiceGetAll({ creationMethod, token });
		if (!countryResponse.ok || !countryResponse.data) {
			return new ServiceResponse<EmployeeLogInAndLoadCachedDataResponse>(false, null, countryResponse.errors);
		}
		const languageResponse = await languageServiceGetAll({ creationMethod, token });
		if (!languageResponse.ok || !languageResponse.data) {
			return new ServiceResponse<EmployeeLogInAndLoadCachedDataResponse>(false, null, languageResponse.errors);
		}
		const branchResponse = await branchServiceGetAll({ creationMethod, token });
		if (!branchResponse.ok || !branchResponse.data) {
			return new ServiceResponse<EmployeeLogInAndLoadCachedDataResponse>(false, null, branchResponse.errors);
		}

		const branches = branchResponse.data;
		const branch = branches.find((branch) => branch.code === employee.branchCode);

		if (!branch) {
			return new ServiceResponse<EmployeeLogInAndLoadCachedDataResponse>(false, null, [
				{ message: 'No branch found' },
			]);
		}

		const paymentTermResponse = await paymentTermServiceGetAll({
			provider: branch.provider,
			creationMethod,
			token,
		});
		if (!paymentTermResponse.ok || !paymentTermResponse.data) {
			return new ServiceResponse<EmployeeLogInAndLoadCachedDataResponse>(false, null, paymentTermResponse.errors);
		}
		const paymentMethodResponse = await paymentMethodServiceGetAll({
			provider: branch.provider,
			creationMethod,
			token,
		});
		if (!paymentMethodResponse.ok || !paymentMethodResponse.data) {
			return new ServiceResponse<EmployeeLogInAndLoadCachedDataResponse>(
				false,
				null,
				paymentMethodResponse.errors,
			);
		}

		const providerResponse = await providerServiceGetAll({ isPublic: false, creationMethod, token });
		if (!providerResponse.ok || !providerResponse.data) {
			return new ServiceResponse<EmployeeLogInAndLoadCachedDataResponse>(false, null, providerResponse.errors);
		}
		const spendingTypeResponse = await spendingTypeServiceGetAll({ creationMethod, token });
		if (!spendingTypeResponse.ok || !spendingTypeResponse.data) {
			return new ServiceResponse<EmployeeLogInAndLoadCachedDataResponse>(
				false,
				null,
				spendingTypeResponse.errors,
			);
		}
		const regularizationResponse = await regularizationSubjectGetAllService({ creationMethod, token });
		if (!regularizationResponse.ok || !regularizationResponse.data) {
			return new ServiceResponse<EmployeeLogInAndLoadCachedDataResponse>(
				false,
				null,
				regularizationResponse.errors,
			);
		}
		const excessRateResponse = await excessRateServiceGetAll({ creationMethod, token });
		if (!excessRateResponse.ok || !excessRateResponse.data) {
			return new ServiceResponse<EmployeeLogInAndLoadCachedDataResponse>(false, null, excessRateResponse.errors);
		}
		const repairTypeResponse = await repairTypeServiceGetAll({ creationMethod, token });
		if (!repairTypeResponse.ok || !repairTypeResponse.data) {
			return new ServiceResponse<EmployeeLogInAndLoadCachedDataResponse>(false, null, repairTypeResponse.errors);
		}
		const serviceResponse = await servicesServiceGetAll({ creationMethod, token });
		if (!serviceResponse.ok || !serviceResponse.data) {
			return new ServiceResponse<EmployeeLogInAndLoadCachedDataResponse>(false, null, serviceResponse.errors);
		}
		const taxOfficeResponse = await taxOfficeServiceGetAll({ creationMethod, token });
		if (!taxOfficeResponse.ok || !taxOfficeResponse.data) {
			return new ServiceResponse<EmployeeLogInAndLoadCachedDataResponse>(false, null, taxOfficeResponse.errors);
		}

		const response: EmployeeLogInAndLoadCachedDataResponse = {
			branches,
			countries: countryResponse.data,
			employee,
			excessRates: excessRateResponse.data,
			languages: languageResponse.data,
			paymentMethods: paymentMethodResponse.data,
			paymentTerms: paymentTermResponse.data,
			providers: providerResponse.data,
			regularizationSubjects: regularizationResponse.data,
			repairTypes: repairTypeResponse.data,
			services: serviceResponse.data,
			spendingTypes: spendingTypeResponse.data,
			taxOffices: taxOfficeResponse.data,
			vehicleGroups: vehicleGroupResponse.data,
		};

		return new ServiceResponse<EmployeeLogInAndLoadCachedDataResponse>(true, response, []);
	},
);

/**
 * Refreshes the token for an employee.
 *
 * @param {IEmployee} payload - The employee whose token is being refreshed.
 * @param {IServiceBaseParams} params - Base service parameters for the token refresh.
 * @returns {Promise<void>} - A promise that resolves with the employee's information after the token is refreshed.
 */
export const employeeRefreshToken = createAsyncAction<IEmployee, IServiceBaseParams>(
	'employee/refreshToken',
	employeeServiceRefreshToken,
);

/**
 * Updates the state with the employee's information.
 *
 * @param {IEmployee} payload - The employee's information to update the state.
 */
export const employeeUpdate = createAction<IEmployee>('employee/update');
