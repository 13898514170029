import { createSlice } from '@reduxjs/toolkit';

import type { IFineTransmitter } from '@crac/core/modules/fleet/fineTransmitter/entities/FineTransmitter';
import {
	fleetFineTransmitterClear,
	fleetFineTransmitterInsert,
	fleetFineTransmitterModify,
	fleetFineTransmitterSearch,
} from '@crac/core/modules/fleet/fineTransmitter/state/actions/FineTransmitterActions';
import type { IFleetFineTransmitterReducerState } from '@crac/core/redux-store/reducersState/fleet/FleetFineTransmitterReducerState';

const initialState: IFleetFineTransmitterReducerState = {
	fleetFineTransmitterInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetFineTransmitters: [],
	fleetFineTransmitterSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetFineTransmitterModifyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const fineTransmitterSlice = createSlice({
	name: 'fineTransmitterSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR
		builder.addCase(fleetFineTransmitterClear, (state) => {
			return {
				...state,
				fleetFineTransmitters: [],
			};
		});
		// SEARCH
		builder
			.addCase(fleetFineTransmitterSearch.pending, (state) => {
				return {
					...state,
					fleetFineTransmitterSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetFineTransmitterSearch.fulfilled, (state, action) => {
				return {
					...state,
					fleetFineTransmitterSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					fleetFineTransmitters: action.payload,
				};
			})
			.addCase(fleetFineTransmitterSearch.rejected, (state, action) => {
				return {
					...state,
					fleetFineTransmitterSearchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// INSERT
		builder
			.addCase(fleetFineTransmitterInsert.pending, (state) => {
				return {
					...state,
					fleetFineTransmitterInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetFineTransmitterInsert.fulfilled, (state) => {
				return {
					...state,
					fleetFineTransmitterInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetFineTransmitterInsert.rejected, (state, action) => {
				return {
					...state,
					fleetFineTransmitterInsertRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// MODIFY
		builder
			.addCase(fleetFineTransmitterModify.pending, (state) => {
				return {
					...state,
					fleetFineTransmitterModifyRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetFineTransmitterModify.fulfilled, (state, action) => {
				const { code } = action.payload as IFineTransmitter;
				const fleetFineTransmitters = state.fleetFineTransmitters?.map((fineTransmitter) =>
					fineTransmitter.code === code ? { ...action.payload } : fineTransmitter,
				);
				return {
					...state,
					fleetFineTransmitters,
					fleetFineTransmitterModifyRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetFineTransmitterModify.rejected, (state, action) => {
				return {
					...state,
					fleetFineTransmitterModifyRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const fineTransmitterReducer = fineTransmitterSlice.reducer;
