import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaRegCheckSquare } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const Regularizations = lazyWithRetry(() => import('./pages/Regularizations'));

export const regularizationsRoutes: { [key: number]: IRouteConfigType } = {};

regularizationsRoutes[routesIdMap.BookingRegularizations] = {
	component: Regularizations,
	exact: true,
	id: routesIdMap.BookingRegularizations,
	name: 'Regularizations',
	path: '/booking/regularizations',
	permissions: [PermissionsType.BookingRegularization],
	icon: FaRegCheckSquare,
	menuText: 'Regularizations',
};
