import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

import { FleetPrevisionEndPoints } from './FleetPrevisionEndPoints';
import type { IFleetPrevision } from '../entities/FleetPrevision';

export interface IFleetPrevisionGetByPrevisionCodeParams extends IServiceBaseParams {
	provider: string;
	previsionCode: string;
}

export const fleetPrevisionGetByPrevisionCodeService = (model: IFleetPrevisionGetByPrevisionCodeParams) => {
	return Api.get<IFleetPrevision, IFleetPrevisionGetByPrevisionCodeParams>(
		FleetPrevisionEndPoints.GET_BY_PREVISION_CODE,
		model,
	);
};
