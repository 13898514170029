'use client';

import * as React from 'react';

import ReactSelect from 'react-select';

import { ClearIndicator } from './components/ClearIndicator';
import { DropdownIndicator } from './components/DropdownIndicator';
import { MultiValueRemove } from './components/MultiValueRemove';
import { cn } from '../lib/utils/tailwindClass';

const Select = React.forwardRef<
	React.ElementRef<typeof ReactSelect>,
	React.ComponentPropsWithoutRef<typeof ReactSelect>
>(({ className, ...props }, ref) => {
	return (
		<ReactSelect
			className={className}
			classNames={{
				control: ({ isFocused, isDisabled }) =>
					cn(
						isFocused ? 'select-control-focus' : 'select-control-no-focus',
						isDisabled && 'tw-cursor-not-allowed tw-opacity-50',
						'select-control-base',
					),
				placeholder: () => 'select-placeholder',
				input: () => 'select-input',
				valueContainer: () => 'select-value-container',
				singleValue: () => 'select-single-value',
				multiValue: () => 'select-multi-value',
				multiValueLabel: () => 'select-multi-value-label',
				multiValueRemove: () => 'select-multi-value-remove',
				indicatorsContainer: () => 'select-indicators-container',
				clearIndicator: () => 'select-indicators-container',
				indicatorSeparator: () => 'select-indicator-separator',
				dropdownIndicator: () => 'select-dropdown-indicator',
				menu: () => 'select-menu',
				groupHeading: () => 'select-group-heading',
				option: ({ isFocused, isSelected }) =>
					cn(isFocused && 'select-option-focus', isSelected && 'select-option-selected', 'select-option'),
				noOptionsMessage: () => 'select-no-options-message',
			}}
			closeMenuOnSelect={!props.isMulti}
			components={{ DropdownIndicator, ClearIndicator, MultiValueRemove }}
			styles={{
				multiValueLabel: (base) => ({
					...base,
					whiteSpace: 'normal',
					overflow: 'visible',
				}),
			}}
			unstyled
			{...props}
			ref={ref}
		/>
	);
});

Select.displayName = 'Select';

export { Select };
