import { VehicleEngineEndPoints } from './VehicleEngineEndPoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IVehicleEngine } from '../entities/VehicleEngine';

export interface IVehicleEngineGetByIdParams extends IServiceBaseParams {
	id: number;
}

export const vehicleEngineServiceGetById = (params: IVehicleEngineGetByIdParams) => {
	return Api.get<IVehicleEngine, IVehicleEngineGetByIdParams>(VehicleEngineEndPoints.GET_BY_ID, params);
};
