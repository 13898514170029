import { PermissionsType } from '@crac/core/models/types/PermissionsType';

import { routesIdMap } from '~/config/routesIdMap';

import { lazyWithRetry } from '../shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '../shared/types/RouteConfigType';

const Example = lazyWithRetry(() => import('./Example'));
const ExampleForm = lazyWithRetry(() => import('./ExampleForm'));
const ExampleArray = lazyWithRetry(() => import('./ExampleFieldArray'));
const ExampleInputField = lazyWithRetry(() => import('./ExampleInputField'));
const ExampleSelectField = lazyWithRetry(() => import('./ExampleSelectField'));
const ExampleValidations = lazyWithRetry(() => import('./ExampleValidations'));
const ExampleMenu = lazyWithRetry(() => import('./ExampleMenu'));

export const cracFormRoutes: { [key: number]: IRouteConfigType } = {};

/* Menu de ejemplos */
cracFormRoutes[routesIdMap.CracForm] = {
	component: ExampleMenu,
	exact: true,
	id: routesIdMap.CracForm,
	name: 'Crac form example menu',
	path: '/crac-form',
	permissions: [PermissionsType.IT],
};

/* Ejemplo con otros casos */
cracFormRoutes[routesIdMap.CracFormExample] = {
	component: Example,
	exact: true,
	id: routesIdMap.CracFormExample,
	name: 'Crac from example',
	path: '/crac-form/example',
	permissions: [PermissionsType.IT],
};

/* Ejemplo de formulario */
cracFormRoutes[routesIdMap.CracFormExampleForm] = {
	component: ExampleForm,
	exact: true,
	id: routesIdMap.CracFormExampleForm,
	name: 'Crac from example',
	path: '/crac-form/example-form',
	permissions: [PermissionsType.IT],
};

/* Example Input Field */
cracFormRoutes[routesIdMap.CracFormInputField] = {
	component: ExampleInputField,
	exact: true,
	id: routesIdMap.CracFormInputField,
	name: 'Crac from example input field',
	path: '/crac-form/example-input',
	permissions: [PermissionsType.IT],
};

/* Example Select Field */
cracFormRoutes[routesIdMap.CracFormSelectField] = {
	component: ExampleSelectField,
	exact: true,
	id: routesIdMap.CracFormSelectField,
	name: 'Crac from example select field',
	path: '/crac-form/example-select',
	permissions: [PermissionsType.IT],
};

/* Example Field Array */
cracFormRoutes[routesIdMap.CracFormFieldArray] = {
	component: ExampleArray,
	exact: true,
	id: routesIdMap.CracFormFieldArray,
	name: 'Crac from example field array',
	path: '/crac-form/example-array',
	permissions: [PermissionsType.IT],
};

/* Example Form Field Validations */
cracFormRoutes[routesIdMap.CracFormValidations] = {
	component: ExampleValidations,
	exact: true,
	id: routesIdMap.CracFormValidations,
	name: 'Crac from example form validations',
	path: '/crac-form/example-validations',
	permissions: [PermissionsType.IT],
};
