import { DropOffEndpoints } from './DropOffEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../../shared/types/ServiceResponse';
import type { IBooking } from '../../entities/Booking';

export interface IBookingDropOffStartParams extends IServiceBaseParams {
	actualDropOffDateTime: string | Date;
	bookingNumber: string;
	dropOffBranchCode: string;
	dropOffFuelPercentage: number;
	dropOffKM: number;
}

/**
 * Init Drop Off booking
 * @param {Object} booking Data register booking
 * @returns {Promise} `BookingPropType` Use await for resolve
 */
export const bookingDropOffStartService = (model: IBookingDropOffStartParams): Promise<ServiceResponse<IBooking>> => {
	return Api.post<IBooking, IBookingDropOffStartParams>(DropOffEndpoints.DROPOFF_START, model);
};
