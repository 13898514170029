import type { FC } from 'react';
import { useMemo } from 'react';

import { getOptionsFromEnum } from '@crac/core/helpers/commons';
import { SupplierType } from '@crac/core/modules/supplier/types/SupplierType';
import { WorkshopType } from '@crac/core/modules/workshop/types/WorkshopType';
import { FieldWatcher, InputField, SelectField } from '@crac/form/field';
import { Col, Row } from '@crac/ui/grid';

import { SelectProvider } from '~/features/shared/components/selectProvider';

import { useSupplierFormValidateNif } from '../hooks/useSupplierFormValidateNif';
import type { ISupplierFormContentProps } from '../types';

export const SupplierFormBasicFields: FC<Partial<ISupplierFormContentProps>> = ({
	initialValues,
	supplierType,
	fields,
}) => {
	const typeOptions = useMemo(() => getOptionsFromEnum(WorkshopType), []);

	const { nifValidation } = useSupplierFormValidateNif();

	const isEdit = Boolean(initialValues);
	const showNIF = fields?.address === undefined;

	return (
		<Row>
			<Col lg={6}>
				<SelectProvider disabled={isEdit} isClearable name="provider" required validateOnLoad={isEdit} />
			</Col>
			{supplierType === SupplierType.WORKSHOP ? (
				<Col lg={6}>
					<SelectField
						isClearable
						label="Type"
						name="workshopType"
						options={typeOptions}
						validateOnLoad={isEdit}
						validation={{ required: true }}
					/>
				</Col>
			) : null}
			<Col lg={supplierType === SupplierType.WORKSHOP ? 12 : 6}>
				<InputField
					id="name"
					label="Name"
					name="name"
					validateOnLoad={isEdit}
					validation={{ required: true, maxLength: 50 }}
				/>
			</Col>

			{showNIF ? (
				<Col>
					<FieldWatcher name="provider">
						{({ provider }) => (
							<InputField
								disabled={!provider}
								id="nif"
								label="NIF"
								name="nif"
								validateOnLoad={isEdit}
								validation={{
									required: true,
									validate: nifValidation,
								}}
							/>
						)}
					</FieldWatcher>
				</Col>
			) : null}
		</Row>
	);
};
