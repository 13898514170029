import { createSyncAction } from '@crac/core/modules/shared/state/createAction';
import { createAsyncAction } from '@crac/core/modules/shared/state/createAsyncAction';

import type { IRevisionCost } from '../../entities/RevisionCost';
import type { IRevisionCostSaveParams } from '../../services/RevisionCostSaveService';
import { revisionCostSaveService } from '../../services/RevisionCostSaveService';
import type { IRevisionCostSearchParams } from '../../services/RevisionCostSearchService';
import { revisionCostSearchService } from '../../services/RevisionCostSearchService';

export const revisionCostSearch = createAsyncAction<IRevisionCost[], IRevisionCostSearchParams>(
	'workshop/revisionCost/search',
	revisionCostSearchService,
);

export const revisionCostSave = createAsyncAction<IRevisionCost[], IRevisionCostSaveParams>(
	'workshop/revisionCost/save',
	revisionCostSaveService,
);

export const revisionCostClear = createSyncAction('workshop/revisionCost/clear');
