import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';
import type { IVehicle } from '@crac/core/modules/vehicle/entities/Vehicle';

import { VehicleModifyEndPoints } from './VehicleModifyEndpoints';

export interface IVehicleChangeBrandParkingParams extends IServiceBaseParams {
	brandParkingId: number;
	plateNumbers: string[];
}

export const vehicleModifyChangeBrandParkingService = (
	model: IVehicleChangeBrandParkingParams,
): Promise<ServiceResponse<IVehicle[]>> => {
	return Api.post<IVehicle[], IVehicleChangeBrandParkingParams>(VehicleModifyEndPoints.CHANGE_BRAND_PARKING, model);
};
