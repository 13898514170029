import { VehicleModifyEndPoints } from './VehicleModifyEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../../shared/types/ServiceResponse';
import type { IVehicle } from '../../../vehicle/entities/Vehicle';

export interface IVehicleChangeVehicleModelParams extends IServiceBaseParams {
	plateNumbers: string[];
	model: string;
	brand: string;
}

export const vehicleModifyChangeVehicleModelService = (
	model: IVehicleChangeVehicleModelParams,
): Promise<ServiceResponse<IVehicle[]>> => {
	return Api.post<IVehicle[], IVehicleChangeVehicleModelParams>(VehicleModifyEndPoints.CHANGE_VEHICLE_MODEL, model);
};
