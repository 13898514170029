import type { IWorkshopAssignment } from '../../models/entities/WorkshopAssignment';
import type {
	IWorkshopAssignmentDeleteParams,
	IWorkshopAssignmentInsertParams,
	IWorkshopAssignmentModifyParams,
	IWorkshopAssignmentSearchParams,
} from '../../models/serviceParams/WorkshopAssignmentParams';
import { Api } from '../../modules/shared/api';
import { WorkshopAssignmentEndPoints } from '../endPoints/FleetWorkshopAssignmentEndPoints';

/**
 * @deprecated - use 'workshopAssignmentSearchService' from 'packages\core\src\modules\workshop\assignment\services\WorkshopAssignmentSearchService.ts' instead.
 */
export const workshopAssignmentServiceSearch = (model: IWorkshopAssignmentSearchParams) => {
	return Api.get<IWorkshopAssignment[], IWorkshopAssignmentSearchParams>(WorkshopAssignmentEndPoints.SEARCH, model);
};

/**
 * @deprecated - use 'workshopAssignmentInsertService' from 'packages\core\src\modules\workshop\assignment\services\WorkshopAssignmentInsertService.ts' instead.
 */
export const workshopAssignmentServiceInsert = (model: IWorkshopAssignmentInsertParams) => {
	return Api.post<IWorkshopAssignment, IWorkshopAssignmentInsertParams>(WorkshopAssignmentEndPoints.INSERT, model);
};

/**
 * @deprecated - use 'workshopAssignmentModifyService' from 'packages\core\src\modules\workshop\assignment\services\WorkshopAssignmentModifyService.ts' instead.
 */
export const workshopAssignmentServiceModify = (model: IWorkshopAssignmentModifyParams) => {
	return Api.post<IWorkshopAssignment, IWorkshopAssignmentModifyParams>(WorkshopAssignmentEndPoints.MODIFY, model);
};

/**
 * @deprecated - use 'workshopAssignmentDeleteService' from 'packages\core\src\modules\workshop\assignment\services\WorkshopAssignmentDeleteService.ts' instead.
 */
export const workshopAssignmentServiceDelete = (model: IWorkshopAssignmentDeleteParams) => {
	return Api.post<IWorkshopAssignment, IWorkshopAssignmentDeleteParams>(WorkshopAssignmentEndPoints.DELETE, model);
};
