import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaRegFileAlt } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const Groups = lazyWithRetry(() => import('./pages/Groups'));

export const groupsRoutes: { [key: number]: IRouteConfigType } = {};

groupsRoutes[routesIdMap.BookingsNewGroups] = {
	component: Groups,
	exact: true,
	id: routesIdMap.BookingsNewGroups,
	name: 'Booking groups',
	path: '/booking/new/groups',
	permissions: [PermissionsType.BookingNew],
	icon: FaRegFileAlt,
};
