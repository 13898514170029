import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaLink } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const SaleAcceptReconditioning = lazyWithRetry(() => import('./pages/SaleAcceptReconditioning'));
const SaleAcceptReconditioningLink = lazyWithRetry(() => import('./pages/SaleAcceptReconditioningLink'));

export const saleAcceptReconditioningRoutes: { [key: number]: IRouteConfigType } = {};

saleAcceptReconditioningRoutes[routesIdMap.SaleAcceptReconditioning] = {
	component: SaleAcceptReconditioning,
	exact: true,
	id: routesIdMap.SaleAcceptReconditioning,
	name: 'Sale Accept Reconditioning',
	path: '/sale/accept-reconditioning',
	permissions: [PermissionsType.Sale],
	menuText: 'Accept reconditioning',
	icon: FaLink,
};

saleAcceptReconditioningRoutes[routesIdMap.SaleAcceptReconditioningLink] = {
	component: SaleAcceptReconditioningLink,
	exact: true,
	id: routesIdMap.SaleAcceptReconditioningLink,
	name: 'Sale Accept Reconditioning link',
	path: '/sale/accept-reconditioning/:movement/:plateNumber',
	permissions: [PermissionsType.Sale],
	icon: FaLink,
};
