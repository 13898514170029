import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { BudgetEndPoints } from './BudgetEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IBudget } from '../entities/Budget';

export interface IBudgetRejectParams extends IServiceBaseParams {
	budgetId: number;
}

/**
 * Reject budget
 * @param {number} `budgetId` number
 * @returns IBudget
 */
export const budgetRejectService = (model: IBudgetRejectParams): Promise<ServiceResponse<IBudget>> => {
	return Api.post<IBudget, IBudgetRejectParams>(BudgetEndPoints.REJECT, model);
};
