/* eslint-disable complexity */
import React from 'react';

import type { ColProps } from './types';
import { cn } from '../lib/utils/tailwindClass';

// Helper function to extract size, offset, and order
const extractBreakpointProps = (breakpoint: ColProps['md']) => {
	if (typeof breakpoint === 'number' || typeof breakpoint === 'string') {
		return {
			size: breakpoint,
			offset: undefined,
			order: undefined,
		};
	}
	return breakpoint || {};
};

const Col = React.forwardRef<HTMLDivElement, ColProps>(
	({ children, className, xs, sm, md, lg, xl, xxl, hidden, tag: Tag = 'div', ...props }, ref) => {
		// Extract breakpoint properties
		const xsProps = extractBreakpointProps(xs);
		const smProps = extractBreakpointProps(sm);
		const mdProps = extractBreakpointProps(md);
		const lgProps = extractBreakpointProps(lg);
		const xlProps = extractBreakpointProps(xl);
		const xxlProps = extractBreakpointProps(xxl);

		return (
			<Tag
				className={cn(
					'tw-col',

					// Default col value
					xsProps.size === undefined &&
						smProps.size === undefined &&
						mdProps.size === undefined &&
						lgProps.size === undefined &&
						xlProps.size === undefined &&
						xxlProps.size === undefined &&
						'tw-flex-[1_0_0]',

					// XS breakpoints
					(xsProps.size === 1 || xsProps.size === '1') && 'tw-w-1/12 tw-flex-[0_0_auto]',
					(xsProps.size === 2 || xsProps.size === '2') && 'tw-w-2/12 tw-flex-[0_0_auto]',
					(xsProps.size === 3 || xsProps.size === '3') && 'tw-w-3/12 tw-flex-[0_0_auto]',
					(xsProps.size === 4 || xsProps.size === '4') && 'tw-w-4/12 tw-flex-[0_0_auto]',
					(xsProps.size === 5 || xsProps.size === '5') && 'tw-w-5/12 tw-flex-[0_0_auto]',
					(xsProps.size === 6 || xsProps.size === '6') && 'tw-w-6/12 tw-flex-[0_0_auto]',
					(xsProps.size === 7 || xsProps.size === '7') && 'tw-w-7/12 tw-flex-[0_0_auto]',
					(xsProps.size === 8 || xsProps.size === '8') && 'tw-w-8/12 tw-flex-[0_0_auto]',
					(xsProps.size === 9 || xsProps.size === '9') && 'tw-w-9/12 tw-flex-[0_0_auto]',
					(xsProps.size === 10 || xsProps.size === '10') && 'tw-w-10/12 tw-flex-[0_0_auto]',
					(xsProps.size === 11 || xsProps.size === '11') && 'tw-w-11/12 tw-flex-[0_0_auto]',
					(xsProps.size === 12 || xsProps.size === '12') && 'tw-w-full tw-flex-[0_0_auto]',
					xsProps.size === 'auto' && 'tw-w-auto tw-flex-[0_0_auto]',

					// SM breakpoints
					(smProps.size === 1 || smProps.size === '1') && 'sm:tw-w-1/12 sm:tw-flex-[0_0_auto]',
					(smProps.size === 2 || smProps.size === '2') && 'sm:tw-w-2/12 sm:tw-flex-[0_0_auto]',
					(smProps.size === 3 || smProps.size === '3') && 'sm:tw-w-3/12 sm:tw-flex-[0_0_auto]',
					(smProps.size === 4 || smProps.size === '4') && 'sm:tw-w-4/12 sm:tw-flex-[0_0_auto]',
					(smProps.size === 5 || smProps.size === '5') && 'sm:tw-w-5/12 sm:tw-flex-[0_0_auto]',
					(smProps.size === 6 || smProps.size === '6') && 'sm:tw-w-6/12 sm:tw-flex-[0_0_auto]',
					(smProps.size === 7 || smProps.size === '7') && 'sm:tw-w-7/12 sm:tw-flex-[0_0_auto]',
					(smProps.size === 8 || smProps.size === '8') && 'sm:tw-w-8/12 sm:tw-flex-[0_0_auto]',
					(smProps.size === 9 || smProps.size === '9') && 'sm:tw-w-9/12 sm:tw-flex-[0_0_auto]',
					(smProps.size === 10 || smProps.size === '10') && 'sm:tw-w-10/12 sm:tw-flex-[0_0_auto]',
					(smProps.size === 11 || smProps.size === '11') && 'sm:tw-w-11/12 sm:tw-flex-[0_0_auto]',
					(smProps.size === 12 || smProps.size === '12') && 'sm:tw-w-full sm:tw-flex-[0_0_auto]',
					smProps.size === 'auto' && 'sm:tw-w-auto sm:tw-flex-[0_0_auto]',

					// MD breakpoints
					(mdProps.size === 1 || mdProps.size === '1') && 'md:tw-w-1/12 md:tw-flex-[0_0_auto]',
					(mdProps.size === 2 || mdProps.size === '2') && 'md:tw-w-2/12 md:tw-flex-[0_0_auto]',
					(mdProps.size === 3 || mdProps.size === '3') && 'md:tw-w-3/12 md:tw-flex-[0_0_auto]',
					(mdProps.size === 4 || mdProps.size === '4') && 'md:tw-w-4/12 md:tw-flex-[0_0_auto]',
					(mdProps.size === 5 || mdProps.size === '5') && 'md:tw-w-5/12 md:tw-flex-[0_0_auto]',
					(mdProps.size === 6 || mdProps.size === '6') && 'md:tw-w-6/12 md:tw-flex-[0_0_auto]',
					(mdProps.size === 7 || mdProps.size === '7') && 'md:tw-w-7/12 md:tw-flex-[0_0_auto]',
					(mdProps.size === 8 || mdProps.size === '8') && 'md:tw-w-8/12 md:tw-flex-[0_0_auto]',
					(mdProps.size === 9 || mdProps.size === '9') && 'md:tw-w-9/12 md:tw-flex-[0_0_auto]',
					(mdProps.size === 10 || mdProps.size === '10') && 'md:tw-w-10/12 md:tw-flex-[0_0_auto]',
					(mdProps.size === 11 || mdProps.size === '11') && 'md:tw-w-11/12 md:tw-flex-[0_0_auto]',
					(mdProps.size === 12 || mdProps.size === '12') && 'md:tw-w-full md:tw-flex-[0_0_auto]',
					mdProps.size === 'auto' && 'md:tw-w-auto md:tw-flex-[0_0_auto]',

					// LG breakpoints
					(lgProps.size === 1 || lgProps.size === '1') && 'lg:tw-w-1/12 lg:tw-flex-[0_0_auto]',
					(lgProps.size === 2 || lgProps.size === '2') && 'lg:tw-w-2/12 lg:tw-flex-[0_0_auto]',
					(lgProps.size === 3 || lgProps.size === '3') && 'lg:tw-w-3/12 lg:tw-flex-[0_0_auto]',
					(lgProps.size === 4 || lgProps.size === '4') && 'lg:tw-w-4/12 lg:tw-flex-[0_0_auto]',
					(lgProps.size === 5 || lgProps.size === '5') && 'lg:tw-w-5/12 lg:tw-flex-[0_0_auto]',
					(lgProps.size === 6 || lgProps.size === '6') && 'lg:tw-w-6/12 lg:tw-flex-[0_0_auto]',
					(lgProps.size === 7 || lgProps.size === '7') && 'lg:tw-w-7/12 lg:tw-flex-[0_0_auto]',
					(lgProps.size === 8 || lgProps.size === '8') && 'lg:tw-w-8/12 lg:tw-flex-[0_0_auto]',
					(lgProps.size === 9 || lgProps.size === '9') && 'lg:tw-w-9/12 lg:tw-flex-[0_0_auto]',
					(lgProps.size === 10 || lgProps.size === '10') && 'lg:tw-w-10/12 lg:tw-flex-[0_0_auto]',
					(lgProps.size === 11 || lgProps.size === '11') && 'lg:tw-w-11/12 lg:tw-flex-[0_0_auto]',
					(lgProps.size === 12 || lgProps.size === '12') && 'lg:tw-w-full lg:tw-flex-[0_0_auto]',
					lgProps.size === 'auto' && 'lg:tw-w-auto lg:tw-flex-[0_0_auto]',

					// XL breakpoints
					(xlProps.size === 1 || xlProps.size === '1') && 'xl:tw-w-1/12 xl:tw-flex-[0_0_auto]',
					(xlProps.size === 2 || xlProps.size === '2') && 'xl:tw-w-2/12 xl:tw-flex-[0_0_auto]',
					(xlProps.size === 3 || xlProps.size === '3') && 'xl:tw-w-3/12 xl:tw-flex-[0_0_auto]',
					(xlProps.size === 4 || xlProps.size === '4') && 'xl:tw-w-4/12 xl:tw-flex-[0_0_auto]',
					(xlProps.size === 5 || xlProps.size === '5') && 'xl:tw-w-5/12 xl:tw-flex-[0_0_auto]',
					(xlProps.size === 6 || xlProps.size === '6') && 'xl:tw-w-6/12 xl:tw-flex-[0_0_auto]',
					(xlProps.size === 7 || xlProps.size === '7') && 'xl:tw-w-7/12 xl:tw-flex-[0_0_auto]',
					(xlProps.size === 8 || xlProps.size === '8') && 'xl:tw-w-8/12 xl:tw-flex-[0_0_auto]',
					(xlProps.size === 9 || xlProps.size === '9') && 'xl:tw-w-9/12 xl:tw-flex-[0_0_auto]',
					(xlProps.size === 10 || xlProps.size === '10') && 'xl:tw-w-10/12 xl:tw-flex-[0_0_auto]',
					(xlProps.size === 11 || xlProps.size === '11') && 'xl:tw-w-11/12 xl:tw-flex-[0_0_auto]',
					(xlProps.size === 12 || xlProps.size === '12') && 'xl:tw-w-full xl:tw-flex-[0_0_auto]',
					xlProps.size === 'auto' && 'xl:tw-w-auto xl:tw-flex-[0_0_auto]',

					// XXL breakpoints
					(xxlProps.size === 1 || xxlProps.size === '1') && 'xxl:tw-w-1/12 xxl:tw-flex-[0_0_auto]',
					(xxlProps.size === 2 || xxlProps.size === '2') && 'xxl:tw-w-2/12 xxl:tw-flex-[0_0_auto]',
					(xxlProps.size === 3 || xxlProps.size === '3') && 'xxl:tw-w-3/12 xxl:tw-flex-[0_0_auto]',
					(xxlProps.size === 4 || xxlProps.size === '4') && 'xxl:tw-w-4/12 xxl:tw-flex-[0_0_auto]',
					(xxlProps.size === 5 || xxlProps.size === '5') && 'xxl:tw-w-5/12 xxl:tw-flex-[0_0_auto]',
					(xxlProps.size === 6 || xxlProps.size === '6') && 'xxl:tw-w-6/12 xxl:tw-flex-[0_0_auto]',
					(xxlProps.size === 7 || xxlProps.size === '7') && 'xxl:tw-w-7/12 xxl:tw-flex-[0_0_auto]',
					(xxlProps.size === 8 || xxlProps.size === '8') && 'xxl:tw-w-8/12 xxl:tw-flex-[0_0_auto]',
					(xxlProps.size === 9 || xxlProps.size === '9') && 'xxl:tw-w-9/12 xxl:tw-flex-[0_0_auto]',
					(xxlProps.size === 10 || xxlProps.size === '10') && 'xxl:tw-w-10/12 xxl:tw-flex-[0_0_auto]',
					(xxlProps.size === 11 || xxlProps.size === '11') && 'xxl:tw-w-11/12 xxl:tw-flex-[0_0_auto]',
					(xxlProps.size === 12 || xxlProps.size === '12') && 'xxl:tw-w-full xxl:tw-flex-[0_0_auto]',
					xxlProps.size === 'auto' && 'xxl:tw-w-auto xxl:tw-flex-[0_0_auto]',

					// Offset classes
					(xsProps.offset === 1 || xsProps.offset === '1') && 'tw-ml-[8.333333%]',
					(xsProps.offset === 2 || xsProps.offset === '2') && 'tw-ml-[16.666667%]',
					(xsProps.offset === 3 || xsProps.offset === '3') && 'tw-ml-[25%]',
					(xsProps.offset === 4 || xsProps.offset === '4') && 'tw-ml-[33.333333%]',
					(xsProps.offset === 5 || xsProps.offset === '5') && 'tw-ml-[41.666667%]',
					(xsProps.offset === 6 || xsProps.offset === '6') && 'tw-ml-[50%]',
					(xsProps.offset === 7 || xsProps.offset === '7') && 'tw-ml-[58.333333%]',
					(xsProps.offset === 8 || xsProps.offset === '8') && 'tw-ml-[66.666667%]',
					(xsProps.offset === 9 || xsProps.offset === '9') && 'tw-ml-[75%]',
					(xsProps.offset === 10 || xsProps.offset === '10') && 'tw-ml-[83.333333%]',
					(xsProps.offset === 11 || xsProps.offset === '11') && 'tw-ml-[91.666667%]',
					(xsProps.offset === 12 || xsProps.offset === '12') && 'tw-ml-[100%]',

					(smProps.offset === 1 || smProps.offset === '1') && 'sm:tw-ml-[8.333333%]',
					(smProps.offset === 2 || smProps.offset === '2') && 'sm:tw-ml-[16.666667%]',
					(smProps.offset === 3 || smProps.offset === '3') && 'sm:tw-ml-[25%]',
					(smProps.offset === 4 || smProps.offset === '4') && 'sm:tw-ml-[33.333333%]',
					(smProps.offset === 5 || smProps.offset === '5') && 'sm:tw-ml-[41.666667%]',
					(smProps.offset === 6 || smProps.offset === '6') && 'sm:tw-ml-[50%]',
					(smProps.offset === 7 || smProps.offset === '7') && 'sm:tw-ml-[58.333333%]',
					(smProps.offset === 8 || smProps.offset === '8') && 'sm:tw-ml-[66.666667%]',
					(smProps.offset === 9 || smProps.offset === '9') && 'sm:tw-ml-[75%]',
					(smProps.offset === 10 || smProps.offset === '10') && 'sm:tw-ml-[83.333333%]',
					(smProps.offset === 11 || smProps.offset === '11') && 'sm:tw-ml-[91.666667%]',
					(smProps.offset === 12 || smProps.offset === '12') && 'sm:tw-ml-[100%]',

					(mdProps.offset === 1 || mdProps.offset === '1') && 'md:tw-ml-[8.333333%]',
					(mdProps.offset === 2 || mdProps.offset === '2') && 'md:tw-ml-[16.666667%]',
					(mdProps.offset === 3 || mdProps.offset === '3') && 'md:tw-ml-[25%]',
					(mdProps.offset === 4 || mdProps.offset === '4') && 'md:tw-ml-[33.333333%]',
					(mdProps.offset === 5 || mdProps.offset === '5') && 'md:tw-ml-[41.666667%]',
					(mdProps.offset === 6 || mdProps.offset === '6') && 'md:tw-ml-[50%]',
					(mdProps.offset === 7 || mdProps.offset === '7') && 'md:tw-ml-[58.333333%]',
					(mdProps.offset === 8 || mdProps.offset === '8') && 'md:tw-ml-[66.666667%]',
					(mdProps.offset === 9 || mdProps.offset === '9') && 'md:tw-ml-[75%]',
					(mdProps.offset === 10 || mdProps.offset === '10') && 'md:tw-ml-[83.333333%]',
					(mdProps.offset === 11 || mdProps.offset === '11') && 'md:tw-ml-[91.666667%]',
					(mdProps.offset === 12 || mdProps.offset === '12') && 'md:tw-ml-[100%]',

					(lgProps.offset === 1 || lgProps.offset === '1') && 'lg:tw-ml-[8.333333%]',
					(lgProps.offset === 2 || lgProps.offset === '2') && 'lg:tw-ml-[16.666667%]',
					(lgProps.offset === 3 || lgProps.offset === '3') && 'lg:tw-ml-[25%]',
					(lgProps.offset === 4 || lgProps.offset === '4') && 'lg:tw-ml-[33.333333%]',
					(lgProps.offset === 5 || lgProps.offset === '5') && 'lg:tw-ml-[41.666667%]',
					(lgProps.offset === 6 || lgProps.offset === '6') && 'lg:tw-ml-[50%]',
					(lgProps.offset === 7 || lgProps.offset === '7') && 'lg:tw-ml-[58.333333%]',
					(lgProps.offset === 8 || lgProps.offset === '8') && 'lg:tw-ml-[66.666667%]',
					(lgProps.offset === 9 || lgProps.offset === '9') && 'lg:tw-ml-[75%]',
					(lgProps.offset === 10 || lgProps.offset === '10') && 'lg:tw-ml-[83.333333%]',
					(lgProps.offset === 11 || lgProps.offset === '11') && 'lg:tw-ml-[91.666667%]',
					(lgProps.offset === 12 || lgProps.offset === '12') && 'lg:tw-ml-[100%]',

					(xlProps.offset === 1 || xlProps.offset === '1') && 'xl:tw-ml-[8.333333%]',
					(xlProps.offset === 2 || xlProps.offset === '2') && 'xl:tw-ml-[16.666667%]',
					(xlProps.offset === 3 || xlProps.offset === '3') && 'xl:tw-ml-[25%]',
					(xlProps.offset === 4 || xlProps.offset === '4') && 'xl:tw-ml-[33.333333%]',
					(xlProps.offset === 5 || xlProps.offset === '5') && 'xl:tw-ml-[41.666667%]',
					(xlProps.offset === 6 || xlProps.offset === '6') && 'xl:tw-ml-[50%]',
					(xlProps.offset === 7 || xlProps.offset === '7') && 'xl:tw-ml-[58.333333%]',
					(xlProps.offset === 8 || xlProps.offset === '8') && 'xl:tw-ml-[66.666667%]',
					(xlProps.offset === 9 || xlProps.offset === '9') && 'xl:tw-ml-[75%]',
					(xlProps.offset === 10 || xlProps.offset === '10') && 'xl:tw-ml-[83.333333%]',
					(xlProps.offset === 11 || xlProps.offset === '11') && 'xl:tw-ml-[91.666667%]',
					(xlProps.offset === 12 || xlProps.offset === '12') && 'xl:tw-ml-[100%]',

					(xxlProps.offset === 1 || xxlProps.offset === '1') && 'xxl:tw-ml-[8.333333%]',
					(xxlProps.offset === 2 || xxlProps.offset === '2') && 'xxl:tw-ml-[16.666667%]',
					(xxlProps.offset === 3 || xxlProps.offset === '3') && 'xxl:tw-ml-[25%]',
					(xxlProps.offset === 4 || xxlProps.offset === '4') && 'xxl:tw-ml-[33.333333%]',
					(xxlProps.offset === 5 || xxlProps.offset === '5') && 'xxl:tw-ml-[41.666667%]',
					(xxlProps.offset === 6 || xxlProps.offset === '6') && 'xxl:tw-ml-[50%]',
					(xxlProps.offset === 7 || xxlProps.offset === '7') && 'xxl:tw-ml-[58.333333%]',
					(xxlProps.offset === 8 || xxlProps.offset === '8') && 'xxl:tw-ml-[66.666667%]',
					(xxlProps.offset === 9 || xxlProps.offset === '9') && 'xxl:tw-ml-[75%]',
					(xxlProps.offset === 10 || xxlProps.offset === '10') && 'xxl:tw-ml-[83.333333%]',
					(xxlProps.offset === 11 || xxlProps.offset === '11') && 'xxl:tw-ml-[91.666667%]',
					(xxlProps.offset === 12 || xxlProps.offset === '12') && 'xxl:tw-ml-[100%]',

					// Order classes
					(xsProps.order === 1 || xsProps.order === '1') && 'tw-order-1',
					(xsProps.order === 2 || xsProps.order === '2') && 'tw-order-2',
					(xsProps.order === 3 || xsProps.order === '3') && 'tw-order-3',
					(xsProps.order === 4 || xsProps.order === '4') && 'tw-order-4',
					(xsProps.order === 5 || xsProps.order === '5') && 'tw-order-5',
					(xsProps.order === 6 || xsProps.order === '6') && 'tw-order-6',
					(xsProps.order === 7 || xsProps.order === '7') && 'tw-order-7',
					(xsProps.order === 8 || xsProps.order === '8') && 'tw-order-8',
					(xsProps.order === 9 || xsProps.order === '9') && 'tw-order-9',
					(xsProps.order === 10 || xsProps.order === '10') && 'tw-order-10',
					(xsProps.order === 11 || xsProps.order === '11') && 'tw-order-11',
					(xsProps.order === 12 || xsProps.order === '12') && 'tw-order-12',
					xsProps.order === 'first' && 'tw-order-first',
					xsProps.order === 'last' && 'tw-order-last',
					xsProps.order === 'none' && 'tw-order-none',

					(smProps.order === 1 || smProps.order === '1') && 'sm:tw-order-1',
					(smProps.order === 2 || smProps.order === '2') && 'sm:tw-order-2',
					(smProps.order === 3 || smProps.order === '3') && 'sm:tw-order-3',
					(smProps.order === 4 || smProps.order === '4') && 'sm:tw-order-4',
					(smProps.order === 5 || smProps.order === '5') && 'sm:tw-order-5',
					(smProps.order === 6 || smProps.order === '6') && 'sm:tw-order-6',
					(smProps.order === 7 || smProps.order === '7') && 'sm:tw-order-7',
					(smProps.order === 8 || smProps.order === '8') && 'sm:tw-order-8',
					(smProps.order === 9 || smProps.order === '9') && 'sm:tw-order-9',
					(smProps.order === 10 || smProps.order === '10') && 'sm:tw-order-10',
					(smProps.order === 11 || smProps.order === '11') && 'sm:tw-order-11',
					(smProps.order === 12 || smProps.order === '12') && 'sm:tw-order-12',
					smProps.order === 'first' && 'sm:tw-order-first',
					smProps.order === 'last' && 'sm:tw-order-last',
					smProps.order === 'none' && 'sm:tw-order-none',

					(mdProps.order === 1 || mdProps.order === '1') && 'md:tw-order-1',
					(mdProps.order === 2 || mdProps.order === '2') && 'md:tw-order-2',
					(mdProps.order === 3 || mdProps.order === '3') && 'md:tw-order-3',
					(mdProps.order === 4 || mdProps.order === '4') && 'md:tw-order-4',
					(mdProps.order === 5 || mdProps.order === '5') && 'md:tw-order-5',
					(mdProps.order === 6 || mdProps.order === '6') && 'md:tw-order-6',
					(mdProps.order === 6 || mdProps.order === '6') && 'md:tw-order-6',
					(mdProps.order === 7 || mdProps.order === '7') && 'md:tw-order-7',
					(mdProps.order === 8 || mdProps.order === '8') && 'md:tw-order-8',
					(mdProps.order === 9 || mdProps.order === '9') && 'md:tw-order-9',
					(mdProps.order === 10 || mdProps.order === '10') && 'md:tw-order-10',
					(mdProps.order === 11 || mdProps.order === '11') && 'md:tw-order-11',
					(mdProps.order === 12 || mdProps.order === '12') && 'md:tw-order-12',
					mdProps.order === 'first' && 'md:tw-order-first',
					mdProps.order === 'last' && 'md:tw-order-last',
					mdProps.order === 'none' && 'md:tw-order-none',

					(lgProps.order === 1 || lgProps.order === '1') && 'lg:tw-order-1',
					(lgProps.order === 2 || lgProps.order === '2') && 'lg:tw-order-2',
					(lgProps.order === 3 || lgProps.order === '3') && 'lg:tw-order-3',
					(lgProps.order === 4 || lgProps.order === '4') && 'lg:tw-order-4',
					(lgProps.order === 5 || lgProps.order === '5') && 'lg:tw-order-5',
					(lgProps.order === 6 || lgProps.order === '6') && 'lg:tw-order-6',
					(lgProps.order === 6 || lgProps.order === '6') && 'lg:tw-order-6',
					(lgProps.order === 7 || lgProps.order === '7') && 'lg:tw-order-7',
					(lgProps.order === 8 || lgProps.order === '8') && 'lg:tw-order-8',
					(lgProps.order === 9 || lgProps.order === '9') && 'lg:tw-order-9',
					(lgProps.order === 10 || lgProps.order === '10') && 'lg:tw-order-10',
					(lgProps.order === 11 || lgProps.order === '11') && 'lg:tw-order-11',
					(lgProps.order === 12 || lgProps.order === '12') && 'lg:tw-order-12',
					lgProps.order === 'first' && 'lg:tw-order-first',
					lgProps.order === 'last' && 'lg:tw-order-last',
					lgProps.order === 'none' && 'lg:tw-order-none',

					(xlProps.order === 1 || xlProps.order === '1') && 'xl:tw-order-1',
					(xlProps.order === 2 || xlProps.order === '2') && 'xl:tw-order-2',
					(xlProps.order === 3 || xlProps.order === '3') && 'xl:tw-order-3',
					(xlProps.order === 4 || xlProps.order === '4') && 'xl:tw-order-4',
					(xlProps.order === 5 || xlProps.order === '5') && 'xl:tw-order-5',
					(xlProps.order === 6 || xlProps.order === '6') && 'xl:tw-order-6',
					(xlProps.order === 6 || xlProps.order === '6') && 'xl:tw-order-6',
					(xlProps.order === 7 || xlProps.order === '7') && 'xl:tw-order-7',
					(xlProps.order === 8 || xlProps.order === '8') && 'xl:tw-order-8',
					(xlProps.order === 9 || xlProps.order === '9') && 'xl:tw-order-9',
					(xlProps.order === 10 || xlProps.order === '10') && 'xl:tw-order-10',
					(xlProps.order === 11 || xlProps.order === '11') && 'xl:tw-order-11',
					(xlProps.order === 12 || xlProps.order === '12') && 'xl:tw-order-12',
					xlProps.order === 'first' && 'xl:tw-order-first',
					xlProps.order === 'last' && 'xl:tw-order-last',
					xlProps.order === 'none' && 'xl:tw-order-none',

					(xxlProps.order === 1 || xxlProps.order === '1') && 'xxl:tw-order-1',
					(xxlProps.order === 2 || xxlProps.order === '2') && 'xxl:tw-order-2',
					(xxlProps.order === 3 || xxlProps.order === '3') && 'xxl:tw-order-3',
					(xxlProps.order === 4 || xxlProps.order === '4') && 'xxl:tw-order-4',
					(xxlProps.order === 5 || xxlProps.order === '5') && 'xxl:tw-order-5',
					(xxlProps.order === 6 || xxlProps.order === '6') && 'xxl:tw-order-6',
					(xxlProps.order === 6 || xxlProps.order === '6') && 'xxl:tw-order-6',
					(xxlProps.order === 7 || xxlProps.order === '7') && 'xxl:tw-order-7',
					(xxlProps.order === 8 || xxlProps.order === '8') && 'xxl:tw-order-8',
					(xxlProps.order === 9 || xxlProps.order === '9') && 'xxl:tw-order-9',
					(xxlProps.order === 10 || xxlProps.order === '10') && 'xxl:tw-order-10',
					(xxlProps.order === 11 || xxlProps.order === '11') && 'xxl:tw-order-11',
					(xxlProps.order === 12 || xxlProps.order === '12') && 'xxl:tw-order-12',
					xxlProps.order === 'first' && 'xxl:tw-order-first',
					xxlProps.order === 'last' && 'xxl:tw-order-last',
					xxlProps.order === 'none' && 'xxl:tw-order-none',

					// Additional classes
					hidden && 'tw-hidden',

					// External classes
					className,
				)}
				ref={ref}
				{...props}
			>
				{children}
			</Tag>
		);
	},
);

Col.displayName = 'Col';

export { Col };
