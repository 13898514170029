import type { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';

import { Col } from '@crac/components/external/reactstrap/col';
import { Row } from '@crac/components/external/reactstrap/row';
import { Fieldset } from '@crac/components/intranet/shared/fieldset/Fieldset';
import { formatSelect } from '@crac/core/helpers/commons';
import type { ICountry } from '@crac/core/models/entities/Country';
import type { ISelectOptionType } from '@crac/core/models/types/SelectOptionType';
import type { ISupplierInsertParams } from '@crac/core/modules/supplier/services/SupplierInsertService';
import type { ISupplierModifyParams } from '@crac/core/modules/supplier/services/SupplierModifyService';
import type { SupplierType } from '@crac/core/modules/supplier/types/SupplierType';
import { FieldAction, FieldWatcher, InputField, SelectField } from '@crac/form/field';
import { useFormContext } from '@crac/form/form/hooks';

import { useCitySelector } from '~/features/shared/state/selectors/CitySelectors';
import { useCountrySelector } from '~/features/shared/state/selectors/CountrySelectors';
import { useProvinceSelector } from '~/features/shared/state/selectors/ProvinceSelectors';
import { useAppDispatch } from '~/redux/hooks';

import { handlePostalCodeChange, handlePostalCodeTextChange } from '../handlers';
import { useSupplierFormValidateNif } from '../hooks/useSupplierFormValidateNif';

const isCountryWithData = (countries: ICountry[], value?: string) => {
	return Boolean(countries.find(({ code }) => code === value)?.data);
};

interface ISupplierFormAddressFields {
	initialValues?: ISupplierInsertParams | Partial<ISupplierModifyParams>;
	supplierType: SupplierType;
}

export const SupplierFormAddressFields: FC<ISupplierFormAddressFields> = ({ initialValues, supplierType }) => {
	const dispatch = useAppDispatch();
	const { nifValidation } = useSupplierFormValidateNif();

	//* State
	const [isDataLoaded, setIsDataLoaded] = useState(false);

	//* Selectors
	const { countries } = useCountrySelector();
	const { cities } = useCitySelector();
	const { provinces } = useProvinceSelector();

	const countyOptions = useMemo(() => formatSelect('name', 'name', provinces), [provinces]);
	const cityOptions = useMemo(() => formatSelect('name', 'name', cities), [cities]);

	const countryOptions = useMemo(
		() => countries.map((item) => ({ label: item.name, value: item.code })),
		[countries],
	);

	const { setValue } = useFormContext();
	const required = true;

	//* Search city and province
	useEffect(() => {
		if (isDataLoaded || !initialValues?.country || !initialValues?.postCode) {
			return;
		}

		const country = countries.find((element) => element.code === initialValues?.country);
		if (country && country.data) {
			handlePostalCodeChange(dispatch)(initialValues.postCode, initialValues.country);
			setIsDataLoaded(true);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialValues?.country, initialValues?.postCode, isDataLoaded]);

	//* Set city and province
	useEffect(() => {
		if (cities.length === 1) {
			setValue('city', cities[0].name);
		}
		if (provinces.length === 1) {
			setValue('county', provinces[0].name);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cities, provinces]);

	return (
		<Fieldset legend="Address information">
			<Row>
				<Col lg={6}>
					<SelectField
						isClearable
						label="Country"
						name="country"
						options={countryOptions}
						validation={{ required }}
					/>
				</Col>
				<FieldWatcher name="country">
					{({ country }) => (
						<>
							<Col lg={6}>
								<InputField
									disabled={!country}
									label="NIF"
									name="nif"
									validateOnLoad={Boolean(initialValues)}
									validation={{
										required,
										validate: nifValidation,
									}}
								/>
							</Col>

							<Col lg={6}>
								{isCountryWithData(countries, country) ? (
									<SelectField
										// defaultOptions={postCodes}
										disabled={!country}
										isClearable
										label="Post code"
										loadOptions={(label: string) =>
											handlePostalCodeTextChange(country)({ label } as ISelectOptionType)
										}
										name="postCode"
										onChange={(postCode) => handlePostalCodeChange(dispatch)(postCode, country)}
										validateOnLoad={Boolean(initialValues)}
										validation={{ required }}
									/>
								) : (
									<InputField
										disabled={!country}
										label="Post code"
										name="postCode"
										validateOnLoad={Boolean(initialValues)}
										validation={{ required, maxLength: 30 }}
									/>
								)}
							</Col>

							<Col lg={6}>
								{isCountryWithData(countries, country) ? (
									<SelectField
										disabled={!country}
										isClearable
										label="County"
										name="county"
										options={countyOptions}
										validateOnLoad={Boolean(initialValues)}
										validation={{ required }}
									/>
								) : (
									<InputField
										disabled={!country}
										label="County"
										name="county"
										validateOnLoad={Boolean(initialValues)}
										validation={{ required, maxLength: 30 }}
									/>
								)}
							</Col>

							<Col lg={6}>
								{isCountryWithData(countries, country) ? (
									<SelectField
										disabled={!country}
										isClearable
										label="City"
										name="city"
										options={cityOptions}
										validateOnLoad={Boolean(initialValues)}
										validation={{ required }}
									/>
								) : (
									<InputField
										disabled={!country}
										label="City"
										name="city"
										validateOnLoad={Boolean(initialValues)}
										validation={{ required, maxLength: 30 }}
									/>
								)}
							</Col>

							<Col lg={6}>
								<InputField
									label="Address"
									name="address"
									validateOnLoad={Boolean(initialValues)}
									validation={{ required, maxLength: 50 }}
								/>
							</Col>
						</>
					)}
				</FieldWatcher>
				<FieldAction name="country" set={['nif', 'postCode', 'county', 'city', 'address']} to={undefined} />
			</Row>
		</Fieldset>
	);
};
