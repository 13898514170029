import { cva } from 'class-variance-authority';

import { SizeVariantType } from '../lib/types';

export const switchVariants = cva('tw-peer switch', {
	variants: {
		size: {
			[SizeVariantType.sm]: 'tw-h-5 tw-w-9',
			[SizeVariantType.default]: 'tw-h-6 tw-w-11',
			[SizeVariantType.lg]: 'tw-h-7 tw-w-14',
		},
	},
	defaultVariants: {
		size: SizeVariantType.default,
	},
});

export const thumbVariants = cva('switch-thumb', {
	variants: {
		size: {
			[SizeVariantType.sm]: 'tw-h-4 tw-w-4 data-[state=checked]:tw-translate-x-4',
			[SizeVariantType.default]: 'tw-h-5 tw-w-5 data-[state=checked]:tw-translate-x-5',
			[SizeVariantType.lg]: 'tw-h-6 tw-w-6 data-[state=checked]:tw-translate-x-7',
		},
	},
	defaultVariants: {
		size: SizeVariantType.default,
	},
});
