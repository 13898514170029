import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { VehicleDetailEndPoints } from './VehicleDetailEndPoints';
import type { IVehicle } from '../../entities/Vehicle';

export interface IVehicleGetByPlateNumberParams extends IServiceBaseParams {
	plateNumber: string;
	bookingNumber?: string;
}

/**
 * Get vehicle by plate number
 *  {string} plateNumber Plate number
 * @returns {Promise} `ResponsePropType`
 */

export const vehicleGetByPlateNumberService = (
	model: IVehicleGetByPlateNumberParams,
): Promise<ServiceResponse<IVehicle>> => {
	return Api.get<IVehicle, IVehicleGetByPlateNumberParams>(VehicleDetailEndPoints.GET_BY_PLATE, model);
};
