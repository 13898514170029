/* eslint-disable react/no-unused-prop-types */
import { type FC } from 'react';

import type { Location } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import { DevelopToolbar } from '@crac/components/intranet/shared/developToolbar';
import type { IEmployee } from '@crac/core/models/entities/Employee';
import { ComponentConsumersType } from '@crac/core/models/types/ComponentConsumersType';
import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IPermissionsToggleParams } from '@crac/core/redux/actions/PermissionsActions';
import { Header } from '@crac/ui/header';
import type { INavItem } from '@crac/ui/sidebar';

import { HeaderDropdown } from './HeaderDropdown';
import { HeaderPermissions } from './HeaderPermissions';

interface IHeaderProps {
	employee: IEmployee;
	permissionsToolEnabled: boolean;
	sidebarOpen: boolean;
	permissions: string[];
	location: Location<any>;
	menuItems: INavItem[];
	mobileSidebarOpen?: boolean;
	isDevelopment: boolean;
	setMobileSidebarOpen?: (open: boolean) => void;
	togglePermissions: (payload: IPermissionsToggleParams) => void;
	updatePermissions: (permissions: string[]) => void;
	renderSidebarHeader: () => React.ReactNode;
}

export const AppHeader: FC<IHeaderProps> = ({
	employee,
	sidebarOpen,
	menuItems,
	mobileSidebarOpen,
	isDevelopment,
	setMobileSidebarOpen,
	renderSidebarHeader,
	...props
}) => {
	return (
		<>
			<DevelopToolbar consumer={ComponentConsumersType.RAC} portalId="top-page-content" />
			<Header
				AnchorComponent={NavLink}
				className={isDevelopment ? 'tw-top-[34px]' : ''}
				menuItems={menuItems}
				mobileSidebarOpen={mobileSidebarOpen}
				renderSidebarHeader={renderSidebarHeader}
				setMobileSidebarOpen={setMobileSidebarOpen}
				sidebarOpen={sidebarOpen}
			>
				{/* User dropdown */}
				<HeaderDropdown employee={employee} />

				{/* Permissions menu */}
				{employee.permissions.includes(PermissionsType.IT) && (
					<HeaderPermissions {...props} employee={employee} />
				)}
			</Header>
		</>
	);
};
