import { DropOffEndpoints } from './DropOffEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../../shared/types/ServiceResponse';
import type { IBooking } from '../../entities/Booking';

export interface IBookingDropOffEndParams extends IServiceBaseParams {
	bookingNumber: string;
	file?: File;
	notAccord: boolean;
	notPresent: boolean;
}

/**
 * End drop off
 *  {Object} booking Data register booking
 * @return {Promise} `BookingPropType` Use await for resolve
 */
export const bookingDropOffEndService = (model: IBookingDropOffEndParams): Promise<ServiceResponse<IBooking>> => {
	return Api.post<IBooking, IBookingDropOffEndParams>(DropOffEndpoints.DROPOFF_END, model);
};
