import { VehicleModifyEndPoints } from './VehicleModifyEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../../shared/types/ServiceResponse';
import type { IVehicle } from '../../../vehicle/entities/Vehicle';

export interface IVehicleChangePlateNumberParams extends IServiceBaseParams {
	oldPlateNumber: string;
	newPlateNumber: string;
}

export const vehicleModifyChangePlateNumberService = (
	model: IVehicleChangePlateNumberParams,
): Promise<ServiceResponse<IVehicle>> => {
	return Api.post<IVehicle, IVehicleChangePlateNumberParams>(VehicleModifyEndPoints.CHANGE_PLATE_NUMBER, model);
};
