import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

import { RevisionTypeEndPoints } from './RevisionTypeEndpoint';
import type { IRevisionType } from '../entities/RevisionType';

export interface IRevisionTypeDeactivateParams extends IServiceBaseParams {
	code: string;
}

export const revisionTypeDeactivateService = (model: IRevisionTypeDeactivateParams) => {
	return Api.post<IRevisionType, IRevisionTypeDeactivateParams>(RevisionTypeEndPoints.DEACTIVATE, model);
};
