import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { bookingDropOffGetByBranch } from '@crac/core/modules/booking/dropOff/state/actions/DropOffActions';
import type { IDropOffStateType } from '@crac/core/modules/booking/dropOff/state/stateType/DropOffStateType';
import { bookingDetailClear } from '@crac/core/redux/actions/BookingActions';
import {
	bookingDropOffClearPendings,
	bookingDropOffEnd,
	bookingDropOffGetPendingsByPlateNumberAndBranchCode,
	bookingDropOffInit,
	bookingDropOffStart,
} from '@crac/core/redux/actions/BookingDropOffActions';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';

const initialState: IDropOffStateType = {
	bookings: [],
	dropOffEndRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	dropOffInitRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	dropOffSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	dropOffStartRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	dropOffGetByBranchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const dropOffSlice = createSlice({
	name: 'BookingDropOffSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// BOOKING_DROPOFF_GET_PENDING_BY_PLATE_AND_BRANCH
		builder
			.addCase(bookingDropOffGetPendingsByPlateNumberAndBranchCode.pending, (state) => {
				return {
					...state,
					dropOffSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingDropOffGetPendingsByPlateNumberAndBranchCode.fulfilled, (state, action) => {
				return {
					...state,
					bookings: deepCopy(action.payload),
					dropOffSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingDropOffGetPendingsByPlateNumberAndBranchCode.rejected, (state, action) => {
				return {
					...state,
					dropOffSearchRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// BOOKING_DROPOFF_END_REQUEST
		builder
			.addCase(bookingDropOffEnd.pending, (state) => {
				return {
					...state,
					dropOffEndRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingDropOffEnd.fulfilled, (state) => {
				return {
					...state,
					dropOffEndRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingDropOffEnd.rejected, (state, action) => {
				return {
					...state,
					dropOffEndRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// BOOKING_DROPOFF_START
		builder
			.addCase(bookingDropOffStart.pending, (state) => {
				return {
					...state,
					dropOffStartRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingDropOffStart.fulfilled, (state) => {
				return {
					...state,
					dropOffStartRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingDropOffStart.rejected, (state, action) => {
				return {
					...state,
					dropOffStartRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// BOOKING_DROPOFF_INIT
		builder
			.addCase(bookingDropOffInit.pending, (state) => {
				return {
					...state,
					dropOffInitRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingDropOffInit.fulfilled, (state) => {
				return {
					...state,
					dropOffInitRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingDropOffInit.rejected, (state, action) => {
				return {
					...state,
					dropOffInitRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});

		// BOOKING_DROPOFF_GET_BY_BRANCH
		builder
			.addCase(bookingDropOffGetByBranch.pending, (state) => {
				return {
					...state,
					dropOffGetByBranchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingDropOffGetByBranch.fulfilled, (state, action) => {
				return {
					...state,
					bookings: deepCopy(action.payload) || [],
					dropOffGetByBranchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingDropOffGetByBranch.rejected, (state, action) => {
				return {
					...state,
					dropOffGetByBranchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		/**
		 * CLEAR CACHE
		 * CLEAR
		 * BOOKING_DROPOFF_GET_PENDING_BY_PLATE_AND_BRANCH_CLEAR
		 */
		builder.addMatcher(isAnyOf(clearGlobalCache, bookingDetailClear, bookingDropOffClearPendings), () => {
			return initialState;
		});
	},
});

export const dropOffReducer = dropOffSlice.reducer;
