import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaSignInAlt } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const Search = lazyWithRetry(() => import('./pages/Search'));

export const searchRoutes: { [key: number]: IRouteConfigType } = {};

searchRoutes[routesIdMap.BookingsPickUp] = {
	component: Search,
	exact: true,
	id: routesIdMap.BookingsPickUp,
	name: 'Pick up',
	path: '/booking/pick-up',
	permissions: [PermissionsType.BookingPickUp],
	icon: FaSignInAlt,
	menuText: 'Pick up',
};
