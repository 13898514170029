import { DropOffEndpoints } from './DropOffEndpoints';
import type { IBooking } from '../../../../models/entities/Booking';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../../shared/types/ServiceResponse';

export interface IBookingDropOffSendInvoiceParams extends IServiceBaseParams {
	bookingNumber: string;
	email: string;
}

/**
 * Send drop off invoice email by booking number
 *  {string} bookingNumber booking number
 * @return {Promise} `BookingPropType` Use await for resolve
 */
export const bookingDropOffSendInvoiceService = (
	model: IBookingDropOffSendInvoiceParams,
): Promise<ServiceResponse<IBooking>> => {
	return Api.post<IBooking, IBookingDropOffSendInvoiceParams>(DropOffEndpoints.DROPOFF_SEND_INVOICE, model);
};
