import { BudgetEndPoints } from './BudgetEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IBudgetFile } from '../entities/Budget';

export interface IBudgetDeleteFileParams extends IServiceBaseParams {
	id: number;
}

/**
 * Delete file from budget
 * @param id id of the budget file
 * @returns `IBudgetFile`
 */
export const budgetDeleteFileService = (model: IBudgetDeleteFileParams) => {
	return Api.post<IBudgetFile, IBudgetDeleteFileParams>(BudgetEndPoints.DELETE_FILE, model);
};
