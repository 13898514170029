import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { RevisionCostEndPoints } from './RevisionCostEndpoints';
import type { IRevisionCost } from '../entities/RevisionCost';

export interface IRevisionCostSearchParams extends IServiceBaseParams {
	id?: number;
	plateNumber?: string;
	budgetId?: number;
	budgetLineId?: number;
	revisionLineId?: number;
}

export const revisionCostSearchService = (
	model: IRevisionCostSearchParams,
): Promise<ServiceResponse<IRevisionCost[]>> => {
	return Api.get<IRevisionCost[], IRevisionCostSearchParams>(RevisionCostEndPoints.SEARCH, model);
};
