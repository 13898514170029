import { insuranceServiceImport } from '../../../../../data/services/InsuranceService';
import { linkServiceAddToInsurance } from '../../../../../data/services/LinkService';
import type { IInsuranceImportParams } from '../../../../../models/serviceParams/InsuranceParams';
import type { ILinkAddToInsuranceParams } from '../../../../../models/serviceParams/LinkParams';
import { createSyncAction } from '../../../../shared/state/createAction';
import { createAsyncAction } from '../../../../shared/state/createAsyncAction';

export const fleetDocumentationAddToInsurance = createAsyncAction<boolean, ILinkAddToInsuranceParams>(
	'fleet/documentation/addToInsurance',
	linkServiceAddToInsurance,
);

export const fleetDocumentationClear = createSyncAction('fleet/documentation/clear');

export const fleetDocumentationImport = createAsyncAction<boolean, IInsuranceImportParams>(
	'fleet/documentation/import',
	insuranceServiceImport,
);
