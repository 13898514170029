import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

import { ExcessRateEndpoints } from './ExcessRateEndpoints';
import type { IExcessRatePerProvider } from '../entities/ExcessRatePerProvider';

/**
 * Get all excess rates
 * @param model - search parameters
 * @returns - list of excess rates
 */
export const excessRateGetAllService = (model: IServiceBaseParams) => {
	return Api.get<IExcessRatePerProvider[], IServiceBaseParams>(ExcessRateEndpoints.GET_ALL, model);
};
