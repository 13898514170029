import {
	bookingPickUpServiceGetPendingByBranch,
	bookingPickUpServiceStartContract,
} from '../../data/services/BookingPickUpService';
import { bookingServiceRegisterContract } from '../../data/services/BookingService';
import type { IBooking } from '../../models/entities/Booking';
import type { IBookingRegisterContractParams } from '../../models/serviceParams/BookingParams';
import type {
	IBookingPickUpGetPendingByBranchParams,
	IBookingStartContractParams,
} from '../../models/serviceParams/BookingPickUpParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * @deprecated Use `bookingPickUpGetPendingByBranch` from `modules/booking/availability/state/actions`
 * Retrieves pending bookings for pick-up at a specific branch.
 *
 * @param {IBooking[]} payload - The bookings to be retrieved.
 * @param {IBookingPickUpGetPendingByBranchParams} params - Parameters to identify pending bookings at a branch.
 * @returns {Promise<void>} - A promise that resolves to an array of pending bookings.
 */
export const bookingPickUpGetPendingByBranch = createAsyncAction<IBooking[], IBookingPickUpGetPendingByBranchParams>(
	'bookingPickUp/getPendingByBranch',
	bookingPickUpServiceGetPendingByBranch,
);

/**
 * @deprecated Use `bookingPickUpStartContract` from `modules/booking/availability/state/actions`
 * Initiates the contract start process for a booking pick-up.
 *
 * @param {IBooking} payload - The booking for which to start the contract.
 * @param {IBookingStartContractParams} params - Parameters needed to start the contract for the booking pick-up.
 * @returns {Promise<void>} - A promise that resolves after starting the contract process.
 */
export const bookingPickUpStartContract = createAsyncAction<IBooking, IBookingStartContractParams>(
	'bookingPickUp/startContract',
	bookingPickUpServiceStartContract,
);

/**
 * @deprecated Use `bookingRegisterContract` from `modules/booking/availability/state/actions`
 * Registers a contract for a booking.
 *
 * @param {IBooking} payload - The booking for which the contract is registered.
 * @param {IBookingRegisterContractParams} params - Parameters required for registering the contract.
 * @returns {Promise<void>} - A promise that resolves after registering the contract.
 */
export const bookingRegisterContract = createAsyncAction<IBooking, IBookingRegisterContractParams>(
	'booking/registerContract',
	bookingServiceRegisterContract,
);
