import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaMagnifyingGlass } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const Search = lazyWithRetry(() => import('./pages/Search'));

export const searchRoutes: { [key: number]: IRouteConfigType } = {};

searchRoutes[routesIdMap.Vehicles] = {
	component: Search,
	exact: true,
	id: routesIdMap.Vehicles,
	name: 'Search vehicle',
	path: '/vehicle/search',
	permissions: [PermissionsType.Vehicle],
	menuText: 'Search',
	icon: FaMagnifyingGlass,
};
