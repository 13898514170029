import { createSyncAction } from '../../../../../modules/shared/state/createAction';
import { createAsyncAction } from '../../../../../modules/shared/state/createAsyncAction';
import type { IFleetPrevision } from '../../entities/FleetPrevision';
import type { IFleetPrevisionModifyParams } from '../../services/FleerPrevisionModifyService';
import { fleetPrevisionModifyService } from '../../services/FleerPrevisionModifyService';
import type { IFleetPrevisionGetByPrevisionCodeParams } from '../../services/FleetPrevisionGetByPrevisionCodeService';
import { fleetPrevisionGetByPrevisionCodeService } from '../../services/FleetPrevisionGetByPrevisionCodeService';
import type { IFleetPrevisionInsertArrayParams } from '../../services/FleetPrevisionInsertService';
import { fleetPrevisionInsertService } from '../../services/FleetPrevisionInsertService';
import type { IFleetPrevisionSearchParams } from '../../services/FleetPrevisionSearchService';
import { fleetPrevisionSearchService } from '../../services/FleetPrevisionSearchService';

/**
 * Searches for fleet previsions based on specified parameters.
 *
 * @param {IFleetPrevision[]} payload - The fleet previsions to be returned.
 * @param {IFleetPrevisionSearchParams} params - Parameters for the search.
 * @returns {Promise<void>} - A promise that resolves to an array of fleet previsions.
 */
export const fleetPrevisionSearch = createAsyncAction<IFleetPrevision[], IFleetPrevisionSearchParams>(
	'fleet/prevision/search',
	fleetPrevisionSearchService,
);

/**
 * Inserts new fleet previsions into the system.
 *
 * @param {IFleetPrevision[]} payload - The fleet previsions to be inserted.
 * @param {IFleetPrevisionSearchParams} params - Parameters detailing the fleet previsions to be inserted.
 * @returns {Promise<void>} - A promise that resolves after the fleet previsions are successfully inserted.
 */
export const fleetPrevisionInsert = createAsyncAction<IFleetPrevision[], IFleetPrevisionInsertArrayParams>(
	'fleet/prevision/insert',
	fleetPrevisionInsertService,
);

/**
 * Modifies existing fleet previsions.
 *
 * @param {IFleetPrevision} payload - The fleet previsions to be modified.
 * @param {IFleetPrevisionModifyParams} params - Parameters detailing the fleet previsions to be modified.
 * @returns {Promise<void>} - A promise that resolves after the fleet previsions are successfully modified.
 */
export const fleetPrevisionModify = createAsyncAction<IFleetPrevision, IFleetPrevisionModifyParams>(
	'fleet/prevision/modify',
	fleetPrevisionModifyService,
);

/**
 * Clears the search results for fleet previsions.
 */
export const fleetPrevisionClear = createSyncAction('fleet/prevision/clear');

/**
 * Searches for fleet previsions based on specified parameters.
 *
 * @param {IFleetPrevision[]} payload - The fleet previsions to be returned.
 * @param {IFleetPrevisionSearchParams} params - Parameters for the search.
 * @returns {Promise<void>} - A promise that resolves to an array of fleet previsions.
 */
export const fleetPrevisionGetByPrevisionCode = createAsyncAction<
	IFleetPrevision,
	IFleetPrevisionGetByPrevisionCodeParams
>('fleet/prevision/getByPrevisionCode', fleetPrevisionGetByPrevisionCodeService);

/**
 * Clears the search results for fleet previsions.
 */
export const fleetPrevisionGetByPrevisionCodeClear = createSyncAction('fleet/prevision/getByPrevisionCodeClear');
