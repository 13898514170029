import { FaChartArea, FaChartBar } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

import { getPricingPermission } from '../utils/helpers';

const Dashboard = lazyWithRetry(() => import('./pages/Dashboard'));

export const dashboardRoutes: { [key: number]: IRouteConfigType } = {};

dashboardRoutes[routesIdMap.PricingDashboard] = {
	component: Dashboard,
	exact: true,
	id: routesIdMap.PricingDashboard,
	name: 'Pricing',
	path: '/pricing',
	permissions: getPricingPermission(),
	icon: FaChartArea,
	menuText: 'Pricing',
};
