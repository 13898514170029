import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaCar } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const SalePriceModifySearch = lazyWithRetry(() => import('./pages/SalePriceModifySearch'));
const SalePriceModify = lazyWithRetry(() => import('./pages/SalePriceModify'));

export const salePriceModifyRoutes: { [key: number]: IRouteConfigType } = {};

salePriceModifyRoutes[routesIdMap.SalePriceModifySearch] = {
	component: SalePriceModifySearch,
	exact: true,
	id: routesIdMap.SalePriceModifySearch,
	name: 'Change sale price',
	menuText: 'Change sale price',
	path: '/sale/price-modify',
	permissions: [PermissionsType.Sale],
	childrens: [routesIdMap.SalePriceModify],
	icon: FaCar,
};

salePriceModifyRoutes[routesIdMap.SalePriceModify] = {
	component: SalePriceModify,
	exact: true,
	id: routesIdMap.SalePriceModify,
	name: 'Change sale price',
	menuText: 'Change sale price',
	path: '/sale/price-modify/change-price',
	permissions: [PermissionsType.Sale],
	childrens: [],
	icon: FaCar,
};
