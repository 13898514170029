import { VehicleModifyEndPoints } from './VehicleModifyEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../../shared/types/ServiceResponse';
import type { IVehicle } from '../../../vehicle/entities/Vehicle';
import type { FleetTypology } from '../../types/FleetTypology';

export interface IVehicleChangeFleetTypologyParams extends IServiceBaseParams {
	plateNumbers: string[];
	fleetTypology: FleetTypology;
}

export const vehicleModifyChangeFleetTypologyService = (
	model: IVehicleChangeFleetTypologyParams,
): Promise<ServiceResponse<IVehicle[]>> => {
	return Api.post<IVehicle[], IVehicleChangeFleetTypologyParams>(VehicleModifyEndPoints.CHANGE_FLEET_TYPOLOGY, model);
};
