import { VehicleGroupEndPoints } from './VehicleGroupEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IGroup } from '../entities/VehicleGroup';

export interface IVehicleGroupGetByBranchParams extends IServiceBaseParams {
	branchCode: string;
}

/**
 * Get by branch
 */
export const vehicleGroupGetByBranchService = (model: IVehicleGroupGetByBranchParams) =>
	Api.get<IGroup[], IVehicleGroupGetByBranchParams>(VehicleGroupEndPoints.GET_BY_BRANCH, model);
