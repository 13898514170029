import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaGear, FaPencil, FaPlus } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const Supplier = lazyWithRetry(() => import('./pages/Supplier'));
const SupplierNew = lazyWithRetry(() => import('./pages/SupplierNew'));
const SupplierModify = lazyWithRetry(() => import('./pages/SupplierModify'));

export const supplierRoutes: { [key: number]: IRouteConfigType } = {};

supplierRoutes[routesIdMap.WorkshopSupplier] = {
	component: Supplier,
	exact: true,
	id: routesIdMap.WorkshopSupplier,
	name: 'Supplier',
	path: '/workshop/supplier',
	permissions: [PermissionsType.Fleet],
	icon: FaGear,
	childrens: [routesIdMap.WorkshopSupplierNew, routesIdMap.WorkshopSupplierModify],
	menuText: 'Supplier',
};

supplierRoutes[routesIdMap.WorkshopSupplierNew] = {
	component: SupplierNew,
	exact: true,
	id: routesIdMap.WorkshopSupplierNew,
	name: 'New supplier',
	path: '/workshop/supplier/new',
	permissions: [PermissionsType.Fleet],
	isChild: true,
	icon: FaPlus,
	menuText: 'New supplier',
};

supplierRoutes[routesIdMap.WorkshopSupplierModify] = {
	component: SupplierModify,
	exact: true,
	id: routesIdMap.WorkshopSupplierModify,
	name: 'Modify supplier',
	path: '/workshop/supplier/modify/:id',
	permissions: [PermissionsType.Fleet],
	isChild: true,
	icon: FaPencil,
};
