import type { IVehicleSearchParams } from '../../../../../models/serviceParams/VehicleParams';
import { createSyncAction } from '../../../../shared/state/createAction';
import { createAsyncAction } from '../../../../shared/state/createAsyncAction';
import type { IVehicle } from '../../../../vehicle/entities/Vehicle';
import type { IFleetPreviousRegistrationDeleteParams } from '../../services/PreviousRegistrationDeleteService';
import { previousRegistrationDeleteService } from '../../services/PreviousRegistrationDeleteService';
import type { IFleetPreviousRegistrationImportParams } from '../../services/PreviousRegistrationImportService';
import { previousRegistrationImportService } from '../../services/PreviousRegistrationImportService';
import type { IFleetPreviousRegistrationModifyParams } from '../../services/PreviousRegistrationModifyService';
import { previousRegistrationModifyService } from '../../services/PreviousRegistrationModifyService';
import type { IFleetPreviousRegistrationRegisterParams } from '../../services/PreviousRegistrationRegisterService';
import { previousRegistrationRegisterService } from '../../services/PreviousRegistrationRegisterService';
import { previousRegistrationSearchService } from '../../services/PreviousRegistrationSearchService';

/**
 * Clears the search results for fleet previous registration search.
 */
export const fleetPreviousRegistrationSearchClear = createSyncAction('fleet/previousRegistration/searchClear');

/**
 * Searches for fleet previous registrations based on specified parameters.
 * @param {IVehicle[]} payload - The search results.
 * @param {IVehicleSearchParams} params - The search parameters.
 * @returns {Promise<void>} A promise that resolves when the action is complete.
 */
export const fleetPreviousRegistrationSearch = createAsyncAction<IVehicle[], IVehicleSearchParams>(
	'fleet/previousRegistration/search',
	previousRegistrationSearchService,
);

/**
 * Clears results for fleet previous registration import.
 */
export const fleetPreviousRegistrationImportClear = createSyncAction('fleet/previousRegistration/importClear');

/**
 * Imports fleet previous registrations.
 * @param {IVehicle[]} payload - The imported vehicles.
 * @param {IFleetPreviousRegistrationImportParams} params - The import parameters.
 * @returns {Promise<void>} A promise that resolves when the action is complete.
 */
export const fleetPreviousRegistrationImport = createAsyncAction<IVehicle[], IFleetPreviousRegistrationImportParams>(
	'fleet/previousRegistration/import',
	previousRegistrationImportService,
);

/**
 * Modify previous registration
 * @params `IFleetPreviousRegistrationModifyParams` - vehicle params
 * @returns `Promise<IVehicle>` returns the modified vehicle
 */
export const fleetPreviousRegistrationModify = createAsyncAction<IVehicle, IFleetPreviousRegistrationModifyParams>(
	'fleet/previousRegistration/modify',
	previousRegistrationModifyService,
);

/**
 * Register previous registration
 * @params `IFleetPreviousRegistrationRegisterParams` - vehicle params
 * @returns `Promise<IVehicle[]>` returns the registered vehicles
 */
export const fleetPreviousRegistrationRegister = createAsyncAction<
	IVehicle[],
	IFleetPreviousRegistrationRegisterParams
>('fleet/previousRegistration/register', previousRegistrationRegisterService);

/**
 * Delete previous registration
 * @params `IFleetPreviousRegistrationDeleteParams` - delete params
 * @returns `Promise<IVehicle[]>` returns the deletes vehicles
 */
export const fleetPreviousRegistrationDelete = createAsyncAction<IVehicle[], IFleetPreviousRegistrationDeleteParams>(
	'fleet/previousRegistration/delete',
	previousRegistrationDeleteService,
);
