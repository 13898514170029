import { RegularizationEndPoints } from './RegularizationEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IRegularizationSubject } from '../entities/RegularizationSubject';

/**
 * Get all booking regularization subjects
 * @return {BookingRegularizationSubjectPropType} BookingRegularizationSubjectPropType
 */
export const regularizationSubjectGetAllService = (model: IServiceBaseParams) => {
	return Api.get<IRegularizationSubject[], IServiceBaseParams>(RegularizationEndPoints.GET_ALL_SUBJECT, model);
};
