import { VehicleGroupEndPoints } from './VehicleGroupEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IVehicleGroupByProvider } from '../entities/VehicleGroup';

/**
 * Get by provider
 */
export const vehicleGroupGetByProviderService = (model: IServiceBaseParams) =>
	Api.get<IVehicleGroupByProvider[], IServiceBaseParams>(VehicleGroupEndPoints.GET_BY_PROVIDER, model);
