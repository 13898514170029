import { createSyncAction } from '../../../../shared/state/createAction';
import { createAsyncAction } from '../../../../shared/state/createAsyncAction';
import type { IVehicleEngine } from '../../entities/VehicleEngine';
import type { IVehicleEngineDeleteParams } from '../../services/VehicleEngineDeleteService';
import { vehicleEngineServiceDelete } from '../../services/VehicleEngineDeleteService';
import type { IVehicleEngineGetByIdParams } from '../../services/VehicleEngineGetByIdService';
import { vehicleEngineServiceGetById } from '../../services/VehicleEngineGetByIdService';
import type { IVehicleEngineInsertParams } from '../../services/VehicleEngineInsertService';
import { vehicleEngineServiceInsert } from '../../services/VehicleEngineInsertService';
import type { IVehicleEngineModifyParams } from '../../services/VehicleEngineModifyService';
import { vehicleEngineServiceModify } from '../../services/VehicleEngineModifyService';
import { vehicleEngineServiceSearch } from '../../services/VehicleEngineSearchService';
import type { IVehicleEngineSearchParams } from '../../services/VehicleEngineSearchService';

export const vehicleEngineSearch = createAsyncAction<IVehicleEngine[], IVehicleEngineSearchParams>(
	'vehicleEngine/search',
	vehicleEngineServiceSearch,
);
export const vehicleEngineInsert = createAsyncAction<IVehicleEngine, IVehicleEngineInsertParams>(
	'vehicleEngine/insert',
	vehicleEngineServiceInsert,
);
export const vehicleEngineModify = createAsyncAction<IVehicleEngine, IVehicleEngineModifyParams>(
	'vehicleEngine/modify',
	vehicleEngineServiceModify,
);
export const vehicleEngineDelete = createAsyncAction<IVehicleEngine, IVehicleEngineDeleteParams>(
	'vehicleEngine/delete',
	vehicleEngineServiceDelete,
);
export const vehicleEngineClear = createSyncAction('vehicleEngine/clear');

export const vehicleEngineGetById = createAsyncAction<IVehicleEngine, IVehicleEngineGetByIdParams>(
	'vehicleEngine/getById',
	vehicleEngineServiceGetById,
);
