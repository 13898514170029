import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

import { FleetTransferEndPoints } from './FleetTransferEndpoints';
import type { IFleetTransfer } from '../entities/FleetTransfer';
import type { ITransferSave } from '../entities/TransferSave';

export interface IFleetTransferModifyParams extends IServiceBaseParams {
	transfers: Required<ITransferSave>[];
}

export const fleetTransferModifyService = (model: IFleetTransferModifyParams) => {
	return Api.post<IFleetTransfer[], IFleetTransferModifyParams>(FleetTransferEndPoints.MODIFY, model);
};
