import type { IBooking } from '../../models/entities/Booking';
import type {
	IBookingDropOffEndParams,
	IBookingDropOffGetPendingByPlateNumberAndBranchCodeParams,
	IBookingDropOffInitParams,
	IBookingDropOffSendInvoiceParams,
	IBookingDropOffStartParams,
} from '../../models/serviceParams/BookingDropOffParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { BookingEndPoints } from '../endPoints/BookingEndPoints';

/**
 * @deprecated Use `bookingDropOffStartService` from `modules/booking/dropOff/services`
 * Init Drop Off booking
 *  {Object} booking Data register booking
 * @returns {Promise} `BookingPropType` Use await for resolve
 */
export const bookingDropOffServiceStart = (model: IBookingDropOffStartParams): Promise<ServiceResponse<IBooking>> => {
	return Api.post<IBooking, IBookingDropOffStartParams>(BookingEndPoints.DROPOFF_START, model);
};

/**
 * @deprecated Use `bookingDropOffSendInvoiceService` from `modules/booking/dropOff/services`
 * Send drop off invoice email by booking number
 *  {string} bookingNumber booking number
 * @return {Promise} `BookingPropType` Use await for resolve
 */
export const bookingDropOffServiceSendInvoice = (
	model: IBookingDropOffSendInvoiceParams,
): Promise<ServiceResponse<IBooking>> => {
	return Api.post<IBooking, IBookingDropOffSendInvoiceParams>(BookingEndPoints.DROPOFF_SEND_INVOICE, model);
};

/**
 * @deprecated Use `bookingDropOffGetPendingByPlateNumberAndBranchCodeService` from `modules/booking/dropOff/services`
 * Get list pendind drop off by plate and branch code
 *  {string} plateNumber Plate number
 *  {string} branchCode Branch code
 * @return {Promise} `BookingPropType` Use await for resolve
 */
export const bookingDropOffServiceGetPendingsByPlateNumberAndBranchCode = (
	model: IBookingDropOffGetPendingByPlateNumberAndBranchCodeParams,
): Promise<ServiceResponse<IBooking[]>> => {
	return Api.get<IBooking[], IBookingDropOffGetPendingByPlateNumberAndBranchCodeParams>(
		BookingEndPoints.DROPOFF_GET_PENDING_BY_PLATE_AND_BRANCH,
		model,
	);
};

/**
 * @deprecated Use `bookingDropOffInitService` from `modules/booking/dropOff/services`
 * Inicia el proceso de dropOff, lo usan en navision para borrar datos temporales.
 *  {Number} bookingNumber Booking number
 */
export const bookingDropOffServiceInit = (model: IBookingDropOffInitParams): Promise<ServiceResponse<IBooking>> => {
	return Api.post<IBooking, IBookingDropOffInitParams>(BookingEndPoints.DROPOFF_INIT, model);
};

/**
 * @deprecated Use `bookingDropOffEndService` from `modules/booking/dropOff/services`
 * End drop off
 *  {Object} booking Data register booking
 * @return {Promise} `BookingPropType` Use await for resolve
 */
export const bookingDropOffServiceEnd = (model: IBookingDropOffEndParams): Promise<ServiceResponse<IBooking>> => {
	return Api.post<IBooking, IBookingDropOffEndParams>(BookingEndPoints.DROPOFF_END, model);
};
