import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

import { WorkshopAssignmentEndPoints } from './WorkshopAssignmentEndPoints';
import type { IWorkshopAssignment } from '../entities/WorkshopAssignment';

export interface IWorkshopAssignmentModifyParams extends IWorkshopAssignment, IServiceBaseParams {}

/**
 * Modify a workshop assignment
 * @param model - workshop assignment to modify
 * @returns - modified workshop assignment
 */
export const workshopAssignmentModifyService = (model: IWorkshopAssignmentModifyParams) => {
	return Api.post<IWorkshopAssignment, IWorkshopAssignmentModifyParams>(WorkshopAssignmentEndPoints.MODIFY, model);
};
