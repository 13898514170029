import { FleetMovementRestrictionEndPoints } from './MovementRestrictionEndpoints';
import { Api } from '../../../../modules/shared/api';
import type { IServiceBaseParams } from '../../../../modules/shared/types/ServiceBaseParams';
import type { IVehicle } from '../../../../modules/vehicle/entities/Vehicle';

export interface IFleetMovementRestrictionRemoveParams extends IServiceBaseParams {
	plateNumbers: string[];
}

export const fleetMovementRestrictionRemoveService = (model: IFleetMovementRestrictionRemoveParams) => {
	return Api.post<IVehicle[], IFleetMovementRestrictionRemoveParams>(FleetMovementRestrictionEndPoints.REMOVE, model);
};
