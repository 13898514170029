import { createSyncAction } from '../../../../../modules/shared/state/createAction';
import { createAsyncAction } from '../../../../../modules/shared/state/createAsyncAction';
import type { IFineTransmitter } from '../../entities/FineTransmitter';
import type { IFineTransmitterInsertParams } from '../../services/FineTransmitterInsertService';
import { fineTransmitterInsertService } from '../../services/FineTransmitterInsertService';
import type { IFineTransmitterModifyParams } from '../../services/FineTransmitterModifyService';
import { fineTransmitterModifyService } from '../../services/FineTransmitterModifyService';
import type { IFineTransmitterSearchParams } from '../../services/FineTransmitterSearchService';
import { fineTransmitterSearchService } from '../../services/FineTransmitterSearchService';

/**
 * Clears the fine reason data from the state.
 */
export const fleetFineTransmitterClear = createSyncAction('fleet/fineTransmitterClear');

/**
 * Searches for fine transmitter based on search parameters.
 * @param {IFineTransmitter[]} payload `IFineTransmitter[]` The payload for the action.
 * @param {IFineTransmitterSearchParams} IFineTransmitterSearchParams The parameters for retrieving fine transmitter.
 * @returns {Promise<void>} `Promise<void>` - A promise that resolves to an array of fine transmitter.
 */
export const fleetFineTransmitterSearch = createAsyncAction<IFineTransmitter[], IFineTransmitterSearchParams>(
	'fleet/fineTransmitter/search',
	fineTransmitterSearchService,
);

/**
 * Inserts a new fine reason into the fleet.
 * @param {IFineTransmitter} payload `IFineTransmitter` The fine reason to be inserted.
 * @param {IFineTransmitterInsertParams} IFineTransmitterInsertParams The parameters for inserting a new fine reason.
 * @returns {Promise<void>} `Promise<void>` - A promise that resolves when the fine reason has been inserted.
 */
export const fleetFineTransmitterInsert = createAsyncAction<IFineTransmitter, IFineTransmitterInsertParams>(
	'fleet/fineTransmitter/insert',
	fineTransmitterInsertService,
);

/**
 * Modifies an existing fine reason in the fleet.
 * @param {IFineTransmitter} payload `IFineTransmitter` The fine reason to be modified.
 * @param {IFineTransmitterModifyParams} IFineTransmitterModifyParams The parameters for modifying an existing fine reason.
 * @returns {Promise<void>} `Promise<void>` - A promise that resolves when the fine reason has been modified.
 */
export const fleetFineTransmitterModify = createAsyncAction<IFineTransmitter, IFineTransmitterModifyParams>(
	'fleet/fineTransmitter/modify',
	fineTransmitterModifyService,
);
