import * as React from 'react';

import { LuX } from 'react-icons/lu';
import type { ClearIndicatorProps } from 'react-select';
import { components } from 'react-select';

export const ClearIndicator = (props: ClearIndicatorProps) => {
	return (
		<components.ClearIndicator {...props}>
			<LuX className="tw-w-4 tw-h-4" />
		</components.ClearIndicator>
	);
};
