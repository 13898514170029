import type { IBooking } from '@crac/core/models/entities/Booking';
import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaRoadAlt } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { bookingDetailLoader } from '~/features/booking/shared/utils/bookingDetailLoader';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const Tolls = lazyWithRetry(() => import('./pages/Tolls'));

export const tollsRoutes: { [key: number]: IRouteConfigType } = {};

tollsRoutes[routesIdMap.BookingsDetailTolls] = {
	component: Tolls,
	exact: true,
	id: routesIdMap.BookingsDetailTolls,
	name: 'Tolls',
	path: '/bookings/detail/:id/tolls',
	permissions: [PermissionsType.BookingDetail],
	loader: ({ params }): Promise<IBooking | null | undefined> => {
		return bookingDetailLoader(params?.id || '');
	},
	icon: FaRoadAlt,
};
