import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaRegCopyAlt } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const Documentation = lazyWithRetry(() => import('~/features/fleet/documentation/pages/Documentation'));

export const documentationRoutes: { [key: number]: IRouteConfigType } = {};

documentationRoutes[routesIdMap.FleetDocumentation] = {
	component: Documentation,
	exact: true,
	id: routesIdMap.FleetDocumentation,
	name: 'Vehicle documents',
	path: '/fleet/documents',
	permissions: [PermissionsType.Fleet],
	icon: FaRegCopyAlt,
	menuText: 'Documents',
};
