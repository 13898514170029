import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { RevisionCostEndPoints } from './RevisionCostEndpoints';
import type { IRevisionCost } from '../entities/RevisionCost';

export interface IRevisionCostSaveParams extends IServiceBaseParams {
	revisionCosts: {
		plateNumber: string;
		budgetId: number;
		budgetLineId: number;
		revision: { id?: number; code?: string };
	}[];
}

export const revisionCostSaveService = (model: IRevisionCostSaveParams): Promise<ServiceResponse<IRevisionCost[]>> => {
	return Api.post<IRevisionCost[], IRevisionCostSaveParams>(RevisionCostEndPoints.SAVE, model);
};
