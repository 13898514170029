import { createSlice } from '@reduxjs/toolkit';

import {
	revisionCostClear,
	revisionCostSave,
	revisionCostSearch,
} from '@crac/core/modules/workshop/revisionCost/state/actions/WorkshopRevisionCostActions';
import type { IRevisionCostReducerState } from '@crac/core/modules/workshop/revisionCost/state/stateType/RevisionCostStateType';

const initialState: IRevisionCostReducerState = {
	revisionCosts: [],
	revisionCostSaveRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	revisionCostSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const revisionCostSlice = createSlice({
	name: 'revisionCostSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// SEARCH
		builder
			.addCase(revisionCostSearch.pending, (state) => {
				return {
					...state,
					revisionCostSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(revisionCostSearch.fulfilled, (state, action) => {
				return {
					...state,
					revisionCostSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					revisionCosts: action.payload,
				};
			})
			.addCase(revisionCostSearch.rejected, (state, action) => {
				return {
					...state,
					revisionCostSearchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// SAVE
		builder
			.addCase(revisionCostSave.pending, (state) => {
				return {
					...state,
					revisionCostSaveRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(revisionCostSave.fulfilled, (state) => {
				return {
					...state,
					revisionCostSaveRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(revisionCostSave.rejected, (state, action) => {
				return {
					...state,
					revisionCostSaveRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CLEAR
		builder.addCase(revisionCostClear, (state) => {
			return {
				...state,
				revisionCosts: [],
			};
		});
	},
});

export const revisionCostReducer = revisionCostSlice.reducer;
