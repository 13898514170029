import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaPencil, FaPlus, FaRegBuildingAlt } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const FineTransmitterSearch = lazyWithRetry(() => import('./pages/FineTransmitterSearch'));
const FineTransmitterNew = lazyWithRetry(() => import('./pages/FineTransmitterNew'));
const FineTransmitterModify = lazyWithRetry(() => import('./pages/FineTransmitterModify'));

export const fineTransmitterRoutes: { [key: number]: IRouteConfigType } = {};

// TRANSMITTER
fineTransmitterRoutes[routesIdMap.FleetFineTransmitter] = {
	component: FineTransmitterSearch,
	exact: true,
	id: routesIdMap.FleetFineTransmitter,
	name: 'Fine transmitter',
	menuText: 'Transmitters',
	path: '/fleet/fine-transmitter',
	permissions: [PermissionsType.FineTransmitter],
	childrens: [routesIdMap.FleetFineTransmitterNew, routesIdMap.FleetFineTransmitterModify],
	icon: FaRegBuildingAlt,
};

fineTransmitterRoutes[routesIdMap.FleetFineTransmitterNew] = {
	component: FineTransmitterNew,
	exact: true,
	id: routesIdMap.FleetFineTransmitterNew,
	name: 'New fine transmitter',
	path: '/fleet/fine-transmitter/new',
	permissions: [PermissionsType.FineTransmitter],
	isChild: true,
	icon: FaPlus,
	menuText: 'New fine transmitter',
};

fineTransmitterRoutes[routesIdMap.FleetFineTransmitterModify] = {
	component: FineTransmitterModify,
	exact: true,
	id: routesIdMap.FleetFineTransmitterModify,
	name: 'Modify fine transmitter',
	path: '/fleet/fine-transmitter/modify/:code',
	permissions: [PermissionsType.FineTransmitter],
	isChild: true,
	icon: FaPencil,
};
