import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

import { InsuranceRateEndPoints } from './InsuranceRateEndpoints';
import type { IInsuranceRate } from '../entities/InsuranceRate';

export interface IInsuranceRateDeactivateParams extends IServiceBaseParams {
	provider: string;
	id: number;
}

/**
 * Deactivate insurance rate
 * @param model - IInsuranceRateDeactivateParams
 * @returns IInsuranceRate
 */
export const insuranceRateDeactivateService = (model: IInsuranceRateDeactivateParams) => {
	return Api.post<IInsuranceRate, IInsuranceRateDeactivateParams>(InsuranceRateEndPoints.DEACTIVATE, model);
};
