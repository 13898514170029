import * as React from 'react';

import * as DialogPrimitive from '@radix-ui/react-dialog';

import { cn } from '../../lib/utils/tailwindClass';

export const DialogTitle = React.forwardRef<
	React.ElementRef<typeof DialogPrimitive.Title>,
	React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
	<DialogPrimitive.Title className={cn('dialog-title', className)} ref={ref} {...props} />
));

DialogTitle.displayName = DialogPrimitive.Title.displayName;
