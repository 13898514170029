import { createSyncAction } from '@crac/core/modules/shared/state/createAction';
import { createAsyncAction } from '@crac/core/modules/shared/state/createAsyncAction';

import type { IRevisionLine } from '../../entities/RevisionLine';
import type { IRevisionLineGetByBudgetParams } from '../../services/RevisionLineGetByBudgetService';
import { revisionLineGetByBudgetService } from '../../services/RevisionLineGetByBudgetService';

export const revisionLineGetByBudget = createAsyncAction<IRevisionLine[], IRevisionLineGetByBudgetParams>(
	'workshop/revisionLine/getByBudget',
	revisionLineGetByBudgetService,
);

export const revisionLineClear = createSyncAction('workshop/revisionLine/clear');
