import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { IVehicle } from '@crac/core/modules/vehicle/entities/Vehicle';
import type { MovementRestrictionType } from '@crac/core/modules/vehicle/types/MovementRestrictionType';

import { FleetMovementRestrictionEndPoints } from './MovementRestrictionEndpoints';

export interface IFleetMovementRestriction {
	plateNumber: string;
	movementRestriction: MovementRestrictionType;
}

export interface IFleetMovementRestrictionUpdateParams extends IServiceBaseParams {
	restrictionMovements: IFleetMovementRestriction[];
}

export const fleetMovementRestrictionUpdateService = (model: IFleetMovementRestrictionUpdateParams) => {
	return Api.post<IVehicle[], IFleetMovementRestrictionUpdateParams>(FleetMovementRestrictionEndPoints.UPDATE, model);
};
