import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { DamageRepairCostEndPoints } from './DamageRepairCostEndPoints';
import type { RepairCostType } from '../../../../models/types/RepairCostType';
import type { IDamageRepairCost } from '../entities/DamageRepairCost';

export interface IDamageRepairCostInsertParams extends IServiceBaseParams {
	damageRepairCosts: {
		damagePlateNumber: string;
		damageDocumentNumber?: string;
		damageLineNumber: number;
		repairCostType: RepairCostType;
		repairCostId: number;
		repairCostLine: number;
	}[];
}

export const damageRepairCostInsertService = (
	model: IDamageRepairCostInsertParams,
): Promise<ServiceResponse<IDamageRepairCost>> => {
	return Api.post<IDamageRepairCost, IDamageRepairCostInsertParams>(DamageRepairCostEndPoints.SAVE, model);
};
