import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaRegFileAlt } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const Availability = lazyWithRetry(() => import('./pages/Availability'));

export const availabilityRoutes: { [key: number]: IRouteConfigType } = {};

availabilityRoutes[routesIdMap.BookingsNewAvailability] = {
	component: Availability,
	exact: true,
	id: routesIdMap.BookingsNewAvailability,
	name: 'Booking availability',
	path: '/booking/new/availability',
	permissions: [PermissionsType.BookingNew],
	icon: FaRegFileAlt,
};
