import { RegularizationEndPoints } from './RegularizationEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IRegularization } from '../entities/Regularization';

export interface IRegularizationGetByCodeParams extends IServiceBaseParams {
	code: string;
}

/**
 * Get booking regularization by code
 *  {string} code Booking regularization code
 * @return {IRegularization} IRegularization
 */
export const regularizationGetByCodeService = (params: IRegularizationGetByCodeParams) => {
	return Api.get<IRegularization, IRegularizationGetByCodeParams>(RegularizationEndPoints.GET_BY_CODE, params);
};
