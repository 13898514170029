import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaCheckAlt } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const Revision = lazyWithRetry(() => import('./pages/Revision'));

export const revisionRoutes: { [key: number]: IRouteConfigType } = {};

revisionRoutes[routesIdMap.VehiclesRevision] = {
	component: Revision,
	exact: true,
	id: routesIdMap.VehiclesRevision,
	name: 'Vehicle revision',
	path: '/vehicle/revision',
	permissions: [PermissionsType.VehicleRevision],
	icon: FaCheckAlt,
	menuText: 'Revision',
};
