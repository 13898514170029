import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaCircleInfo, FaComments } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const Detail = lazyWithRetry(() => import('./pages/Detail'));
const Comments = lazyWithRetry(() => import('./pages/Comments'));

export const detailRoutes: { [key: number]: IRouteConfigType } = {};

detailRoutes[routesIdMap.CompaniesDetail] = {
	component: Detail,
	exact: true,
	id: routesIdMap.CompaniesDetail,
	name: 'Detail',
	path: '/companies/detail/:id',
	permissions: [PermissionsType.CompanyDetail],
	icon: FaCircleInfo,
};

detailRoutes[routesIdMap.CompaniesDetailComments] = {
	component: Comments,
	exact: true,
	id: routesIdMap.CompaniesDetailComments,
	name: 'Comments',
	path: '/companies/detail/:id/comments',
	permissions: [PermissionsType.CompanyDetail],
	icon: FaComments,
};
