import { PreviousRegistrationEndPoints } from './PreviousRegistrationEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IVehicle } from '../../../vehicle/entities/Vehicle';

export interface IFleetPreviousRegistrationRegisterParams extends IServiceBaseParams {
	provider: string;
	previsionCode: string;
	plateNumbers: string[];
}

/**
 * Register previous registration
 * @param model - IFleetPreviousRegistrationRegisterParams
 * @returns - IVehicle[]
 */
export const previousRegistrationRegisterService = (model: IFleetPreviousRegistrationRegisterParams) => {
	return Api.post<IVehicle[], IFleetPreviousRegistrationRegisterParams>(
		PreviousRegistrationEndPoints.REGISTER,
		model,
	);
};
