import { VehicleModifyEndPoints } from './VehicleModifyEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../../shared/types/ServiceResponse';
import type { IVehicle } from '../../../vehicle/entities/Vehicle';

export interface IVehicleChangeSaleAmountParams extends IServiceBaseParams {
	saleAmount: number;
	plateNumbers: string[];
}

export const vehicleModifyChangeSaleAmountService = (
	model: IVehicleChangeSaleAmountParams,
): Promise<ServiceResponse<IVehicle[]>> => {
	return Api.post<IVehicle[], IVehicleChangeSaleAmountParams>(VehicleModifyEndPoints.CHANGE_SALE_PRICE, model);
};
