import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaPencil, FaPlus, FaTagsAlt } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

import { getPricingPermission } from '../utils/helpers';

const DiscountCoupon = lazyWithRetry(() => import('./pages/DiscountCoupon'));
const DiscountCouponNew = lazyWithRetry(() => import('./pages/DiscountCouponNew'));
const DiscountCouponEdit = lazyWithRetry(() => import('./pages/DiscountCouponEdit'));

export const discountCouponRoutes: { [key: number]: IRouteConfigType } = {};

// DISCOUNT COUPON
discountCouponRoutes[routesIdMap.DiscountCoupon] = {
	component: DiscountCoupon,
	exact: true,
	id: routesIdMap.DiscountCoupon,
	name: 'Discount coupons',
	menuText: 'Discount coupons',
	path: '/pricing/discount-coupon',
	permissions: getPricingPermission([PermissionsType.PricingDiscountCoupon]),
	childrens: [routesIdMap.DiscountCouponNew, routesIdMap.DiscountCouponModify],
	icon: FaTagsAlt,
};

discountCouponRoutes[routesIdMap.DiscountCouponNew] = {
	component: DiscountCouponNew,
	exact: true,
	isChild: true,
	id: routesIdMap.DiscountCouponNew,
	name: 'New discount coupon',
	path: '/pricing/discount-coupon/new',
	permissions: getPricingPermission([PermissionsType.PricingDiscountCoupon]),
	menuText: 'New discount coupon',
	icon: FaPlus,
};

discountCouponRoutes[routesIdMap.DiscountCouponModify] = {
	component: DiscountCouponEdit,
	exact: true,
	isChild: true,
	id: routesIdMap.DiscountCouponModify,
	name: 'Discount coupon modify',
	path: '/pricing/discount-coupon/modify/:id',
	permissions: getPricingPermission([PermissionsType.PricingDiscountCoupon]),
	icon: FaPencil,
};
