import { createSlice } from '@reduxjs/toolkit';

import type { IVehicleColor } from '@crac/core/modules/fleet/vehicleColor/entities/VehicleColor';
import {
	fleetVehicleColorClear,
	fleetVehicleColorDelete,
	fleetVehicleColorInsert,
	fleetVehicleColorModify,
	fleetVehicleColorSearch,
} from '@crac/core/modules/fleet/vehicleColor/state/actions/FleetVehicleColorActions';
import type { IFleetVehicleColorReducerState } from '@crac/core/modules/fleet/vehicleColor/state/stateType/FleetVehicleColorStateType';

const initialState: IFleetVehicleColorReducerState = {
	fleetVehicleColorInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetVehicleColors: [],
	fleetVehicleColorSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetVehicleColorModifyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetVehicleColorDeleteRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const VehicleColorSlice = createSlice({
	name: 'FleetVehicleColor',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// SEARCH
		builder
			.addCase(fleetVehicleColorSearch.pending, (state) => {
				return {
					...state,
					fleetVehicleColorSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetVehicleColorSearch.fulfilled, (state, action) => {
				return {
					...state,
					fleetVehicleColorSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					fleetVehicleColors: action.payload,
				};
			})
			.addCase(fleetVehicleColorSearch.rejected, (state, action) => {
				return {
					...state,
					fleetVehicleColorSearchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CLEAR SEARCH
		builder.addCase(fleetVehicleColorClear, (state) => {
			return {
				...state,
				fleetVehicleColors: [],
			};
		});
		// INSERT
		builder
			.addCase(fleetVehicleColorInsert.pending, (state) => {
				return {
					...state,
					fleetVehicleColorInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetVehicleColorInsert.fulfilled, (state) => {
				return {
					...state,
					fleetVehicleColorInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetVehicleColorInsert.rejected, (state, action) => {
				return {
					...state,
					fleetVehicleColorInsertRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// MODIFY
		builder
			.addCase(fleetVehicleColorModify.pending, (state) => {
				return {
					...state,
					fleetVehicleColorModifyRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetVehicleColorModify.fulfilled, (state, { payload }) => {
				const fleetVehicleColors = state.fleetVehicleColors.map((item) =>
					payload.code === item.code ? payload : item,
				);
				return {
					...state,
					fleetVehicleColors,
					fleetVehicleColorModifyRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetVehicleColorModify.rejected, (state, action) => {
				return {
					...state,
					fleetVehicleColorModifyRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// DELETE
		builder
			.addCase(fleetVehicleColorDelete.pending, (state) => {
				return {
					...state,
					fleetVehicleColorDeleteRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetVehicleColorDelete.fulfilled, (state, action) => {
				const { code } = action.payload as IVehicleColor;
				const fleetVehicleColors = state.fleetVehicleColors?.filter(
					(vehicleColor) => vehicleColor.code !== code,
				);
				return {
					...state,
					fleetVehicleColors,
					fleetVehicleColorDeleteRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetVehicleColorDelete.rejected, (state, action) => {
				return {
					...state,
					fleetVehicleColorDeleteRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const vehicleColorReducer = VehicleColorSlice.reducer;
