import { VehicleModifyEndPoints } from './VehicleModifyEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../../shared/types/ServiceResponse';
import type { IVehicle } from '../../../vehicle/entities/Vehicle';

export interface IVehicleChangeDeFleetDateParams extends IServiceBaseParams {
	plateNumbers: string[];
	expectedDefleetDate: string;
}

export const vehicleModifyChangeDeFleetDateService = (
	model: IVehicleChangeDeFleetDateParams,
): Promise<ServiceResponse<IVehicle[]>> => {
	return Api.post<IVehicle[], IVehicleChangeDeFleetDateParams>(VehicleModifyEndPoints.CHANGE_DEFLEET_DATE, model);
};
