import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaFileAlt } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const Expedients = lazyWithRetry(() => import('./pages/Expedients'));

export const expedientsRoutes: { [key: number]: IRouteConfigType } = {};

expedientsRoutes[routesIdMap.VehiclesDetailExpedients] = {
	component: Expedients,
	exact: true,
	id: routesIdMap.VehiclesDetailExpedients,
	name: 'Expedients',
	path: '/vehicles/detail/:id/expedients',
	permissions: [PermissionsType.VehicleDetail],
	icon: FaFileAlt,
};
