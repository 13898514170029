/**
 * @deprecated - use `FineReasonEndPoints` from `packages\core\src\modules\fleet\fineReason\services\FineReasonEndpoints.ts`
 */
export enum FineReasonEndPoints {
	/**
	 * FineReason search
	 * @host `/finereason/search/`
	 */
	SEARCH = '/finereason/search/',

	/**
	 * FineReason insert
	 * @host `/finereason/insert/`
	 */
	INSERT = '/finereason/insert/',

	/**
	 * FineReason modify
	 * @host `/finereason/modify/`
	 */
	MODIFY = '/finereason/modify/',

	/**
	 * FineReason get all
	 * @host `/finereason/getall/`
	 */
	GET_ALL = '/finereason/getall/',

	/**
	 * FineReason get by code
	 * @host `/finereason/getbycode/`
	 */
	GET_BY_CODE = '/finereason/getbycode/',
}
