import { VehicleModelEndPoints } from './FleetVehicleModelEndpoints';
import { API_ENDPOINT } from '../../../shared/api/ApiEndPoint';
import { getToken } from '../../../shared/api/helpers';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';

export interface IFleetVehicleModelExportParams extends IServiceBaseParams {
	provider: string;
}

export const fleetVehicleModelExportService = async (model: IFleetVehicleModelExportParams) => {
	const { provider } = model;
	const token = model.token ?? getToken();
	const headers = new Headers();
	headers.append('Content-Language', 'en-GB');
	headers.append('Authorization', `Bearer ${token}`);

	try {
		const request = await fetch(`${API_ENDPOINT}${VehicleModelEndPoints.EXPORT}?provider=${provider}`, { headers });

		if (!request.ok) {
			const jsonResponse = await request.json();
			return {
				ok: false,
				errors: [{ message: jsonResponse.message as string }],
			};
		}

		const response = await request.blob();

		const link = document.createElement('a');

		link.href = window.URL.createObjectURL(response);
		link.download = `${provider.replaceAll(' ', '_')}_models`;

		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);

		return { ok: true, errors: [] };
	} catch (error) {
		let msg = 'Document cannot be downloaded';
		if (error instanceof Error) {
			const { message } = error;
			msg = message;
		}

		return {
			ok: false,
			errors: [{ message: msg }],
		};
	}
};
