import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

import { VehicleBrandEndPoints } from './FleetVehicleBrandEndpoints';
import type { IVehicleBrand } from '../entities/VehicleBrand';

export interface IFleetVehicleBrandInsertParams extends IVehicleBrand, IServiceBaseParams {}

export const fleetVehicleBrandInsertService = (model: IFleetVehicleBrandInsertParams) => {
	return Api.post<IVehicleBrand, IFleetVehicleBrandInsertParams>(VehicleBrandEndPoints.INSERT, model);
};
