import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaCircleStop, FaPencil, FaPlus } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

import { getPricingPermission } from '../utils/helpers';

const StopSales = lazyWithRetry(() => import('./pages/StopSales'));
const StopSalesNew = lazyWithRetry(() => import('./pages/StopSalesNew'));
const StopSalesModify = lazyWithRetry(() => import('./pages/StopSalesModify'));

export const stopSalesRoutes: { [key: number]: IRouteConfigType } = {};

stopSalesRoutes[routesIdMap.StopSales] = {
	component: StopSales,
	exact: true,
	id: routesIdMap.StopSales,
	name: 'Stop Sales',
	path: '/pricing/stop-sales/',
	permissions: getPricingPermission([PermissionsType.PricingStopSale]),
	childrens: [routesIdMap.StopSalesNew, routesIdMap.StopSalesNewFrom, routesIdMap.StopSalesModify],
	icon: FaCircleStop,
	menuText: 'Stop sales',
};

stopSalesRoutes[routesIdMap.StopSalesNew] = {
	component: StopSalesNew,
	exact: true,
	isChild: true,
	id: routesIdMap.StopSalesNew,
	name: 'New stop sale',
	path: '/pricing/stop-sales/new',
	permissions: getPricingPermission([PermissionsType.PricingStopSale]),
	icon: FaPlus,
	menuText: 'New stop sale',
};

stopSalesRoutes[routesIdMap.StopSalesNewFrom] = {
	component: StopSalesNew,
	exact: true,
	isChild: true,
	id: routesIdMap.StopSalesNewFrom,
	name: 'Stop sales - New from',
	path: '/pricing/stop-sales/new-from/:id',
	permissions: getPricingPermission([PermissionsType.PricingStopSale]),
	icon: FaPlus,
};

stopSalesRoutes[routesIdMap.StopSalesModify] = {
	component: StopSalesModify,
	exact: true,
	isChild: true,
	id: routesIdMap.StopSalesModify,
	name: 'Modify stop sale',
	path: '/pricing/stop-sales/modify',
	permissions: getPricingPermission([PermissionsType.PricingStopSale]),
	icon: FaPencil,
};
