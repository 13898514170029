import { createSlice } from '@reduxjs/toolkit';

import { damageRepairCostInsert } from '@crac/core/modules/workshop/damageRepair/state/actions/WorkshopDamageRepairCostActions';
import type { IDamageRepairCostReducerState } from '@crac/core/modules/workshop/damageRepair/state/stateType/DamageRepairCostStateType';
const initialState: IDamageRepairCostReducerState = {
	damageRepairCostInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const damageRepairSlice = createSlice({
	name: 'DamageRepairSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(damageRepairCostInsert.pending, (state) => {
				return {
					...state,
					damageRepairCostInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(damageRepairCostInsert.fulfilled, (state) => {
				return {
					...state,
					damageRepairCostInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(damageRepairCostInsert.rejected, (state, action) => {
				return {
					...state,
					damageRepairCostInsertRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const damageRepairReducer = damageRepairSlice.reducer;
