import { createSlice } from '@reduxjs/toolkit';

import {
	fineGetByBooking,
	fineGetById,
	fineGetByPlateNumber,
} from '@crac/core/modules/fleet/fine/state/actions/FineActions';
import type { IFineReducerState } from '@crac/core/modules/fleet/fine/state/stateType/FineStateType';
import { linkGetByFineNumber, linkGetFineNotification } from '@crac/core/redux/actions/LinkActions';

const initialState: IFineReducerState = {
	fine: null,
	fines: [],
	fineLinks: [],
	fineLinksRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fineGetByBookingRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fineGetByIdRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fineGetByPlateNumberRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fineInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fineModifyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fineSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	notificationLinks: [],
	notificationLinksRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const fineSlice = createSlice({
	name: 'FineSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// GET BY ID
		builder
			.addCase(fineGetById.pending, (state) => {
				return {
					...state,
					fine: null,
					fineGetByIdRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fineGetById.fulfilled, (state, action) => {
				return {
					...state,
					fine: action.payload,
					fineGetByIdRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fineGetById.rejected, (state, action) => {
				return {
					...state,
					fine: null,
					fineGetByIdRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// GET BY BOOKING
		builder
			.addCase(fineGetByBooking.pending, (state) => {
				return {
					...state,
					fines: [],
					fineGetByBookingRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fineGetByBooking.fulfilled, (state, action) => {
				return {
					...state,
					fines: action.payload,
					fineGetByBookingRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fineGetByBooking.rejected, (state, action) => {
				return {
					...state,
					fineGetByBookingRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// GET BY FINE NUMBER
		builder
			.addCase(linkGetByFineNumber.pending, (state) => {
				return {
					...state,
					fineLinks: [],
					fineLinksRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(linkGetByFineNumber.fulfilled, (state, action) => {
				return {
					...state,
					fineLinks: action.payload,
					fineLinksRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(linkGetByFineNumber.rejected, (state, action) => {
				return {
					...state,
					fineLinks: [],
					fineLinksRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// GET FINE NOTIFICATION
		builder
			.addCase(linkGetFineNotification.pending, (state) => {
				return {
					...state,
					notificationLinks: [],
					notificationLinksRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(linkGetFineNotification.fulfilled, (state, action) => {
				return {
					...state,
					notificationLinks: action.payload,
					notificationLinksRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(linkGetFineNotification.rejected, (state, action) => {
				return {
					...state,
					notificationLinks: [],
					notificationLinksRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// GET BY PLATENUMBER
		builder
			.addCase(fineGetByPlateNumber.pending, (state) => {
				return {
					...state,
					fineGetByPlateNumberRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fineGetByPlateNumber.fulfilled, (state, action) => {
				return {
					...state,
					fines: action.payload,
					fineGetByPlateNumberRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fineGetByPlateNumber.rejected, (state, action) => {
				return {
					...state,
					fineGetByPlateNumberRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const fineReducer = fineSlice.reducer;
