import { revisionLineServiceGetByBudget } from '../../data/services/RevisionLineService';
import type { IRevisionLine } from '../../models/entities/RevisionLine';
import type { IRevisionLineGetByBudgetParams } from '../../models/serviceParams/RevisionLineParams';
import { createSyncAction } from '../../modules/shared/state/createAction';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * @deprecated - use 'revisionLineGetByBudget' from 'packages\core\src\modules\workshop\revisionCost\state\actions\WorkshopRevisionLineActions.ts' instead.
 */
export const revisionLineGetByBudget = createAsyncAction<IRevisionLine[], IRevisionLineGetByBudgetParams>(
	'workshop/revisionLine/getByBudget',
	revisionLineServiceGetByBudget,
);

/**
 * @deprecated - use 'revisionLineClear' from 'packages\core\src\modules\workshop\revisionCost\state\actions\WorkshopRevisionLineActions.ts' instead.
 */
export const revisionLineClear = createSyncAction('workshop/revisionLine/clear');
