import { createAction } from '@reduxjs/toolkit';

import {
	lockerServiceCancel,
	lockerServiceCellLineGetByLockerCell,
	lockerServiceCellOpenExternal,
	lockerServiceClose,
	lockerServiceGetByBranch,
	lockerServiceGetEmptyByBranch,
	lockerServiceGetLinesByPlateNumber,
	lockerServiceGetPresent,
	lockerServiceGetReturnKeys,
	lockerServiceInsert,
	lockerServiceLoadPresents,
	lockerServiceOpen,
} from '../../data/services/LockerService';
import type { ILockerCell } from '../../models/entities/LockerCell';
import type {
	ILockerCelLineGetByPlateNumberParams,
	ILockerCelLineGetReturnKeysParams,
	ILockerCellCancelParams,
	ILockerCellCloseParams,
	ILockerCellGetByBranchParams,
	ILockerCellGetEmptyByBranchParams,
	ILockerCellGetPresentParams,
	ILockerCellInsertParams,
	ILockerCellLineGetByLockerCellParams,
	ILockerCellOpenExternalParams,
	ILockerCellOpenParams,
} from '../../models/serviceParams/LockersParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';
import type { IServiceBaseParams } from '../../modules/shared/types/ServiceBaseParams';

/**
 * Retrieves locker cells by branch.
 *
 * @param {ILockerCell[]} payload - The locker cells to be retrieved.
 * @param {ILockerCellGetByBranchParams} params - Parameters to identify locker cells in a specific branch.
 * @returns {Promise<void>} - A promise that resolves to an array of locker cells.
 */
export const lockerCellGetByBranch = createAsyncAction<ILockerCell[], ILockerCellGetByBranchParams>(
	'lockerCell/getByBranch',
	lockerServiceGetByBranch,
);

/**
 * Retrieves an empty locker cell by branch.
 *
 * @param {ILockerCell} payload - The empty locker cell to be retrieved.
 * @param {ILockerCellGetEmptyByBranchParams} params - Parameters to identify an empty locker cell in a specific branch.
 * @returns {Promise<void>} - A promise that resolves to an empty locker cell.
 */
export const lockerCellGetEmptyByBranch = createAsyncAction<ILockerCell, ILockerCellGetEmptyByBranchParams>(
	'lockerCell/getEmptyByBranch',
	lockerServiceGetEmptyByBranch,
);

/**
 * Opens a locker cell.
 *
 * @param {ILockerCell} payload - The locker cell to be opened.
 * @param {ILockerCellOpenParams} params - Parameters for opening the locker cell.
 * @returns {Promise<void>} - A promise that resolves after the locker cell is opened.
 */
export const lockerCellOpen = createAsyncAction<ILockerCell, ILockerCellOpenParams>(
	'lockerCell/open',
	lockerServiceOpen,
);

/**
 * Closes a locker cell.
 *
 * @param {ILockerCell} payload - The locker cell to be closed.
 * @param {ILockerCellCloseParams} params - Parameters for closing the locker cell.
 * @returns {Promise<void>} - A promise that resolves after the locker cell is closed.
 */
export const lockerCellClose = createAsyncAction<ILockerCell, ILockerCellCloseParams>(
	'lockerCell/close',
	lockerServiceClose,
);

/**
 * Cancels a locker cell operation.
 *
 * @param {ILockerCell} payload - The locker cell operation to be cancelled.
 * @param {ILockerCellCancelParams} params - Parameters for cancelling the locker cell operation.
 * @returns {Promise<void>} - A promise that resolves after the locker cell operation is cancelled.
 */
export const lockerCellCancel = createAsyncAction<ILockerCell, ILockerCellCancelParams>(
	'lockerCell/cancel',
	lockerServiceCancel,
);

/**
 * Retrieves locker cells for returning keys.
 *
 * @param {ILockerCell[]} payload - The locker cells to be retrieved.
 * @param {ILockerCelLineGetReturnKeysParams} params - Parameters to identify locker cells for returning keys.
 * @returns {Promise<void>} - A promise that resolves to an array of locker cells.
 */
export const lockerCellGetReturnKeys = createAsyncAction<ILockerCell[], ILockerCelLineGetReturnKeysParams>(
	'lockerCell/getReturnKeys',
	lockerServiceGetReturnKeys,
);

/**
 * Retrieves locker cell lines by plate number.
 *
 * @param {ILockerCell[]} payload - The locker cell lines to be retrieved.
 * @param {ILockerCelLineGetByPlateNumberParams} params - Parameters to identify locker cell lines by plate number.
 * @returns {Promise<void>} - A promise that resolves to an array of locker cell lines.
 */
export const lockerCellLinesGetByPlateNumber = createAsyncAction<ILockerCell[], ILockerCelLineGetByPlateNumberParams>(
	'lockerCell/linesGetByPlateNumber',
	lockerServiceGetLinesByPlateNumber,
);

/**
 * Retrieves locker cell lines by locker cell.
 *
 * @param {ILockerCell[]} payload - The locker cell lines to be retrieved.
 * @param {ILockerCellLineGetByLockerCellParams} params - Parameters to identify locker cell lines by locker cell.
 * @returns {Promise<void>} - A promise that resolves to an array of locker cell lines.
 */
export const lockerCellLinesGetByLockerCell = createAsyncAction<ILockerCell[], ILockerCellLineGetByLockerCellParams>(
	'lockerCell/linesGetByLockerCell',
	lockerServiceCellLineGetByLockerCell,
);

/**
 * Updates the status of locker cells.
 *
 * @param {ILockerCell[]} payload - The updated locker cells.
 */
export const lockerStatusUpdate = createAction<ILockerCell[]>('lockerCell/statusUpdate');

/**
 * Updates the list of locker cell lines.
 *
 * @param {ILockerCell[]} payload - The updated list of locker cell lines.
 */
export const lockerCellLinesUpdate = createAction<ILockerCell[]>('lockerCell/linesUpdate');

/**
 * Opens a locker cell externally.
 */
export const lockerCellOpenExternal = createAsyncAction<ILockerCell, ILockerCellOpenExternalParams>(
	'lockerCell/openExternal',
	lockerServiceCellOpenExternal,
);

/**
 * Inserts a locker cell.
 */
export const lockerCellInsert = createAsyncAction<ILockerCell, ILockerCellInsertParams>(
	'lockerCell/insert',
	lockerServiceInsert,
);

/**
 * load presents
 */
export const lockerCellLoadPresents = createAsyncAction<boolean, IServiceBaseParams>(
	'lockerCell/loadPresents',
	lockerServiceLoadPresents,
);

/**
 * get present
 */
export const lockerCellGetPresent = createAsyncAction<ILockerCell, ILockerCellGetPresentParams>(
	'lockerCell/getPresent',
	lockerServiceGetPresent,
);

/*
 * Clear search
 */
export const lockerCellClearSearch = createAction('lockerCell/clearSearch');
