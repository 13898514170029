export enum RegularizationEndPoints {
	/**
	 * Booking regularization get all subject
	 * @host `/bookingRegularizationSubject/getAll/`
	 */
	GET_ALL_SUBJECT = '/bookingRegularizationSubject/getAll/',

	/**
	 * Regularization insert
	 * @host `/bookingRegularization/insert/`
	 */
	INSERT = '/bookingRegularization/insert/',

	/**
	 * Regularization update
	 * @host `/bookingRegularization/update/`
	 */
	UPDATE = '/bookingRegularization/update/',

	/**
	 * @host `/bookingRegularization/getByCode/`
	 */
	GET_BY_CODE = '/bookingRegularization/getByCode/',

	/**
	 * @host `/bookingRegularization/getPendingByUser/`
	 */
	GET_PENDING_BY_USER = '/bookingRegularization/getPendingByUser/',

	/**
	 * @host `/bookingRegularization/getByBooking/`
	 */
	GET_BY_BOOKING = '/bookingRegularization/getByBooking/',
}
