import type { IBooking } from '@crac/core/models/entities/Booking';
import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaPencil } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { bookingDetailLoader } from '~/features/booking/shared/utils/bookingDetailLoader';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const ExtendAvailability = lazyWithRetry(() => import('./pages/ExtendAvailability'));
const ExtendConfirm = lazyWithRetry(() => import('./pages/ExtendConfirm'));

export const extendRoutes: { [key: number]: IRouteConfigType } = {};

extendRoutes[routesIdMap.BookingsDetailExtend] = {
	component: ExtendAvailability,
	exact: true,
	id: routesIdMap.BookingsDetailExtend,
	name: 'Extend',
	path: '/bookings/detail/:id/extend',
	permissions: [PermissionsType.Booking, PermissionsType.BookingCanExtend],
	loader: ({ params }): Promise<IBooking | null | undefined> => {
		return bookingDetailLoader(params?.id || '');
	},
	icon: FaPencil,
};

extendRoutes[routesIdMap.BookingsDetailExtendConfirm] = {
	component: ExtendConfirm,
	exact: true,
	id: routesIdMap.BookingsDetailExtendConfirm,
	name: 'Extend',
	path: '/bookings/detail/:id/extend/confirm',
	permissions: [PermissionsType.Booking, PermissionsType.BookingCanExtend],
	loader: ({ params }): Promise<IBooking | null | undefined> => {
		return bookingDetailLoader(params?.id || '');
	},
	icon: FaPencil,
};
