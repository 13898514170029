'use client';

import * as React from 'react';

import * as SwitchPrimitives from '@radix-ui/react-switch';

import type { SwitchProps } from './types';
import { switchVariants, thumbVariants } from './utils';
import { cn } from '../lib/utils/tailwindClass';

const Switch = React.forwardRef<React.ElementRef<typeof SwitchPrimitives.Root>, SwitchProps>(
	({ className, size, isDisabled, ...props }, ref) => (
		<SwitchPrimitives.Root
			className={cn(switchVariants({ size, className }))}
			disabled={isDisabled}
			{...props}
			ref={ref}
		>
			<SwitchPrimitives.Thumb className={cn(thumbVariants({ size }))} />
		</SwitchPrimitives.Root>
	),
);

Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
