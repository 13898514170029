import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaCalendarDays } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const Dashboard = lazyWithRetry(() => import('./pages/Dashboard'));

export const dashboardRoutes: { [key: number]: IRouteConfigType } = {};

dashboardRoutes[routesIdMap.BookingDashboard] = {
	component: Dashboard,
	exact: true,
	id: routesIdMap.BookingDashboard,
	name: 'Booking dashboard',
	permissions: [PermissionsType.Booking],
	path: '/booking',
	icon: FaCalendarDays,
	menuText: 'Booking',
};
