import { VehicleBrandEndPoints } from './FleetVehicleBrandEndpoints';
import { Api } from '../../../../modules/shared/api';
import type { IServiceBaseParams } from '../../../../modules/shared/types/ServiceBaseParams';
import type { IVehicleBrand } from '../entities/VehicleBrand';

export interface IFleetVehicleBrandDeleteParams extends IServiceBaseParams {
	brand: string;
}

export const fleetVehicleBrandDeleteService = (model: IFleetVehicleBrandDeleteParams) => {
	return Api.post<IVehicleBrand, IFleetVehicleBrandDeleteParams>(VehicleBrandEndPoints.DELETE, model);
};
