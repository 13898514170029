import { createAction } from '@reduxjs/toolkit';

import { createAsyncAction } from '@crac/core/modules/shared/state/createAsyncAction';
import type { IVehicle } from '@crac/core/modules/vehicle/entities/Vehicle';

import type { IFleetTransfer } from '../../entities/FleetTransfer';
import type { IFleetTransferDeactivateParams } from '../../services/FleetTransferDeactivateService';
import { fleetTransferDeactivateService } from '../../services/FleetTransferDeactivateService';
import type { IFleetTransferExportParams } from '../../services/FleetTransferExportService';
import { fleetTransferExportService } from '../../services/FleetTransferExportService';
import type { IFleetTransferInsertParams } from '../../services/FleetTransferInsertService';
import { fleetTransferInsertService } from '../../services/FleetTransferInsertService';
import type { IFleetTransferModifyParams } from '../../services/FleetTransferModifyService';
import { fleetTransferModifyService } from '../../services/FleetTransferModifyService';
import type { IFleetTransferSearchParams } from '../../services/FleetTransferSearchService';
import { fleetTransferSearchService } from '../../services/FleetTransferSearchService';

/**
 * Searches for fleet transfers based on specified parameters.
 *
 * @param {IFleetTransfer[]} payload - The fleet transfers to be returned.
 * @param {IFleetTransferSearchParams} params - Parameters for the search.
 * @returns {Promise<void>} - A promise that resolves to an array of fleet transfers.
 */
export const fleetTransferSearch = createAsyncAction<IFleetTransfer[], IFleetTransferSearchParams>(
	'fleetTransfer/search',
	fleetTransferSearchService,
);

/**
 * Clears the search results for fleet transfers.
 */
export const fleetTransferSearchClear = createAction('fleetTransfer/searchClear');

/**
 * Deactivates specific fleet transfers.
 *
 * @param {IFleetTransfer[]} payload - The fleet transfers to be deactivated.
 * @param {IFleetTransferDeactivateParams} params - Parameters for deactivating the fleet transfers.
 * @returns {Promise<void>} - A promise that resolves after the fleet transfers are deactivated.
 */
export const fleetTransferDeactivate = createAsyncAction<IFleetTransfer[], IFleetTransferDeactivateParams>(
	'fleetTransfer/deactivate',
	fleetTransferDeactivateService,
);

/**
 * Inserts new fleet transfers into the system.
 *
 * @param {IFleetTransfer[]} payload - The fleet transfers to be inserted.
 * @param {IFleetTransferInsertParams} params - Parameters detailing the fleet transfers to be inserted.
 * @returns {Promise<void>} - A promise that resolves after the fleet transfers are successfully inserted.
 */
export const fleetTransferInsert = createAsyncAction<IFleetTransfer[], IFleetTransferInsertParams>(
	'fleetTransfer/insert',
	fleetTransferInsertService,
);

/**
 * Modifies existing fleet transfers.
 *
 * @param {IFleetTransfer[]} payload - The fleet transfers to be modified.
 * @param {IFleetTransferModifyParams} params - Parameters detailing the modifications to be made on the fleet transfers.
 * @returns {Promise<void>} - A promise that resolves after the fleet transfers are successfully modified.
 */
export const fleetTransferModify = createAsyncAction<IFleetTransfer[], IFleetTransferModifyParams>(
	'fleetTransfer/modify',
	fleetTransferModifyService,
);

/**
 * Updates the list of selected vehicles for a new fleet transfer.
 *
 * @param {IVehicle[]} payload - The list of vehicles selected for the new fleet transfer.
 */
export const fleeTransferNewUpdateVehiclesSelected = createAction<IVehicle[]>(
	'fleetTransfer/newUpdateVehiclesSelected',
);

export const fleetTransferExport = createAsyncAction<null, IFleetTransferExportParams>(
	'fleetTransfer/export',
	fleetTransferExportService,
);
