import { PreviousRegistrationEndPoints } from './PreviousRegistrationEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IVehicle } from '../../../vehicle/entities/Vehicle';

export interface IFleetPreviousRegistrationModifyParams extends IServiceBaseParams {
	provider: string;
	frameNumber: string;
	plateNumber?: string;
	color?: string;
	registrationDate?: string | Date;
}

/**
 * Modify previous registration
 * @param model - IFleetPreviousRegistrationModifyParams
 * @returns IVehicle
 */
export const previousRegistrationModifyService = (model: IFleetPreviousRegistrationModifyParams) => {
	return Api.post<IVehicle, IFleetPreviousRegistrationModifyParams>(PreviousRegistrationEndPoints.MODIFY, model);
};
