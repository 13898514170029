import type { IBooking } from '@crac/core/models/entities/Booking';
import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaRegCopyAlt } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { bookingDetailLoader } from '~/features/booking/shared/utils/bookingDetailLoader';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const Documents = lazyWithRetry(() => import('./pages/Documents'));

export const documentsRoutes: { [key: number]: IRouteConfigType } = {};

documentsRoutes[routesIdMap.BookingsDetailDocuments] = {
	component: Documents,
	exact: true,
	id: routesIdMap.BookingsDetailDocuments,
	name: 'Documents',
	path: '/bookings/detail/:id/documents',
	permissions: [PermissionsType.BookingDetail],
	loader: ({ params }): Promise<IBooking | null | undefined> => {
		return bookingDetailLoader(params?.id || '');
	},
	icon: FaRegCopyAlt,
};
