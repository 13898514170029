import { PreviousRegistrationEndPoints } from './PreviousRegistrationEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IVehicle } from '../../../vehicle/entities/Vehicle';

export interface IFleetPreviousRegistrationImportParams extends IServiceBaseParams {
	file: File;
	provider: string;
}

/**
 * Import previous registration
 * @param model - IPreviousRegistrationImportParams
 * @returns - IVehicle[]
 */
export const previousRegistrationImportService = (model: IFleetPreviousRegistrationImportParams) => {
	return Api.post<IVehicle[], IFleetPreviousRegistrationImportParams>(PreviousRegistrationEndPoints.IMPORT, model);
};
