export enum VehicleDetailEndPoints {
	/**
	 * Vehicle update OBE
	 * @host `/vehicle/updateobe/`
	 */
	UPDATE_OBE = '/vehicle/updateobe/',

	/**
	 * Vehicle assign Tag
	 * @host `/vehicle/assignTag/`
	 */
	ASSIGN_TAG = '/vehicle/assignTag/',

	/**
	 * Vehicle block
	 * @host `/vehicle/block`
	 */
	BLOCK_VEHICLE = '/vehicle/block',

	/**
	 * Vehicle get by plate number
	 * @host `/vehicle/getbyplatenumber/`
	 */
	GET_BY_PLATE = '/vehicle/getbyplatenumber/',

	/**
	 * Vehicle Open
	 * @host `/vehicle/unlock`
	 */
	UNLOCK = '/vehicle/unlock',

	/**
	 * Vehicle close
	 * @host `/vehicle/lock`
	 */
	LOCK = '/vehicle/lock',

	/**
	 * Change last revision km
	 * @host `/vehicle/changeLastRevisionKm`
	 */
	CHANGE_LAST_REVISION_KM = '/vehicle/changeLastRevisionKm',

	/**
	 * Update keys location
	 * @host `/vehicle/updateKeysLocation`
	 */
	UPDATE_KEYS_LOCATION = '/vehicle/updateKeysLocation',

	/**
	 * Change end amortization date
	 * @host `/vehicle/changeAmortizationEndDate`
	 */
	CHANGE_END_AMORTIZATION_DATE = '/vehicle/changeAmortizationEndDate',

	/**
	 * Change carrier
	 * @host `/vehicle/changeCarrier`
	 */
	CHANGE_CARRIER = '/vehicle/changeCarrier',
}
