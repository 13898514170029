import { VehicleModifyEndPoints } from './VehicleModifyEndpoints';
import { Api } from '../../../../modules/shared/api';
import type { IServiceBaseParams } from '../../../../modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../../../modules/shared/types/ServiceResponse';
import type { IVehicle } from '../../../../modules/vehicle/entities/Vehicle';

export interface IVehicleChangeInsuranceCompanyParams extends IServiceBaseParams {
	plateNumbers: string[];
	insuranceCompanyCode: string;
}

export const vehicleModifyChangeInsuranceCompanyService = (
	model: IVehicleChangeInsuranceCompanyParams,
): Promise<ServiceResponse<IVehicle[]>> => {
	return Api.post<IVehicle[], IVehicleChangeInsuranceCompanyParams>(
		VehicleModifyEndPoints.CHANGE_INSURANCE_COMPANY,
		model,
	);
};
