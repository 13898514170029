import { RegularizationEndPoints } from './RegularizationEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IRegularization } from '../entities/Regularization';

export interface IRegularizationGetByBookingParams extends IServiceBaseParams {
	bookingNumber: string;
}

/**
 * Get booking regularization by booking
 *  {string} bookingNumber Booking number
 * @return {IRegularization[]} IRegularization
 */
export const regularizationGetByBookingService = (model: IRegularizationGetByBookingParams) => {
	return Api.get<IRegularization[], IRegularizationGetByBookingParams>(RegularizationEndPoints.GET_BY_BOOKING, model);
};
