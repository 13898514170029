import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { FineTransmitterEndPoints } from './FineTransmitterEndpoints';
import type { IFineTransmitter } from '../entities/FineTransmitter';

export interface IFineTransmitterSearchParams extends IServiceBaseParams {
	code?: string;
	country?: string;
	email?: string;
	name?: string;
	searchName?: string;
	telephoneNumber?: string;
}

/**
 * Search a fine transmitter
 * @return {Promise<IFineTransmitter[]>} `IFineTransmitter[]`
 */
export const fineTransmitterSearchService = (
	model: IFineTransmitterSearchParams,
): Promise<ServiceResponse<IFineTransmitter[]>> => {
	return Api.get<IFineTransmitter[], IFineTransmitterSearchParams>(FineTransmitterEndPoints.SEARCH, model);
};
