import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaCarCrash, FaPencil, FaPlus } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const Accident = lazyWithRetry(() => import('./pages/Accident'));
const AccidentNew = lazyWithRetry(() => import('./pages/AccidentNew'));
const AccidentModify = lazyWithRetry(() => import('./pages/AccidentModify'));

export const accidentRoutes: { [key: number]: IRouteConfigType } = {};

accidentRoutes[routesIdMap.WorkshopAccident] = {
	component: Accident,
	exact: true,
	id: routesIdMap.WorkshopAccident,
	name: 'Accident',
	path: '/workshop/accident',
	icon: FaCarCrash,
	permissions: [PermissionsType.AccidentManagement],
	menuText: 'Accident',
	childrens: [routesIdMap.WorkshopAccidentNew],
};

accidentRoutes[routesIdMap.WorkshopAccidentNew] = {
	component: AccidentNew,
	exact: true,
	id: routesIdMap.WorkshopAccidentNew,
	name: 'New accident',
	path: '/workshop/accident/new',
	permissions: [PermissionsType.AccidentManagement],
	menuText: 'New accident',
	icon: FaPlus,
	isChild: true,
};

accidentRoutes[routesIdMap.WorkshopAccidentModify] = {
	component: AccidentModify,
	exact: true,
	id: routesIdMap.WorkshopAccidentModify,
	name: 'Modify accident',
	path: '/workshop/accident/:documentNumber/:plateNumber/:key',
	permissions: [PermissionsType.AccidentManagement],
	icon: FaPencil,
};
