import type { IBooking } from '@crac/core/models/entities/Booking';
import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaCarAlt, FaPlus } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { bookingDetailLoader } from '~/features/booking/shared/utils/bookingDetailLoader';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const Vehicles = lazyWithRetry(() => import('./pages/Vehicles'));
const VehicleChange = lazyWithRetry(() => import('./pages/VehicleChange'));

export const vehiclesRoutes: { [key: number]: IRouteConfigType } = {};

vehiclesRoutes[routesIdMap.BookingsDetailChangeVehicle] = {
	component: Vehicles,
	exact: true,
	id: routesIdMap.BookingsDetailChangeVehicle,
	name: 'Vehicle changes',
	path: '/bookings/detail/:id/vehicle-changes',
	permissions: [PermissionsType.BookingDetail],
	loader: ({ params }): Promise<IBooking | null | undefined> => {
		return bookingDetailLoader(params?.id || '');
	},
	icon: FaCarAlt,
};

vehiclesRoutes[routesIdMap.BookingsDetailChangeVehicleChange] = {
	component: VehicleChange,
	exact: true,
	id: routesIdMap.BookingsDetailChangeVehicleChange,
	name: 'Vehicle changes',
	path: '/bookings/detail/:id/vehicle-changes/add',
	permissions: [PermissionsType.BookingDetail],
	loader: ({ params }): Promise<IBooking | null | undefined> => {
		return bookingDetailLoader(params?.id || '');
	},
	icon: FaPlus,
};
