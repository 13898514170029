import { SaleServiceEndpoints } from './SaleServiceEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ISaleService } from '../entities/SaleService';

export interface ISaleServiceSearchParams extends IServiceBaseParams {
	ids?: number[];
	name?: string;
}

export const saleServiceSearchService = (model: ISaleServiceSearchParams) => {
	return Api.get<ISaleService[], ISaleServiceSearchParams>(SaleServiceEndpoints.SEARCH, model);
};
