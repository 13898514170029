import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import {
	fleetDocumentationAddToInsurance,
	fleetDocumentationClear,
	fleetDocumentationImport,
} from '@crac/core/modules/fleet/documentation/state/actions/FleetDocumentationActions';
import type { IFleetDocumentationReducerState } from '@crac/core/modules/fleet/documentation/state/stateType/FleetDocumentationStateType';
import { linkAddToVehicle } from '@crac/core/redux/actions/LinkActions';
const initialState: IFleetDocumentationReducerState = {
	fleetDocumentationAddToInsuranceRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetDocumentationAddToVehicleDocumentationRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetDocumentationAddToTempDocsRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const documentationSlice = createSlice({
	initialState,
	name: 'FleetDocumentationSlice',
	reducers: {},
	extraReducers: (builder) => {
		/* ADD TO VEHICLE */
		builder
			.addCase(linkAddToVehicle.pending, (state) => {
				return {
					...state,
					fleetDocumentationAddToVehicleDocumentationRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(linkAddToVehicle.rejected, (state, action) => {
				return {
					...state,
					fleetDocumentationAddToVehicleDocumentationRequest: {
						inProgress: false,
						messages: action.payload ?? [],
						ok: false,
					},
				};
			})
			.addCase(linkAddToVehicle.fulfilled, (state) => {
				return {
					...state,
					fleetDocumentationAddToVehicleDocumentationRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			});
		/* CLEAR REQUESTS */
		builder.addCase(fleetDocumentationClear, () => {
			return initialState;
		});
		/* ADD TO INSURANCE */
		builder
			.addMatcher(
				isAnyOf(fleetDocumentationAddToInsurance.pending, fleetDocumentationImport.pending),
				(state) => {
					return {
						...state,
						fleetDocumentationAddToInsuranceRequest: {
							inProgress: true,
							messages: [],
							ok: true,
						},
					};
				},
			)
			.addMatcher(
				isAnyOf(fleetDocumentationAddToInsurance.rejected, fleetDocumentationImport.rejected),
				(state, action) => {
					return {
						...state,
						fleetDocumentationAddToInsuranceRequest: {
							inProgress: false,
							messages: action.payload ?? [],
							ok: false,
						},
					};
				},
			)
			.addMatcher(
				isAnyOf(fleetDocumentationAddToInsurance.fulfilled, fleetDocumentationImport.fulfilled),
				(state) => {
					return {
						...state,
						fleetDocumentationAddToInsuranceRequest: {
							inProgress: false,
							messages: [],
							ok: true,
						},
					};
				},
			);
	},
});

export const documentationReducer = documentationSlice.reducer;
