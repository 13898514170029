import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaMagnifyingGlass } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const CustomerSearch = lazyWithRetry(() => import('~/features/customer/search/pages/Search'));

export const searchRoutes: { [key: number]: IRouteConfigType } = {};

searchRoutes[routesIdMap.Customers] = {
	component: CustomerSearch,
	exact: true,
	id: routesIdMap.Customers,
	name: 'Search customer',
	path: '/customer/search',
	permissions: [PermissionsType.Customer],
	icon: FaMagnifyingGlass,
	menuText: 'Search',
};
