import type { IBooking } from '@crac/core/models/entities/Booking';
import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaPlus, FaRegPenToSquare } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { bookingDetailLoader } from '~/features/booking/shared/utils/bookingDetailLoader';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const Regularizations = lazyWithRetry(() => import('./pages/Regularizations'));

export const regularizationsRoutes: { [key: number]: IRouteConfigType } = {};

regularizationsRoutes[routesIdMap.BookingsDetailRegularizations] = {
	component: Regularizations,
	exact: true,
	id: routesIdMap.BookingsDetailRegularizations,
	name: 'Regularizations',
	path: '/bookings/detail/:id/regularizations',
	permissions: [PermissionsType.BookingDetail],
	loader: ({ params }): Promise<IBooking | null | undefined> => {
		return bookingDetailLoader(params?.id || '');
	},
	icon: FaRegPenToSquare,
};

regularizationsRoutes[routesIdMap.BookingsDetailRegularizationsNew] = {
	component: Regularizations,
	exact: true,
	id: routesIdMap.BookingsDetailRegularizationsNew,
	name: 'New',
	path: '/bookings/detail/:id/regularizations/new',
	permissions: [PermissionsType.BookingDetail],
	loader: ({ params }): Promise<IBooking | null | undefined> => {
		return bookingDetailLoader(params?.id || '');
	},
	icon: FaPlus,
};

regularizationsRoutes[routesIdMap.BookingsDetailRegularizationsDetail] = {
	component: Regularizations,
	exact: true,
	id: routesIdMap.BookingsDetailRegularizationsDetail,
	name: 'Detail',
	path: '/bookings/detail/:id/regularizations/:number',
	permissions: [PermissionsType.BookingDetail],
	loader: ({ params }): Promise<IBooking | null | undefined> => {
		return bookingDetailLoader(params?.id || '');
	},
	icon: FaRegPenToSquare,
};
