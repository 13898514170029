import { damageRepairCostServiceInsert } from '../../data/services/DamageRepairCostService';
import type { IDamageRepairCost } from '../../models/entities/DamageRepairCost';
import type { IDamageRepairCostInsertParams } from '../../models/serviceParams/DamageRepairCostParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * @deprecated - Use `damageRepairCostInsert` from `packages\core\src\modules\workshop\damageRepair\state\actions\WorkshopDamageRepairCostActions.ts` instead.
 * Insert damage repair cost
 * @param model - damage repair cost to insert
 * @returns - inserted damage repair cost
 */
export const damageRepairCostInsert = createAsyncAction<IDamageRepairCost, IDamageRepairCostInsertParams>(
	'workshop/damageRepairCost/insert',
	damageRepairCostServiceInsert,
);
