import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

import { VehicleModelEndPoints } from './FleetVehicleModelEndpoints';
import type { IVehicleModel } from '../entities/VehicleModel';

export interface IFleetVehicleModelDeactivateParams extends IServiceBaseParams {
	provider: string;
	brand: string;
	modelCode: string;
}

export const fleetVehicleModelActivateService = (model: IFleetVehicleModelDeactivateParams) => {
	return Api.post<IVehicleModel, IFleetVehicleModelDeactivateParams>(VehicleModelEndPoints.ACTIVATE, model);
};
