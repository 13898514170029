import { FineTransmitterEndPoints } from './FineTransmitterEndpoints';
import { Api } from '../../../../modules/shared/api';
import type { IServiceBaseParams } from '../../../../modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../../../modules/shared/types/ServiceResponse';
import type { IFineTransmitter } from '../entities/FineTransmitter';

export interface IFineTransmitterSearchParams extends IServiceBaseParams {
	code?: string;
	country?: string;
	email?: string;
	name?: string;
	searchName?: string;
	telephoneNumber?: string;
}

/**
 * Search a fine transmitter
 * @return {Promise<IFineTransmitter[]>} `IFineTransmitter[]`
 */
export const fineTransmitterSearchService = (
	model: IFineTransmitterSearchParams,
): Promise<ServiceResponse<IFineTransmitter[]>> => {
	return Api.get<IFineTransmitter[], IFineTransmitterSearchParams>(FineTransmitterEndPoints.SEARCH, model);
};
