import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaPlus, FaRegFileLines } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const Documents = lazyWithRetry(() => import('./pages/Documents'));
const DocumentsAdd = lazyWithRetry(() => import('./pages/DocumentsAdd'));

export const documentsRoutes: { [key: number]: IRouteConfigType } = {};

documentsRoutes[routesIdMap.VehiclesDetailDocuments] = {
	component: Documents,
	exact: true,
	id: routesIdMap.VehiclesDetailDocuments,
	name: 'Documentation',
	path: '/vehicles/detail/:id/documentation',
	permissions: [PermissionsType.VehicleDetail],
	childrens: [routesIdMap.VehiclesDetailDocumentsAdd],
	icon: FaRegFileLines,
};

documentsRoutes[routesIdMap.VehiclesDetailDocumentsAdd] = {
	component: DocumentsAdd,
	exact: true,
	id: routesIdMap.VehiclesDetailDocumentsAdd,
	name: 'Add documents',
	path: '/vehicles/detail/:id/documentation/add',
	permissions: [PermissionsType.VehicleDetail, PermissionsType.Fleet],
	isChild: true,
	icon: FaPlus,
};
