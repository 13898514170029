import { Api } from '@crac/core/modules/shared/api';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { FineReasonEndPoints } from './FineReasonEndpoints';
import type { IFineReason } from '../entities/FineReason';

/**
 * Get all fine reason
 * @return {Array<FineReason>} List FineReason object
 */
export const fineReasonGetAllService = (): Promise<ServiceResponse<IFineReason[]>> => {
	return Api.get<IFineReason[], undefined>(FineReasonEndPoints.GET_ALL);
};
