import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaRegIdCard } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const OverView = lazyWithRetry(() => import('./pages/OverView'));

export const overViewRoutes: { [key: number]: IRouteConfigType } = {};

overViewRoutes[routesIdMap.CustomersDetailOverviews] = {
	component: OverView,
	exact: true,
	id: routesIdMap.CustomersDetailOverviews,
	name: 'Overviews',
	path: '/customers/detail/:id/overviews',
	permissions: [PermissionsType.CustomerDetail],
	icon: FaRegIdCard,
};
