import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

import { VehicleColorEndPoints } from './FleetVehicleColorEndpoints';
import type { IVehicleColor } from '../entities/VehicleColor';

export interface IFleetVehicleColorDeleteParams extends IVehicleColor, IServiceBaseParams {}

export const fleetVehicleColorDeleteService = (model: IFleetVehicleColorDeleteParams) => {
	return Api.post<IVehicleColor, IFleetVehicleColorDeleteParams>(VehicleColorEndPoints.DELETE, model);
};
