import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

import { RevisionTypeEndPoints } from './RevisionTypeEndpoint';
import type { IRevisionType } from '../entities/RevisionType';

export interface IRevisionTypeReactivateParams extends IServiceBaseParams {
	code: string;
}

export const revisionTypeReactivateService = (model: IRevisionTypeReactivateParams) => {
	return Api.post<IRevisionType, IRevisionTypeReactivateParams>(RevisionTypeEndPoints.REACTIVATE, model);
};
