import { batchRoutes } from './batch/routes';
import { brandParkingsRoutes } from './brandParkings/routes';
import { carrierRoutes } from './carrier/routes';
import { connectedVehiclesRoutes } from './connectedVehicles/routes';
import { dashboardRoutes } from './dashboard/routes';
import { documentationRoutes } from './documentation/routes';
import { finesRoutes } from './fine/routes';
import { fineReasonRoutes } from './fineReason/routes';
import { fineTransmitterRoutes } from './fineTransmitter/routes';
import { insuranceRateRoutes } from './insuranceRate/routes';
import { insuranceSupplierRoutes } from './insuranceSupplier/routes';
import { metaModelRoutes } from './metaModel/routes';
import { movementRestrictionRoutes } from './movementRestriction/routes';
import { previousRegistrationRoutes } from './previousRegistration/routes';
import { previsionRoutes } from './prevision/routes';
import { saleSupplierRoutes } from './saleSupplier/routes';
import { transferRoutes } from './transfer/routes';
import { vehicleBrandRoutes } from './vehicleBrand/routes';
import { vehicleColorRoutes } from './vehicleColor/routes';
import { vehicleEngineRoutes } from './vehicleEngine/routes';
import { vehicleGroupRoutes } from './vehicleGroup/routes';
import { vehicleModelRoutes } from './vehicleModel/routes';
import { vehicleModifyRoutes } from './vehicleModify/routes';
import { vehicleVersionRoutes } from './vehicleVersion/routes';

export const fleetRoutes = {
	...batchRoutes,
	...brandParkingsRoutes,
	...carrierRoutes,
	...connectedVehiclesRoutes,
	...dashboardRoutes,
	...documentationRoutes,
	...fineReasonRoutes,
	...finesRoutes,
	...fineTransmitterRoutes,
	...insuranceRateRoutes,
	...insuranceSupplierRoutes,
	...metaModelRoutes,
	...movementRestrictionRoutes,
	...previousRegistrationRoutes,
	...previsionRoutes,
	...transferRoutes,
	...vehicleBrandRoutes,
	...vehicleColorRoutes,
	...vehicleGroupRoutes,
	...vehicleModelRoutes,
	...vehicleModifyRoutes,
	...vehicleEngineRoutes,
	...vehicleBrandRoutes,
	...vehicleVersionRoutes,
	...vehicleEngineRoutes,
	...saleSupplierRoutes,
};
