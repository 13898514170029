import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaTicketAlt } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const Coupons = lazyWithRetry(() => import('./pages/Coupons'));
const CouponDetail = lazyWithRetry(() => import('./pages/CouponsDetail'));

export const couponsRoutes: { [key: number]: IRouteConfigType } = {};

couponsRoutes[routesIdMap.CustomersDetailCoupons] = {
	component: Coupons,
	exact: true,
	id: routesIdMap.CustomersDetailCoupons,
	name: 'Coupons',
	path: '/customers/detail/:id/coupons',
	permissions: [PermissionsType.CustomerDetail],
	childrens: [routesIdMap.CustomersDetailCouponsDetail],
	icon: FaTicketAlt,
};

couponsRoutes[routesIdMap.CustomersDetailCouponsDetail] = {
	component: CouponDetail,
	exact: true,
	id: routesIdMap.CustomersDetailCouponsDetail,
	name: 'Coupon detail',
	path: '/customers/detail/:id/coupons/:code',
	permissions: [PermissionsType.CustomerDetail],
	isChild: true,
	icon: FaTicketAlt,
};
