export enum SupplierLocationEndpoints {
	/**
	 * search supplier locations
	 * @host `/supplierPhysicalLocation/search`
	 */
	SEARCH = '/supplierPhysicalLocation/search',

	/**
	 * insert supplier location
	 * @host `/supplierPhysicalLocation/insert`
	 */
	INSERT = '/supplierPhysicalLocation/insert',

	/**
	 * modify supplier location
	 * @host `/supplierPhysicalLocation/modify`
	 */
	MODIFY = '/supplierPhysicalLocation/modify',

	/**
	 * delete supplier location
	 * @host `/supplierPhysicalLocation/delete`
	 */
	DELETE = '/supplierPhysicalLocation/delete',
}
