import { useEffect, useMemo } from 'react';

import classNames from 'classnames';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';

import { FullPageLoader } from '@crac/components/intranet/shared/fullPageLoader';
import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { isProduction } from '@crac/core/modules/shared/api/ApiEndPoint';
import { setHostName } from '@crac/core/redux/actions/ComputerActions';
import { emailSendFeedback } from '@crac/core/redux/actions/EmailActions';
import { employeeUpdate } from '@crac/core/redux/actions/EmployeeActions';
import { toggleFeedBackForm } from '@crac/core/redux/actions/FeedBackActions';
import { toggleEnabledPermissions, updateUserPermissions } from '@crac/core/redux/actions/PermissionsActions';
import { Container } from '@crac/ui/container';
import { Sidebar } from '@crac/ui/sidebar';

import { REAL_TIME_SCRIPT_CODES } from '~/constants/RealTimeEventNames';
import { AppContext } from '~/context/AppContext';
import { RacSignalContext } from '~/context/RacSignalContext';
import { useBookingDetailSelector } from '~/features/booking/detail/detail/state/selector';
import {
	useEmployeeBranchSelector,
	useEmployeeProviderSelector,
	useEmployeeSelector,
} from '~/features/employee/state/selectors';
import { FeedBack } from '~/features/shared/components/layout/feedBack';
import { RingCentralIntegration } from '~/features/shared/components/layout/ringcentralIntegration';
import { getNavigationItems } from '~/features/shared/components/layout/utils/menuItems';
import { useAlertMessage } from '~/features/shared/hooks/useAlertMessage';
import { useBindAndMemoizeActions } from '~/features/shared/hooks/useBindAndMemoizeActions';
import {
	useFeedbackSelector,
	useLoaderSelector,
	usePermissionsSelector,
} from '~/features/shared/state/selectors/CommonsSelectors';
import { useEmailSelector } from '~/features/shared/state/selectors/EmailSelectors';
import { removeBrowserCache } from '~/serviceWorker';

import { BreadCrumbs } from './Breadcrumbs';
import { AppHeader } from './header';
import { useLayoutState } from './hooks/useLayoutState';
import { useSetHostName } from './hooks/useSetHostName';
import { useSignalR } from './hooks/useSignalR';
import { useSignalRConnection } from './hooks/useSignalRConnection';
import { MobileFeedbackBtn } from './MobileFeedbackBtn';

const actionsImported = {
	emailSendFeedback,
	employeeUpdate,
	setHostName,
	toggleFeedBack: toggleFeedBackForm,
	togglePermissions: toggleEnabledPermissions,
	updatePermissions: updateUserPermissions,
};

export const LayoutComponent = () => {
	// HOOKS
	const history = useNavigate();
	const location = useLocation();

	const { showAlert } = useAlertMessage();
	const { sidebarOpen, setSidebarOpen, mobileSidebarOpen, setMobileSidebarOpen } = useLayoutState(location);

	const actions = useBindAndMemoizeActions(actionsImported);
	const { emailSendFeedback, employeeUpdate, setHostName, toggleFeedBack, togglePermissions, updatePermissions } =
		actions;

	const { booking } = useBookingDetailSelector();
	const employee = useEmployeeSelector();
	const { isOpen: feedBackIsOpen } = useFeedbackSelector();
	const loader = useLoaderSelector();
	const permissionsTool = usePermissionsSelector();
	const { sendFeedBackRequest } = useEmailSelector();

	useSetHostName(setHostName);
	const hubConnection = useSignalR(employee.token);
	// Init SignalR
	useSignalRConnection(hubConnection, employee, employeeUpdate);
	// const { handleLoadCachedData, isSubmitted, isLoading } = useLoadCachedData(employee, true);

	const updateAllBrowsers = () => {
		window.location.reload();
	};

	const refreshEmployeeData = (data: any) => {
		employeeUpdate({
			...employee,
			branchCode: data.branchCode,
			name: data.name,
			permissions: data.permissions,
			token: data.token,
			userName: data.userName,
		});
	};

	const beforeUpdatingBrowser = async () => {
		await showAlert({
			title: 'We have updated the application!',
			message: 'It is necessary to refresh the browser.',
			type: 'warning',
		});

		updateAllBrowsers();
	};

	const launchScript = (response: any): void => {
		if (response.ok) {
			switch (response.script) {
				case 'F5':
					beforeUpdatingBrowser();
					break;
				case 'CLOSE_SESSION':
					history('/login');
					break;
				case 'DELETE_BROWSER_CACHES':
					removeBrowserCache();
					setTimeout(() => {
						window.location.reload();
						window.location.reload();
					}, 0);
					break;
				case REAL_TIME_SCRIPT_CODES.UPDATE_ALL_CACHED_DATA:
					// handleLoadCachedData();
					break;
				default:
					break;
			}
		}
	};

	const showMessage = (res: any) => {
		if (res.ok) {
			showAlert({ message: res.message, type: 'info' });
		}
	};

	const showGlobalMessage = (res: any) => {
		if (res.ok) {
			showAlert({ message: res.message, type: 'info' });
		}
	};

	useEffect(() => {
		if (hubConnection) {
			hubConnection.addListener('onLaunchScript', launchScript);
			hubConnection.addListener('onRefreshEmployeeData', refreshEmployeeData);
			hubConnection.addListener('onShowPrivateMessage', showMessage);
			hubConnection.addListener('onShowGlobalMessage', showGlobalMessage);
		}

		/*
		 * REFRESH WINDOW WHEN CACHED DATA FINISH LOADING
		 * if (isSubmitted && !isLoading) {
		 * 	updateAllBrowsers();
		 * }
		 */

		return () => {
			hubConnection.removeListener('onLaunchScript');
			hubConnection.removeListener('onRefreshEmployeeData');
			hubConnection.removeListener('onShowPrivateMessage');
			hubConnection.removeListener('onShowGlobalMessage');
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hubConnection]);

	const employeeProvider = useEmployeeProviderSelector();
	const employeeBranch = useEmployeeBranchSelector();

	useSetHostName(setHostName);

	// VALUES
	const permissions = useMemo(() => {
		return permissionsTool.isEnabled && employee.permissions.includes(PermissionsType.IT)
			? (permissionsTool.permissions as string[])
			: employee.permissions;
	}, [permissionsTool.isEnabled, permissionsTool.permissions, employee.permissions]);

	const valuesEmployee = useMemo(
		() => ({
			employeeBranch,
			employeeProvider,
			permissions,
			user: { ...employee, permissions },
		}),
		[employee, employeeBranch, employeeProvider, permissions],
	);

	const menuItems = useMemo(() => getNavigationItems(permissions, location), [permissions, location]);
	const isDevelopment = !isProduction;

	return (
		<RacSignalContext.Provider value={hubConnection}>
			<AppContext.Provider value={valuesEmployee}>
				<div className="tw-app">
					{/* Page Loader */}
					<FullPageLoader
						icon={loader.icon}
						isVisible={loader.isVisible as boolean}
						message={loader.message}
					/>

					{/* Header */}
					<AppHeader
						employee={employee}
						isDevelopment={isDevelopment}
						location={location}
						menuItems={menuItems}
						mobileSidebarOpen={mobileSidebarOpen}
						permissions={permissionsTool.permissions as string[]}
						permissionsToolEnabled={permissionsTool.isEnabled}
						renderSidebarHeader={() => <MobileFeedbackBtn toggleFeedBack={toggleFeedBack} />}
						setMobileSidebarOpen={setMobileSidebarOpen}
						sidebarOpen={sidebarOpen}
						togglePermissions={togglePermissions}
						updatePermissions={updatePermissions}
					/>

					<section className="tw-app-body">
						{/* Sidebar */}
						<Sidebar
							AnchorComponent={NavLink}
							className={isDevelopment ? 'tw-app-sidebar-dev' : 'tw-app-sidebar'}
							menuItems={menuItems}
							setSidebarOpen={setSidebarOpen}
							sidebarOpen={sidebarOpen}
						/>
						{/* Main */}
						<main
							className={classNames('tw-main', {
								'tw-open-sidebar': sidebarOpen,
								dev: isDevelopment,
							})}
						>
							<BreadCrumbs />
							<Container fluid>
								<Outlet />
							</Container>
						</main>
					</section>

					{/* Fixed ring central */}
					{permissions.includes(PermissionsType.BookingNewExternal) && (
						<RingCentralIntegration history={history} />
					)}
					{/* Fixed feedback btn */}
					<FeedBack
						booking={booking}
						employee={employee}
						isOpen={feedBackIsOpen}
						onToggleFeedback={toggleFeedBack}
						sendFeedBack={emailSendFeedback}
						sendFeedBackRequest={sendFeedBackRequest}
					/>
				</div>
			</AppContext.Provider>
		</RacSignalContext.Provider>
	);
};

export const Layout = LayoutComponent;
