import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaCloud } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const WebCache = lazyWithRetry(() => import('./pages/WebCache'));

export const webcacheRoutes: { [key: number]: IRouteConfigType } = {};

webcacheRoutes[routesIdMap.ITWebCache] = {
	component: WebCache,
	exact: true,
	id: routesIdMap.ITWebCache,
	name: 'Web cache',
	path: '/it/web-cache',
	permissions: [PermissionsType.IT],
	icon: FaCloud,
	menuText: 'Web cache',
};
