import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

import { FleetPrevisionEndPoints } from './FleetPrevisionEndPoints';
import type { IFleetPrevision } from '../entities/FleetPrevision';

export interface IFleetPrevisionInsertParams {
	provider: string;
	insuranceProviderCode: string;
	group: string;
	fleetTypology?: number;
	model?: string;
	branch: string;
	purchaseDate: string | Date;
	saleDate: string | Date;
	quantity: number;
	supplier?: string;
	brand?: string;
}

export interface IFleetPrevisionInsertArrayParams extends IServiceBaseParams {
	fleetPrevisions: IFleetPrevisionInsertParams[];
	previsionCode?: string;
}

export const fleetPrevisionInsertService = (model: IFleetPrevisionInsertArrayParams) => {
	return Api.post<IFleetPrevision[], IFleetPrevisionInsertArrayParams>(FleetPrevisionEndPoints.INSERT, model);
};
