'use client';

import * as React from 'react';

import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { LuCheck } from 'react-icons/lu';

import type { CheckboxProps } from './types';
import { cn } from '../lib/utils/tailwindClass';

const Checkbox = React.forwardRef<React.ElementRef<typeof CheckboxPrimitive.Root>, CheckboxProps>(
	({ className, isDisabled, ...props }, ref) => (
		<CheckboxPrimitive.Root
			className={cn('tw-peer checkbox', className)}
			disabled={isDisabled}
			ref={ref}
			{...props}
		>
			<CheckboxPrimitive.Indicator className="checkbox-indicator">
				<LuCheck className="tw-h-[14px] tw-w-[14px]" />
			</CheckboxPrimitive.Indicator>
		</CheckboxPrimitive.Root>
	),
);

Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
