import type { FC } from 'react';
import React from 'react';

import * as FaIcons from '.';

import { cn } from '../lib/utils/tailwindClass';

interface IconProps {
	name: keyof typeof FaIcons;
	size?: number | 'sm' | 'md' | 'lg';
	color?: string;
	className?: string;
}

export const Icon: FC<IconProps> = ({ name, size = 'md', color, className, ...props }) => {
	// eslint-disable-next-line import/namespace
	const Icon = FaIcons[name];

	const numberSize = typeof size === 'number' ? size : undefined;

	if (!Icon) {
		// eslint-disable-next-line no-console
		console.warn(`Icon ${name} not found`);
		return null;
	}

	return (
		<Icon
			className={cn(className, {
				'tw-h-3 tw-w-3': size === 'sm',
				'tw-h-4 tw-w-4': size === 'md',
				'tw-h-5 tw-w-5': size === 'lg',
			})}
			color={color}
			size={numberSize}
			{...props}
		/>
	);
};
