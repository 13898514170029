import { Api } from '@crac/core/modules/shared/api';

import { SaleServiceRateEndpoints } from './SaleServiceRateEndpoints';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ISaleServiceRate } from '../entities/SaleServiceRate';

interface ISaleServiceRateNew extends Partial<ISaleServiceRate> {
	amount: number;
	company: string;
	quoteFrom: string;
	saleServiceId: number;
}

export interface ISaleServiceRateInsertParams extends IServiceBaseParams {
	saleServiceRates: ISaleServiceRateNew[];
}

export const saleServiceRateInsertService = (model: ISaleServiceRateInsertParams) => {
	return Api.post<ISaleServiceRate[], ISaleServiceRateInsertParams>(SaleServiceRateEndpoints.INSERT, model);
};
