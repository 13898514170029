import {
	commentServiceAddToBooking,
	commentServiceAddToCompany,
	commentServiceAddToCustomer,
	commentServiceAddToVehicle,
	commentServiceGetByBooking,
	commentServiceGetByCompany,
	commentServiceGetByCustomer,
	commentServiceGetByVehicle,
	commentServiceRemoveFromBooking,
	commentServiceRemoveFromCompany,
	commentServiceRemoveFromCustomer,
	commentServiceRemoveFromVehicle,
} from '../../data/services/CommentService';
import type { IComment } from '../../models/entities/Comment';
import type {
	ICommentAddToBookingParams,
	ICommentAddToCompanyParams,
	ICommentAddToCustomerParams,
	ICommentAddToVehicleParams,
	ICommentGetByBookingParams,
	ICommentGetByCompanyParams,
	ICommentGetByCustomerParams,
	ICommentGetByVehicleParams,
	ICommentRemoveFromBookingParams,
	ICommentRemoveFromCompanyParams,
	ICommentRemoveFromCustomerParams,
	ICommentRemoveFromVehicleParams,
} from '../../models/serviceParams/CommentParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * @deprecated - use `commentGetByCustomer` from 'packages\core\src\modules\comment\state\actions\CommentActions.ts' instead.
 * Retrieves comments associated with a specific customer.
 *
 * @param {IComment[]} payload - The comments to be retrieved.
 * @param {ICommentGetByCustomerParams} params - Parameters to identify comments related to a specific customer.
 * @returns {Promise<void>} - A promise that resolves to an array of comments.
 */
export const commentGetByCustomer = createAsyncAction<IComment[], ICommentGetByCustomerParams>(
	'comment/getByCustomer',
	commentServiceGetByCustomer,
);

/**
 * @deprecated - use `commentGetByCompany` from 'packages\core\src\modules\comment\state\actions\CommentActions.ts' instead.
 * Retrieves comments associated with a specific company.
 *
 * @param {IComment[]} payload - The comments to be retrieved.
 * @param {ICommentGetByCompanyParams} params - Parameters to identify comments related to a specific company.
 * @returns {Promise<void>} - A promise that resolves to an array of comments.
 */
export const commentGetByCompany = createAsyncAction<IComment[], ICommentGetByCompanyParams>(
	'comment/getByCompany',
	commentServiceGetByCompany,
);

/**
 * @deprecated - use `commentGetByBooking` from 'packages\core\src\modules\comment\state\actions\CommentActions.ts' instead.
 * Retrieves comments associated with a specific booking.
 *
 * @param {IComment[]} payload - The comments to be retrieved.
 * @param {ICommentGetByBookingParams} params - Parameters to identify comments related to a specific booking.
 * @returns {Promise<void>} - A promise that resolves to an array of comments.
 */
export const commentGetByBooking = createAsyncAction<IComment[], ICommentGetByBookingParams>(
	'comment/getByBooking',
	commentServiceGetByBooking,
);

/**
 * @deprecated - use `commentGetByVehicle` from 'packages\core\src\modules\comment\state\actions\CommentActions.ts' instead.
 * Retrieves comments associated with a specific vehicle.
 *
 * @param {IComment[]} payload - The comments to be retrieved.
 * @param {ICommentGetByVehicleParams} params - Parameters to identify comments related to a specific vehicle.
 * @returns {Promise<void>} - A promise that resolves to an array of comments.
 */
export const commentGetByVehicle = createAsyncAction<IComment[], ICommentGetByVehicleParams>(
	'comment/getByVehicle',
	commentServiceGetByVehicle,
);

/**
 * @deprecated - use `commentAddToVehicle` from 'packages\core\src\modules\comment\state\actions\CommentActions.ts' instead.
 * Adds a comment to a specific vehicle.
 *
 * @param {IComment} payload - The comment to be added.
 * @param {ICommentAddToVehicleParams} params - Parameters detailing the comment and the vehicle it is related to.
 * @returns {Promise<void>} - A promise that resolves after the comment is added.
 */
export const commentAddToVehicle = createAsyncAction<IComment, ICommentAddToVehicleParams>(
	'comment/addToVehicle',
	commentServiceAddToVehicle,
);

/**
 * @deprecated - use `commentAddToBooking` from 'packages\core\src\modules\comment\state\actions\CommentActions.ts' instead.
 * Adds a comment to a specific booking.
 *
 * @param {IComment} payload - The comment to be added.
 * @param {ICommentAddToBookingParams} params - Parameters detailing the comment and the booking it is related to.
 * @returns {Promise<void>} - A promise that resolves after the comment is added.
 */
export const commentAddToBooking = createAsyncAction<IComment, ICommentAddToBookingParams>(
	'comment/addToBooking',
	commentServiceAddToBooking,
);

/**
 * @deprecated - use `commentAddToCustomer` from 'packages\core\src\modules\comment\state\actions\CommentActions.ts' instead.
 * Adds a comment to a specific customer.
 *
 * @param {IComment} payload - The comment to be added.
 * @param {ICommentAddToCustomerParams} params - Parameters detailing the comment and the customer it is related to.
 * @returns {Promise<void>} - A promise that resolves after the comment is added.
 */
export const commentAddToCustomer = createAsyncAction<IComment, ICommentAddToCustomerParams>(
	'comment/addToCustomer',
	commentServiceAddToCustomer,
);

/**
 * @deprecated - use `commentAddToCompany` from 'packages\core\src\modules\comment\state\actions\CommentActions.ts' instead.
 * Adds a comment to a specific company.
 *
 * @param {IComment} payload - The comment to be added.
 * @param {ICommentAddToCompanyParams} params - Parameters detailing the comment and the company it is related to.
 * @returns {Promise<void>} - A promise that resolves after the comment is added.
 */
export const commentAddToCompany = createAsyncAction<IComment, ICommentAddToCompanyParams>(
	'comment/addToCompany',
	commentServiceAddToCompany,
);

/**
 * @deprecated - use `commentRemoveFromVehicle` from 'packages\core\src\modules\comment\state\actions\CommentActions.ts' instead.
 * Removes a comment from a specific vehicle.
 *
 * @param {IComment} payload - The comment to be removed.
 * @param {ICommentRemoveFromVehicleParams} params - Parameters detailing the comment and the vehicle it is related to.
 * @returns {Promise<void>} - A promise that resolves after the comment is removed.
 */
export const commentRemoveFromVehicle = createAsyncAction<IComment, ICommentRemoveFromVehicleParams>(
	'comment/removeFromVehicle',
	commentServiceRemoveFromVehicle,
);

/**
 * @deprecated - use `commentRemoveFromCustomer` from 'packages\core\src\modules\comment\state\actions\CommentActions.ts' instead.
 * Removes a comment from a specific customer.
 *
 * @param {IComment} payload - The comment to be removed.
 * @param {ICommentRemoveFromCustomerParams} params - Parameters detailing the comment and the customer it is related to.
 * @returns {Promise<void>} - A promise that resolves after the comment is removed.
 */
export const commentRemoveFromCustomer = createAsyncAction<IComment, ICommentRemoveFromCustomerParams>(
	'comment/removeFromCustomer',
	commentServiceRemoveFromCustomer,
);

/**
 * @deprecated - use `commentRemoveFromCompany` from 'packages\core\src\modules\comment\state\actions\CommentActions.ts' instead.
 * Removes a comment from a specific company.
 *
 * @param {IComment} payload - The comment to be removed.
 * @param {ICommentRemoveFromCompanyParams} params - Parameters detailing the comment and the company it is related to.
 * @returns {Promise<void>} - A promise that resolves after the comment is removed.
 */
export const commentRemoveFromCompany = createAsyncAction<IComment, ICommentRemoveFromCompanyParams>(
	'comment/removeFromCompany',
	commentServiceRemoveFromCompany,
);

/**
 * @deprecated - use `commentRemoveFromBooking` from 'packages\core\src\modules\comment\state\actions\CommentActions.ts' instead.
 * Removes a comment from a specific booking.
 *
 * @param {IComment} payload - The comment to be removed.
 * @param {ICommentRemoveFromBookingParams} params - Parameters detailing the comment and the booking it is related to.
 * @returns {Promise<void>} - A promise that resolves after the comment is removed.
 */
export const commentRemoveFromBooking = createAsyncAction<IComment, ICommentRemoveFromBookingParams>(
	'comment/removeFromBooking',
	commentServiceRemoveFromBooking,
);
