import { BudgetEndPoints } from './BudgetEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';

export interface IBudgetOpenFileParams extends IServiceBaseParams {
	id: number;
}

/**
 * Open the budget file by id
 * @param id id of the budget file
 * @returns `boolean`
 */
export const budgetOpenFileService = (model: IBudgetOpenFileParams) => {
	return Api.get<boolean, IBudgetOpenFileParams>(BudgetEndPoints.OPEN_FILE, model);
};
