import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { setBookingLinesInvoiceTo } from '@crac/core/business/booking/BookingInvoiceTo';
import { bookingNewSetGroup, updateBookingLine } from '@crac/core/business/booking/BookingNewManageState';
import { deepCopy } from '@crac/core/helpers/commons';
import { reducerStorage } from '@crac/core/helpers/reducerStorage';
import type { ICustomer } from '@crac/core/models/entities/Customer';
import { BookingType } from '@crac/core/models/types/BookingType';
import { InvoiceToCustomerType } from '@crac/core/models/types/InvoiceToCustomerType';
import { UnitTime } from '@crac/core/models/types/UnitTime';
import type { IBookingLine } from '@crac/core/modules/booking/bookingLine/entities/BookingLine';
import { CreationMethod } from '@crac/core/modules/shared/types/CreationMethod';
import { bookingDetailClear } from '@crac/core/redux/actions/BookingActions';
import {
	bookingNewAddService,
	bookingNewClearSession,
	bookingNewInitAvailabilityStep,
	bookingNewInitGroupsStep,
	bookingNewInvoiceToCustomerTypeToComapany,
	bookingNewInvoiceToCustomerTypeToCustomer,
	bookingNewRemoveService,
	bookingNewSetAgency,
	bookingNewSetAvailabilityData,
	bookingNewSetCompany,
	bookingNewSetCreationMethod,
	bookingNewSetCurrentProvider,
	bookingNewSetCustomer,
	bookingNewSetDiscount,
	bookingNewSetGroup as bookingNewSetGroupAction,
	bookingNewSetVendor,
	bookingNewUpdateBookingLine,
} from '@crac/core/redux/actions/BookingNewActions';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import type {
	IBookingNewInsertReducerState,
	IBookingNewReducerState,
} from '@crac/core/redux-store/reducersState/booking/BookingNewReducerState';
const initialState: IBookingNewReducerState = {
	agency: null,
	availabilityFormValue: null,
	booking: {
		bookingLines: [],
		discount: 0,
	},
	bookingType: BookingType.DIRECT,
	company: null,
	creationMethod: CreationMethod.BRANCH,
	currentProvider: null,
	customer: null,
	customerAgeRange: '',
	group: null,
	invoiceToCustomerType: InvoiceToCustomerType.GENERIC,
	package: null,
	services: [],
	vendor: null,
	currentAppliedDiscount: undefined,
	unitTime: UnitTime.Day,
};

const { getStorage, removeStorage, updateStorage } = reducerStorage<IBookingNewReducerState>(
	'NEW_BOOKING_DATA',
	initialState,
	'sessionStorage',
);

const getInvoiceToCustomerType = (state: IBookingNewReducerState, customer: ICustomer): InvoiceToCustomerType => {
	const { currentProvider } = state;

	if (currentProvider) {
		const { genericCustomerCode } = currentProvider;

		if (customer.code !== genericCustomerCode) {
			return InvoiceToCustomerType.CUSTOMER;
		}
	}

	return InvoiceToCustomerType.GENERIC;
};

const setBookingInvoiceToCustomerTypeOnBookingLines = (
	booking: IBookingNewInsertReducerState,
	invoiceTo: string,
	invoiceToCustomerType: InvoiceToCustomerType,
): IBookingLine[] => {
	const bookingLines = booking.bookingLines as IBookingLine[];

	return bookingLines.map((line: IBookingLine) => {
		if (!line.invoiceToAgency) {
			return {
				...line,
				invoiceTo,
				invoiceToCustomerType,
			};
		}

		return line;
	});
};

const newSlice = createSlice({
	name: 'BookingNewSlice',
	initialState: getStorage(),
	reducers: {},
	extraReducers: (builder) => {
		/**
		 * STEP RESETS
		 */
		builder.addCase(bookingNewInitAvailabilityStep, (state) => {
			return updateStorage(state, {
				...initialState,
				availabilityFormValue: null,
				customer: null,
				group: null,
			});
		});
		builder.addCase(bookingNewInitGroupsStep, (state) => {
			return updateStorage(state, {
				...state,
				booking: {
					...state.booking,
					bookingLines: [],
				},
				group: null,
				package: null,
				services: [],
			});
		});
		/**
		 * SET AVAILABILITY DATA
		 */
		builder.addCase(bookingNewSetAvailabilityData, (state, { payload }) => {
			const { availabilityFormValue } = payload;
			return updateStorage(state, {
				availabilityFormValue,
				booking: {
					...state.booking,
					customerBirthDate: availabilityFormValue.customerBirthDate,
					dropOffBranchCode: availabilityFormValue.dropOffBranchCode,
					dropOffDateTime: availabilityFormValue.dropOffDateTime,
					pickUpBranchCode: availabilityFormValue.pickUpBranchCode,
					pickUpDateTime: availabilityFormValue.pickUpDateTime,
					quoteDateTime: availabilityFormValue.quoteDateTime,
				},
			});
		});
		builder.addCase(bookingNewSetCurrentProvider, (state, action) => {
			return updateStorage(state, {
				booking: state.booking,
				currentProvider: action.payload,
			});
		});
		/**
		 * SET GROUP AND PACKAGE
		 */
		builder.addCase(bookingNewSetGroupAction, (state, action) => {
			return updateStorage(state, bookingNewSetGroup(state, action.payload.group, action.payload.packageCode));
		});
		/**
		 * SET BOOKING LINES
		 */
		builder.addCase(bookingNewAddService, (state, action) => {
			return updateStorage(state, {
				...state,

				booking: {
					...state.booking,
					bookingLines: setBookingLinesInvoiceTo(state, action.payload.bookingLines, state.package),
				},
				services: action.payload.services,
			});
		});
		builder.addCase(bookingNewRemoveService, (state, action) => {
			return updateStorage(state, {
				...state,

				booking: {
					...state.booking,
					bookingLines: action.payload.bookingLines,
				},
				services: action.payload.services,
			});
		});
		/**
		 * SET BOOKING LINES
		 */
		builder.addCase(bookingNewUpdateBookingLine, (state, action) => {
			return updateStorage(state, {
				...state,
				booking: updateBookingLine(state, action.payload),
			});
		});
		/**
		 * SET AGENCY, VENDOR OR COMPANY
		 */
		builder.addCase(bookingNewSetAgency, (state, action) => {
			return updateStorage(state, {
				...state,
				agency: deepCopy(action.payload),
				bookingType: action.payload ? action.payload.contractType : undefined,
				unitTime: action.payload ? action.payload.unitTime : undefined,
				// Company: null,
			});
		});
		builder.addCase(bookingNewSetVendor, (state, action) => {
			return updateStorage(state, {
				...state,
				bookingType: action.payload ? action.payload.contractType : undefined,
				unitTime: action.payload ? action.payload.unitTime : undefined,
				company: null,
				vendor: action.payload,
			});
		});
		builder.addCase(bookingNewSetCompany, (state, action) => {
			return updateStorage(state, {
				...state,
				agency: action.payload
					? {
							code: action.payload.code,
							contractType: action.payload?.contractType,
							name: action.payload?.name,
							amountToInvoice: action.payload.amountToInvoice,
						}
					: null,
				bookingType: action.payload ? action.payload.contractType : undefined,
				unitTime: action.payload ? action.payload.unitTime : undefined,
				company: action.payload,
				vendor: null,
			});
		});
		/**
		 * CUSTOMER
		 */
		builder.addCase(bookingNewSetCustomer, (state, action) => {
			return updateStorage(state, {
				...state,
				customer: action.payload,
			});
		});
		// CHANGE INVOICETO CUSTOMER TYPE
		builder.addCase(bookingNewInvoiceToCustomerTypeToComapany, (state, action) => {
			return {
				...state,
				booking: {
					...state.booking,
					bookingLines: setBookingInvoiceToCustomerTypeOnBookingLines(
						state.booking as IBookingNewInsertReducerState,
						action.payload.code,
						InvoiceToCustomerType.COMPANY,
					),
				},
				invoiceToCustomerCode: action.payload.code,
				invoiceToCustomerType: InvoiceToCustomerType.COMPANY,
			};
		});
		builder.addCase(bookingNewInvoiceToCustomerTypeToCustomer, (state, action) => {
			return {
				...state,
				booking: {
					...state.booking,
					bookingLines: setBookingInvoiceToCustomerTypeOnBookingLines(
						state.booking as IBookingNewInsertReducerState,
						action.payload.code,
						getInvoiceToCustomerType(state, action.payload),
					),
				},
				invoiceToCustomerCode: action.payload.code,
				invoiceToCustomerType: getInvoiceToCustomerType(state, action.payload),
			};
		});
		builder.addCase(bookingNewSetDiscount, (state, action) => {
			return {
				...state,
				booking: {
					...state.booking,
					bookingLines: action.payload.bookingLines,
					discount: action.payload.discount,
				},
				currentAppliedDiscount: action.payload.currentAppliedDiscount,
			};
		});
		builder.addCase(bookingNewSetCreationMethod, (state, action) => {
			return {
				...state,
				creationMethod: action.payload,
			};
		});
		/**
		 * CLEAR SESSION
		 * CLEAR
		 * CLEAR CACHE
		 */
		builder.addMatcher(isAnyOf(bookingNewClearSession, bookingDetailClear, clearGlobalCache), () => {
			removeStorage();
			return initialState;
		});
	},
});

export const newReducer = newSlice.reducer;
