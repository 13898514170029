import { FleetPrevisionEndPoints } from './FleetPrevisionEndPoints';
import type { IFleetPrevisionInsertParams } from './FleetPrevisionInsertService';
import { Api } from '../../../../modules/shared/api';
import type { IServiceBaseParams } from '../../../../modules/shared/types/ServiceBaseParams';
import type { IFleetPrevision } from '../entities/FleetPrevision';

export interface IFleetPrevisionModifyParams extends IServiceBaseParams, IFleetPrevisionInsertParams {
	previsionCode: string;
}

export const fleetPrevisionModifyService = (model: IFleetPrevisionModifyParams) => {
	return Api.post<IFleetPrevision, IFleetPrevisionModifyParams>(FleetPrevisionEndPoints.MODIFY, model);
};
