import { BudgetEndPoints } from './BudgetEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IBudgetFile } from '../entities/Budget';

export interface IBudgetAddFilesParams extends IServiceBaseParams {
	budgetId: number;
	files: File[];
}

/**
 * Insert files of a budget
 * @param budgetId id of the budget
 * @param files array of files to insert
 * @returns `IBudgetFile[]`
 */
export const budgetAddFilesService = (model: IBudgetAddFilesParams) => {
	return Api.post<IBudgetFile[], IBudgetAddFilesParams>(BudgetEndPoints.ADD_FILES, model);
};
