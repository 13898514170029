import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

import { RevisionTypeEndPoints } from './RevisionTypeEndpoint';
import type { IRevisionType } from '../entities/RevisionType';

export interface IRevisionTypeModifyParams extends IRevisionType, IServiceBaseParams {}

export const revisionTypeModifyService = (model: IRevisionTypeModifyParams) => {
	return Api.post<IRevisionType, IRevisionTypeModifyParams>(RevisionTypeEndPoints.MODIFY, model);
};
