export enum VehicleVersionEndpoints {
	/**
	 * Searches vehicleVersions
	 * @host `/vehicleVersion/search`
	 */
	SEARCH = '/vehicleVersion/search/',
	/**
	 * Inserts vehicleVersion
	 * @host `/vehicleVersion/insert`
	 */
	INSERT = '/vehicleVersion/insert/',
	/**
	 * Modifies vehicleVersion
	 * @host `/vehicleVersion/modify`
	 */
	MODIFY = '/vehicleVersion/modify/',
	/**
	 * Deletes vehicleVersion
	 * @host `/vehicleVersion/delete`
	 */
	DELETE = '/vehicleVersion/delete/',

	/**
	 * get version by id
	 * @host `/vehicleVersion/getById`,
	 */
	GET_BY_ID = '/vehicleVersion/getById',
}
