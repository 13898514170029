import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

import { FleetTransferEndPoints } from './FleetTransferEndpoints';
import type { IFleetTransfer } from '../entities/FleetTransfer';
import type { ITransferSave } from '../entities/TransferSave';

export interface IFleetTransferInsertParams extends IServiceBaseParams {
	transfers: Exclude<ITransferSave, 'id' | 'code'>[];
}

export const fleetTransferInsertService = (model: IFleetTransferInsertParams) => {
	return Api.post<IFleetTransfer[], IFleetTransferInsertParams>(FleetTransferEndPoints.INSERT, model);
};
