import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaPencil, FaPlus, FaRetweetAlt } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

import { getPricingPermission } from '../utils/helpers';

const RentRateTypeRelation = lazyWithRetry(() => import('./pages/RentRateTypeRelation'));
const RentRateTypeRelationNew = lazyWithRetry(() => import('./pages/RentRateTypeRelationNew'));
const RentRateTypeRelationModify = lazyWithRetry(() => import('./pages/RentRateTypeRelationModify'));

export const rentRateTypeRelationRoutes: { [key: number]: IRouteConfigType } = {};

// RELATIONS
rentRateTypeRelationRoutes[routesIdMap.RentRateTypeRelation] = {
	component: RentRateTypeRelation,
	exact: true,
	id: routesIdMap.RentRateTypeRelation,
	menuText: 'Relations',
	name: 'Rent rate relations',
	path: '/pricing/rent-rates/relations',
	permissions: getPricingPermission([PermissionsType.PricingRentRateTypeRelation]),
	childrens: [routesIdMap.RentRateTypeRelationNew, routesIdMap.RentRateTypeRelationModify],
	icon: FaRetweetAlt,
};

rentRateTypeRelationRoutes[routesIdMap.RentRateTypeRelationNew] = {
	component: RentRateTypeRelationNew,
	exact: true,
	isChild: true,
	id: routesIdMap.RentRateTypeRelationNew,
	name: 'New rent rate relation',
	path: '/pricing/rent-rates/relations/new',
	permissions: getPricingPermission([PermissionsType.PricingRentRateTypeRelation]),
	menuText: 'New relation',
	icon: FaPlus,
};

rentRateTypeRelationRoutes[routesIdMap.RentRateTypeRelationModify] = {
	component: RentRateTypeRelationModify,
	exact: true,
	isChild: true,
	id: routesIdMap.RentRateTypeRelationModify,
	name: 'Modify rent rate relation',
	path: '/pricing/rent-rates/relations/:id',
	permissions: getPricingPermission([PermissionsType.PricingRentRateTypeRelation]),
	icon: FaPencil,
};
