import React from 'react';

import { cn } from '../../lib/utils/tailwindClass';

export const BreadcrumbPage = React.forwardRef<HTMLSpanElement, React.ComponentPropsWithoutRef<'span'>>(
	({ className, ...props }, ref) => (
		<span
			aria-current="page"
			aria-disabled="true"
			className={cn('breadcrumb-page', className)}
			ref={ref}
			role="link"
			{...props}
		/>
	),
);

BreadcrumbPage.displayName = 'BreadcrumbPage';
