import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import { App } from './features/App';
import { store } from './redux';
import reportWebVitals from './reportWebVitals';
import '~/assets/scss/global.css';
import '~/assets/scss/_App.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<Provider store={store}>
		<App />
	</Provider>,
);

/*
 * If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.log))
 * or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 */
reportWebVitals();
