import { webcacheRoutes } from './cache/routes';
import { customerDigitizationRoutes } from './customerDigitization/routes';
import { dashboardRoutes } from './dashboard/routes';
import { fiturRoutes } from './fitur/routes';
import { lockersRoutes } from './lockers/routes';
import type { IRouteConfigType } from '../shared/types/RouteConfigType';

export const itRoutes: { [key: number]: IRouteConfigType } = {
	...customerDigitizationRoutes,
	...dashboardRoutes,
	...lockersRoutes,
	...webcacheRoutes,
	...fiturRoutes,
};
