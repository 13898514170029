import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaCarAlt } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const Dashboard = lazyWithRetry(() => import('./pages/Dashboard'));

export const dashboardRoutes: { [key: number]: IRouteConfigType } = {};

dashboardRoutes[routesIdMap.VehicleDashboard] = {
	component: Dashboard,
	exact: true,
	id: routesIdMap.VehicleDashboard,
	name: 'Vehicle',
	path: '/vehicle',
	permissions: [PermissionsType.Vehicle],
	menuText: 'Vehicle',
	icon: FaCarAlt,
};
