import { createSlice } from '@reduxjs/toolkit';

import {
	vehicleEngineClear,
	vehicleEngineDelete,
	vehicleEngineGetById,
	vehicleEngineInsert,
	vehicleEngineModify,
	vehicleEngineSearch,
} from '@crac/core/modules/fleet/vehicleEngine/state/actions/VehicleEngineActions';
import type { IVehicleEngineReducerState } from '@crac/core/modules/fleet/vehicleEngine/state/stateType/VehicleEngineReducerState';

const initialState: IVehicleEngineReducerState = {
	engines: [],
	vehicleEngineDeleteRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	vehicleEngineInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	vehicleEngineModifyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	vehicleEngineSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	vehicleEngineGetByIdRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const vehicleEngineSlice = createSlice({
	name: 'VehicleEngineSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// SEARCH
		builder
			.addCase(vehicleEngineSearch.pending, (state) => {
				return {
					...state,
					vehicleEngineSearchRequest: { inProgress: true, messages: [], ok: true },
				};
			})
			.addCase(vehicleEngineSearch.rejected, (state, action) => {
				return {
					...state,
					vehicleEngineSearchRequest: { inProgress: false, messages: action.payload || [], ok: false },
				};
			})
			.addCase(vehicleEngineSearch.fulfilled, (state, action) => {
				return {
					...state,
					vehicleEngineSearchRequest: { inProgress: false, messages: [], ok: true },
					engines: action.payload,
				};
			});
		// INSERT
		builder
			.addCase(vehicleEngineInsert.pending, (state) => {
				return {
					...state,
					vehicleEngineInsertRequest: { inProgress: true, messages: [], ok: true },
				};
			})
			.addCase(vehicleEngineInsert.rejected, (state, action) => {
				return {
					...state,
					vehicleEngineInsertRequest: { inProgress: false, messages: action.payload || [], ok: false },
				};
			})
			.addCase(vehicleEngineInsert.fulfilled, (state, action) => {
				return {
					...state,
					vehicleEngineInsertRequest: { inProgress: false, messages: [], ok: true },
					engines: [...state.engines, action.payload],
				};
			});
		// MODIFY
		builder
			.addCase(vehicleEngineModify.pending, (state) => {
				return {
					...state,
					vehicleEngineModifyRequest: { inProgress: true, messages: [], ok: true },
				};
			})
			.addCase(vehicleEngineModify.rejected, (state, action) => {
				return {
					...state,
					vehicleEngineModifyRequest: { inProgress: false, messages: action.payload || [], ok: false },
				};
			})
			.addCase(vehicleEngineModify.fulfilled, (state, action) => {
				return {
					...state,
					vehicleEngineModifyRequest: { inProgress: false, messages: [], ok: true },
					engines: state.engines.map((item) => (item.id === action.payload.id ? action.payload : item)),
				};
			});
		// DELETE
		builder
			.addCase(vehicleEngineDelete.pending, (state) => {
				return {
					...state,
					vehicleEngineDeleteRequest: { inProgress: true, messages: [], ok: true },
				};
			})
			.addCase(vehicleEngineDelete.rejected, (state, action) => {
				return {
					...state,
					vehicleEngineDeleteRequest: { inProgress: false, messages: action.payload || [], ok: false },
				};
			})
			.addCase(vehicleEngineDelete.fulfilled, (state, action) => {
				return {
					...state,
					vehicleEngineDeleteRequest: { inProgress: false, messages: [], ok: true },
					engines: state.engines.filter(({ id }) => id !== action.payload.id),
				};
			});
		// GET BY ID
		builder
			.addCase(vehicleEngineGetById.pending, (state) => {
				return {
					...state,
					vehicleEngineGetByIdRequest: { inProgress: true, messages: [], ok: true },
				};
			})
			.addCase(vehicleEngineGetById.rejected, (state, action) => {
				return {
					...state,
					vehicleEngineGetByIdRequest: { inProgress: false, messages: action.payload || [], ok: false },
				};
			})
			.addCase(vehicleEngineGetById.fulfilled, (state, action) => {
				return {
					...state,
					vehicleEngineGetByIdRequest: { inProgress: false, messages: [], ok: true },
					engines: [...state.engines, action.payload],
				};
			});
		// CLEAR
		builder.addCase(vehicleEngineClear, (state) => {
			return {
				...state,
				engines: [],
			};
		});
	},
});

export const vehicleEngineReducer = vehicleEngineSlice.reducer;
