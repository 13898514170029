import { ExcessRateEndpoints } from './ExcessRateEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IExcessRate } from '../entities/ExcessRate';

export interface IExcessRateModifyParams extends IExcessRate, IServiceBaseParams {
	provider: string;
}

/**
 * Modify an excess rate
 * @param model - excess rate to modify
 * @returns - modified excess rate
 */
export const excessRateModifyService = (model: IExcessRateModifyParams) => {
	return Api.post<IExcessRate, IExcessRateModifyParams>(ExcessRateEndpoints.MODIFY, model);
};
