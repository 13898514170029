import { BudgetEndPoints } from './BudgetEndpoints';
import type { WorkType } from '../../../../models/types/WorkType';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../../shared/types/ServiceResponse';
import type { IBudget } from '../entities/Budget';

export interface IBudgetImportParams extends IServiceBaseParams {
	plateNumber: string;
	vehicleLineId: number;
	file?: File;
	pdf: File;
	workType: WorkType;
	workShopCode: string;
	budgetId?: number;
}

export const budgetImportService = (model: IBudgetImportParams): Promise<ServiceResponse<IBudget>> => {
	return Api.post<IBudget, IBudgetImportParams>(BudgetEndPoints.IMPORT, model);
};
