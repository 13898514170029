import { FaTableCells } from 'react-icons/fa6';

import { PermissionsType } from '@crac/core/models/types/PermissionsType';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const Lockers = lazyWithRetry(() => import('./pages/Lockers'));
const LockerManagement = lazyWithRetry(() => import('./pages/LockerManagement'));

export const lockersRoutes: { [key: number]: IRouteConfigType } = {};

lockersRoutes[routesIdMap.Lockers] = {
	component: Lockers,
	exact: true,
	id: routesIdMap.Lockers,
	name: 'Lockers',
	path: '/vehicle/lockers',
	permissions: [PermissionsType.LockerView],
	icon: FaTableCells,
	menuText: 'Lockers',
};

lockersRoutes[routesIdMap.LockersManagement] = {
	component: LockerManagement,
	exact: true,
	id: routesIdMap.LockersManagement,
	name: 'Lockers management',
	path: '/vehicle/set-locker-cell',
	permissions: [PermissionsType.LockerManagement],
	icon: FaTableCells,
	menuText: 'Lockers management',
};
