import { VehicleBrandEndPoints } from './FleetVehicleBrandEndpoints';
import { Api } from '../../../../modules/shared/api';
import type { IServiceBaseParams } from '../../../../modules/shared/types/ServiceBaseParams';
import type { IVehicleBrand } from '../entities/VehicleBrand';

export interface IFleetVehicleBrandInsertParams extends IVehicleBrand, IServiceBaseParams {}

export const fleetVehicleBrandInsertService = (model: IFleetVehicleBrandInsertParams) => {
	return Api.post<IVehicleBrand, IFleetVehicleBrandInsertParams>(VehicleBrandEndPoints.INSERT, model);
};
