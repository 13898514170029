import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaRegFileAlt } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const Services = lazyWithRetry(() => import('./pages/Services'));

export const servicesRoutes: { [key: number]: IRouteConfigType } = {};

servicesRoutes[routesIdMap.BookingsNewServices] = {
	component: Services,
	exact: true,
	id: routesIdMap.BookingsNewServices,
	name: 'Booking services',
	path: '/booking/new/services',
	permissions: [PermissionsType.BookingNew],
	icon: FaRegFileAlt,
};
