import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

import { InsuranceRateEndPoints } from './InsuranceRateEndpoints';
import type { IInsuranceRate } from '../entities/InsuranceRate';

export interface IInsuranceRateModifyParams extends IInsuranceRate, IServiceBaseParams {}

/**
 * Modify insurance rate
 * @param model - IInsuranceRateModifyParams
 * @returns IInsuranceRate
 */
export const insuranceRateModifyService = (model: IInsuranceRateModifyParams) => {
	return Api.post<IInsuranceRate, IInsuranceRateModifyParams>(InsuranceRateEndPoints.MODIFY, model);
};
