import {
	workshopAssignmentServiceDelete,
	workshopAssignmentServiceInsert,
	workshopAssignmentServiceModify,
	workshopAssignmentServiceSearch,
} from '../../data/services/WorkshopAssignmentService';
import type { IWorkshopAssignment } from '../../models/entities/WorkshopAssignment';
import type {
	IWorkshopAssignmentDeleteParams,
	IWorkshopAssignmentInsertParams,
	IWorkshopAssignmentModifyParams,
	IWorkshopAssignmentSearchParams,
} from '../../models/serviceParams/WorkshopAssignmentParams';
import { createSyncAction } from '../../modules/shared/state/createAction';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * @deprecated - use 'workshopAssignmentSearch' from 'packages\core\src\modules\workshop\assignment\state\actions\WorkshopAssignmentActions.ts' instead.
 */
export const workshopAssignmentSearch = createAsyncAction<IWorkshopAssignment[], IWorkshopAssignmentSearchParams>(
	'workshop/assignment/search',
	workshopAssignmentServiceSearch,
);

/**
 * @deprecated - use 'workshopAssignmentClear' from 'packages\core\src\modules\workshop\assignment\state\actions\WorkshopAssignmentActions.ts' instead.
 */
export const workshopAssignmentClear = createSyncAction('workshop/assignment/clear');

/**
 * @deprecated - use 'workshopAssignmentInsert' from 'packages\core\src\modules\workshop\assignment\state\actions\WorkshopAssignmentActions.ts' instead.
 */
export const workshopAssignmentInsert = createAsyncAction<IWorkshopAssignment, IWorkshopAssignmentInsertParams>(
	'workshop/assignment/insert',
	workshopAssignmentServiceInsert,
);

/**
 * @deprecated - use 'workshopAssignmentModify' from 'packages\core\src\modules\workshop\assignment\state\actions\WorkshopAssignmentActions.ts' instead.
 */
export const workshopAssignmentModify = createAsyncAction<IWorkshopAssignment, IWorkshopAssignmentModifyParams>(
	'workshop/assignment/modify',
	workshopAssignmentServiceModify,
);

/**
 * @deprecated - use 'workshopAssignmentDelete' from 'packages\core\src\modules\workshop\assignment\state\actions\WorkshopAssignmentActions.ts' instead.
 */
export const workshopAssignmentDelete = createAsyncAction<IWorkshopAssignment, IWorkshopAssignmentDeleteParams>(
	'workshop/assignment/delete',
	workshopAssignmentServiceDelete,
);
