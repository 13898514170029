import {
	excessRateServiceDelete,
	excessRateServiceGetAll,
	excessRateServiceInsert,
	excessRateServiceModify,
	excessRateServiceSearch,
} from '../../data/services/ExcessRateService';
import type { IExcessRate, IExcessRatePerProvider } from '../../models/entities/ExcessRate';
import type {
	IExcessRateDeleteParams,
	IExcessRateInsertParams,
	IExcessRateModifyParams,
	IExcessRateSearchParams,
} from '../../models/serviceParams/ExcessRateParams';
import { createSyncAction } from '../../modules/shared/state/createAction';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';
import type { IServiceBaseParams } from '../../modules/shared/types/ServiceBaseParams';

/**
 * @deprecated - use 'excessRateSearch' from 'packages\core\src\modules\workshop\excessRate\state\actions\ExcessRateActions.ts' instead.
 * Searches for excess rates based on specified parameters.
 *
 * @param {IExcessRate[]} payload - The excess rates to be returned.
 * @param {IExcessRateSearchParams} params - Parameters for the search.
 * @returns {Promise<void>} - A promise that resolves to an array of excess rates.
 */
export const excessRateSearch = createAsyncAction<IExcessRate[], IExcessRateSearchParams>(
	'excessRate/search',
	excessRateServiceSearch,
);

/**
 * @deprecated - use 'excessRateInsert' from 'packages\core\src\modules\workshop\excessRate\state\actions\ExcessRateActions.ts' instead.
 * Inserts new excess rates into the system.
 *
 * @param {IExcessRate[]} payload - The excess rates to be inserted.
 * @param {IExcessRateInsertParams} params - Parameters detailing the excess rates to be inserted.
 * @returns {Promise<void>} - A promise that resolves after the excess rates are successfully inserted.
 */
export const excessRateInsert = createAsyncAction<IExcessRate, IExcessRateInsertParams>(
	'excessRate/insert',
	excessRateServiceInsert,
);

/**
 * @deprecated - use 'excessRateClear' from 'packages\core\src\modules\workshop\excessRate\state\actions\ExcessRateActions.ts' instead.
 * Clears the excess rate state.
 */
export const excessRateClear = createSyncAction('excessRate/clear');

/**
 * @deprecated - use 'excessRateModify' from 'packages\core\src\modules\workshop\excessRate\state\actions\ExcessRateActions.ts' instead.
 * Modifies existing excess rates.
 *
 * @param {IExcessRate} payload - The excess rates to be modified.
 * @param {IExcessRateModifyParams} params - Parameters detailing the excess rates to be modified.
 * @returns {Promise<void>} - A promise that resolves after the excess rates are successfully modified.
 */
export const excessRateModify = createAsyncAction<IExcessRate, IExcessRateModifyParams>(
	'excessRate/modify',
	excessRateServiceModify,
);

/**
 * @deprecated - use 'excessRateDelete' from 'packages\core\src\modules\workshop\excessRate\state\actions\ExcessRateActions.ts' instead.
 * Deletes excess rates from the system.
 *
 * @param {IExcessRate} payload - The excess rate to be deleted.
 * @param {IExcessRateDeleteParams} params - Parameters detailing the excess rates to be deleted.
 * @returns {Promise<void>} - A promise that resolves after the excess rates are successfully deleted.
 */
export const excessRateDelete = createAsyncAction<IExcessRate, IExcessRateDeleteParams>(
	'excessRate/delete',
	excessRateServiceDelete,
);

/**
 * @deprecated - use 'excessRateGetAll' from 'packages\core\src\modules\workshop\excessRate\state\actions\ExcessRateActions.ts' instead.
 * Get all excess rates.
 *
 * @param {IExcessRatePerProvider[]} payload - The excess rates to be returned.
 * @param {IServiceBaseParams} params - Parameters detailing the excess rates to be returned.
 * @returns {Promise<void>} - A promise that resolves to an array of excess rates.
 */
export const excessRateGetAll = createAsyncAction<IExcessRatePerProvider[], IServiceBaseParams>(
	'excessRate/getAll',
	excessRateServiceGetAll,
);
