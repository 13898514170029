import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

import { ExcessTypeEndpoints } from './ExcessTypeEndpoints';
import type { IExcessType } from '../entities/ExcessType';

export interface IExcessTypeSaveParams extends IExcessType, IServiceBaseParams {
	provider: string;
}

export const excessTypeInsertService = (model: IExcessTypeSaveParams) => {
	return Api.post<IExcessType, IExcessTypeSaveParams>(ExcessTypeEndpoints.INSERT, model);
};
