import { createAction } from '@reduxjs/toolkit';

import { createAsyncAction } from '@crac/core/modules/shared/state/createAsyncAction';

import type { IVehicleColor } from '../../entities/VehicleColor';
import type { IFleetVehicleColorDeleteParams } from '../../services/FleetVehicleColorDeleteService';
import { fleetVehicleColorDeleteService } from '../../services/FleetVehicleColorDeleteService';
import type { IFleetVehicleColorInsertParams } from '../../services/FleetVehicleColorInsertService';
import { fleetVehicleColorInsertService } from '../../services/FleetVehicleColorInsertService';
import type { IFleetVehicleColorModifyParams } from '../../services/FleetVehicleColorModifyService';
import { fleetVehicleColorModifyService } from '../../services/FleetVehicleColorModifyService';
import type { IFleetVehicleColorSearchParams } from '../../services/FleetVehicleColorSearchService';
import { fleetVehicleColorSearchService } from '../../services/FleetVehicleColorSearchService';

/**
 * Clears the vehicle brand data from the state.
 */
export const fleetVehicleColorClear = createAction('fleet/vehicleColorClear');

/**
 * Searches for vehicle colors based on search parameters.
 * @param {IVehicleColor[]} payload `IVehicleColor[]` The payload for the action, typically search results.
 * @param {IFleetVehicleColorSearchParams} IFleetVehicleColorSearchParams The parameters used for searching vehicle colors.
 * @returns {Promise<void>} `Promise<void>` - A promise that resolves to an array of vehicle colors based on the search criteria.
 */
export const fleetVehicleColorSearch = createAsyncAction<IVehicleColor[], IFleetVehicleColorSearchParams>(
	'fleet/vehicleColor/search',
	fleetVehicleColorSearchService,
);

/**
 * Inserts a new vehicle color into the fleet.
 * @param {IVehicleColor} payload `IVehicleColor` The vehicle color to be inserted.
 * @param {IFleetVehicleColorInsertParams} IFleetVehicleColorInsertParams The parameters for inserting a new vehicle color.
 * @returns {Promise<void>} `Promise<void>` - A promise that resolves when the vehicle color has been inserted.
 */
export const fleetVehicleColorInsert = createAsyncAction<IVehicleColor, IFleetVehicleColorInsertParams>(
	'fleet/vehicleColor/insert',
	fleetVehicleColorInsertService,
);

/**
 * Modifies an existing vehicle color in the fleet.
 * @param {IVehicleColor} payload `IVehicleColor` The vehicle color to be modified.
 * @param {IFleetVehicleColorModifyParams} IFleetVehicleColorModifyParams The parameters for modifying an existing vehicle color.
 * @returns {Promise<void>} `Promise<void>` - A promise that resolves when the vehicle color has been modified.
 */
export const fleetVehicleColorModify = createAsyncAction<IVehicleColor, IFleetVehicleColorModifyParams>(
	'fleet/vehicleColor/modify',
	fleetVehicleColorModifyService,
);

/**
 * Deletes a vehicle color from the fleet.
 * @param {IVehicleColor} payload `IVehicleColor` The vehicle color to be deleted.
 * @param {IFleetVehicleColorDeleteParams} IFleetVehicleColorDeleteParams The parameters for deleting a vehicle color.
 * @returns {Promise<void>} `Promise<void>` - A promise that resolves when the vehicle color has been deleted.
 */
export const fleetVehicleColorDelete = createAsyncAction<IVehicleColor, IFleetVehicleColorDeleteParams>(
	'fleet/vehicleColor/delete',
	fleetVehicleColorDeleteService,
);
