import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaSignOutAlt } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const Search = lazyWithRetry(() => import('./pages/Search'));

export const searchRoutes: { [key: number]: IRouteConfigType } = {};

searchRoutes[routesIdMap.BookingsDropOff] = {
	component: Search,
	exact: true,
	id: routesIdMap.BookingsDropOff,
	name: 'Drop off',
	path: '/booking/drop-off',
	permissions: [PermissionsType.BookingDropOff],
	menuText: 'Drop off',
	icon: FaSignOutAlt,
};
