import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaChartColumn, FaPencil, FaPlus } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

import { getPricingPermission } from '../utils/helpers';

const RentRateVariation = lazyWithRetry(() => import('./pages/RentRateVariation'));
const RentRateVariationNew = lazyWithRetry(() => import('./pages/RentRateVariationNew'));
const RentRateVariationEdit = lazyWithRetry(() => import('./pages/RentRateVariationEdit'));

export const rentRateVariationRoutes: { [key: number]: IRouteConfigType } = {};

// VARIATIONS
rentRateVariationRoutes[routesIdMap.RentRateVariation] = {
	component: RentRateVariation,
	exact: true,
	id: routesIdMap.RentRateVariation,
	menuText: 'Variations',
	name: 'Rent rate variations',
	path: '/pricing/rent-rate-variation',
	permissions: getPricingPermission([PermissionsType.PricingRentRateVariation]),
	childrens: [routesIdMap.RentRateVariationNew, routesIdMap.RentRateVariationEdit],
	icon: FaChartColumn,
};

rentRateVariationRoutes[routesIdMap.RentRateVariationNew] = {
	component: RentRateVariationNew,
	exact: true,
	isChild: true,
	id: routesIdMap.RentRateVariationNew,
	name: 'New rent rate variation',
	path: '/pricing/rent-rate-variation/new',
	permissions: getPricingPermission([PermissionsType.PricingRentRateVariation]),
	icon: FaPlus,
	menuText: 'New variation',
};

rentRateVariationRoutes[routesIdMap.RentRateVariationEdit] = {
	component: RentRateVariationEdit,
	exact: true,
	isChild: true,
	id: routesIdMap.RentRateVariationEdit,
	name: 'Edit variation',
	path: '/pricing/rent-rate-variation/modify/:id',
	permissions: getPricingPermission([PermissionsType.PricingRentRateVariation]),
	icon: FaPencil,
};
