import { FleetMovementRestrictionEndPoints } from './MovementRestrictionEndpoints';
import { Api } from '../../../../modules/shared/api';
import type { IServiceBaseParams } from '../../../../modules/shared/types/ServiceBaseParams';
import type { IVehicle } from '../../../../modules/vehicle/entities/Vehicle';
import type { MovementRestrictionType } from '../../../../modules/vehicle/types/MovementRestrictionType';

export interface IFleetMovementRestriction {
	plateNumber: string;
	movementRestriction: MovementRestrictionType;
}

export interface IFleetMovementRestrictionUpdateParams extends IServiceBaseParams {
	restrictionMovements: IFleetMovementRestriction[];
}

export const fleetMovementRestrictionUpdateService = (model: IFleetMovementRestrictionUpdateParams) => {
	return Api.post<IVehicle[], IFleetMovementRestrictionUpdateParams>(FleetMovementRestrictionEndPoints.UPDATE, model);
};
