import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaMapMarker, FaPlus } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const Location = lazyWithRetry(() => import('./pages/Location'));
const LocationNew = lazyWithRetry(() => import('./pages/LocationNew'));
const LocationModify = lazyWithRetry(() => import('./pages/LocationModify'));

export const locationRoutes: { [key: number]: IRouteConfigType } = {};

locationRoutes[routesIdMap.WorkshopLocation] = {
	component: Location,
	exact: true,
	id: routesIdMap.WorkshopLocation,
	name: 'Locations',
	path: '/workshop/locations',
	permissions: [PermissionsType.Fleet],
	icon: FaMapMarker,
	menuText: 'Locations',
	childrens: [routesIdMap.WorkshopLocationNew, routesIdMap.WorkshopLocationModify],
};

locationRoutes[routesIdMap.WorkshopLocationNew] = {
	component: LocationNew,
	exact: true,
	id: routesIdMap.WorkshopLocationNew,
	name: 'New Location',
	path: '/workshop/locations/new',
	permissions: [PermissionsType.Fleet],
	icon: FaPlus,
	menuText: 'New Location',
	isChild: true,
};

locationRoutes[routesIdMap.WorkshopLocationModify] = {
	component: LocationModify,
	exact: true,
	id: routesIdMap.WorkshopLocationModify,
	name: 'Modify Location',
	path: '/workshop/locations/:id',
	permissions: [PermissionsType.Fleet],
	isChild: true,
};
