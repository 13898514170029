import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaRegCheckSquare } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const RevisionChecklist = lazyWithRetry(() => import('./pages/RevisionChecklist'));

export const revisionChecklistRoutes: { [key: number]: IRouteConfigType } = {};

revisionChecklistRoutes[routesIdMap.WorkshopRevisionChecklist] = {
	component: RevisionChecklist,
	exact: true,
	id: routesIdMap.WorkshopRevisionChecklist,
	name: 'Revision checklist',
	menuText: 'Checklist',
	path: '/workshop/revision-checklist',
	icon: FaRegCheckSquare,
	// TODO
	permissions: [PermissionsType.RevisionType],
};
