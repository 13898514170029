import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { BudgetEndPoints } from './BudgetEndpoints';
import type { WorkType } from '../../../../models/types/WorkType';
import type { IBudget } from '../entities/Budget';

export interface IBudgetGetByPlateNumberParams extends IServiceBaseParams {
	plateNumber: string;
	workType: WorkType;
}

/**
 * Get budgets by plateNumber
 * @param {string} plateNumber Vehicle plateNumber
 * @return {Promise<ApiDataResponseType<Budget[]>>} `Promise<ApiDataResponseType<Budget[]>>`
 */
export const budgetGetByPlateNumberService = (
	model: IBudgetGetByPlateNumberParams,
): Promise<ServiceResponse<IBudget[]>> => {
	return Api.get<IBudget[], IBudgetGetByPlateNumberParams>(BudgetEndPoints.GET_BY_PLATE_NUMBER, model);
};
