import { VehicleModifyEndPoints } from './VehicleModifyEndpoints';
import { Api } from '../../../../modules/shared/api';
import type { IServiceBaseParams } from '../../../../modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../../../modules/shared/types/ServiceResponse';
import type { IVehicle } from '../../../../modules/vehicle/entities/Vehicle';

export interface IVehicleChangeBrandParkingParams extends IServiceBaseParams {
	brandParkingId: number;
	plateNumbers: string[];
}

export const vehicleModifyChangeBrandParkingService = (
	model: IVehicleChangeBrandParkingParams,
): Promise<ServiceResponse<IVehicle[]>> => {
	return Api.post<IVehicle[], IVehicleChangeBrandParkingParams>(VehicleModifyEndPoints.CHANGE_BRAND_PARKING, model);
};
