import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { CustomerEndPoints } from './CustomerEndpoints';
import type { ICustomer } from '../entities/Customer';

export interface ICustomerGetByCodeParams extends IServiceBaseParams {
	customerCode: string;
	closedBookings?: boolean;
}

/**
 * Search customer by code
 *  {string} customerCode Customer code
 * @return {Promise} `CustomerPropType` Return customer
 */
export const customerGetByCodeService = (model: ICustomerGetByCodeParams): Promise<ServiceResponse<ICustomer>> => {
	return Api.get<ICustomer, ICustomerGetByCodeParams>(CustomerEndPoints.GET_BY_CODE, model);
};
