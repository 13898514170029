import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaDesktopAlt } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const Computer = lazyWithRetry(() => import('./pages/Computer'));

export const computerRoutes: { [key: number]: IRouteConfigType } = {};

computerRoutes[routesIdMap.ManagementComputers] = {
	component: Computer,
	exact: true,
	id: routesIdMap.ManagementComputers,
	name: 'Computers',
	path: '/management/computers',
	permissions: [PermissionsType.Access],
	icon: FaDesktopAlt,
	menuText: 'Computers',
};

computerRoutes[routesIdMap.ManagementComputersDetail] = {
	component: Computer,
	exact: true,
	id: routesIdMap.ManagementComputersDetail,
	name: 'Computer',
	path: '/management/computers/:id',
	permissions: [PermissionsType.Access],
	icon: FaDesktopAlt,
};
