import { VehicleModifyEndPoints } from './VehicleModifyEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../../shared/types/ServiceResponse';
import type { IVehicle } from '../../../vehicle/entities/Vehicle';

export interface IVehicleChangeAgreedRepurchaseDateParams extends IServiceBaseParams {
	plateNumbers: string[];
	agreedRepurchaseDate: string;
}

export const vehicleModifyChangeAgreedRepurchaseDateService = (
	model: IVehicleChangeAgreedRepurchaseDateParams,
): Promise<ServiceResponse<IVehicle[]>> => {
	return Api.post<IVehicle[], IVehicleChangeAgreedRepurchaseDateParams>(
		VehicleModifyEndPoints.CHANGE_AGREED_REPURCHASE_DATE,
		model,
	);
};
