import { SupplierLocationEndpoints } from './SupplierLocationEndpoints';
import type { ISupplierLocationInsertParams } from './SupplierLocationInsertService';
import { Api } from '../../../shared/api';
import type { ISupplierLocation } from '../entities/SupplierLocation';

export interface ISupplierLocationModifyParams extends ISupplierLocationInsertParams {
	id: number;
}

export const supplierLocationModifyService = (model: ISupplierLocationModifyParams) =>
	Api.post<ISupplierLocation, ISupplierLocationModifyParams>(SupplierLocationEndpoints.MODIFY, model);
