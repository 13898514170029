import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

import { WorkshopAssignmentEndPoints } from './WorkshopAssignmentEndPoints';
import type { IWorkshopAssignment } from '../entities/WorkshopAssignment';

export interface IWorkshopAssignmentInsertParams extends IWorkshopAssignment, IServiceBaseParams {}

/**
 * Insert a new workshop assignment
 * @param model - workshop assignment to insert
 * @returns - inserted workshop assignment
 */
export const workshopAssignmentInsertService = (model: IWorkshopAssignmentInsertParams) => {
	return Api.post<IWorkshopAssignment, IWorkshopAssignmentInsertParams>(WorkshopAssignmentEndPoints.INSERT, model);
};
