import { createSlice } from '@reduxjs/toolkit';

import {
	fleetTransferDeactivate,
	fleetTransferExport,
	fleetTransferInsert,
	fleetTransferModify,
	fleetTransferSearch,
	fleetTransferSearchClear,
} from '@crac/core/modules/fleet/transfer/state/actions/FleetTransferActions';
import type { IFleetTransferReducerState as ITransferReducerState } from '@crac/core/modules/fleet/transfer/state/stateType/FleetTransferStateType';

const initialState: ITransferReducerState = {
	fleetTransferDeactivateRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetTransferInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetTransfers: [],
	fleetTransferSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetTransferModifyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetTransferExportRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const transferSlice = createSlice({
	name: 'Transfer',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// SEARCH
		builder
			.addCase(fleetTransferSearch.pending, (state) => {
				return {
					...state,
					fleetTransferSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetTransferSearch.fulfilled, (state, action) => {
				return {
					...state,
					fleetTransferSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					fleetTransfers: action.payload,
				};
			})
			.addCase(fleetTransferSearch.rejected, (state, action) => {
				return {
					...state,
					fleetTransferSearchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// INSERT
		builder
			.addCase(fleetTransferInsert.pending, (state) => {
				return {
					...state,
					fleetTransferInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetTransferInsert.fulfilled, (state, action) => {
				return {
					...state,
					fleetTransferInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					fleetTransfers: action.payload,
				};
			})
			.addCase(fleetTransferInsert.rejected, (state, action) => {
				return {
					...state,
					fleetTransferInsertRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CLEAR
		builder.addCase(fleetTransferSearchClear, (state) => {
			return {
				...state,
				fleetTransfers: [],
			};
		});
		// DEACTIVATE
		builder
			.addCase(fleetTransferDeactivate.pending, (state) => {
				return {
					...state,
					fleetTransferDeactivateRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetTransferDeactivate.fulfilled, (state, action) => {
				return {
					...state,
					fleetTransferDeactivateRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					fleetTransfers: [
						...state.fleetTransfers.filter(({ id }) => !action.payload.map((item) => item.id).includes(id)),
						...action.payload,
					],
				};
			})
			.addCase(fleetTransferDeactivate.rejected, (state, action) => {
				return {
					...state,
					fleetTransferDeactivateRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// MODIFY
		builder
			.addCase(fleetTransferModify.pending, (state) => {
				return {
					...state,
					fleetTransferModifyRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetTransferModify.fulfilled, (state, action) => {
				return {
					...state,
					fleetTransferModifyRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					fleetTransfers: action.payload,
				};
			})
			.addCase(fleetTransferModify.rejected, (state, action) => {
				return {
					...state,
					fleetTransferModifyRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// EXPORT
		builder.addCase(fleetTransferExport.pending, (state) => {
			return {
				...state,
				fleetTransferExportRequest: {
					inProgress: true,
					messages: [],
					ok: true,
				},
			};
		});
		builder.addCase(fleetTransferExport.rejected, (state, action) => {
			return {
				...state,
				fleetTransferExportRequest: {
					inProgress: false,
					messages: action.payload || [],
					ok: false,
				},
			};
		});
		builder.addCase(fleetTransferExport.fulfilled, (state) => {
			return {
				...state,
				fleetTransferExportRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
			};
		});
	},
});

export const transferReducer = transferSlice.reducer;
