import { type FC } from 'react';

import { splitCamelCase } from '@crac/core/helpers/commons';
import type { IEmployee } from '@crac/core/models/entities/Employee';
import type { IPermissionsToggleParams } from '@crac/core/redux/actions/PermissionsActions';
import { Button } from '@crac/ui/button';
import { Checkbox } from '@crac/ui/checkbox';
import { Icon } from '@crac/ui/icons/Icon';
import { Label } from '@crac/ui/label';
import { Select } from '@crac/ui/select';
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from '@crac/ui/sheet';
import { Switch } from '@crac/ui/switch';

import { permissionsCategories } from './permissionsCategories';
import { useHeaderPermissions } from './useHeaderPermissions';

interface IHeaderPermissionsProps {
	employee: IEmployee;
	permissionsToolEnabled: boolean;
	permissions: string[];
	togglePermissions: (payload: IPermissionsToggleParams) => void;
	updatePermissions: (permissions: string[]) => void;
}

export const HeaderPermissions: FC<IHeaderPermissionsProps> = ({
	employee,
	permissionsToolEnabled,
	permissions,
	togglePermissions,
	updatePermissions,
}) => {
	const {
		permissionType,
		permissionsByType,
		handleChangePermissionType,
		handleChangePermission,
		handleTogglePermissionTool,
	} = useHeaderPermissions({
		employee,
		permissions,
		togglePermissions,
		updatePermissions,
	});

	return (
		<Sheet>
			<SheetTrigger asChild>
				<Button className="permissions-sheet-trigger tw-text-primary" color="light">
					<Icon className="tw-w-4 tw-h-4" name="FaBars" />
				</Button>
			</SheetTrigger>
			<SheetContent>
				<SheetHeader>
					<SheetTitle className="tw-text-left">Permissions panel</SheetTitle>
					<div className="tw-mt-6">
						<Label className="permissions-label">
							<Switch checked={permissionsToolEnabled} onCheckedChange={handleTogglePermissionTool} />
							Toggle custom permissions
						</Label>
						{permissionsToolEnabled && permissions ? (
							<div className="tw-mt-6">
								<Select
									className="tw-text-left"
									defaultValue={permissionType}
									// @ts-expect-error todo
									onChange={handleChangePermissionType}
									options={permissionsCategories}
								/>
								<div className="permissions-container" id="appPermissionsSidebar">
									{permissionsByType?.map((permission) => (
										<Label className="permission-label" key={permission}>
											<Checkbox
												defaultChecked={permissions.includes(permission)}
												name={permission}
												onCheckedChange={(checked) =>
													handleChangePermission(permission, checked as boolean)
												}
											/>
											{splitCamelCase(permission)}
										</Label>
									))}
								</div>
							</div>
						) : null}
					</div>
				</SheetHeader>
			</SheetContent>
		</Sheet>
	);
};
