import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { IVehicle } from '@crac/core/modules/vehicle/entities/Vehicle';

import { VehicleEndPoints } from '../../../../data/endPoints/VehicleEndPoints';

export interface ISalePriceModifyChangeAmountParams extends IServiceBaseParams {
	saleAmount: number;
	plateNumbers: string[];
}

export const salePriceModifyChangePriceService = (model: ISalePriceModifyChangeAmountParams) => {
	return Api.post<IVehicle[], ISalePriceModifyChangeAmountParams>(VehicleEndPoints.CHANGE_SALE_PRICE, model);
};
