import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaList } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const History = lazyWithRetry(() => import('./pages/History'));

export const historyRoutes: { [key: number]: IRouteConfigType } = {};

historyRoutes[routesIdMap.VehiclesDetailHistory] = {
	component: History,
	exact: true,
	id: routesIdMap.VehiclesDetailHistory,
	name: 'History',
	path: '/vehicles/detail/:id/history',
	permissions: [PermissionsType.VehicleDetail],
	icon: FaList,
};
