import { VehicleGroupEndPoints } from './VehicleGroupEndpoints';
import type { IVehicleGroupInsertParams } from './VehicleGroupInsertService';
import { Api } from '../../../shared/api';
import type { IVehicleGroup } from '../entities/VehicleGroup';

/**
 * Modify vehicle group
 * @params IVehicleGroupInsertParams
 * @returns {Object} IVehicleGroup
 */
export const vehicleGroupModifyService = (model: IVehicleGroupInsertParams) =>
	Api.post<IVehicleGroup, IVehicleGroupInsertParams>(VehicleGroupEndPoints.MODIFY, model);
