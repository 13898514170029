import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaPencil, FaPlus, FaTriangleExclamation } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const ExcessType = lazyWithRetry(() => import('./pages/ExcessType'));
const ExcessTypeNew = lazyWithRetry(() => import('./pages/ExcessTypeNew'));
const ExcessTypeModify = lazyWithRetry(() => import('./pages/ExcessTypeModify'));

export const excessTypeRoutes: { [key: number]: IRouteConfigType } = {};

excessTypeRoutes[routesIdMap.WorkshopExcessType] = {
	component: ExcessType,
	exact: true,
	id: routesIdMap.WorkshopExcessType,
	name: 'Excess type',
	path: '/workshop/excess-type',
	icon: FaTriangleExclamation,
	permissions: [PermissionsType.ExcessType],
	childrens: [routesIdMap.WorkshopExcessTypeNew, routesIdMap.WorkshopExcessTypeModify],
	menuText: 'Excess type',
};

excessTypeRoutes[routesIdMap.WorkshopExcessTypeNew] = {
	component: ExcessTypeNew,
	exact: true,
	id: routesIdMap.WorkshopExcessTypeNew,
	name: 'New excess type',
	path: '/workshop/excess-type/new',
	permissions: [PermissionsType.ExcessType],
	isChild: true,
	menuText: 'New excess type',
	icon: FaPlus,
};

excessTypeRoutes[routesIdMap.WorkshopExcessTypeModify] = {
	component: ExcessTypeModify,
	exact: true,
	id: routesIdMap.WorkshopExcessTypeModify,
	name: 'Modify excess type',
	path: '/workshop/excess-type/modify',
	permissions: [PermissionsType.ExcessType],
	isChild: true,
	icon: FaPencil,
};
