import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaCar, FaPencil, FaPlus } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const MetaModel = lazyWithRetry(() => import('./pages/MetaModel'));
const MetaModelNew = lazyWithRetry(() => import('./pages/MetaModelNew'));
const MetaModelModify = lazyWithRetry(() => import('./pages/MetaModelModify'));

export const metaModelRoutes: { [key: number]: IRouteConfigType } = {};

metaModelRoutes[routesIdMap.FleetMetaModel] = {
	component: MetaModel,
	exact: true,
	id: routesIdMap.FleetMetaModel,
	name: 'Meta models',
	path: '/fleet/meta-model',
	permissions: [PermissionsType.MetaModel],
	icon: FaCar,
	menuText: 'Meta models',
	childrens: [routesIdMap.FleetMetaModelNew, routesIdMap.FleetMetaModelModify],
};

metaModelRoutes[routesIdMap.FleetMetaModelNew] = {
	component: MetaModelNew,
	exact: true,
	id: routesIdMap.FleetMetaModelNew,
	name: 'New meta model',
	path: '/fleet/meta-model/new',
	permissions: [PermissionsType.MetaModelManagement],
	icon: FaPlus,
	menuText: 'New meta model',
};

metaModelRoutes[routesIdMap.FleetMetaModelModify] = {
	component: MetaModelModify,
	exact: true,
	id: routesIdMap.FleetMetaModelModify,
	name: 'Modify meta model',
	path: '/fleet/meta-model/:id',
	permissions: [PermissionsType.MetaModel],
	icon: FaPencil,
};
