import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaCheckAlt } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const Pendings = lazyWithRetry(() => import('./pages/Pendings'));

export const pendingsRoutes: { [key: number]: IRouteConfigType } = {};

pendingsRoutes[routesIdMap.CustomersDetailPendings] = {
	component: Pendings,
	exact: true,
	id: routesIdMap.CustomersDetailPendings,
	name: 'Pendings',
	path: '/customers/detail/:id/pendings',
	permissions: [PermissionsType.CustomerDetail],
	icon: FaCheckAlt,
};
