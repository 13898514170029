import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

import { InsuranceRateEndPoints } from './InsuranceRateEndpoints';
import type { IInsuranceRate } from '../entities/InsuranceRate';

export interface IInsuranceRateInsertParams extends IInsuranceRate, IServiceBaseParams {}

/**
 * Insert insurance rate
 * @param model - IInsuranceRateInsertParams
 * @returns IInsuranceRate
 */
export const insuranceRateInsertService = (model: IInsuranceRateInsertParams) => {
	return Api.post<IInsuranceRate, IInsuranceRateInsertParams>(InsuranceRateEndPoints.INSERT, model);
};
