import { FineReasonEndPoints } from './FineReasonEndpoints';
import { Api } from '../../../../modules/shared/api';
import type { IServiceBaseParams } from '../../../../modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../../../modules/shared/types/ServiceResponse';
import type { IFineReason } from '../entities/FineReason';

export interface IFineReasonInsertParams extends IServiceBaseParams {
	companyPay: boolean;
	description: string;
	toll: boolean;
}

/**
 * Insert a fine reason
 * @return {Promise<FineReasonType>} `FineReasonType`
 */
export const fineReasonInsertService = (model: IFineReasonInsertParams): Promise<ServiceResponse<IFineReason>> => {
	return Api.post<IFineReason, IFineReasonInsertParams>(FineReasonEndPoints.INSERT, model);
};
