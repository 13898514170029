import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaPlus, FaRegCalendarAlt } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

import { getPricingPermission } from '../utils/helpers';

const MaximumBookingDuration = lazyWithRetry(() => import('./pages/MaximumBookingDuration'));
const MaximumBookingDurationNew = lazyWithRetry(() => import('./pages/MaximumBookingDurationNew'));

export const maximumBookingDurationRoutes: { [key: number]: IRouteConfigType } = {};

maximumBookingDurationRoutes[routesIdMap.MaximumBookingDuration] = {
	component: MaximumBookingDuration,
	exact: true,
	id: routesIdMap.MaximumBookingDuration,
	name: 'Maximum booking durations',
	path: '/pricing/maximum-booking-durations',
	permissions: getPricingPermission([PermissionsType.PricingMaximumBookingDuration]),
	childrens: [routesIdMap.MaximumBookingDurationNew, routesIdMap.MaximumBookingDurationNewFrom],
	icon: FaRegCalendarAlt,
	menuText: 'Maximum booking durations',
};

maximumBookingDurationRoutes[routesIdMap.MaximumBookingDurationNew] = {
	component: MaximumBookingDurationNew,
	exact: true,
	isChild: true,
	id: routesIdMap.MaximumBookingDurationNew,
	name: 'New maximum booking duration',
	path: '/pricing/maximum-booking-durations/new',
	permissions: getPricingPermission([PermissionsType.PricingMaximumBookingDuration]),
	icon: FaPlus,
	menuText: 'New maximum booking duration',
};

maximumBookingDurationRoutes[routesIdMap.MaximumBookingDurationNewFrom] = {
	component: MaximumBookingDurationNew,
	exact: true,
	isChild: true,
	id: routesIdMap.MaximumBookingDurationNewFrom,
	name: 'Maximum Booking Duration - New from',
	path: '/pricing/maximum-booking-durations/newFrom/:id',
	permissions: getPricingPermission([PermissionsType.PricingMaximumBookingDuration]),
	icon: FaPlus,
};
