import { createAction } from '@reduxjs/toolkit';

import {
	bookingDropOffServiceEnd,
	bookingDropOffServiceGetPendingsByPlateNumberAndBranchCode,
	bookingDropOffServiceInit,
	bookingDropOffServiceSendInvoice,
	bookingDropOffServiceStart,
} from '../../data/services/BookingDropOffService';
import type { IBooking } from '../../models/entities/Booking';
import type {
	IBookingDropOffEndParams,
	IBookingDropOffGetPendingByPlateNumberAndBranchCodeParams,
	IBookingDropOffInitParams,
	IBookingDropOffSendInvoiceParams,
	IBookingDropOffStartParams,
} from '../../models/serviceParams/BookingDropOffParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * @deprecated Use `bookingDropOffStart` from `modules/booking/dropOff/state/actions`
 * Initiates the drop-off process for a booking.
 *
 * @param {IBooking} payload - The booking to start the drop-off process.
 * @param {IBookingDropOffStartParams} params - Parameters needed to start the drop-off process.
 * @returns {Promise<void>} - A promise that resolves after initiating the drop-off process.
 */
export const bookingDropOffStart = createAsyncAction<IBooking, IBookingDropOffStartParams>(
	'bookingDropOff/start',
	bookingDropOffServiceStart,
);

/**
 * @deprecated Use `bookingDropOffSendInvoice` from `modules/booking/dropOff/state/actions`
 * Sends an invoice related to a booking drop-off.
 *
 * @param {IBooking} payload - The booking for which the invoice is sent.
 * @param {IBookingDropOffSendInvoiceParams} params - Parameters for sending the invoice.
 * @returns {Promise<void>} - A promise that resolves after sending the invoice.
 */
export const bookingDropOffSendInvoice = createAsyncAction<IBooking, IBookingDropOffSendInvoiceParams>(
	'bookingDropOff/sendInvoice',
	bookingDropOffServiceSendInvoice,
);

/**
 * @deprecated Use `bookingDropOffGetPendingsByPlateNumberAndBranchCode` from `modules/booking/dropOff/state/actions`
 * Retrieves pending drop-offs based on plate number and branch code.
 *
 * @param {IBooking[]} payload - List of bookings with pending drop-offs.
 * @param {IBookingDropOffGetPendingByPlateNumberAndBranchCodeParams} params - Parameters to identify pending drop-offs.
 * @returns {Promise<void>} - A promise that resolves to an array of pending bookings.
 */
export const bookingDropOffGetPendingsByPlateNumberAndBranchCode = createAsyncAction<
	IBooking[],
	IBookingDropOffGetPendingByPlateNumberAndBranchCodeParams
>('bookingDropOff/getPendingsByPlateNumberAndBranchCode', bookingDropOffServiceGetPendingsByPlateNumberAndBranchCode);

/**
 * @deprecated Use `bookingDropOffClearPendings` from `modules/booking/dropOff/state/actions`
 * Clears the pending drop-offs from the state.
 */
export const bookingDropOffClearPendings = createAction('bookingDropOff/clearPendings');

/**
 * @deprecated Use `bookingDropOffInit` from `modules/booking/dropOff/state/actions`
 * Initializes the booking drop-off process.
 *
 * @param {IBooking} payload - The booking to initialize for drop-off.
 * @param {IBookingDropOffInitParams} params - Parameters needed to initialize the drop-off.
 * @returns {Promise<void>} - A promise that resolves after initializing the drop-off process.
 */
export const bookingDropOffInit = createAsyncAction<IBooking, IBookingDropOffInitParams>(
	'bookingDropOff/init',
	bookingDropOffServiceInit,
);

/**
 * @deprecated Use `bookingDropOffEnd` from `modules/booking/dropOff/state/actions`
 * Ends the drop-off process for a booking.
 *
 * @param {IBooking} payload - The booking to end the drop-off process.
 * @param {IBookingDropOffEndParams} params - Parameters needed to end the drop-off process.
 * @returns {Promise<void>} - A promise that resolves after ending the drop-off process.
 */
export const bookingDropOffEnd = createAsyncAction<IBooking, IBookingDropOffEndParams>(
	'bookingDropOff/end',
	bookingDropOffServiceEnd,
);
