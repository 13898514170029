import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaPencil, FaPercentAlt, FaPlus } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

import { getPricingPermission } from '../utils/helpers';

const CouponConfiguration = lazyWithRetry(() => import('./pages/CouponConfiguration'));
const CouponConfigurationNew = lazyWithRetry(() => import('./pages/CouponConfigurationNew'));
const CouponConfigurationModify = lazyWithRetry(() => import('./pages/CouponConfigurationModify'));

export const couponConfigurationRoutes: { [key: number]: IRouteConfigType } = {};

// COUPON CONFIGURATION
couponConfigurationRoutes[routesIdMap.PricingCouponConfiguration] = {
	component: CouponConfiguration,
	exact: true,
	id: routesIdMap.PricingCouponConfiguration,
	name: 'Partner coupon configuration',
	menuText: 'Pre-applied coupon',
	path: '/pricing/coupon-configuration',
	permissions: getPricingPermission([PermissionsType.PricingCouponConfiguration]),
	childrens: [routesIdMap.PricingCouponConfigurationModify, routesIdMap.PricingCouponConfigurationNew],
	icon: FaPercentAlt,
};

couponConfigurationRoutes[routesIdMap.PricingCouponConfigurationNew] = {
	component: CouponConfigurationNew,
	exact: true,
	id: routesIdMap.PricingCouponConfigurationNew,
	name: 'New partner coupon configuration',
	path: '/pricing/coupon-configuration/new',
	permissions: getPricingPermission([PermissionsType.PricingCouponConfiguration]),
	icon: FaPlus,
	menuText: 'New partner coupon configuration',
};

couponConfigurationRoutes[routesIdMap.PricingCouponConfigurationModify] = {
	component: CouponConfigurationModify,
	exact: true,
	id: routesIdMap.PricingCouponConfigurationModify,
	name: 'Coupon configuration modify',
	path: '/pricing/coupon-configuration/:id',
	permissions: getPricingPermission([PermissionsType.PricingCouponConfiguration]),
	icon: FaPencil,
};
