import { VehicleColorEndPoints } from './FleetVehicleColorEndpoints';
import { Api } from '../../../../modules/shared/api';
import type { IServiceBaseParams } from '../../../../modules/shared/types/ServiceBaseParams';
import type { IVehicleColor } from '../entities/VehicleColor';

export interface IFleetVehicleColorModifyParams extends IVehicleColor, IServiceBaseParams {}

export const fleetVehicleColorModifyService = (model: IFleetVehicleColorModifyParams) => {
	return Api.post<IVehicleColor, IFleetVehicleColorModifyParams>(VehicleColorEndPoints.MODIFY, model);
};
