import { createAsyncAction } from '@crac/core/modules/shared/state/createAsyncAction';

import type { IDamageRepairCost } from '../../entities/DamageRepairCost';
import type { IDamageRepairCostInsertParams } from '../../services/DamageRepairCostInsertService';
import { damageRepairCostInsertService } from '../../services/DamageRepairCostInsertService';

export const damageRepairCostInsert = createAsyncAction<IDamageRepairCost, IDamageRepairCostInsertParams>(
	'workshop/damageRepairCost/insert',
	damageRepairCostInsertService,
);
