import { createSlice } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import {
	bookingLineClear,
	bookingLineDelete,
	bookingLineGetByBooking,
	bookingLineInsert,
	bookingLineModify,
	bookingLineModifyAmount,
} from '@crac/core/modules/booking/bookingLine/state/actions/BookingLineActions';
import type { IBookingLineStateType } from '@crac/core/modules/booking/bookingLine/state/stateType/BookingLineStateType';

const initialState: IBookingLineStateType = {
	bookingLineDeleteRequest: { inProgress: false, messages: [], ok: true },
	bookingLineInsertRequest: { inProgress: false, messages: [], ok: true },
	bookingLineModifyRequest: { inProgress: false, messages: [], ok: true },
	bookingLineModifyAmountRequest: { inProgress: false, messages: [], ok: true },
	bookingLinesRequest: { inProgress: false, messages: [], ok: true },
	bookingLines: [],
	bookingLinesToChangeInvoiceTo: [],
};

const linesSlice = createSlice({
	name: 'BookingLinesSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR
		builder.addCase(bookingLineClear, () => {
			return initialState;
		});
		// BOOKING_LINE_GET_BY_BOOKING
		builder
			.addCase(bookingLineGetByBooking.pending, (state) => {
				return {
					...state,
					bookingLinesRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
					bookingLines: [],
				};
			})
			.addCase(bookingLineGetByBooking.fulfilled, (state, action) => {
				return {
					...state,
					bookingLines: action.payload,
					bookingLinesRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingLineGetByBooking.rejected, (state, action) => {
				return {
					...state,
					bookingLinesRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// BOOKING_LINE_MODIFY
		builder
			.addCase(bookingLineModify.pending, (state) => {
				return {
					...state,
					bookingLineModifyRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingLineModify.fulfilled, (state, action) => {
				return {
					...state,
					bookingLineModifyRequest: { inProgress: false, messages: [], ok: true },
					bookingLines: [
						...state.bookingLines.filter((line) => line.key !== action.payload[0].key),
						...action.payload,
					],
				};
			})
			.addCase(bookingLineModify.rejected, (state, action) => {
				return {
					...state,
					bookingLineModifyRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// BOOKING_LINE_REMOVE
		builder
			.addCase(bookingLineDelete.pending, (state) => {
				return {
					...state,
					bookingLineDeleteRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingLineDelete.fulfilled, (state, action) => {
				return {
					...state,
					bookingLineDeleteRequest: { inProgress: false, messages: [], ok: true },
					bookingLines: state.bookingLines.filter((item) => item.key !== action.payload),
				};
			})
			.addCase(bookingLineDelete.rejected, (state, action) => {
				return {
					...state,
					bookingLineDeleteRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// BOOKING_LINE_INSERT
		builder
			.addCase(bookingLineInsert.pending, (state) => {
				return {
					...state,
					bookingLineInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingLineInsert.fulfilled, (state, action) => {
				return {
					...state,
					bookingLineInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					bookingLines: [...state.bookingLines, ...action.payload],
				};
			})
			.addCase(bookingLineInsert.rejected, (state, action) => {
				return {
					...state,
					bookingLineInsertRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// BOOKING_LINE_ADMON_MODIFY
		builder
			.addCase(bookingLineModifyAmount.pending, (state) => {
				return {
					...state,
					bookingLineModifyAmountRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingLineModifyAmount.fulfilled, (state, action) => {
				return {
					...state,
					bookingLineModifyAmountRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					bookingLines: [
						...state.bookingLines.filter(
							(line) => !action.payload.map((bline) => bline.key).includes(line.key),
						),
						...action.payload,
					],
				};
			})
			.addCase(bookingLineModifyAmount.rejected, (state, action) => {
				return {
					...state,
					bookingLineModifyAmountRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
	},
});

export const linesReducer = linesSlice.reducer;
