import { DropOffEndpoints } from './DropOffEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../../shared/types/ServiceResponse';
import type { IBooking } from '../../entities/Booking';

export interface IBookingDropOffGetByBranchParams extends IServiceBaseParams {
	branchCode: string;
}

/**
 * Get list pending drop off by branch code
 * {string} branchCode Branch code
 * @return {Promise} `BookingPropType` Use await for resolve
 */
export const bookingDropOffGetByBranchService = (
	model: IBookingDropOffGetByBranchParams,
): Promise<ServiceResponse<IBooking[]>> => {
	return Api.get<IBooking[], IBookingDropOffGetByBranchParams>(DropOffEndpoints.DROPOFF_GET_BY_BRANCH, model);
};
