import { createSlice } from '@reduxjs/toolkit';

import type { IFine } from '@crac/core/modules/fleet/fine/entities/Fine';
import { fineClear, fineInsert, fineModify, fineSearch } from '@crac/core/modules/fleet/fine/state/actions/FineActions';
import type { IFineReducerState } from '@crac/core/modules/fleet/fine/state/stateType/FineStateType';

const initialState: IFineReducerState = {
	fine: null,
	fines: [],
	fineLinks: [],
	fineLinksRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fineGetByBookingRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fineGetByIdRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fineGetByPlateNumberRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fineInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fineModifyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fineSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	notificationLinks: [],
	notificationLinksRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const fineSlice = createSlice({
	name: 'FineSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR
		builder.addCase(fineClear, (state) => {
			return {
				...state,
				fines: [],
			};
		});
		// SEARCH
		builder
			.addCase(fineSearch.pending, (state) => {
				return {
					...state,
					fineSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fineSearch.fulfilled, (state, action) => {
				return {
					...state,
					fineSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					fines: action.payload,
				};
			})
			.addCase(fineSearch.rejected, (state, action) => {
				return {
					...state,
					fineSearchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// INSERT
		builder
			.addCase(fineInsert.pending, (state) => {
				return {
					...state,
					fineInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fineInsert.fulfilled, (state, action) => {
				return {
					...state,
					fines: [...state.fines, action.payload],
					fineInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fineInsert.rejected, (state, action) => {
				return {
					...state,
					fineInsertRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// MODIFY
		builder
			.addCase(fineModify.pending, (state) => {
				return {
					...state,
					fineModifyRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fineModify.fulfilled, (state, action) => {
				const { code } = action.payload as IFine;
				const fleetFines = state.fines?.map((fine) => (fine.code === code ? { ...action.payload } : fine));
				return {
					...state,
					fines: fleetFines,
					fineModifyRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fineModify.rejected, (state, action) => {
				return {
					...state,
					fineModifyRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const fineReducer = fineSlice.reducer;
