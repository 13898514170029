import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaPlus, FaTable } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const Lockers = lazyWithRetry(() => import('./pages/Lockers'));
const LockersNew = lazyWithRetry(() => import('./pages/LockersNew'));

export const lockersRoutes: { [key: number]: IRouteConfigType } = {};

lockersRoutes[routesIdMap.ITLockers] = {
	component: Lockers,
	exact: true,
	id: routesIdMap.ITLockers,
	name: 'Lockers',
	path: '/it/lockers',
	permissions: [PermissionsType.IT],
	icon: FaTable,
	menuText: 'Lockers',
	childrens: [routesIdMap.ITLockersNew],
};

lockersRoutes[routesIdMap.ITLockersNew] = {
	component: LockersNew,
	exact: true,
	id: routesIdMap.ITLockersNew,
	name: 'New locker',
	path: '/it/lockers/new',
	permissions: [PermissionsType.IT],
	isChild: true,
	icon: FaPlus,
	menuText: 'New locker',
};
