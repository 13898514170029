import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaRegCheckSquare } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const Preparation = lazyWithRetry(() => import('./pages/Preparation'));

export const preparationRoutes: { [key: number]: IRouteConfigType } = {};

preparationRoutes[routesIdMap.VehiclesPreparation] = {
	component: Preparation,
	exact: true,
	id: routesIdMap.VehiclesPreparation,
	name: 'Vehicle preparation',
	path: '/vehicle/preparation',
	permissions: [PermissionsType.VehiclePreparation],
	icon: FaRegCheckSquare,
	menuText: 'Preparation',
};
