import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

import { ExcessTypeEndpoints } from './ExcessTypeEndpoints';
import type { IExcessType } from '../entities/ExcessType';

export interface IExcessTypeSearchParams extends IServiceBaseParams {
	provider: string;
	code?: string;
	name?: string;
}

export const excessTypeSearchService = (model: IExcessTypeSearchParams) => {
	return Api.get<IExcessType[], IExcessTypeSearchParams>(ExcessTypeEndpoints.SEARCH, model);
};
