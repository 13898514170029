import { getPackageAmountsWithDiscount } from './booking/BookingPackages';
import { getChoosableGroups } from './booking/BookingVehicleGroups';
import type { IAgency } from '../models/entities/Agency';
import type { IVehicleGroupAvailabilityAndPrice } from '../models/entities/Availability';
import type { IBooking } from '../models/entities/Booking';
import type { IBookingPackage } from '../models/entities/BookingPackage';
import type { IPaymentLine } from '../models/entities/PaymentLine';
import type { IProvider } from '../models/entities/Provider';
import type { IService } from '../models/entities/Service';
import type { IVehicleGroup } from '../models/entities/VehicleGroup';
import type { IBookingLine } from '../modules/booking/bookingLine/entities/BookingLine';

/**
 * Sort availability group by available, amount and code
 */
const sortAvailabilityGroups = (
	accomulate: IVehicleGroupAvailabilityAndPrice,
	currentValue: IVehicleGroupAvailabilityAndPrice,
): number => {
	const aAvailable = accomulate.available && accomulate.permittedTransfer && !accomulate.noRates;
	const bAvailable = currentValue.available && currentValue.permittedTransfer && !currentValue.noRates;

	const aGroupCode = accomulate.code;
	const bGroupCode = currentValue.code;

	if (aAvailable === bAvailable) {
		if (accomulate.retailAmount === currentValue.retailAmount) {
			return aGroupCode.localeCompare(bGroupCode);
		}

		return accomulate.retailAmount < currentValue.retailAmount ? -1 : 1;
	}

	return aAvailable ? -1 : 1;
};

export const sortAvailabilityGroupsByPackageAmountOrRentAmount = (groups: IVehicleGroupAvailabilityAndPrice[]) => {
	// Sort by package amount
	const hasMoreOnePackage = getChoosableGroups(groups).some((group) => group.packages.length > 1);

	if (hasMoreOnePackage) {
		return groups.sort(sortAvailabilityGroups);
	}

	return groups.sort((accomulate, currentValue) => {
		const [aPackage] = accomulate.packages;
		const [bPackage] = currentValue.packages;

		if (!aPackage || !bPackage) {
			return sortAvailabilityGroups(accomulate, currentValue);
		}

		const aAvailable = accomulate.available && accomulate.permittedTransfer && !accomulate.noRates;
		const bAvailable = currentValue.available && currentValue.permittedTransfer && !currentValue.noRates;

		if (aAvailable === bAvailable) {
			if (aPackage.retailAmount === bPackage.retailAmount) {
				return accomulate.code.localeCompare(currentValue.code);
			}

			return aPackage.retailAmount < bPackage.retailAmount ? -1 : 1;
		}

		return aAvailable ? -1 : 1;
	});
};

export const sortPaymentLine = (accomulate: IPaymentLine, currentValue: IPaymentLine): number => {
	const aDate = Number(new Date(accomulate.dateTime));
	const bDate = Number(new Date(currentValue.dateTime));

	if (aDate === bDate) {
		return accomulate.operation < currentValue.operation ? -1 : 1;
	}

	return aDate < bDate ? -1 : 1;
};

export const sortInvoiceToBookingLines = (accomulate: IBookingLine, currentValue: IBookingLine): number => {
	const aLineType = accomulate.bookingLineType;
	const bLineType = currentValue.bookingLineType;

	if (aLineType === bLineType) {
		if (accomulate.code === currentValue.code) {
			return accomulate.key - currentValue.key;
		}

		return accomulate.code.localeCompare(currentValue.code);
	}

	return aLineType - bLineType;
};

/**
 * Order service list by code and order
 */
export const sortServicesByOrderAndCode = (accomulate: IService, currentValue: IService): number => {
	const aOrder = accomulate.order;
	const bOrder = currentValue.order;

	if (aOrder === bOrder) {
		return accomulate.code.localeCompare(currentValue.code);
	}

	return aOrder < bOrder ? -1 : 1;
};

/**
 * Ordena listado de paquetes por precio y código
 */
export const sortPackageList = (accomulate: IBookingPackage, current: IBookingPackage): number => {
	const aPackageAmount = accomulate.retailAmount;
	const cPackageAmount = current.retailAmount;

	if (aPackageAmount === cPackageAmount) {
		return accomulate.code.localeCompare(current.code);
	}

	return aPackageAmount < cPackageAmount ? -1 : 1;
};

export const sortAgenciesByCode = (accumulate: IAgency, current: IAgency): number =>
	accumulate.code.localeCompare(current.code);

export const sortVehicleGroupsByCode = (accumulate: IVehicleGroup, current: IVehicleGroup): number =>
	accumulate.code.localeCompare(current.code);

export const sortInvoiceToAgency = (accumulateLine: IBookingLine, currentLine: IBookingLine): number => {
	if (accumulateLine.invoiceToAgency === currentLine.invoiceToAgency) {
		return 0;
	}

	return accumulateLine.invoiceToAgency ? 1 : -1;
};

/**
 *
 * @param bookings List of bookings
 * @param order order to sort booking list
 */
export const sortBookingListByPickUpDateTime = (bookings: IBooking[], reverse?: boolean): IBooking[] => {
	return bookings.sort((accumulateBooking, currentBooking) => {
		if (accumulateBooking.pickUpDateTime && currentBooking.pickUpDateTime) {
			if (reverse) {
				return accumulateBooking.pickUpDateTime > currentBooking.pickUpDateTime ? -1 : 1;
			}

			return accumulateBooking.pickUpDateTime < currentBooking.pickUpDateTime ? -1 : 1;
		}
		return 1;
	});
};

/**
 * Sort package list by total amount. Only use when vehicle group has one package
 */
export const sortBookingGroupByTotalAmount = ({
	days,
	vehicleGroups,
	agencyCode,
	provider,
}: {
	days: number;
	vehicleGroups: IVehicleGroupAvailabilityAndPrice[];
	agencyCode?: string;
	provider?: IProvider | null;
}) => {
	const orderedVehicleGroups = vehicleGroups.sort((aVehicleGroup, bVehicleGroup) => {
		const aAmounts = getPackageAmountsWithDiscount({
			agencyCode,
			bookingPackage: aVehicleGroup.packages[0],
			days,
			discount: aVehicleGroup.discount,
			provider: provider ?? undefined,
			services: aVehicleGroup.services,
		});

		const bAmounts = getPackageAmountsWithDiscount({
			agencyCode,
			bookingPackage: bVehicleGroup.packages[0],
			days,
			discount: bVehicleGroup.discount,
			provider: provider ?? undefined,
			services: bVehicleGroup.services,
		});

		if (!aAmounts || !bAmounts) {
			return 0;
		}

		if (aAmounts.newAmount === bAmounts.newAmount) {
			// Return aVehicleGroup.code.localeCompare(bVehicleGroup.code);
			if (aAmounts.oldAmount === bAmounts.oldAmount) {
				return aVehicleGroup.code.localeCompare(bVehicleGroup.code);
			}

			return aAmounts?.oldAmount < bAmounts?.oldAmount ? -1 : 1;
		}

		return aAmounts?.newAmount < bAmounts?.newAmount ? -1 : 1;
	});

	return orderedVehicleGroups;
};

/**
 * Sort booking lines by extension number and key
 */
export const sortBookingLinesByExtensionNumberAndKey = (aLine: IBookingLine, bLine: IBookingLine) => {
	if (aLine?.extensionNumber === undefined || bLine?.extensionNumber === undefined) {
		return 0;
	}

	if (aLine.extensionNumber === bLine.extensionNumber) {
		return aLine.key < bLine.key ? -1 : 1;
	}

	return aLine?.extensionNumber > bLine?.extensionNumber ? 1 : -1;
};
