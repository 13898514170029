import { revisionCostServiceSave, revisionCostServiceSearch } from '../../data/services/RevisionCostService';
import type { IRevisionCost } from '../../models/entities/RevisionCost';
import type { IRevisionCostSaveParams, IRevisionCostSearchParams } from '../../models/serviceParams/RevisionCostParams';
import { createSyncAction } from '../../modules/shared/state/createAction';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * @deprecated - use 'revisionCostSearch' from 'packages\core\src\modules\workshop\revisionCost\state\actions\WorkshopRevisionCostActions.ts' instead.
 */
export const revisionCostSearch = createAsyncAction<IRevisionCost[], IRevisionCostSearchParams>(
	'workshop/revisionCost/search',
	revisionCostServiceSearch,
);

/**
 * @deprecated - use 'revisionCostSave' from 'packages\core\src\modules\workshop\revisionCost\state\actions\WorkshopRevisionCostActions.ts' instead.
 */
export const revisionCostSave = createAsyncAction<IRevisionCost[], IRevisionCostSaveParams>(
	'workshop/revisionCost/save',
	revisionCostServiceSave,
);

/**
 * @deprecated - use 'revisionCostClear' from 'packages\core\src\modules\workshop\revisionCost\state\actions\WorkshopRevisionCostActions.ts' instead.
 */
export const revisionCostClear = createSyncAction('workshop/revisionCost/clear');
