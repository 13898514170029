import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

import { VehicleBrandEndPoints } from './FleetVehicleBrandEndpoints';
import type { IVehicleBrand } from '../entities/VehicleBrand';

export interface IFleetVehicleBrandModifyParams extends IVehicleBrand, IServiceBaseParams {}

export const fleetVehicleBrandModifyService = (model: IFleetVehicleBrandModifyParams) => {
	return Api.post<IVehicleBrand, IFleetVehicleBrandModifyParams>(VehicleBrandEndPoints.MODIFY, model);
};
