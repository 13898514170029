import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaList, FaPencil, FaPlus } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

import { getPricingPermission } from '../utils/helpers';

const ServiceRate = lazyWithRetry(() => import('./pages/ServiceRate'));
const ServiceRateNew = lazyWithRetry(() => import('./pages/ServiceRateNew'));

export const serviceRateRoutes: { [key: number]: IRouteConfigType } = {};

// RATES
serviceRateRoutes[routesIdMap.ServiceRates] = {
	component: ServiceRate,
	exact: true,
	id: routesIdMap.ServiceRates,
	name: 'Service rates',
	menuText: 'Rates',
	path: '/pricing/service-rates',
	permissions: getPricingPermission([PermissionsType.PricingServiceRate]),
	childrens: [routesIdMap.ServiceRatesNew, routesIdMap.ServiceRatesNewFrom],
	icon: FaList,
};
serviceRateRoutes[routesIdMap.ServiceRatesNew] = {
	component: ServiceRateNew,
	exact: true,
	isChild: true,
	id: routesIdMap.ServiceRatesNew,
	name: 'New service rate',
	path: '/pricing/service-rates/new',
	permissions: getPricingPermission([PermissionsType.PricingServiceRate]),
	menuText: 'New service rate',
	icon: FaPlus,
};
serviceRateRoutes[routesIdMap.ServiceRatesNewFrom] = {
	component: ServiceRateNew,
	exact: true,
	isChild: true,
	id: routesIdMap.ServiceRatesNewFrom,
	name: 'New service rate',
	path: '/pricing/service-rates/newfrom/:id',
	permissions: getPricingPermission([PermissionsType.PricingServiceRate]),
	icon: FaPencil,
};
