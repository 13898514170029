import {
	fleetVehicleModelServiceActivate,
	fleetVehicleModelServiceDeactivate,
	fleetVehicleModelServiceInsert,
	fleetVehicleModelServiceModify,
	fleetVehicleModelServiceSearch,
} from '../../data/services/FleetVehicleModelService';
import type { IVehicleModel } from '../../models/entities/VehicleModel';
import type {
	IFleetVehicleModelDeactivateParams,
	IFleetVehicleModelInsertParams,
	IFleetVehicleModelModifyParams,
	IFleetVehicleModelSearchParams,
} from '../../models/serviceParams/FleetVehicleModelParams';
import { createSyncAction } from '../../modules/shared/state/createAction';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * @deprecated - use 'fleetVehicleModelSearch' from 'packages\core\src\modules\fleet\vehicleModel\state\actions\FleetVehicleModelActions.ts' instead.
 * Searches for fleet vehicle models based on search parameters.
 * @param {IFleetVehicleModelSearchParams} params The parameters used for searching fleet vehicle models.
 * @returns {Promise<void>} A promise that resolves when the search operation is complete.
 */
export const fleetVehicleModelSearch = createAsyncAction<IVehicleModel[], IFleetVehicleModelSearchParams>(
	'fleet/vehicleModel/search',
	fleetVehicleModelServiceSearch,
);

/**
 * @deprecated - use 'fleetVehicleModelClear' from 'packages\core\src\modules\fleet\vehicleModel\state\actions\FleetVehicleModelActions.ts' instead.
 * Clears the fleet vehicle model data from the state.
 */
export const fleetVehicleModelClear = createSyncAction('fleet/vehicleModel/clear');

/**
 * @deprecated - use 'fleetVehicleModelInsert' from 'packages\core\src\modules\fleet\vehicleModel\state\actions\FleetVehicleModelActions.ts' instead.
 * Inserts a new fleet vehicle model.
 * @param {IFleetVehicleModelInsertParams} params The parameters for inserting a new fleet vehicle model.
 * @returns {Promise<void>} A promise that resolves when the insertion operation is complete.
 */
export const fleetVehicleModelInsert = createAsyncAction<IVehicleModel, IFleetVehicleModelInsertParams>(
	'fleet/vehicleModel/insert',
	fleetVehicleModelServiceInsert,
);

/**
 * @deprecated - use 'fleetVehicleModelModify' from 'packages\core\src\modules\fleet\vehicleModel\state\actions\FleetVehicleModelActions.ts' instead.
 * Modifies an existing fleet vehicle model.
 * @param {IFleetVehicleModelModifyParams} params The parameters for modifying an existing fleet vehicle model.
 * @returns {Promise<void>} A promise that resolves when the modification operation is complete.
 */
export const fleetVehicleModelModify = createAsyncAction<IVehicleModel, IFleetVehicleModelModifyParams>(
	'fleet/vehicleModel/modify',
	fleetVehicleModelServiceModify,
);

/**
 * @deprecated - use 'fleetVehicleModelDeactivate' from 'packages\core\src\modules\fleet\vehicleModel\state\actions\FleetVehicleModelActions.ts' instead.
 * Deactivates a fleet vehicle model.
 * @param {IFleetVehicleModelDeactivateParams} params The parameters for deactivating a fleet vehicle model.
 * @returns {Promise<void>} A promise that resolves when the deactivation operation is complete.
 */
export const fleetVehicleModelDeactivate = createAsyncAction<IVehicleModel, IFleetVehicleModelDeactivateParams>(
	'fleet/vehicleModel/deactivate',
	fleetVehicleModelServiceDeactivate,
);

/**
 * @deprecated - use 'fleetVehicleModelActivate' from 'packages\core\src\modules\fleet\vehicleModel\state\actions\FleetVehicleModelActions.ts' instead.
 * Activates a fleet vehicle model.
 * @param {IFleetVehicleModelDeactivateParams} params The parameters for activating a fleet vehicle model.
 * @returns {Promise<void>} A promise that resolves when the activation operation is complete.
 */
export const fleetVehicleModelActivate = createAsyncAction<IVehicleModel, IFleetVehicleModelDeactivateParams>(
	'fleet/vehicleModel/activate',
	fleetVehicleModelServiceActivate,
);
