import { BudgetEndPoints } from './BudgetEndpoints';
import type { WorkType } from '../../../../models/types/WorkType';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../../shared/types/ServiceResponse';
import type { IBudget } from '../entities/Budget';

export interface IBudgetGetByVehicleLineParams extends IServiceBaseParams {
	vehicleLineId: number;
	workType: WorkType;
}

/**
 * Get budgets by the vehicle line movement
 * @param number `vehicleLine`
 * @returns `IBudget[]`
 */
export const budgetGetByVehicleLineService = (
	model: IBudgetGetByVehicleLineParams,
): Promise<ServiceResponse<IBudget[]>> => {
	return Api.get<IBudget[], IBudgetGetByVehicleLineParams>(BudgetEndPoints.GET_BY_VEHICLE_LINE, model);
};
