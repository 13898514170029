import { BudgetEndPoints } from './BudgetEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IBudgetFile } from '../entities/Budget';

export interface IBudgetGetFilesParams extends IServiceBaseParams {
	budgetId: number;
}

/**
 * Get the files of a budget
 * @param budgetId id of the budget
 * @returns `IBudgetFile[]`
 */
export const budgetGetFilesService = (model: IBudgetGetFilesParams) => {
	return Api.get<IBudgetFile[], IBudgetGetFilesParams>(BudgetEndPoints.GET_FILES, model);
};
