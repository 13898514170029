import * as React from 'react';

import * as DialogPrimitive from '@radix-ui/react-dialog';

import { cn } from '../../lib/utils/tailwindClass';

export const DialogOverlay = React.forwardRef<
	React.ElementRef<typeof DialogPrimitive.Overlay>,
	React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
	<DialogPrimitive.Overlay className={cn('dialog-overlay', className)} ref={ref} {...props} />
));

DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;
