import { vehicleServiceSearch } from '../../../../data/services/VehicleService';
import type { IVehicleSearchParams } from '../../../../models/serviceParams/VehicleParams';

/**
 * Search for previous registration
 * @param model - The search parameters
 * @returns - A promise that resolves when the search is complete
 */
export const previousRegistrationSearchService = (model: IVehicleSearchParams) => {
	return vehicleServiceSearch(model);
};
