import type { IBooking } from '@crac/core/models/entities/Booking';
import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaCircleInfo } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { bookingDetailLoader } from '~/features/booking/shared/utils/bookingDetailLoader';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const Detail = lazyWithRetry(() => import('./pages/Detail'));

export const detailRoutes: { [key: number]: IRouteConfigType } = {};

detailRoutes[routesIdMap.BookingsDetail] = {
	component: Detail,
	exact: true,
	id: routesIdMap.BookingsDetail,
	name: 'Detail',
	path: '/bookings/detail/:id',
	permissions: [PermissionsType.Booking, PermissionsType.BookingDetail],
	loader: ({ params }): Promise<IBooking | null | undefined> => {
		return bookingDetailLoader(params?.id || '');
	},
	icon: FaCircleInfo,
};
