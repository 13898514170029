import React from 'react';

import { Slot } from '@radix-ui/react-slot';

import { cn } from '../../lib/utils/tailwindClass';

export const BreadcrumbLink = React.forwardRef<
	HTMLAnchorElement,
	React.ComponentPropsWithoutRef<'a'> & {
		asChild?: boolean;
	}
>(({ asChild, className, ...props }, ref) => {
	const Comp = asChild ? Slot : 'a';

	return <Comp className={cn('breadcrumb-link', className)} ref={ref} {...props} />;
});

BreadcrumbLink.displayName = 'BreadcrumbLink';
