import type { IBooking } from '@crac/core/models/entities/Booking';
import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaSignInAlt } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { bookingDetailLoader } from '~/features/booking/shared/utils/bookingDetailLoader';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const Payment = lazyWithRetry(() => import('./pages/Payment'));

export const paymentRoutes: { [key: number]: IRouteConfigType } = {};

paymentRoutes[routesIdMap.BookingsPickUpPayment] = {
	component: Payment,
	exact: true,
	id: routesIdMap.BookingsPickUpPayment,
	name: 'Payments',
	path: '/booking/pick-up/:id/payments',
	permissions: [PermissionsType.BookingPickUpProcess],
	loader: ({ params }): Promise<IBooking | null | undefined> => {
		return bookingDetailLoader(params?.id || '');
	},
	icon: FaSignInAlt,
};
