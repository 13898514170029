export enum VehicleModifyEndPoints {
	/**
	 * Change last revision km
	 * @host `/vehicle/changeLastRevisionKm`
	 */
	CHANGE_LAST_REVISION_KM = '/vehicle/changeLastRevisionKm',

	/**
	 * Change fleet typology
	 * @host `/vehicle/changeFleetTypology`
	 */
	CHANGE_FLEET_TYPOLOGY = '/vehicle/changeFleetTypology',

	/**
	 * Change carrier
	 * @host `/vehicle/changeCarrier`
	 */
	CHANGE_CARRIER = '/vehicle/changeCarrier',

	/**
	 * Change registration date
	 * @host `/vehicle/changeRegistrationDate`
	 */
	CHANGE_REGISTRATION_DATE = '/vehicle/changeRegistrationDate',

	/**
	 * Change brand parking
	 * @host `/vehicle/changeBrandParking`
	 */
	CHANGE_BRAND_PARKING = '/vehicle/changeBrandParking',

	/**
	 * Change sale amount
	 * @host `/vehicle/changeSaleAmount`
	 */
	CHANGE_SALE_PRICE = '/vehicle/changeSaleAmount',

	/**
	 * Change vehicle model
	 * @host `/vehicle/changeVehicleModel`
	 */
	CHANGE_VEHICLE_MODEL = '/vehicle/changeVehicleModel',

	/**
	 * Change plate number
	 * @host `/vehicle/changePlateNumber`
	 */
	CHANGE_PLATE_NUMBER = '/vehicle/changePlateNumber',

	/**
	 * Change insurance company
	 * @host `/Vehicle/changeInsuranceCompany`
	 */
	CHANGE_INSURANCE_COMPANY = '/vehicle/changeInsuranceCompany',

	/**
	 * Change unregister traffic date
	 * @host `/vehicle/changeUnregisterTrafficDate`
	 */
	CHANGE_UNREGISTER_TRAFFIC_DATE = '/vehicle/changeUnregisterTrafficDate',

	/**
	 * Change rent start date
	 * @host `/vehicle/changeRentStartDate`
	 */
	CHANGE_RENT_START_DATE = '/vehicle/changeRentStartDate',

	/**
	 * Change rent end date
	 * @host `/vehicle/changeRentEndDate`
	 */
	CHANGE_RENT_END_DATE = '/vehicle/changeRentEndDate',

	/**
	 * Change next ITV date
	 * @host `/vehicle/changeNextITVDate`
	 */
	CHANGE_NEXT_ITV_DATE = '/vehicle/changeNextITVDate',

	/**
	 * Change agreed repurchase date
	 * @host `/vehicle/changeAgreedRepurchaseDate`
	 */
	CHANGE_AGREED_REPURCHASE_DATE = '/vehicle/changeAgreedRepurchaseDate',

	/**
	 * Change defleet date
	 * @host `/vehicle/changeExpectedDefleetDate`
	 */
	CHANGE_DEFLEET_DATE = '/vehicle/changeExpectedDefleetDate',

	/**
	 * Change vehicle group
	 * @host `/vehicle/changeVehicleGroup`
	 */
	CHANGE_VEHICLE_GROUP = '/vehicle/changeVehicleGroup',
}
