import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaCircleInfo } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const CustomerDetailData = lazyWithRetry(() => import('./pages/Detail'));

export const dataRoutes: { [key: number]: IRouteConfigType } = {};

dataRoutes[routesIdMap.CustomersDetail] = {
	component: CustomerDetailData,
	exact: true,
	id: routesIdMap.CustomersDetail,
	name: 'Detail',
	path: '/customers/detail/:id',
	permissions: [PermissionsType.CustomerDetail],
	icon: FaCircleInfo,
};
