import type { IService } from '../../models/entities/Service';
import { Api } from '../../modules/shared/api';
import type { IServiceBaseParams } from '../../modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { ServicesEndPoints } from '../endPoints/ServicesEndPoints';

/**
 * @deprecated Use `serviceGetAllService` from `modules/service/services`
 */
export const servicesServiceGetAll = (params: IServiceBaseParams): Promise<ServiceResponse<IService[]>> => {
	return Api.get<IService[], IServiceBaseParams>(ServicesEndPoints.GET_ALL, params);
};
