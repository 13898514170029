import { BudgetLineEndpoints } from './BudgetLineEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../../shared/types/ServiceResponse';
import type { IBudgetLine } from '../entities/BudgetLine';

export interface IBudgetLineGetByBudgetAndVehicleParams extends IServiceBaseParams {
	plateNumber: string;
	budgetId: number;
}

/**
 * Get budgetLines by budget code and plateNumber
 * @param {string} budgetCode Budget code
 * @param {string} plateNumber vehicle plateNumber
 * @return {Promise<ServiceResponse<IBudgetLine[]>>} `Promise<ApiDataResponseType<IBudgetLine[]>>`
 */
export const budgetLineGetByBudgetAndVehicleService = (
	model: IBudgetLineGetByBudgetAndVehicleParams,
): Promise<ServiceResponse<IBudgetLine[]>> => {
	return Api.get<IBudgetLine[], IBudgetLineGetByBudgetAndVehicleParams>(
		BudgetLineEndpoints.GET_BY_BUDGET_AND_VEHICLE,
		model,
	);
};
