import { PreviousRegistrationEndPoints } from './PreviousRegistrationEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IVehicle } from '../../../vehicle/entities/Vehicle';

export interface IFleetPreviousRegistrationDeleteParams extends IServiceBaseParams {
	provider: string;
	frameNumbers: string[];
}

/**
 * Delete previous registration
 * @param model - IFleetPreviousRegistrationDeleteParams
 * @returns - IVehicle[]
 */
export const previousRegistrationDeleteService = (model: IFleetPreviousRegistrationDeleteParams) => {
	return Api.post<IVehicle[], IFleetPreviousRegistrationDeleteParams>(PreviousRegistrationEndPoints.DELETE, model);
};
