import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaWrenchAlt } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const Dashboard = lazyWithRetry(() => import('./pages/DashBoard'));

export const dashboardRoutes: { [key: number]: IRouteConfigType } = {};

// DASHBOARD
dashboardRoutes[routesIdMap.Workshop] = {
	component: Dashboard,
	exact: true,
	id: routesIdMap.Workshop,
	name: 'Workshop',
	path: '/workshop',
	permissions: [PermissionsType.Fleet],
	icon: FaWrenchAlt,
	menuText: 'Workshop',
};
