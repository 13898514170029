import { SaleOrderEndPoints } from './SaleOrderEndPoints';
import type { ShowActiveType } from '../../../../models/types/ShowActiveType';
import { Api } from '../../../../modules/shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ISaleOrder } from '../entities/SaleOrder';
import type { SaleOrderStateType } from '../types/SaleOrderStateType';
import type { SaleOrderType } from '../types/SaleOrderType';

export interface ISaleOrderSearchParams extends IServiceBaseParams {
	customerCode?: string;
	frameNumbers?: string[];
	id?: number;
	orderDateFrom?: string;
	orderDateTo?: string;
	plateNumbers?: string[];
	showActive?: ShowActiveType;
	state?: SaleOrderStateType;
	type?: SaleOrderType;
}

/**
 * Search for vehicle sale orders
 * @param params - Parameters for the search of sale orders.
 * @returns A promise that resolves to the inserted sale orders.
 */
export const saleOrderSearchService = (params: ISaleOrderSearchParams) => {
	return Api.get<ISaleOrder[], ISaleOrderSearchParams>(SaleOrderEndPoints.SEARCH, params);
};
