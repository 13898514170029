import { Suspense } from 'react';

import { Navigate, Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import { useUnAuthorizedRequest } from '@crac/components/hooks/useUnAuthorizedRequest';
import { TooltipProvider } from '@crac/ui/tooltip';

import { Login } from '~/features/login/pages/Login';
import { routes } from '~/features/routes';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

import { PrivateRoute } from './shared/components/privateRoute';
import { RouteErrorBoundary } from './shared/components/routeErrorBoundary';

export const App = () => {
	const routesIds = Object.keys(routes).map((key) => Number(key));
	useUnAuthorizedRequest();

	const router = createBrowserRouter(
		createRoutesFromElements(
			<>
				<Route element={<PrivateRoute />} path="/">
					<Route element={<Navigate to="/" />} path="/*" />
					{routesIds.map((key) => {
						const route: IRouteConfigType = routes[key];
						if (!route || route.isChild) {
							return null;
						}

						const Component = route.component;

						if (!Component) {
							return null;
						}

						if (!route.childrens) {
							return (
								<Route
									element={
										<Suspense fallback={null}>
											<Component title={route.name} />
										</Suspense>
									}
									errorElement={route.errorElement || <RouteErrorBoundary />}
									key={route.id}
									loader={route.loader}
									path={route.path}
								/>
							);
						}

						return (
							<Route
								element={
									<Suspense fallback={null}>
										<Component title={route.name} />
									</Suspense>
								}
								errorElement={route.errorElement || <RouteErrorBoundary />}
								key={route.id}
								loader={route.loader}
								path={route.path}
							>
								{route.childrens.map((key) => {
									const childRoute = routes[key];
									const ChildComponent = childRoute.component;
									return (
										<Route
											element={
												<Suspense fallback={null}>
													<ChildComponent title={childRoute.name} />
												</Suspense>
											}
											errorElement={route.errorElement || <RouteErrorBoundary />}
											key={childRoute.id}
											loader={childRoute.loader}
											path={childRoute.path}
										/>
									);
								})}
							</Route>
						);
					})}
				</Route>
				<Route element={<Login />} path="/login" />
				<Route element={<Navigate to="/login" />} path="*" />
			</>,
		),
	);

	return (
		<TooltipProvider delayDuration={0}>
			<RouterProvider router={router} />
		</TooltipProvider>
	);
};
