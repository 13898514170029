import { RegularizationEndPoints } from './RegularizationEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IRegularization } from '../entities/Regularization';

/**
 * Get pending booking regularization by user
 * @return {IRegularization[]} IRegularization
 */
export const regularizationGetPendingByUserService = () => {
	return Api.get<IRegularization[], IServiceBaseParams>(RegularizationEndPoints.GET_PENDING_BY_USER);
};
