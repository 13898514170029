import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { webcacheRoutes } from './cache/routes';
import { customerDigitizationRoutes } from './customerDigitization/routes';
import { dashboardRoutes } from './dashboard/routes';
import { fiturRoutes } from './fitur/routes';
import { lockersRoutes } from './lockers/routes';

export const itRoutes: { [key: number]: IRouteConfig } = {
	...customerDigitizationRoutes,
	...dashboardRoutes,
	...lockersRoutes,
	...webcacheRoutes,
	...fiturRoutes,
};
