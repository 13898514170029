import { VehicleModifyEndPoints } from './VehicleModifyEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../../shared/types/ServiceResponse';
import type { IVehicle } from '../../../vehicle/entities/Vehicle';

export interface IVehicleChangeRegistrationDateParams extends IServiceBaseParams {
	plateNumbers: string[];
	registrationDate: string;
}

export const vehicleChangeRegistrationDateService = (
	model: IVehicleChangeRegistrationDateParams,
): Promise<ServiceResponse<IVehicle[]>> => {
	return Api.post<IVehicle[], IVehicleChangeRegistrationDateParams>(
		VehicleModifyEndPoints.CHANGE_REGISTRATION_DATE,
		model,
	);
};
