import type { IBooking } from '@crac/core/models/entities/Booking';
import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaSignInAlt } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { bookingDetailLoader } from '~/features/booking/shared/utils/bookingDetailLoader';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const Customer = lazyWithRetry(() => import('./pages/Customer'));

export const customerRoutes: { [key: number]: IRouteConfigType } = {};

customerRoutes[routesIdMap.BookingsPickUpCustomer] = {
	component: Customer,
	exact: true,
	id: routesIdMap.BookingsPickUpCustomer,
	name: 'Check customer',
	path: '/booking/pick-up/:id/check-customer/:customer',
	permissions: [PermissionsType.BookingPickUpProcess],
	loader: ({ params }): Promise<IBooking | null | undefined> => {
		return bookingDetailLoader(params?.id || '');
	},
	icon: FaSignInAlt,
};

customerRoutes[routesIdMap.BookingsPickUpCustomerNew] = {
	component: Customer,
	exact: true,
	id: routesIdMap.BookingsPickUpCustomerNew,
	name: 'New customer',
	path: '/booking/pick-up/:id/customer-new',
	permissions: [PermissionsType.BookingPickUpProcess],
	loader: ({ params }): Promise<IBooking | null | undefined> => {
		return bookingDetailLoader(params?.id || '');
	},
	icon: FaSignInAlt,
};
