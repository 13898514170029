import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const Fitur = lazyWithRetry(() => import('./pages/Fitur'));

export const fiturRoutes: { [key: number]: IRouteConfig } = {};

fiturRoutes[routesIdMap.ITLockersFitur] = {
	component: Fitur,
	exact: true,
	id: routesIdMap.ITLockersFitur,
	name: 'Lockers Fitur',
	path: '/it/lockers-fitur',
	permissions: [PermissionsType.LockerLoadPresents],
	icon: 'gift',
	menuText: 'Fitur lockers',
};
