import type { IExcessRate, IExcessRatePerProvider } from '../../models/entities/ExcessRate';
import type {
	IExcessRateDeleteParams,
	IExcessRateInsertParams,
	IExcessRateModifyParams,
	IExcessRateSearchParams,
} from '../../models/serviceParams/ExcessRateParams';
import { Api } from '../../modules/shared/api';
import type { IServiceBaseParams } from '../../modules/shared/types/ServiceBaseParams';
import { ExcessRateEndpoints } from '../endPoints/ExcessRateEndpoints';

/**
 * @deprecated - use 'excessRateSearchService' from 'packages\core\src\data\services\ExcessRateSearchService.ts' instead.
 */
export const excessRateServiceSearch = (model: IExcessRateSearchParams) => {
	return Api.get<IExcessRate[], IExcessRateSearchParams>(ExcessRateEndpoints.SEARCH, model);
};

/**
 * @deprecated - use 'excessRateInsertService' from 'packages\core\src\data\services\ExcessRateInsertService.ts' instead.
 */
export const excessRateServiceInsert = (model: IExcessRateInsertParams) => {
	return Api.post<IExcessRate, IExcessRateInsertParams>(ExcessRateEndpoints.INSERT, model);
};

/**
 * @deprecated - use 'excessRateModifyService' from 'packages\core\src\data\services\ExcessRateModifyService.ts' instead.
 */
export const excessRateServiceModify = (model: IExcessRateModifyParams) => {
	return Api.post<IExcessRate, IExcessRateModifyParams>(ExcessRateEndpoints.MODIFY, model);
};

/**
 * @deprecated - use 'excessRateDeleteService' from 'packages\core\src\data\services\ExcessRateDeleteService.ts' instead.
 */
export const excessRateServiceDelete = (model: IExcessRateDeleteParams) => {
	return Api.post<IExcessRate, IExcessRateDeleteParams>(ExcessRateEndpoints.DELETE, model);
};

/**
 * @deprecated - use 'excessRateGetAllService' from 'packages\core\src\data\services\ExcessRateGetAllService.ts' instead.
 */
export const excessRateServiceGetAll = (model: IServiceBaseParams) => {
	return Api.get<IExcessRatePerProvider[], IServiceBaseParams>(ExcessRateEndpoints.GET_ALL, model);
};
