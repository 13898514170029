import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

import { WorkshopAssignmentEndPoints } from './WorkshopAssignmentEndPoints';
import type { IWorkshopAssignment } from '../entities/WorkshopAssignment';

export interface IWorkshopAssignmentSearchParams extends IWorkshopAssignment, IServiceBaseParams {}

/**
 * Search for workshop assignments
 * @param model - search parameters
 * @returns - list of workshop assignments
 */
export const workshopAssignmentSearchService = (model: IWorkshopAssignmentSearchParams) => {
	return Api.get<IWorkshopAssignment[], IWorkshopAssignmentSearchParams>(WorkshopAssignmentEndPoints.SEARCH, model);
};
