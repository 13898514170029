import { VehicleModifyEndPoints } from './VehicleModifyEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../../shared/types/ServiceResponse';
import type { IVehicle } from '../../../vehicle/entities/Vehicle';

export interface IVehicleChangeCarrierParams extends IServiceBaseParams {
	plateNumbers: string[];
	carrierCode: string;
}

export const vehicleModifyChangeCarrierService = (
	model: IVehicleChangeCarrierParams,
): Promise<ServiceResponse<IVehicle[]>> => {
	return Api.post<IVehicle[], IVehicleChangeCarrierParams>(VehicleModifyEndPoints.CHANGE_CARRIER, model);
};
