import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaRegFileAlt } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const Confirmation = lazyWithRetry(() => import('./pages/Confirmation'));

export const confirmationRoutes: { [key: number]: IRouteConfigType } = {};

confirmationRoutes[routesIdMap.BookingsNewConfirmation] = {
	component: Confirmation,
	exact: true,
	id: routesIdMap.BookingsNewConfirmation,
	name: 'Booking confirmation',
	path: '/booking/new/confirmation',
	permissions: [PermissionsType.BookingNew],
	icon: FaRegFileAlt,
};
