import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

import { VehicleBrandEndPoints } from './FleetVehicleBrandEndpoints';
import type { IVehicleBrand } from '../entities/VehicleBrand';

export interface IFleetVehicleBrandSearchParams extends IServiceBaseParams {
	brand?: string;
	description?: string;
	web?: boolean;
}

export const fleetVehicleBrandSearchService = (model: IFleetVehicleBrandSearchParams) => {
	return Api.get<IVehicleBrand[], IFleetVehicleBrandSearchParams>(VehicleBrandEndPoints.SEARCH, model);
};
