import type { FC } from 'react';

import { Fieldset } from '@crac/components/intranet/shared/fieldset/Fieldset';
import { removeKeyWithEmptyString } from '@crac/core/helpers/commons';
import { Col, Row } from '@crac/ui/grid';

import { SupplierFormAddressFields } from './components/SupplierFormAddressFields';
import { SupplierFormBasicFields } from './components/SupplierFormBasicFields';
import { SupplierFormContactFields } from './components/SupplierFormContactFields';
import { SupplierFormContentWrapper } from './components/SupplierFormContentWrapper';
import { SupplierFormSelectPaymentMethod } from './components/SupplierFormSelectPaymentMethod';
import { SelectPaymentTerm } from './components/SupplierFormSelectPaymentTerm';
import type { ISupplierFormContentProps } from './types';

export const SupplierFormContent: FC<ISupplierFormContentProps> = ({
	initialValues,
	onCancel,
	onSubmit,
	supplierType,
	title,
	fields,
}) => {
	if (initialValues) {
		removeKeyWithEmptyString(initialValues);
	}

	return (
		<SupplierFormContentWrapper
			initialValues={initialValues}
			onCancel={onCancel}
			onSubmit={onSubmit}
			supplierType={supplierType}
			title={title}
		>
			<SupplierFormBasicFields fields={fields} initialValues={initialValues} supplierType={supplierType} />

			{fields?.address ? <SupplierFormAddressFields initialValues={initialValues} /> : null}

			{fields?.contact ? <SupplierFormContactFields initialValues={initialValues} /> : null}

			{fields?.paymentTerms ? (
				<Fieldset className="my-0" legend="Billing information">
					<Row>
						<Col lg={6}>
							<SelectPaymentTerm isClearable label="Payment term" name="paymentTermCode" />
						</Col>
						<Col lg={6}>
							<SupplierFormSelectPaymentMethod
								isClearable
								label="Payment method"
								name="paymentMethodCode"
							/>
						</Col>
					</Row>
				</Fieldset>
			) : null}
		</SupplierFormContentWrapper>
	);
};
