import type { IVehicleSearchParams } from '../../../../../models/serviceParams/VehicleParams';
import { createSyncAction } from '../../../../../modules/shared/state/createAction';
import { createAsyncAction } from '../../../../../modules/shared/state/createAsyncAction';
import type { IVehicle } from '../../../../../modules/vehicle/entities/Vehicle';
import { vehicleSearchService } from '../../../../vehicle/search/services/VehicleSearchService';
import { vehicleServiceRequestInsuranceCancellation } from '../../../../vehicle/services/VehicleServiceRequestInsuranceDefleet';
import type { IVehicleRequestInsuranceCancellationParams } from '../../../../vehicle/services/VehicleServiceRequestInsuranceDefleet';
import type { IVehicleChangeAgreedRepurchaseDateParams } from '../../services/VehicleModifyChangeAgreedRepurchaseDate';
import { vehicleModifyChangeAgreedRepurchaseDateService } from '../../services/VehicleModifyChangeAgreedRepurchaseDate';
import type { IVehicleChangeBrandParkingParams } from '../../services/VehicleModifyChangeBrandParkingService';
import { vehicleModifyChangeBrandParkingService } from '../../services/VehicleModifyChangeBrandParkingService';
import type { IVehicleChangeCarrierParams } from '../../services/VehicleModifyChangeCarrierService';
import { vehicleModifyChangeCarrierService } from '../../services/VehicleModifyChangeCarrierService';
import type { IVehicleChangeDeFleetDateParams } from '../../services/VehicleModifyChangeDeFleetDateService';
import { vehicleModifyChangeDeFleetDateService } from '../../services/VehicleModifyChangeDeFleetDateService';
import type { IVehicleChangeFleetTypologyParams } from '../../services/VehicleModifyChangeFleetTypologyService';
import { vehicleModifyChangeFleetTypologyService } from '../../services/VehicleModifyChangeFleetTypologyService';
import type { IVehicleChangeInsuranceCompanyParams } from '../../services/VehicleModifyChangeInsuranceCompanyService';
import { vehicleModifyChangeInsuranceCompanyService } from '../../services/VehicleModifyChangeInsuranceCompanyService';
import type { IVehicleChangeNextITVDateParams } from '../../services/VehicleModifyChangeNextITVDateService';
import { vehicleModifyChangeNextITVDateService } from '../../services/VehicleModifyChangeNextITVDateService';
import type { IVehicleChangePlateNumberParams } from '../../services/VehicleModifyChangePlateNumberService';
import { vehicleModifyChangePlateNumberService } from '../../services/VehicleModifyChangePlateNumberService';
import type { IVehicleChangeRegistrationDateParams } from '../../services/VehicleModifyChangeRegistratioDateService';
import { vehicleChangeRegistrationDateService } from '../../services/VehicleModifyChangeRegistratioDateService';
import type { IVehicleChangeRentEndDateParams } from '../../services/VehicleModifyChangeRentEndDateService';
import { vehicleModifyChangeRentEndDateService } from '../../services/VehicleModifyChangeRentEndDateService';
import type { IVehicleChangeRentStartDateParams } from '../../services/VehicleModifyChangeRentStartDateService';
import { vehicleModifyChangeRentStartDateService } from '../../services/VehicleModifyChangeRentStartDateService';
import type { IVehicleChangeSaleAmountParams } from '../../services/VehicleModifyChangeSaleAmountService';
import { vehicleModifyChangeSaleAmountService } from '../../services/VehicleModifyChangeSaleAmountService';
import type { IVehicleChangeUnregisterTrafficDateParams } from '../../services/VehicleModifyChangeUnregisterTrafficDateService';
import { vehicleModifyChangeUnregisterTrafficDateService } from '../../services/VehicleModifyChangeUnregisterTrafficDateService';
import type { IVehicleChangeVehicleGroupParams } from '../../services/VehicleModifyChangeVehicleGroupService';
import { vehicleModifyChangeVehicleGroupService } from '../../services/VehicleModifyChangeVehicleGroupService';
import type { IVehicleChangeVehicleModelParams } from '../../services/VehicleModifyChangeVehicleModelService';
import { vehicleModifyChangeVehicleModelService } from '../../services/VehicleModifyChangeVehicleModelService';

/**
 * Searches for vehicles based on search parameters.
 * @param {IVehicleSearchParams} params The parameters used for searching vehicles.
 * @returns {Promise<void>} A promise that resolves when the search operation is complete.
 */
export const fleetVehicleModifySearch = createAsyncAction<IVehicle[], IVehicleSearchParams>(
	'fleet/VehicleModify/search',
	vehicleSearchService,
);

/**
 * Changes the vehicle group of a specified vehicles.
 * @param {IVehicleChangeVehicleGroupParams} params The parameters for changing the vehicle group.
 * @returns {Promise<void>} A promise that resolves when the vehicle group change operation is complete.
 */
export const fleetVehicleModifyVehicleGroup = createAsyncAction<IVehicle[], IVehicleChangeVehicleGroupParams>(
	'fleet/VehicleModify/changeVehicleGroup',
	vehicleModifyChangeVehicleGroupService,
);

/**
 * Changes the de-fleet date of a specified vehicles.
 * @param {IVehicleChangeDeFleetDateParams} params The parameters for changing the de-fleet date.
 * @returns {Promise<void>} A promise that resolves when the de-fleet date change operation is complete.
 */
export const fleetVehicleModifyChangeDeFleetDate = createAsyncAction<IVehicle[], IVehicleChangeDeFleetDateParams>(
	'fleet/VehicleModify/changeDefleetDate',
	vehicleModifyChangeDeFleetDateService,
);

/**
 * Agrees on a repurchase date for a specified vehicles.
 * @param {IVehicleChangeAgreedRepurchaseDateParams} params The parameters for agreeing on a repurchase date.
 * @returns {Promise<void>} A promise that resolves when the repurchase date agreement operation is complete.
 */
export const fleetVehicleModifyChangeAgreedRepurchaseDate = createAsyncAction<
	IVehicle[],
	IVehicleChangeAgreedRepurchaseDateParams
>('fleet/VehicleModify/agreeRepurchaseDate', vehicleModifyChangeAgreedRepurchaseDateService);

/**
 * Changes the next ITV (Inspección Técnica de Vehículos) date for a specified vehicles.
 * @param {IVehicleChangeNextITVDateParams} params The parameters for changing the next ITV date.
 * @returns {Promise<void>} A promise that resolves when the ITV date change operation is complete.
 */
export const fleetVehicleModifyChangeNextITVDate = createAsyncAction<IVehicle[], IVehicleChangeNextITVDateParams>(
	'fleet/VehicleModify/ChangeNextITV',
	vehicleModifyChangeNextITVDateService,
);

/**
 * Changes the rent start date of a specified vehicles.
 * @param {IVehicleChangeRentStartDateParams} params The parameters for changing the rent start date.
 * @returns {Promise<void>} A promise that resolves when the rent date change operation is complete.
 */
export const fleetVehicleModifyChangeRentStartDate = createAsyncAction<IVehicle[], IVehicleChangeRentStartDateParams>(
	'fleet/VehicleModify/changeRentStartDate',
	vehicleModifyChangeRentStartDateService,
);

/**
 * Changes the rent end date of a specified vehicles.
 * @param {IVehicleChangeRentEndDateParams} params The parameters for changing the rent end date.
 * @returns {Promise<void>} A promise that resolves when the rent date change operation is complete.
 */
export const fleetVehicleModifyChangeRentEndDate = createAsyncAction<IVehicle[], IVehicleChangeRentEndDateParams>(
	'fleet/VehicleModify/changeRentEndDate',
	vehicleModifyChangeRentEndDateService,
);

/**
 * Changes the unregister traffic date of a specified vehicles.
 * @param {IVehicleChangeUnregisterTrafficDateParams} params The parameters for changing the unregister traffic date.
 * @returns {Promise<void>} A promise that resolves when the unregister traffic date change operation is complete.
 */
export const fleetVehicleModifyChangeUnregisterTrafficDate = createAsyncAction<
	IVehicle[],
	IVehicleChangeUnregisterTrafficDateParams
>('fleet/VehicleModify/changeUnregisterTrafficDate', vehicleModifyChangeUnregisterTrafficDateService);

/**
 * Changes the fleet typology of the specified vehicles
 * @param {IVehicleChangeFleetTypologyParams} params The parameters for changing the fleet typology.
 * @returns {Promise<void>} A promise that resolves when the fleet typology change operation is complete.
 */
export const fleetVehicleModifyChangeFleetTypology = createAsyncAction<IVehicle[], IVehicleChangeFleetTypologyParams>(
	'fleet/VehicleModify/changeFleetTypology',
	vehicleModifyChangeFleetTypologyService,
);

/**
 * Change the supplierCode of the selected vehicles
 * @param {IVehicleChangeCarrierParams} params The parameter for changing the supplierCode
 * @returns {Promise<void>} A promise that resolves when the supplierCode change operation is complete
 */
export const fleetVehicleModifyChangeCarrier = createAsyncAction<IVehicle[], IVehicleChangeCarrierParams>(
	'fleet/VehicleModify/changeCarrier',
	vehicleModifyChangeCarrierService,
);

/**
 * It changes the registration date of an array of vehicles
 * @param {IVehicleChangeRegistrationDateParams} params The parameter for changing the registration date
 * @returns {Promise<void>} A promise that resolves when the registrationDate change operation is complete
 */
export const fleetVehicleModifyChangeRegistrationDate = createAsyncAction<
	IVehicle[],
	IVehicleChangeRegistrationDateParams
>('fleet/VehicleModify/changeRegistrationDate', vehicleChangeRegistrationDateService);

/**
 * It changes the brand parking of an array of vehicles
 * @param {IVehicleChangeBrandParkingParams} params The parameters for changing the brand parking
 * @returns {Promise<void>} A promise that resolves when the brandParking change operation is complete
 */
export const fleetVehicleModifyChangeBrandParking = createAsyncAction<IVehicle[], IVehicleChangeBrandParkingParams>(
	'fleet/VehicleModify/changeBrandParking',
	vehicleModifyChangeBrandParkingService,
);

/**
 * It changes the sale amount of an array of vehicles
 * @param {IVehicleChangeSaleAmountParams} params The parameters for changing the sale amount
 * @returns {Promise<void>} A promise that resolves when the brandParking change operation is complete
 */
export const fleetVehicleModifyChangeSaleAmount = createAsyncAction<IVehicle[], IVehicleChangeSaleAmountParams>(
	'fleet/VehicleModify/changeSaleAmount',
	vehicleModifyChangeSaleAmountService,
);

/**
 * Request insurance cancellation from the fleet/vehicle-modify section
 * @param {IVehicleRequestInsuranceCancellationParams} params parameters for requesting the insurance cancellation
 * @returns {Promise<void>} A promise that resolves when the request insurance cancellation operation is complete
 */
export const fleetVehicleModifyRequestInsuranceCancellation = createAsyncAction<
	IVehicle[],
	IVehicleRequestInsuranceCancellationParams
>('fleet/VehicleModify/requestInsuranceCancellation', vehicleServiceRequestInsuranceCancellation);

/**
 * Changes the vehicle model of the specified vehicles
 * @param {IVehicleChangeVehicleModelParams} params The parameters for changing the vehicle model
 * @returns {Promise<void>} A promise that resolves when the vehicle model change operation is complete
 */
export const fleetVehicleModifyChangeVehicleModel = createAsyncAction<IVehicle[], IVehicleChangeVehicleModelParams>(
	'fleet/VehicleModify/changeModel',
	vehicleModifyChangeVehicleModelService,
);

/**
 * Changes the plate number of the specified vehicle
 * @param {IVehicleChangePlateNumberParams} params The parameters for changing the plate number
 * @returns {Promise<void>} A promise that resolves when the plate number change operation is complete
 */
export const fleetVehicleModifyChangePlateNumber = createAsyncAction<IVehicle, IVehicleChangePlateNumberParams>(
	'fleet/VehicleModify/changePlateNumber',
	vehicleModifyChangePlateNumberService,
);

/**
 * Change the supplier for the vehicle loaded in the detail page
 * @param plateNumbers `string[]` the plate numbers of the vehicles
 * @param insuranceCompanyCode `string` the supplier code
 * @returns `IVehicle[]` the changed vehicles
 */
export const vehicleChangeInsuranceCompany = createAsyncAction<IVehicle[], IVehicleChangeInsuranceCompanyParams>(
	'vehicle/changeInsuranceCompany',
	vehicleModifyChangeInsuranceCompanyService,
);

/**
 * Clears the vehicle modification data from the state.
 */
export const fleetVehicleModifyClear = createSyncAction('fleet/VehicleModify/clear');
