import { createSyncAction } from '../../../../shared/state/createAction';
import { createAsyncAction } from '../../../../shared/state/createAsyncAction';
import type { IServiceBaseParams } from '../../../../shared/types/ServiceBaseParams';
import type { IGroup, IVehicleGroup, IVehicleGroupByProvider } from '../../entities/VehicleGroup';
import { type IVehicleGroupDeleteParams, vehicleGroupDeleteService } from '../../services/VehicleGroupDeleteService';
import {
	type IVehicleGroupGetByBranchParams,
	vehicleGroupGetByBranchService,
} from '../../services/VehicleGroupGetByBranchService';
import { vehicleGroupGetByProviderService } from '../../services/VehicleGroupGetByProviderService';
import type { IVehicleGroupInsertParams } from '../../services/VehicleGroupInsertService';
import { vehicleGroupInsertService } from '../../services/VehicleGroupInsertService';
import { vehicleGroupModifyService } from '../../services/VehicleGroupModifyService';
import { type IVehicleGroupSearchParams, vehicleGroupSearchService } from '../../services/VehicleGroupSearchService';

/**
 * Retrieves vehicle groups associated with a specific branch.
 *
 * @param {IGroup[]} payload - The vehicle groups to be retrieved.
 * @param {IVehicleGroupGetByBranchParams} params - Parameters for identifying vehicle groups related to a specific branch.
 * @returns {Promise<void>} - A promise that resolves to an array of vehicle groups.
 */
export const vehicleGroupGetByBranch = createAsyncAction<IGroup[], IVehicleGroupGetByBranchParams>(
	'vehicleGroup/getByBranch',
	vehicleGroupGetByBranchService,
);

/**
 * Retrieves vehicle groups associated with a specific provider.
 *
 * @param {IVehicleGroup} payload - The vehicle groups to be retrieved.
 * @param {IServiceBaseParams} params - Base service parameters, potentially used for filtering or configuration.
 * @returns {Promise<void>} - A promise that resolves to an array or a specific vehicle group depending on the provider.
 */
export const vehicleGroupGetByProvider = createAsyncAction<IVehicleGroupByProvider[], IServiceBaseParams>(
	'vehicleGroup/getByProvider',
	vehicleGroupGetByProviderService,
);

/**
 * Searches for vehicle groups.
 *
 * @param {IVehicleGroup[]} payload - The vehicle groups to be searched.
 * @param {IVehicleGroupSearchParams} params - Parameters for identifying vehicle groups.
 * @returns {Promise<void>} - A promise that resolves to an array of vehicle groups.
 */
export const vehicleGroupSearch = createAsyncAction<IVehicleGroup[], IVehicleGroupSearchParams>(
	'fleet/vehicleGroup/search',
	vehicleGroupSearchService,
);

/**
 * Inserts a new vehicle group.
 *
 * @param {IVehicleGroup} payload - The vehicle group to be inserted.
 * @param {IVehicleGroupInsertParams} params - Parameters for inserting a new vehicle group.
 * @returns {Promise<void>} - A promise that resolves to the inserted vehicle group.
 */
export const vehicleGroupInsert = createAsyncAction<IVehicleGroup, IVehicleGroupInsertParams>(
	'fleet/vehicleGroup/insert',
	vehicleGroupInsertService,
);

/**
 * Modifies an existing vehicle group.
 *
 * @param {IVehicleGroup} payload - The vehicle group to be modified.
 * @param {IVehicleGroupInsertParams} params - Parameters for modifying an existing vehicle group.
 * @returns {Promise<void>} - A promise that resolves to the modified vehicle group.
 */
export const vehicleGroupModify = createAsyncAction<IVehicleGroup, IVehicleGroupInsertParams>(
	'fleet/vehicleGroup/modify',
	vehicleGroupModifyService,
);

/**
 * Deletes a vehicle group.
 *
 * @param {IVehicleGroup} payload - The vehicle group to be deleted.
 * @param {IVehicleGroupDeleteParams} params - Parameters for deleting a vehicle group.
 * @returns {Promise<void>} - A promise that resolves to the deleted vehicle group.
 */
export const vehicleGroupDelete = createAsyncAction<IVehicleGroup, IVehicleGroupDeleteParams>(
	'fleet/vehicleGroup/delete',
	vehicleGroupDeleteService,
);

/**
 * Clears the vehicle group.
 */
export const vehicleGroupClear = createSyncAction('fleet/vehicleGroup/clear');
