import { SaleOrderLineEndPoints } from './SaleOrderLineEndPoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { SaleOrderLineType } from '../../shared/types/SaleOrderLineType';
import type { ISaleOrderLine } from '../entities/SaleOrderLine';

export interface ISaleOrderLineInsertParams extends IServiceBaseParams {
	orderId: number;
	vehicles: { plateNumber: string; amount: number }[];
	type: SaleOrderLineType;
	serviceId?: number;
}

export const saleOrderLineInsertService = (params: ISaleOrderLineInsertParams) => {
	return Api.post<ISaleOrderLine[], ISaleOrderLineInsertParams>(SaleOrderLineEndPoints.INSERT, params);
};
