import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';
import type { IVehicle } from '@crac/core/modules/vehicle/entities/Vehicle';

import { VehicleModifyEndPoints } from './VehicleModifyEndpoints';

export interface IVehicleChangeInsuranceCompanyParams extends IServiceBaseParams {
	plateNumbers: string[];
	insuranceCompanyCode: string;
}

export const vehicleModifyChangeInsuranceCompanyService = (
	model: IVehicleChangeInsuranceCompanyParams,
): Promise<ServiceResponse<IVehicle[]>> => {
	return Api.post<IVehicle[], IVehicleChangeInsuranceCompanyParams>(
		VehicleModifyEndPoints.CHANGE_INSURANCE_COMPANY,
		model,
	);
};
