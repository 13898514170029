import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

import { WorkshopAssignmentEndPoints } from './WorkshopAssignmentEndPoints';
import type { IWorkshopAssignment } from '../entities/WorkshopAssignment';

export interface IWorkshopAssignmentDeleteParams extends IServiceBaseParams {
	id: number;
}

/**
 * Delete a workshop assignment
 * @param model - workshop assignment to delete
 * @returns - deleted workshop assignment
 */
export const workshopAssignmentDeleteService = (model: IWorkshopAssignmentDeleteParams) => {
	return Api.post<IWorkshopAssignment, IWorkshopAssignmentDeleteParams>(WorkshopAssignmentEndPoints.DELETE, model);
};
