import { createSyncAction } from '../../../../../modules/shared/state/createAction';
import { createAsyncAction } from '../../../../../modules/shared/state/createAsyncAction';
import type { IFineReason } from '../../entities/FineReason';
import type { IFineReasonInsertParams } from '../../services/FineReasonInsertService';
import { fineReasonInsertService } from '../../services/FineReasonInsertService';
import type { IFineReasonModifyParams } from '../../services/FineReasonModifyService';
import { fineReasonModifyService } from '../../services/FineReasonModifyService';
import type { IFineReasonSearchParams } from '../../services/FineReasonSearchService';
import { fineReasonSearchService } from '../../services/FineReasonSearchService';

/**
 * Clears the fine reason data from the state.
 */
export const fleetFineReasonClear = createSyncAction('fleet/fineReasonClear');

/**
 * Searches for fine reasons based on search parameters.
 * @param {IFineReason[]} payload `IFineReason[]` The payload for the action.
 * @param {IFineReasonSearchParams} IFineReasonSearchParams The parameters for retrieving fine reasons.
 * @returns {Promise<void>} `Promise<void>` - A promise that resolves to an array of fine reasons.
 */
export const fleetFineReasonSearch = createAsyncAction<IFineReason[], IFineReasonSearchParams>(
	'fleet/fineReason/search',
	fineReasonSearchService,
);

/**
 * Inserts a new fine reason into the fleet.
 * @param {IFineReason} payload `IFineReason` The fine reason to be inserted.
 * @param {IFineReasonInsertParams} IFineReasonInsertParams The parameters for inserting a new fine reason.
 * @returns {Promise<void>} `Promise<void>` - A promise that resolves when the fine reason has been inserted.
 */
export const fleetFineReasonInsert = createAsyncAction<IFineReason, IFineReasonInsertParams>(
	'fleet/fineReason/insert',
	fineReasonInsertService,
);

/**
 * Modifies an existing fine reason in the fleet.
 * @param {IFineReason} payload `IFineReason` The fine reason to be modified.
 * @param {IFineReasonModifyParams} IFineReasonModifyParams The parameters for modifying an existing fine reason.
 * @returns {Promise<void>} `Promise<void>` - A promise that resolves when the fine reason has been modified.
 */
export const fleetFineReasonModify = createAsyncAction<IFineReason, IFineReasonModifyParams>(
	'fleet/fineReason/modify',
	fineReasonModifyService,
);
