import type { IBooking } from '../../models/entities/Booking';
import type {
	IBookingPickUpGetPendingByBranchParams,
	IBookingStartContractParams,
} from '../../models/serviceParams/BookingPickUpParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { BookingEndPoints } from '../endPoints/BookingEndPoints';

/**
 * @deprecated Use `bookingPickUpGetPendingByBranch` from `modules/booking/pickUp/services`
 * Get list pending Drop Off booking by branch
 *  {Booking} booking Data register booking
 * @return {Promise} `Array<BookingPropType>` Booking data
 */
export const bookingPickUpServiceGetPendingByBranch = (
	model: IBookingPickUpGetPendingByBranchParams,
): Promise<ServiceResponse<IBooking[]>> => {
	return Api.get<IBooking[], IBookingPickUpGetPendingByBranchParams>(
		BookingEndPoints.GET_PENDING_PICKUP_BY_BRANCH,
		model,
	);
};

/**
 * @deprecated Use `bookingPickUpStartContractService` from `modules/booking/pickUp/services`
 * Start contract
 *  {string} bookingNumber
 * @returns {Promise<BookingPropType>} `BookingPropType`
 */
export const bookingPickUpServiceStartContract = (
	model: IBookingStartContractParams,
): Promise<ServiceResponse<IBooking>> => {
	return Api.post<IBooking, IBookingStartContractParams>(BookingEndPoints.START_CONTRACT, model);
};
