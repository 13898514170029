import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaPercent, FaPlus } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const SaleServiceRate = lazyWithRetry(() => import('~/features/sale/saleServiceRate/pages/SaleServiceRate'));
const SaleServiceRateNew = lazyWithRetry(() => import('~/features/sale/saleServiceRate/pages/SaleServiceRateNew'));

export const saleServiceRateRoutes: { [key: number]: IRouteConfigType } = {};

saleServiceRateRoutes[routesIdMap.SaleServiceRate] = {
	component: SaleServiceRate,
	exact: true,
	id: routesIdMap.SaleServiceRate,
	name: 'Sale service rates',
	menuText: 'Service rates',
	path: '/sale/service-rates',
	permissions: [PermissionsType.SaleService],
	childrens: [routesIdMap.SaleServiceRateNew],
	icon: FaPercent,
};

saleServiceRateRoutes[routesIdMap.SaleServiceRateNew] = {
	component: SaleServiceRateNew,
	exact: true,
	id: routesIdMap.SaleServiceRateNew,
	name: 'New sale service rate',
	menuText: 'New service rate',
	path: '/sale/service-rates/new',
	permissions: [PermissionsType.SaleService],
	childrens: [],
	icon: FaPlus,
	isChild: true,
};
