import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { RevisionLineEndPoints } from './RevisionLineEndpoints';
import type { IRevisionLine } from '../entities/RevisionLine';

export interface IRevisionLineGetByBudgetParams extends IServiceBaseParams {
	budgetId: number;
}

export const revisionLineGetByBudgetService = (
	model: IRevisionLineGetByBudgetParams,
): Promise<ServiceResponse<IRevisionLine[]>> => {
	return Api.get<IRevisionLine[], IRevisionLineGetByBudgetParams>(RevisionLineEndPoints.GET_BY_BUDGET, model);
};
