import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaCarAlt, FaKey, FaPencil } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const Detail = lazyWithRetry(() => import('./pages/Detail'));
const ChangeCarrier = lazyWithRetry(() => import('./pages/ChangeCarrier'));
const ChangeKeysLocation = lazyWithRetry(() => import('./pages/ChangeKeysLocation'));
const ChangeState = lazyWithRetry(() => import('./pages/ChangeState'));

export const detailRoutes: { [key: number]: IRouteConfigType } = {};

detailRoutes[routesIdMap.VehiclesDetail] = {
	component: Detail,
	exact: true,
	id: routesIdMap.VehiclesDetail,
	name: 'Detail',
	path: '/vehicles/detail/:id',
	permissions: [PermissionsType.VehicleDetail],
	childrens: [routesIdMap.VehiclesDetailKeysLocation, routesIdMap.VehiclesDetailChangeCarrier],
	icon: FaCarAlt,
};

detailRoutes[routesIdMap.VehiclesDetailChangeCarrier] = {
	component: ChangeCarrier,
	exact: true,
	id: routesIdMap.VehiclesDetailChangeCarrier,
	name: 'Change carrier',
	path: '/vehicles/detail/:id/change-carrier',
	permissions: [PermissionsType.VehicleChangeData],
	isChild: true,
	icon: FaPencil,
};

detailRoutes[routesIdMap.VehiclesDetailKeysLocation] = {
	component: ChangeKeysLocation,
	exact: true,
	id: routesIdMap.VehiclesDetailKeysLocation,
	name: 'Detail',
	path: '/vehicles/detail/:id/update-keys-location',
	permissions: [PermissionsType.VehicleDetail],
	icon: FaKey,
};

detailRoutes[routesIdMap.VehiclesDetailChangeState] = {
	component: ChangeState,
	exact: true,
	id: routesIdMap.VehiclesDetailChangeState,
	name: 'Change state',
	path: '/vehicles/detail/:id/change-state',
	permissions: [PermissionsType.VehicleDetail],
	icon: FaPencil,
};
