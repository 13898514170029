/**
 * @deprecated - use 'DamageRepairCostEndPoints' from 'packages\core\src\modules\workshop\damageRepair\services\DamageRepairCostEndPoints.ts' instead.
 */
export enum DamageRepairCostEndPoints {
	/**
	 * insert damage repair cost
	 * @host `/damageRepairCost/insert/`
	 */
	SAVE = '/damageRepairCost/save/',
}
