import { useEffect, useState } from 'react';

import { getKeysFromEnum } from '@crac/core/helpers/commons';
import type { IEmployee } from '@crac/core/models/entities/Employee';
import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IPermissionsToggleParams } from '@crac/core/redux/actions/PermissionsActions';

import { permissionsCategories } from './permissionsCategories';

interface IUseHeaderPermissionsProps {
	employee: IEmployee;
	permissions: string[];
	togglePermissions: (payload: IPermissionsToggleParams) => void;
	updatePermissions: (permissions: string[]) => void;
}

export const useHeaderPermissions = ({
	employee,
	permissions,
	togglePermissions,
	updatePermissions,
}: IUseHeaderPermissionsProps) => {
	const [permissionType, setPermissionType] = useState(permissionsCategories[1]);
	const [permissionsByType, setPermissionsByType] = useState<string[]>([]);
	const [permissionsToolEnabled, setPermissionsToolEnabled] = useState(false);

	const handleChangePermissionType = (newValue: { value: string; label: string }) => {
		let filteredPermissions = getKeysFromEnum(PermissionsType);

		if (newValue.value !== 'all') {
			filteredPermissions = filteredPermissions.filter((permission) =>
				permission.toLocaleLowerCase().startsWith(newValue.value.toLowerCase()),
			);
		}

		setPermissionType(newValue);
		setPermissionsByType(filteredPermissions);
	};

	const handleChangePermission = (name: string, checked: boolean) => {
		let updatedPermissionList = permissions;
		if (checked && !updatedPermissionList.includes(name)) {
			updatedPermissionList = [...updatedPermissionList, name];
		} else if (updatedPermissionList.includes(name)) {
			updatedPermissionList = updatedPermissionList.filter((permission) => permission !== name);
		}

		updatePermissions([...updatedPermissionList].sort((item1, item2) => item1.localeCompare(item2)));
	};

	const handleTogglePermissionTool = (enabled: boolean) => {
		setPermissionsToolEnabled(enabled);
		const newPermissions: string[] = enabled ? employee.permissions : [];
		togglePermissions({ isEnabled: enabled, permissions: newPermissions });
	};

	useEffect(() => {
		handleChangePermissionType(permissionsCategories[1]);
	}, []);

	return {
		permissionType,
		permissionsByType,
		permissionsToolEnabled,
		handleChangePermissionType,
		handleChangePermission,
		handleTogglePermissionTool,
	};
};
