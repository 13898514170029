import { FineReasonEndPoints } from './FineReasonEndpoints';
import { Api } from '../../../../modules/shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IFineReason } from '../entities/FineReason';

export interface IFineReasonSearchParams extends IServiceBaseParams {
	code?: number;
	companyPay?: boolean;
	description?: string;
	toll?: boolean;
}

/**
 * Search a fine reason
 * @return {Promise<FineReasonType>} `FineReasonType`
 */
export const fineReasonSearchService = (model: IFineReasonSearchParams) => {
	return Api.get<IFineReason[], IFineReasonSearchParams>(FineReasonEndPoints.SEARCH, model);
};
