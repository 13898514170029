import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaHouse } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const DashBoard = lazyWithRetry(() => import('./pages/DashBoard'));

export const dashboardRoutes: { [key: number]: IRouteConfigType } = {};

dashboardRoutes[routesIdMap.Dashboard] = {
	component: DashBoard,
	exact: true,
	id: routesIdMap.Dashboard,
	name: 'Home',
	path: '/',
	permissions: [PermissionsType.Access],
	icon: FaHouse,
};
