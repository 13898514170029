import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaPlus } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const New = lazyWithRetry(() => import('./pages/New'));

export const newRoutes: { [key: number]: IRouteConfigType } = {};

newRoutes[routesIdMap.CompaniesNew] = {
	component: New,
	exact: true,
	id: routesIdMap.CompaniesNew,
	name: 'New company',
	path: '/company/new',
	permissions: [PermissionsType.CompanyNew],
	icon: FaPlus,
	menuText: 'New',
};
