import type { FC } from 'react';

import { Button } from '@crac/ui/button';
import { Icon } from '@crac/ui/icons/Icon';

interface IMobileFeedbackBtnProps {
	toggleFeedBack: (value: boolean) => void;
}

export const MobileFeedbackBtn: FC<IMobileFeedbackBtnProps> = ({ toggleFeedBack }) => {
	return (
		<div className="tw-block sm:tw-hidden">
			<Button
				className="tw-text-white"
				color="danger"
				isBlock
				onClick={() => {
					document.body.classList.remove('sidebar-show');
					toggleFeedBack(true);
				}}
			>
				<Icon className="tw-mr-1" name="FaExclamationTriangle" />
				Send feedback
			</Button>
		</div>
	);
};
