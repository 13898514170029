import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaSignOutAlt } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const Return = lazyWithRetry(() => import('./pages/Return'));

export const returnRoutes: { [key: number]: IRouteConfigType } = {};

returnRoutes[routesIdMap.VehiclesReturn] = {
	component: Return,
	exact: false,
	id: routesIdMap.VehiclesReturn,
	name: 'Return',
	path: '/vehicle/return',
	permissions: [PermissionsType.VehicleReturn],
	icon: FaSignOutAlt,
	menuText: 'Return',
};

returnRoutes[routesIdMap.VehiclesReturnDetail] = {
	component: Return,
	exact: true,
	id: routesIdMap.VehiclesReturnDetail,
	name: 'Detail',
	path: '/vehicle/return/detail',
	permissions: [PermissionsType.VehicleReturn],
	icon: FaSignOutAlt,
};
