import { InsuranceRateEndPoints } from './InsuranceRateEndpoints';
import { Api } from '../../../../modules/shared/api';
import type { IServiceBaseParams } from '../../../../modules/shared/types/ServiceBaseParams';
import type { IInsuranceRate } from '../entities/InsuranceRate';

export interface IInsuranceRateDeactivateParams extends IServiceBaseParams {
	provider: string;
	id: number;
}

/**
 * Deactivate insurance rate
 * @param model - IInsuranceRateDeactivateParams
 * @returns IInsuranceRate
 */
export const insuranceRateDeactivateService = (model: IInsuranceRateDeactivateParams) => {
	return Api.post<IInsuranceRate, IInsuranceRateDeactivateParams>(InsuranceRateEndPoints.DEACTIVATE, model);
};
