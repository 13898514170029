import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

import { VehicleModelEndPoints } from './FleetVehicleModelEndpoints';
import type { IVehicleModel } from '../entities/VehicleModel';

export interface IFleetVehicleModelModifyParams extends IVehicleModel, IServiceBaseParams {}

export const fleetVehicleModelModifyService = (model: IFleetVehicleModelModifyParams) => {
	return Api.post<IVehicleModel, IFleetVehicleModelModifyParams>(VehicleModelEndPoints.MODIFY, model);
};
