import type { FC } from 'react';

import { Link } from 'react-router-dom';

import type { IEmployee } from '@crac/core/models/entities/Employee';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@crac/ui/dropdown';
import { Icon } from '@crac/ui/icons/Icon';

import { routesIdMap } from '~/config/routesIdMap';
import { routes } from '~/features/routes';

interface IHeaderDropdownProps {
	employee: IEmployee;
}

export const HeaderDropdown: FC<IHeaderDropdownProps> = ({ employee }) => {
	return (
		<DropdownMenu modal={false}>
			<DropdownMenuTrigger className="account-dropdown-trigger">
				<Icon className="tw-w-3 tw-h-3" name="FaUser" size="sm" />
				<span className="account-username">{`${employee.name} (${employee.branchCode})`.toUpperCase()}</span>
			</DropdownMenuTrigger>
			<DropdownMenuContent align="start" side="bottom" sideOffset={-10}>
				<DropdownMenuItem asChild>
					<Link className="account-dropdown-link" to={routes[routesIdMap.ChangePassword]?.path || '/'}>
						<Icon className="header-small-icon" name="FaKey" size="sm" /> Change password
					</Link>
				</DropdownMenuItem>
				{employee.allowedBranches.length > 1 ? (
					<DropdownMenuItem asChild>
						<Link className="account-dropdown-link" to={routes[routesIdMap.ChangeBranch]?.path || '/'}>
							<Icon className="header-small-icon" name="FaHome" size="sm" /> Change branch
						</Link>
					</DropdownMenuItem>
				) : null}
				<DropdownMenuItem asChild>
					<Link className="account-dropdown-link" to="/login">
						<Icon className="header-small-icon" name="FaSignOutAlt" size="sm" /> Logout
					</Link>
				</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	);
};
