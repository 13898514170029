import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaPencil, FaPencilAlt, FaPlus, FaShoppingBag } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const SaleOrder = lazyWithRetry(() => import('~/features/sale/saleOrder/pages/SaleOrder'));

const SaleOrderNew = lazyWithRetry(() => import('~/features/sale/saleOrder/pages/SaleOrderNew'));

const SaleOrderNewCustomer = lazyWithRetry(() => import('~/features/sale/saleOrder/pages/SaleOrderNewCustomer'));

const SaleOrderNewConfirmation = lazyWithRetry(
	() => import('~/features/sale/saleOrder/pages/SaleOrderNewConfirmation'),
);

const SaleOrderConfirmPrice = lazyWithRetry(() => import('~/features/sale/saleOrder/pages/SaleOrderConfirmPrice'));

const SaleOrderModify = lazyWithRetry(() => import('~/features/sale/saleOrder/pages/SaleOrderModify'));

const SaleOrderSetBlockToSale = lazyWithRetry(() => import('~/features/sale/saleOrder/pages/SaleOrderSetBlockToSale'));

const SaleOrderStartReconditioning = lazyWithRetry(
	() => import('~/features/sale/saleOrder/pages/SaleOrderStartReconditioning'),
);

const SaleOrderAddVehicles = lazyWithRetry(() => import('~/features/sale/saleOrder/pages/SaleOrderAddVehicles'));

export const saleOrderRoutes: { [key: number]: IRouteConfigType } = {};

// SEARCH
saleOrderRoutes[routesIdMap.SaleOrder] = {
	component: SaleOrder,
	exact: true,
	id: routesIdMap.SaleOrder,
	name: 'Sale orders',
	menuText: 'Orders',
	path: '/sale/orders',
	permissions: [PermissionsType.SaleOrder],
	childrens: [],
	icon: FaShoppingBag,
};

// NEW
saleOrderRoutes[routesIdMap.SaleOrderNew] = {
	component: SaleOrderNew,
	exact: true,
	id: routesIdMap.SaleOrderNew,
	name: 'New sale order',
	menuText: 'New sale order',
	path: '/sale/orders/new',
	permissions: [PermissionsType.SaleOrder],
	childrens: [],
	icon: FaPlus,
};

// B2B
saleOrderRoutes[routesIdMap.SaleOrderNewCompany] = {
	component: SaleOrderNewCustomer,
	exact: true,
	id: routesIdMap.SaleOrderNewCompany,
	name: 'New sale order',
	path: '/sale/orders/new/company/:code?',
	permissions: [PermissionsType.SaleOrder, PermissionsType.SaleOrderInsertB2B],
	childrens: [],
	icon: FaPlus,
};

saleOrderRoutes[routesIdMap.SaleOrderNewCompanyConfirmation] = {
	component: SaleOrderNewConfirmation,
	exact: true,
	id: routesIdMap.SaleOrderNewCompanyConfirmation,
	name: 'New sale order',
	path: '/sale/orders/new/confirmation/:code',
	permissions: [PermissionsType.SaleOrder, PermissionsType.SaleOrderInsertB2B],
	childrens: [],
	icon: FaPlus,
};

// B2C
saleOrderRoutes[routesIdMap.SaleOrderNewCustomer] = {
	component: SaleOrderNewCustomer,
	exact: true,
	id: routesIdMap.SaleOrderNewCustomer,
	name: 'New sale order',
	path: '/sale/orders/new/customer/:plateNumber',
	permissions: [PermissionsType.SaleOrder, PermissionsType.SaleOrderInsertB2C],
	childrens: [],
	icon: FaPlus,
};

saleOrderRoutes[routesIdMap.SaleOrderNewCustomerCheck] = {
	component: SaleOrderNewCustomer,
	exact: true,
	id: routesIdMap.SaleOrderNewCustomerCheck,
	name: 'New sale order',
	path: '/sale/orders/new/customer-check/:plateNumber/:customerCode',
	permissions: [PermissionsType.SaleOrder, PermissionsType.SaleOrderInsertB2C],
	childrens: [],
	icon: FaPlus,
};

saleOrderRoutes[routesIdMap.SaleOrderNewCustomerConfirmation] = {
	component: SaleOrderNewConfirmation,
	exact: true,
	id: routesIdMap.SaleOrderNewCustomerConfirmation,
	name: 'New sale order',
	path: '/sale/orders/new/confirmation/:plateNumber/:customerCode',
	permissions: [PermissionsType.SaleOrder, PermissionsType.SaleOrderInsertB2C],
	childrens: [],
	icon: FaPlus,
};

// MODIFY
saleOrderRoutes[routesIdMap.SaleOrderModify] = {
	component: SaleOrderModify,
	exact: true,
	id: routesIdMap.SaleOrderModify,
	name: 'Modify sale orders',
	path: '/sale/orders/:id',
	permissions: [PermissionsType.SaleOrder],
	childrens: [routesIdMap.SaleOrderSetBlockToSale, routesIdMap.SaleOrderConfirmPrice],
	icon: FaPencil,
};

saleOrderRoutes[routesIdMap.SaleOrderSetBlockToSale] = {
	component: SaleOrderSetBlockToSale,
	exact: true,
	id: routesIdMap.SaleOrderSetBlockToSale,
	name: 'Block to sale',
	path: '/sale/orders/:id/block-to-sale',
	permissions: [PermissionsType.SaleOrder, PermissionsType.SaleOrderSetBlockToSale],
	childrens: [],
	icon: FaPencilAlt,
	isChild: true,
};

saleOrderRoutes[routesIdMap.SaleOrderStartReconditioning] = {
	component: SaleOrderStartReconditioning,
	exact: true,
	id: routesIdMap.SaleOrderStartReconditioning,
	name: 'Start reconditioning',
	path: '/sale/orders/:id/start-reconditioning',
	permissions: [PermissionsType.SaleOrder, PermissionsType.SaleOrderStartReconditioning],
	childrens: [],
	icon: FaPencilAlt,
};

saleOrderRoutes[routesIdMap.SaleOrderConfirmPrice] = {
	component: SaleOrderConfirmPrice,
	exact: true,
	id: routesIdMap.SaleOrderConfirmPrice,
	name: 'Confirm price',
	path: '/sale/orders/:id/confirm-price',
	permissions: [PermissionsType.SaleOrder, PermissionsType.SaleOrderConfirmPrice],
	childrens: [],
	icon: FaPencilAlt,
	isChild: true,
};

saleOrderRoutes[routesIdMap.SaleOrderAddVehicles] = {
	component: SaleOrderAddVehicles,
	exact: true,
	id: routesIdMap.SaleOrderAddVehicles,
	name: 'Add vehicles',
	path: '/sale/orders/:id/add-vehicles',
	permissions: [PermissionsType.SaleOrder, PermissionsType.SaleOrderInsertB2B],
	childrens: [],
	icon: FaPencil,
};
