import { VehicleModifyEndPoints } from './VehicleModifyEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../../shared/types/ServiceResponse';
import type { IVehicle } from '../../../vehicle/entities/Vehicle';

export interface IVehicleChangeNextITVDateParams extends IServiceBaseParams {
	plateNumbers: string[];
	nextITVDate: string;
}

export const vehicleModifyChangeNextITVDateService = (
	model: IVehicleChangeNextITVDateParams,
): Promise<ServiceResponse<IVehicle[]>> => {
	return Api.post<IVehicle[], IVehicleChangeNextITVDateParams>(VehicleModifyEndPoints.CHANGE_NEXT_ITV_DATE, model);
};
