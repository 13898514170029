import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaEyeAlt } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

import { getPricingPermission } from '../utils/helpers';

const PriceViewer = lazyWithRetry(() => import('./pages/PriceViewer'));

export const priceViewerRoutes: { [key: number]: IRouteConfigType } = {};

// PRICE VIEWER
priceViewerRoutes[routesIdMap.RentRatePriceViewer] = {
	component: PriceViewer,
	exact: true,
	id: routesIdMap.RentRatePriceViewer,
	name: 'Price viewer',
	path: '/pricing/viewer',
	permissions: getPricingPermission([PermissionsType.Pricing]),
	icon: FaEyeAlt,
	menuText: 'Price viewer',
};
