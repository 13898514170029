import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

import { createSyncAction } from '../../../../shared/state/createAction';
import { createAsyncAction } from '../../../../shared/state/createAsyncAction';
import type { IExcessRate } from '../../entities/ExcessRate';
import type { IExcessRatePerProvider } from '../../entities/ExcessRatePerProvider';
import type { IExcessRateDeleteParams } from '../../services/ExcessRateDeleteService';
import { excessRateDeleteService } from '../../services/ExcessRateDeleteService';
import { excessRateGetAllService } from '../../services/ExcessRateGetAllService';
import type { IExcessRateInsertParams } from '../../services/ExcessRateInsertService';
import { excessRateInsertService } from '../../services/ExcessRateInsertService';
import { type IExcessRateModifyParams, excessRateModifyService } from '../../services/ExcessRateModifyService';
import type { IExcessRateSearchParams } from '../../services/ExcessRateSearchService';
import { excessRateSearchService } from '../../services/ExcessRateSearchService';

/**
 * Searches for excess rates based on specified parameters.
 *
 * @param {IExcessRate[]} payload - The excess rates to be returned.
 * @param {IExcessRateSearchParams} params - Parameters for the search.
 * @returns {Promise<void>} - A promise that resolves to an array of excess rates.
 */
export const excessRateSearch = createAsyncAction<IExcessRate[], IExcessRateSearchParams>(
	'excessRate/search',
	excessRateSearchService,
);

/**
 * Inserts new excess rates into the system.
 *
 * @param {IExcessRate[]} payload - The excess rates to be inserted.
 * @param {IExcessRateInsertParams} params - Parameters detailing the excess rates to be inserted.
 * @returns {Promise<void>} - A promise that resolves after the excess rates are successfully inserted.
 */
export const excessRateInsert = createAsyncAction<IExcessRate, IExcessRateInsertParams>(
	'excessRate/insert',
	excessRateInsertService,
);

/**
 * Clears the excess rate state.
 */
export const excessRateClear = createSyncAction('excessRate/clear');

/**
 * Modifies existing excess rates.
 *
 * @param {IExcessRate} payload - The excess rates to be modified.
 * @param {IExcessRateModifyParams} params - Parameters detailing the excess rates to be modified.
 * @returns {Promise<void>} - A promise that resolves after the excess rates are successfully modified.
 */
export const excessRateModify = createAsyncAction<IExcessRate, IExcessRateModifyParams>(
	'excessRate/modify',
	excessRateModifyService,
);

/**
 * Deletes excess rates from the system.
 *
 * @param {IExcessRate} payload - The excess rate to be deleted.
 * @param {IExcessRateDeleteParams} params - Parameters detailing the excess rates to be deleted.
 * @returns {Promise<void>} - A promise that resolves after the excess rates are successfully deleted.
 */
export const excessRateDelete = createAsyncAction<IExcessRate, IExcessRateDeleteParams>(
	'excessRate/delete',
	excessRateDeleteService,
);

/**
 * Get all excess rates.
 *
 * @param {IExcessRatePerProvider[]} payload - The excess rates to be returned.
 * @param {IServiceBaseParams} params - Parameters detailing the excess rates to be returned.
 * @returns {Promise<void>} - A promise that resolves to an array of excess rates.
 */
export const excessRateGetAll = createAsyncAction<IExcessRatePerProvider[], IServiceBaseParams>(
	'excessRate/getAll',
	excessRateGetAllService,
);
