import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaPlus, FaTruck } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

import SaleSupplierModify from './pages/SaleSupplierModify';

const SaleSupplier = lazyWithRetry(() => import('./pages/SaleSupplier'));
const SaleSupplierNew = lazyWithRetry(() => import('./pages/SaleSupplierNew'));

export const saleSupplierRoutes: { [key: number]: IRouteConfigType } = {};

saleSupplierRoutes[routesIdMap.SaleSupplier] = {
	component: SaleSupplier,
	exact: true,
	id: routesIdMap.SaleSupplier,
	name: 'Sale Supplier',
	path: '/fleet/sale-supplier',
	permissions: [PermissionsType.Sale],
	icon: FaTruck,
	menuText: 'Supplier',
	childrens: [routesIdMap.SaleSupplierNew, routesIdMap.SaleSupplierModify],
};

saleSupplierRoutes[routesIdMap.SaleSupplierNew] = {
	component: SaleSupplierNew,
	exact: true,
	id: routesIdMap.SaleSupplierNew,
	name: 'Sale Supplier New',
	path: '/fleet/sale-supplier/new',
	permissions: [PermissionsType.Sale],
	icon: FaPlus,
};

saleSupplierRoutes[routesIdMap.SaleSupplierModify] = {
	component: SaleSupplierModify,
	exact: true,
	id: routesIdMap.SaleSupplierModify,
	name: 'Sale Supplier Modify',
	path: '/fleet/sale-supplier/:id',
	permissions: [PermissionsType.Sale],
	icon: FaPlus,
};
