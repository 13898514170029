import { createAction } from '@reduxjs/toolkit';

import { createAsyncAction } from '@crac/core/modules/shared/state/createAsyncAction';
import type { IVehicle } from '@crac/core/modules/vehicle/entities/Vehicle';

import type { IVehicleSearchParams } from '../../../../../models/serviceParams/VehicleParams';
import { vehicleSearchService } from '../../services/VehicleSearchService';

/**
 * Searches for vehicles based on specified parameters.
 *
 * @param {IVehicle[]} payload - The vehicles to be retrieved.
 * @param {IVehicleSearchParams} params - Parameters for the search of vehicles.
 * @returns {Promise<void>} - A promise that resolves to an array of vehicles.
 */
export const vehicleSearch = createAsyncAction<IVehicle[], IVehicleSearchParams>(
	'vehicle/search',
	vehicleSearchService,
);

/**
 * Clears the vehicle search state.
 */
export const vehicleSearchClear = createAction('vehicle/searchClear');

/**
 * Clears the vehicle state.
 */
export const vehicleClear = createAction('vehicle/clear');
