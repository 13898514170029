import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { FaArrowRightArrowLeft, FaPencil, FaPlus } from '@crac/ui/icons';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';
import type { IRouteConfigType } from '~/features/shared/types/RouteConfigType';

const TransferSearch = lazyWithRetry(() => import('./pages/TransferSearch'));
const TransferNew = lazyWithRetry(() => import('./pages/TransferNew'));
const TransferModify = lazyWithRetry(() => import('./pages/TransferModify'));
const TransferModifyAddVehicles = lazyWithRetry(() => import('./pages/TransferModifyAddVehicles'));

export const transferRoutes: { [key: number]: IRouteConfigType } = {};

transferRoutes[routesIdMap.FleetTransfer] = {
	component: TransferSearch,
	exact: true,
	id: routesIdMap.FleetTransfer,
	name: 'Transfer',
	path: '/fleet/transfer',
	permissions: [PermissionsType.FleetTransfer],
	childrens: [routesIdMap.FleetTransferModify],
	icon: FaArrowRightArrowLeft,
	menuText: 'Transfer',
};

transferRoutes[routesIdMap.FleetTransferNew] = {
	component: TransferNew,
	exact: true,
	id: routesIdMap.FleetTransferNew,
	name: 'New transfer',
	path: '/fleet/transfer/new',
	permissions: [PermissionsType.FleetTransfer],
	icon: FaPlus,
	menuText: 'New transfer',
};

transferRoutes[routesIdMap.FleetTransferModify] = {
	component: TransferModify,
	exact: true,
	id: routesIdMap.FleetTransferModify,
	name: 'Transfer modify',
	path: '/fleet/transfer/:id',
	isChild: true,
	permissions: [PermissionsType.FleetTransfer],
	icon: FaPencil,
};

transferRoutes[routesIdMap.FleetTransferModifyAddVehicle] = {
	component: TransferModifyAddVehicles,
	exact: true,
	id: routesIdMap.FleetTransferModifyAddVehicle,
	name: 'Transfer modify - add vehicle',
	path: '/fleet/transfer/:code/add-vehicles',
	permissions: [PermissionsType.FleetTransfer],
	icon: FaPlus,
};
