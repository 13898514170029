import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

import { FleetTransferEndPoints } from './FleetTransferEndpoints';
import type { IFleetTransfer } from '../entities/FleetTransfer';

export interface IFleetTransferSearchParams extends IServiceBaseParams {
	code?: string;
	plateNumber?: string;
	vehicleGroup?: string;
	branchFrom?: number;
	branchTo?: number;
	dateFrom?: string;
	dateTo?: string;
	showActive?: number;
}

export const fleetTransferSearchService = (model: IFleetTransferSearchParams) => {
	return Api.get<IFleetTransfer[], IFleetTransferSearchParams>(FleetTransferEndPoints.SEARCH, model);
};
