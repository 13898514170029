import { VehicleGroupEndPoints } from './VehicleGroupEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IVehicleGroup } from '../entities/VehicleGroup';

export interface IVehicleGroupDeleteParams extends IServiceBaseParams {
	provider: string;
	code: string;
}

/**
 * Delete vehicle group
 * @params IVehicleGroupDeleteParams
 * @returns {Object} IVehicleGroup
 */
export const vehicleGroupDeleteService = (model: IVehicleGroupDeleteParams) =>
	Api.post<IVehicleGroup, IVehicleGroupDeleteParams>(VehicleGroupEndPoints.DELETE, model);
