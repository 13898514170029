import { InsuranceRateEndPoints } from './InsuranceRateEndpoints';
import { Api } from '../../../../modules/shared/api';
import type { IServiceBaseParams } from '../../../../modules/shared/types/ServiceBaseParams';
import type { IInsuranceRate } from '../entities/InsuranceRate';

export interface IInsuranceRateSearchParams extends IServiceBaseParams {
	provider: string;
	insuranceVehicleType?: number;
	insuranceProviderCode?: string;
	showActive?: number;
}

/**
 * Search insurance rate
 * @param model - IInsuranceRateSearchParams
 * @returns IInsuranceRate[]
 */
export const insuranceRateSearchService = (model: IInsuranceRateSearchParams) => {
	return Api.get<IInsuranceRate[], IInsuranceRateSearchParams>(InsuranceRateEndPoints.SEARCH, model);
};
